import { IOrganization } from './organization';
import { IPlayerAccount } from './playerAccount';
import { IProfessionalFull, IProfessionalUser, IProfessionalUserFull } from './professional';
import { IProfessionalAccessPeriod } from './professionalAccessPeriod';
import { IProfessionalGroup } from './professionalGroup';
import { IUserRole } from './role';
import { ISubscription } from './subscription';
import { IUser } from './user';

export interface IUnprocessableEntity<T extends string> {
  message: string;
  errors: Record<T, string[]>;
}

export interface IPaginated<D = object> {
  data: D[];
  links: {
    first: string;
    last: string;
    prev: string | null;
    next: string | null;
  };
  meta: {
    current_page: number;
    from: number;
    last_page: number;
    links: {
      url: string | null;
      label: string | number;
      active: boolean;
    }[];
    path: string;
    per_page: number;
    to: number;
    total: number;
  };
}

export const isPaginatedResponse = <D extends any>(data: {} | null): data is IPaginated<D> =>
  data !== null && data.hasOwnProperty('data') && data.hasOwnProperty('meta');

/**
 * A 'professional_user' object has been loaded in a response. This can happen
 * in relations between a 'user' and a 'professional'.
 */
export interface LoadedProfessionalUser {
  professional_user: IProfessionalUser;
}

/**
 * A 'professional_user' object has been loaded in a response. This can happen
 * in relations between a 'user' and a 'professional'.
 */
export interface LoadedProfessionalUserFull {
  professional_user: IProfessionalUserFull;
}

/**
 * Professionals have been included in a response from a User's direction.
 */
export interface LoadedProfessionalsFromUser {
  professionals: (IProfessionalFull & LoadedProfessionalUser)[];
}

export interface LoadedProfessionals {
  professionals: IProfessionalFull[];
}

export interface LoadedProfessionalsCount {
  professionals_count: number;
}

/**
 * ProfessionalGroupUsers have been included in a response from a ProfessionalGroup's direction.
 */
export interface LoadedProfessionalGroupUsersCount {
  professional_group_users_count: number;
}

/**
 * A professional has been loaded in a response. This can happen on entities
 * that have an (optionally) associated professional.
 */
export interface LoadedProfessional {
  professional: IProfessionalFull | null;
}

/**
 * A user has been loaded in a response. This can happen on entities
 * that have an (optionally) associated user.
 */
export interface LoadedUser {
  user: IUser | null;
}

/**
 * The amount of related users have been loaded in a response.
 */
export interface LoadedUserCount {
  users_count: number;
}

/**
 * The amount of users in a professional group have been loaded in a response.
 */
export interface LoadedProfessionalGroupUserCount {
  professional_group_users_count: number;
}

/**
 * The organization has been loaded within a response from the API. This is
 * typically on professional-endpoints.
 */
export interface LoadedOrganization {
  organization: IOrganization | null;
}

/**
 * The relation between a user and a role has been loaded within a response
 * from the API.
 */
export interface LoadedUserRole {
  user_role: IUserRole;
}

/**
 * Access periods were loaded with the response.
 */
export interface LoadedAccessPeriods {
  access_periods: IProfessionalAccessPeriod[];
}

/**
 * Professional groups were loaded with the response.
 */
export interface LoadedGroups {
  groups: IProfessionalGroup[];
}

/**
 * The professional user's player account has been loaded with the response.
 */
export interface LoadedPlayerAccount {
  player_account: IPlayerAccount | null;
}

export interface LoadedSubscription {
  subscription: ISubscription | null;
}
