import React from 'react';

import { LinearProgress, Divider } from '@material-ui/core';

interface IProps {
  inProgress: boolean;
}

const ProgressDivider: React.FC<IProps> = ({ inProgress }) =>
  inProgress ? <LinearProgress style={{ height: 1 }} /> : <Divider />;

export default ProgressDivider;
