import { GridColDef } from '@material-ui/data-grid';
import { Link } from 'react-router-dom';

import { LoadedProfessional } from 'core/backend';
import { IPlayerAccount } from 'core/playerAccount';
import { routeCreator } from 'services/routing';
import { GridColumn, renderDateCell } from 'utils/mui';

/**
 * The type of data in each table row.
 */
export type UserPlayerAccountsTableDataRow = IPlayerAccount & Partial<LoadedProfessional>;

/**
 * @param {GridCellParams} params
 *   Grid cell parameters.
 */
const renderIdCell: GridColDef['renderCell'] = (params) => {
  const row = params.row as UserPlayerAccountsTableDataRow;

  return (
    <Link
      to={{
        pathname: routeCreator.PlayerAccountDetail(row.id),
        state: row,
      }}
    >
      {row.id}
    </Link>
  );
};

/**
 * @param {GridCellParams} params
 *   Grid cell parameters.
 */
const renderProfessionalCell: GridColDef['renderCell'] = (params) => {
  const row = params.row as UserPlayerAccountsTableDataRow;

  if (row.professional === null) {
    return <>-</>;
  }

  return (
    <Link
      to={{
        pathname: routeCreator.ProfessionalDetail(row.professional.id),
        state: row.professional,
      }}
    >
      {row.professional.name}
    </Link>
  );
};

export const userPlayerAccountsTable: GridColumn<UserPlayerAccountsTableDataRow>[] = [
  {
    field: 'id',
    headerName: 'Id',
    flex: 0.5,
    renderCell: renderIdCell,
    sortable: false,
    filterable: false,
  },
  {
    field: 'professional',
    headerName: 'Practice',
    flex: 1,
    renderCell: renderProfessionalCell,
    sortable: false,
    filterable: false,
  },
  {
    field: 'brain_level',
    headerName: 'Brain level',
    flex: 0.75,
    sortable: false,
    filterable: false,
  },
  {
    field: 'nerve_level',
    headerName: 'Nerve level',
    flex: 0.75,
    sortable: false,
    filterable: false,
  },
  {
    field: 'chapters_played',
    headerName: 'Chapters played',
    flex: 0.75,
    sortable: false,
    filterable: false,
  },
  {
    field: 'last_played',
    headerName: 'Last played',
    flex: 0.75,
    renderCell: renderDateCell(),
    sortable: false,
    filterable: false,
  },
];

export const userPlayerAccountsTableWithoutProfessional = userPlayerAccountsTable.filter(
  (column) => column.field !== 'professional',
);
