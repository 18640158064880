import React from 'react';

import axios, {
  AxiosRequestConfig,
  AxiosResponse,
  CancelTokenSource,
  AxiosError,
  AxiosPromise,
  AxiosInstance,
} from 'axios';

export type RequestState = 'idle' | 'loading' | 'success' | 'failed';

export const RequestContext = React.createContext<AxiosInstance | null>(null);

/**
 * The shape of the state which is used by the reducer.
 *
 * @template D The data to be received from the response.
 * @template E The data of the error to be received from a bad request.
 * @template P The payload data that should be sent.
 */
export interface RequestReducerState<D = unknown, E = unknown, P = unknown> {
  requestState: RequestState;
  config: EnhancedAxiosRequestConfig<P>;
  response?: AxiosResponse<D>;
  error?: AxiosError<E>;
}

/**
 * Each dispatched action to the reducer expects the type to be of a
 * request status, and expects the payload to be a partial of the
 * state's shape.
 */
interface RequestReducerAction {
  type: RequestState;
  payload: Partial<RequestReducerState<unknown>>;
}

export type RequestReducer<D, E, P> = React.Reducer<
  RequestReducerState<D, E, P>,
  RequestReducerAction
>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const requestReducer: RequestReducer<any, any, any> = (state, action) => ({
  ...state,
  requestState: action.type,
  ...action.payload,
});

/**
 * Enhanced axios request configuration to enforce a specific type as data.
 */
export interface EnhancedAxiosRequestConfig<P = undefined>
  extends Exclude<AxiosRequestConfig, 'data'> {
  data?: P;
}

/**
 * The callback that components can use for performing requests.
 *
 * @template D The data to be received from the response.
 * @template P The payload data that should be sent.
 *
 * @param {EnhancedAxiosRequestConfig<P>} config
 *   Request configuration, this overrides the default configuration of the
 *   hook. This can be used to add payload data to a request such as form-data.
 *
 * @return {AxiosPromise<D>}
 *   Returns an axios promise that can be used when you wish to chain this promise.
 */
export type UseRequestCallback<D, P> = (config?: EnhancedAxiosRequestConfig<P>) => AxiosPromise<D>;

/**
 * The returned value of the useRequest hook.
 *
 * @template D The data to be received from the response.
 * @template E The data of the error to be received from a bad request.
 * @template P The payload data that should be sent.
 */
export interface UseRequestHook<D, E, P> {
  /**
   * Request callback function to perform the request.
   */
  request: UseRequestCallback<D, P>;

  /**
   * The data which was received by the requested source. This is equal
   * to the response.data value. Initially this will be null if the request
   * is idle or loading.
   */
  data: D | null;

  /**
   * The error which was received by the API. Note that this can be
   * an 'Error' if something went wrong outside of Axios.
   */
  error?: AxiosError<E>;

  /**
   * The axios response after a request was successful.
   */
  response?: AxiosResponse<D>;

  /**
   * The current request state.
   */
  state: RequestState;

  /**
   * Convenience property for checking if the request state is idle.
   */
  idle: boolean;

  /**
   * Convenience property for checking if the request state is loading.
   */
  loading: boolean;

  /**
   * Convenience property for checking if the request state is successful.
   */
  success: boolean;

  /**
   * Convenience property for checking if the request state is failed.
   */
  failed: boolean;

  /**
   * Function for manually cancelling the request.
   */
  cancel(): void;
}

/**
 * Specific options for the useRequest hook.
 */
export interface UseRequestOptions<D> {
  /**
   * The default value to use when a request has not finished yet. This can
   * be useful when fetching arrays and you directly want to work with the
   * response data.
   */
  defaultValue: D | null;
}

/**
 * Hook for performing http requests. This hook returns a request object
 * that is used to perform http requests, and holds state of the request.
 *
 * @template D The data to be received from the response.
 * @template E The data of the error to be received from a bad request.
 * @template P The payload data that should be sent.
 *
 * @return {UseRequestHook<D, E, P>}
 */
const useRequest = <D, E = never, P = never>(
  defaultConfig: AxiosRequestConfig,
  hookOptions?: Partial<UseRequestOptions<D>>,
): UseRequestHook<D, E, P> => {
  // The token to use for cancelling requests when the component unmounts.
  // const cancelSourceRef = React.useRef<CancelTokenSource | null>(null);
  const cancelSourceRefs = React.useRef<Set<CancelTokenSource>>(new Set());
  const httpClientInstance = React.useContext(RequestContext);

  if (httpClientInstance === null) {
    throw new Error(
      'No http client for the useRequest hook. Did you forget the RequestContext provider?',
    );
  }

  const options: UseRequestOptions<D> = {
    defaultValue: null,
    ...hookOptions,
  };

  const [state, dispatch] = React.useReducer<RequestReducer<D, E, P>>(requestReducer, {
    requestState: 'idle',
    config: defaultConfig,
  });

  /**
   * The callback method to perform requests.
   *
   * This method accepts configuration to override the default configuration
   * that was given when the useRequest hook was instantiated. This can for
   * example be used to add form-data to requests.
   *
   * @param {EnhancedAxiosRequestConfig<P>} config
   *   Optional configuration to override the default configuration.
   *
   * @throws {AxiosError|Error}
   *   Can throw an exception if it was a bad request, or if the request
   *   was cancelled due to the component unmounting.
   *
   * @return {AxiosPromise<D>}
   *   Returns an axios promise so that this method can be chained in an
   *   async function.
   */
  const doRequest: UseRequestCallback<D, P> = async (config = {}) => {
    cancel();

    const mergedConfig = Object.assign({}, defaultConfig, config);
    const tokenSource = axios.CancelToken.source();

    cancelSourceRefs.current.add(tokenSource);

    try {
      dispatch({ type: 'loading', payload: { config: mergedConfig, error: undefined } });

      const response = await httpClientInstance.request<D>({
        ...mergedConfig,
        cancelToken: tokenSource.token,
      });

      dispatch({ type: 'success', payload: { response, error: undefined } });

      return response;
    } catch (error) {
      dispatch({ type: 'failed', payload: { error } });

      throw error;
    } finally {
      cancelSourceRefs.current.delete(tokenSource);
    }
  };

  /**
   * Canceler for the request. This only cancels the request if there is
   * a cancellation token available.
   *
   * @return {void}
   */
  const cancel = (): void => {
    cancelSourceRefs.current.forEach((token) => {
      token.cancel('Cancelled request');
    });

    cancelSourceRefs.current.clear();
  };

  React.useEffect(() => {
    // The request will be cancelled if the component that makes use of
    // this hook is unmounted.
    return cancel;
  }, []);

  // Check if the request was cancelled.
  const cancelled =
    state.requestState === 'failed' &&
    typeof state.error !== 'undefined' &&
    axios.isCancel(state.error);

  const requestActive = cancelSourceRefs.current.size >= 1;

  // There can be multiple requests happening at once. If this happens, override
  // the state so a loading state will be returned.
  const overrideLoading = cancelled && requestActive;

  return {
    request: doRequest,
    data: state.response?.data ?? options.defaultValue,
    error: overrideLoading ? undefined : state.error,
    response: state.response,
    state: overrideLoading ? 'loading' : state.requestState,
    idle: state.requestState === 'idle',
    loading: state.requestState === 'loading' || overrideLoading,
    success: state.requestState === 'success',
    failed: overrideLoading ? false : state.requestState === 'failed',
    cancel,
  };
};

export default useRequest;
