import React from 'react';

import { Button, ListItem, ListItemProps, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { Icon } from 'react-feather';
import { NavLink as RouterLink } from 'react-router-dom';

import Localized from 'components/Localized';
import { ITranslatable } from 'core/core';

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '10px 8px',
    textTransform: 'none',
    width: '100%',
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  title: {
    marginRight: 'auto',
  },
  active: {
    color: theme.palette.primary.main,
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium,
    },
    '& $icon': {
      color: theme.palette.primary.main,
    },
  },
}));

interface IProps extends ITranslatable {
  href: string;
  icon?: Icon;
  title: string;
}

type Props = IProps & ListItemProps & { button?: false };

const NavItem: React.FC<Props> = ({
  className,
  href,
  icon: IconComponent,
  title,
  translationKey,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <ListItem className={clsx(classes.item, className)} disableGutters {...rest}>
      <Button
        activeClassName={classes.active}
        className={classes.button}
        component={RouterLink}
        to={href}
      >
        {IconComponent && <IconComponent className={classes.icon} size='20' />}
        <span className={classes.title}>
          <Localized id={translationKey}>{title}</Localized>
        </span>
      </Button>
    </ListItem>
  );
};
export default NavItem;
