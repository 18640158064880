import { MenuItem } from '@material-ui/core';
import { FieldError } from 'react-hook-form';

import TextField from 'components/mui/TextField';
import { IUser } from 'core/user';
import { useReduceptForm } from 'hooks/useReduceptForm';
import { AvailableLanguages, useLocalization } from 'services/localization/localization';
import { emailValidationPattern } from 'utils/forms';

import { FormComponent } from '..';
import BaseForm from '../BaseForm';
import Select from '../elements/Select';

export type UserFormValues = Pick<
  IUser,
  'first_name' | 'middle_name' | 'last_name' | 'email' | 'language'
>;

type Props = {
  isEditForm?: true;
};

const UserForm: FormComponent<UserFormValues, Props> = ({ onSubmit, isEditForm, ...props }) => {
  const defaultValues: Partial<UserFormValues> = {
    language: 'nl-NL',
    ...props.defaultValues,
  };

  const form = useReduceptForm<UserFormValues>(onSubmit, { defaultValues });
  const {
    register,
    errors,
    control,
    formState: { touched, submitCount },
  } = form;

  const { getLocalizedString } = useLocalization();

  return (
    <BaseForm form={form}>
      <TextField
        error={Boolean(touched.first_name && errors.first_name)}
        fullWidth
        helperText={touched.first_name && errors.first_name?.message}
        label='First name'
        margin='normal'
        translationKey='first_name'
        inputRef={register({ required: getLocalizedString('required') })}
        type='text'
        variant='outlined'
        required
      />
      <TextField
        error={Boolean(touched.middle_name && errors.middle_name)}
        fullWidth
        helperText={touched.middle_name && errors.middle_name?.message}
        label='Middle name'
        margin='normal'
        translationKey='middle_name'
        inputRef={register()}
        type='text'
        variant='outlined'
      />
      <TextField
        error={Boolean(touched.last_name && errors.last_name)}
        fullWidth
        helperText={touched.last_name && errors.last_name?.message}
        label='Last name'
        margin='normal'
        translationKey='last_name'
        inputRef={register({ required: getLocalizedString('required') })}
        type='text'
        variant='outlined'
        required
      />
      <TextField
        error={Boolean((touched.email || submitCount > 0) && errors.email)}
        fullWidth
        helperText={(touched.email || submitCount > 0) && (errors.email as FieldError)?.message}
        label='E-mail'
        margin='normal'
        translationKey='email'
        inputRef={register({
          pattern: emailValidationPattern(getLocalizedString),
          required: true,
        })}
        InputProps={{
          readOnly: isEditForm,
        }}
        type='email'
        variant={isEditForm ? 'filled' : 'outlined'}
        required
      />
      <Select
        label='Language'
        controllerProps={{
          control,
          name: 'language',
          rules: {
            required: getLocalizedString('required'),
          },
        }}
        variant='outlined'
        fullWidth
        margin='normal'
        required
      >
        {Object.entries(AvailableLanguages).map(([key, value]) => (
          <MenuItem selected value={key}>
            {value}
          </MenuItem>
        ))}
      </Select>
    </BaseForm>
  );
};

export default UserForm;
