import React from 'react';

import { Box, InputAdornment, makeStyles, SvgIcon } from '@material-ui/core';
import {
  GridFilterToolbarButton,
  GridToolbarContainerProps,
  useGridSlotComponentProps,
} from '@material-ui/data-grid';
import { Search as SearchIcon } from 'react-feather';
import { useDebounce } from 'react-use';

import useInput from 'hooks/useInput';

import TextField from '../TextField';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    paddingBottom: 0,
  },
}));

interface IProps {
  searchPlaceholder?: string;
  searchColumnField?: string;
}

type Props = IProps & GridToolbarContainerProps;

const DataGridToolbar: React.FC<Props> = (props) => {
  const { apiRef } = useGridSlotComponentProps();
  const [search, setSearch] = useInput<HTMLInputElement>();
  const classes = useStyles();

  useDebounce(
    () => {
      apiRef.current.setFilterModel({
        items: [
          {
            columnField: props.searchColumnField ?? 'search',
            value: search,
          },
        ],
      });
    },
    500,
    [search],
  );

  return (
    <Box display='flex' className={classes.root}>
      <Box pr={1} maxWidth={500}>
        <TextField
          translationKey='search'
          value={search}
          onChange={setSearch}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <SvgIcon fontSize='small' color='action'>
                  <SearchIcon />
                </SvgIcon>
              </InputAdornment>
            ),
          }}
          placeholder={props.searchPlaceholder ?? 'Search'}
          variant='outlined'
        />
      </Box>
      <GridFilterToolbarButton />
    </Box>
  );
};

export default DataGridToolbar;
