import { IProfessionalFull, hasUnlimitedLicenses } from 'core/professional';
import useFetch from './useFetch';

const useProfessionalLicenses = (professionalId: number | string) => {
  const request = useFetch<IProfessionalFull | null>(`/professionals/${professionalId}`);

  const professional = request.data;

  const licensesLeft = professional?.licenses_available_in_current_period ?? 0;
  const unlimitedLicenses = hasUnlimitedLicenses(professional);
  const noLicensesLeft = !unlimitedLicenses && licensesLeft <= 0;

  return { licensesLeft, unlimitedLicenses, noLicensesLeft, professional, request };
};

export default useProfessionalLicenses;
