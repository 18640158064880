import React from 'react';
import { Box, Card, colors, Container, makeStyles, Typography } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import { useSearchParam } from 'react-use';
import Button from 'components/Button';
import LinkButton from 'components/LinkButton';
import Localized from 'components/Localized';
import Page from 'components/Page';
import { ProfessionalUserRoleType, SimpleProfessionalInvite } from 'core/professional';
import useFetch from 'hooks/useFetch';
import useRequest from 'hooks/useRequest';
import { useLocalization } from 'services/localization/localization';
import { routes } from 'services/routing';
import { loggedInSelector } from 'store/session/sessionSelectors';
import { useTypedSelector } from 'store/store';
import { darkBackground } from 'theme/theme';
import { isAxiosError } from 'utils/guard';
import ErrorPage from './ErrorPage';
import LoadingPage from './LoadingPage';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: darkBackground,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  card: {
    padding: theme.spacing(3),
    borderWidth: 8,
    borderColor: colors.common.white,
    hoverBorderColor: colors.common.white,
  },
}));

interface IFormValues {
  token: string;
}

type ErrorResponse = {
  message: string;
};

const ProfessionalInviteConfirm: React.VFC = () => {
  const token = useSearchParam('token');
  const classes = useStyles();
  const loggedIn = useTypedSelector(loggedInSelector);
  const [inviteAccepted, setInviteAccepted] = React.useState<boolean>(false);

  const tokenInfoRequest = useFetch<SimpleProfessionalInvite>(`professional-invites/${token}`);
  const postRequest = useRequest<unknown, ErrorResponse, IFormValues>({
    method: 'POST',
    url: '/professional-invites/confirm',
  });

  const { getLocalizedString } = useLocalization();

  if (loggedIn) {
    return <Redirect to={routes.Dashboard} />;
  }

  const handleSubmit = async () => {
    try {
      await postRequest.request({ data: { token: token! } });
      setInviteAccepted(true);
    } catch (error) {
      if (isAxiosError<ErrorResponse>(error)) {
        const { message } = error.response!.data;

        if (typeof message === 'string') {
          alert(message);
        }
      }
    }
  };

  if (tokenInfoRequest.loading && !tokenInfoRequest.data) {
    return <LoadingPage />;
  }

  if (!tokenInfoRequest.data) {
    return (
      <ErrorPage
        message={getLocalizedString(
          'invalid_token_part_1',
          undefined,
          'Invalid confirmation token',
        )}
      />
    );
  }

  let inner: React.ReactNode = null;

  if (token === null) {
    inner = (
      <>
        <Localized id='invalid_token_part_1'>
          <Typography variant='h4' align='center' gutterBottom>
            Invalid confirmation token.
          </Typography>
        </Localized>
        <Localized id='invalid_token_part_2'>
          <Typography variant='h4' align='center' gutterBottom>
            If you got here by clicking on the link in your email, please contact support.
          </Typography>
        </Localized>
      </>
    );
  } else if (inviteAccepted) {
    inner = (
      <>
        <Localized id='professional-invite__accepted-part-1'>
          <Typography variant='h4' align='center' gutterBottom>
            Invite has been accepted.
          </Typography>
        </Localized>
        {[ProfessionalUserRoleType.Owner, ProfessionalUserRoleType.Therapist].includes(
          tokenInfoRequest.data.role as ProfessionalUserRoleType,
        ) ? (
          <LinkButton
            translationKey='back-to-sign-in'
            to={routes.Login}
            color='secondary'
            size='small'
            type='button'
            variant='text'
          >
            {getLocalizedString('account_confirmed_back_to_sign_in', null)}
          </LinkButton>
        ) : (
          <Localized id='professional-invite__accepted-part-2'>
            <Typography variant='h4' align='center' gutterBottom>
              You can now close this window.
            </Typography>
          </Localized>
        )}
      </>
    );
  } else {
    inner = (
      <>
        <Localized
          id='professional-invite__question'
          vars={{
            professional_name: tokenInfoRequest.data?.professional_name ?? '',
            role: getLocalizedString(tokenInfoRequest.data?.role ?? 'unknown_role'),
          }}
          elems={{ bold_text: <b></b> }}
        >
          <Typography align='center' gutterBottom>
            You are invited by <b>{tokenInfoRequest.data?.professional_name ?? ''}</b> to become a
            <b>{getLocalizedString(tokenInfoRequest.data?.role ?? 'unknown')}</b> at their practice.
            Do you want to accept the invite?
          </Typography>
        </Localized>

        <Button
          translationKey='accept'
          color='primary'
          loading={postRequest.loading}
          disabled={postRequest.loading}
          fullWidth
          size='large'
          type='button'
          variant='contained'
          onClick={handleSubmit}
          style={{ marginTop: 10 }}
        >
          Accept
        </Button>
      </>
    );
  }

  return (
    <Page className={classes.root} title='Professional invite'>
      <Box display='flex' flexDirection='column' height='100%' justifyContent='center'>
        <Container maxWidth='sm'>
          <Card className={classes.card}>
            <Box p={2} display='flex' flexDirection='column' alignItems='center'>
              {inner}
            </Box>
          </Card>
        </Container>
      </Box>
    </Page>
  );
};

export default ProfessionalInviteConfirm;
