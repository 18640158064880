import React from 'react';

import { Tooltip } from '@material-ui/core';
import { GridColDef } from '@material-ui/data-grid';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';

import DataGridCheckCell, {
  renderDataGridCheckCell,
} from 'components/mui/DataGrid/DataGridCheckCell';
import { LoadedProfessionalsFromUser } from 'core/backend';
import { IProfessionalFull, ProfessionalUserRoleType } from 'core/professional';
import { isPlaceholderEmail, IUser } from 'core/user';
import { routeCreator } from 'services/routing';
import { createDateTooltipTitle, GridColumn, renderDateCell } from 'utils/mui';

/**
 * The type of data in each table row.
 */
export type UserTableRowData = IUser & LoadedProfessionalsFromUser;

/**
 * Maps an array of professionals to a single string containing the names.
 *
 * @param {IProfessionalFull[]} professionals
 *   Array of professionals
 *
 * @returns {string}
 *   String containing joined professional names separated by an ampersand:
 *    - "Professional 1 & Professional 2"
 *    - "Professional 5"
 */
const professionalsToNames = (professionals: IProfessionalFull[]): string =>
  professionals
    .map((professional) => `'${professional.name}'`)
    .join(' & ')
    .trimEnd();

/**
 * Cell renderer for an 'email verified' field. This returns a check or cross
 * to indicate if the user is verified or not.
 *
 * @param {GridCellParams} params
 *   Grid cell parameters.
 */
const emailVerifiedCell: GridColDef['renderCell'] = (params) => {
  const verified = params.value !== null;
  const inner = <DataGridCheckCell params={{ ...params, value: verified }} />;

  if (!verified) {
    return inner;
  }

  return (
    <Tooltip title={createDateTooltipTitle(params.value)}>
      <span style={{ display: 'flex', alignItems: 'center' }}>{inner}</span>
    </Tooltip>
  );
};

/**
 * Cell renderer to check if this user is a patient at any professional.
 *
 * @param {GridCellParams} params
 *   Grid cell parameters.
 */
const isPatientCell: GridColDef['renderCell'] = (params) => {
  const row = params.row as UserTableRowData;

  const matches = row.professionals.filter((professional) => {
    return professional.professional_user.role === 'patient';
  });

  const inner = <DataGridCheckCell params={{ ...params, value: matches.length > 0 }} />;

  if (matches.length === 0) {
    return inner;
  }

  // If this user is a patient at one professional, wrap the returned element
  // with a link that takes you directly to the professionals detail page.
  if (matches.length === 1) {
    return (
      <Tooltip title={`Patient at '${matches[0].name}'`}>
        <Link
          style={{ display: 'flex', alignItems: 'center' }}
          to={routeCreator.ProfessionalDetail(matches[0].id)}
        >
          {inner}
        </Link>
      </Tooltip>
    );
  }

  // If this user is a patient at multiple professionals (unlikely), return
  // an array of names in the tooltip.
  const professionalNames = professionalsToNames(matches);

  return (
    <Tooltip title={`Patient at ${professionalNames}`}>
      <span style={{ display: 'flex', alignItems: 'center' }}>{inner}</span>
    </Tooltip>
  );
};

const renderNameCell: GridColDef['renderCell'] = (params) => {
  return (
    <Link
      to={{
        pathname: routeCreator.UserDetail(params.row.id),
        state: params.row,
      }}
    >
      {params.value}
    </Link>
  );
};

const renderEmailCell: GridColDef['renderCell'] = (params) => {
  const row = params.row as UserTableRowData;
  let inner = params.value;

  if (isPlaceholderEmail(row.email)) {
    inner = <span style={{ opacity: 0.2 }}>No e-mail address set</span>;
  }

  return (
    <Link
      to={{
        pathname: routeCreator.UserDetail(params.row.id),
        state: params.row,
      }}
    >
      {inner}
    </Link>
  );
};

/**
 * Cell renderer to check if this user is staff for one or more professionals
 *
 * @param {GridCellParams} params
 *   Grid cell parameters.
 */
const isStaffCell: GridColDef['renderCell'] = (params) => {
  const row = params.row as UserTableRowData;

  const therapists: IProfessionalFull[] = [];

  // Split up the roles of this user at their associated professionals.
  for (const professional of row.professionals) {
    if (
      professional.professional_user.role === ProfessionalUserRoleType.Therapist ||
      professional.professional_user.role === ProfessionalUserRoleType.Owner
    ) {
      therapists.push(professional);
    }
  }

  const matches = therapists.length > 0;

  let title = '';

  if (therapists.length) {
    title += `Therapist at ${professionalsToNames(therapists)}`;
  }

  const inner = (
    <Tooltip title={title}>
      <span style={{ display: 'flex', alignItems: 'center' }}>
        <DataGridCheckCell params={{ ...params, value: matches }} />
      </span>
    </Tooltip>
  );

  // If this user is staff at one professional, wrap the tooltip with a link
  // directly to the associated professional's detail page.
  if (therapists.length === 1) {
    const professional = therapists[0];

    return <Link to={routeCreator.ProfessionalDetail(professional.id)}>{inner}</Link>;
  }

  return inner;
};

export const userTableColumns: GridColumn<UserTableRowData>[] = [
  {
    field: 'name',
    headerName: 'Full name',
    sortable: false,
    flex: 0.75,
    hide: true,
    renderCell: renderNameCell,
  },
  {
    field: 'first_name',
    headerName: 'First name',
    flex: 0.5,
    renderCell: renderNameCell,
  },
  {
    field: 'last_name',
    headerName: 'Last name',
    flex: 0.5,
    renderCell: renderNameCell,
  },
  {
    field: 'email',
    headerName: 'E-mail',
    flex: 1,
    renderCell: renderEmailCell,
  },
  { field: 'language', headerName: 'Language', flex: 0.25 },
  {
    field: 'unclaimed',
    headerName: 'Unclaimed',
    flex: 0.25,
    description:
      'This user is a patient without an own Reducept account. It is owned by the professional.',
    renderCell: renderDataGridCheckCell,
    filterable: false,
    sortable: false,
  },
  {
    field: 'patient',
    headerName: 'Patient',
    description: 'This user is a patient',
    flex: 0.25,
    sortable: false,
    filterable: false,
    renderCell: isPatientCell,
  },
  {
    field: 'staff',
    headerName: 'Staff',
    description: 'This user is a staff member',
    flex: 0.25,
    sortable: false,
    filterable: false,
    renderCell: isStaffCell,
  },
  {
    field: 'email_verified_at',
    headerName: 'Verified',
    description: 'This user verified their e-mail address',
    flex: 0.25,
    renderCell: emailVerifiedCell,
    filterable: false,
  },
  {
    field: 'last_activity_at',
    headerName: 'Last activity',
    flex: 0.75,
    renderCell: renderDateCell(DateTime.DATETIME_SHORT),
    filterable: false,
  },
];
