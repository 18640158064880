import React from 'react';

import { SubmitHandler } from 'react-hook-form';
import { RouteComponentProps } from 'react-router-dom';

import DetailPage from 'components/DetailPage';
import DeviceForm, { DeviceFormValues } from 'components/forms/devices/DeviceForm';
import { IDevice } from 'core/device';
import useRequest from 'hooks/useRequest';
import useStateOrFetch from 'hooks/useStateOrFetch';
import { useLocalization } from 'services/localization/localization';
import { DeviceRouteParams, routeCreator } from 'services/routing';

import ErrorPage from 'pages/ErrorPage';
import LoadingPage from 'pages/LoadingPage';

type FetchResponse = IDevice;
type Props = RouteComponentProps<DeviceRouteParams, any, FetchResponse | undefined>;

const EditDevice: React.FC<Props> = (props) => {
  const { deviceId } = props.match.params;

  const request = useStateOrFetch<FetchResponse | null>(
    props.location.state,
    `/devices/${deviceId}`,
    { includes: ['user', 'professional', 'playerAccount'] },
  );

  const putRequest = useRequest<FetchResponse, never, DeviceFormValues>({
    url: `/devices/${deviceId}`,
    method: 'PUT',
  });

  const { getLocalizedString } = useLocalization();

  if (request.loading && !request.data) {
    return <LoadingPage />;
  }
  if (!request.data) {
    return <ErrorPage />;
  }

  const device = request.data as FetchResponse;

  const handleSubmit: SubmitHandler<DeviceFormValues> = async (values) => {
    await putRequest.request({
      data: values,
    });

    props.history.push(routeCreator.DeviceDetail(deviceId));
  };

  const pageTitle = getLocalizedString('update-model', {
    model: getLocalizedString('device').toLowerCase(),
  });

  return (
    <DetailPage title={pageTitle} subtitle={device.name} maxWidth='xs'>
      <DeviceForm onSubmit={handleSubmit} defaultValues={device} />
    </DetailPage>
  );
};

export default EditDevice;
