import React from 'react';
import { MenuItem } from '@material-ui/core';
import { SubmitHandler } from 'react-hook-form';
import { RouteComponentProps } from 'react-router-dom';
import DetailPage from 'components/DetailPage';
import {
  BaseProfessionalInviteFormValues,
  IBaseProfessionalInviteFormProps,
} from 'components/forms/BaseProfessionalInviteForm';
import BaseProfessionalInviteForm from 'components/forms/BaseProfessionalInviteForm';
import Select from 'components/forms/elements/Select';
import Localized from 'components/Localized';
import { ProfessionalUserRoleType } from 'core/professional';
import { IUser } from 'core/user';
import useRequest from 'hooks/useRequest';
import { useLocalization } from 'services/localization/localization';
import { ProfessionalRouteParams, routeCreator } from 'services/routing';
import { isAdminSelector, isOwnerSelector } from 'store/session/sessionSelectors';
import { useTypedSelector } from 'store/store';

type PostInviteStaffRequestValues = BaseProfessionalInviteFormValues & {
  role: ProfessionalUserRoleType.Therapist | ProfessionalUserRoleType.Owner;
};

type Props = RouteComponentProps<ProfessionalRouteParams>;

const InviteStaff: React.FC<Props> = (props) => {
  const { professionalId } = props.match.params;
  const isAdmin = useTypedSelector(isAdminSelector);
  const isPracticeOwner = useTypedSelector(isOwnerSelector);

  const postRequest = useRequest<IUser, never, PostInviteStaffRequestValues>({
    method: 'POST',
    url: `/professionals/${professionalId}/professional-invites`,
  });

  const { getLocalizedString } = useLocalization();

  const handleSubmit: SubmitHandler<PostInviteStaffRequestValues> = async (values) => {
    await postRequest.request({ data: { ...values } });

    // Send admins back to where they came from. Other users go to the invites overview
    if (props.history.action !== 'POP' && isAdmin) {
      props.history.goBack();
    } else {
      props.history.push(routeCreator.ProfessionalProfessionalInvites(professionalId));
    }
  };

  const allowedRoles = [ProfessionalUserRoleType.Therapist];
  if (isPracticeOwner || isAdmin) {
    allowedRoles.push(ProfessionalUserRoleType.Owner);
  }

  const renderInner: IBaseProfessionalInviteFormProps<PostInviteStaffRequestValues>['children'] = (
    props,
  ) => {
    return (
      <Select
        label='Role'
        controllerProps={{
          control: props.control,
          name: 'role',
          rules: { required: getLocalizedString('required') },
          defaultValue: ProfessionalUserRoleType.Therapist,
        }}
        variant='outlined'
        fullWidth
        margin='normal'
        helperText={getLocalizedString('professional-invite__role-description')}
      >
        {allowedRoles.map((role) => (
          <MenuItem key={role} selected value={role}>
            <Localized id={role}>{role}</Localized>
          </MenuItem>
        ))}
      </Select>
    );
  };

  return (
    <DetailPage title={getLocalizedString('professional-invite__page-title')} maxWidth='sm'>
      <BaseProfessionalInviteForm<PostInviteStaffRequestValues> onSubmit={handleSubmit}>
        {renderInner}
      </BaseProfessionalInviteForm>
    </DetailPage>
  );
};

export default InviteStaff;
