import React from 'react';
import { SubmitHandler } from 'react-hook-form';
import { RouteComponentProps } from 'react-router-dom';

import DetailPage from 'components/DetailPage';
import { IProduct } from 'core/product';
import useRequest from 'hooks/useRequest';
import { routeCreator } from 'services/routing';
import ProductForm from './forms/ProductForm';

type FormValues = Pick<
  IProduct,
  'name' | 'description' | 'amount_licenses' | 'interval' | 'internal_name'
>;

const CreateProduct: React.FC<RouteComponentProps> = (props) => {
  const postRequest = useRequest<IProduct, never, FormValues>({
    method: 'POST',
    url: `/products`,
  });

  const handleSubmit: SubmitHandler<FormValues> = async (values) => {
    const response = await postRequest.request({ data: values });
    props.history.push(routeCreator.ProductDetail(response.data.id));
  };

  return (
    <DetailPage title='New product' maxWidth='sm'>
      <ProductForm onSubmit={handleSubmit} />
    </DetailPage>
  );
};

export default CreateProduct;
