import { renderDataGridCheckCell } from 'components/mui/DataGrid/DataGridCheckCell';
import { IProfessionalAccessPeriod } from 'core/professionalAccessPeriod';
import { GridColumn, renderDateCell } from 'utils/mui';

/**
 * The type of data in each table row.
 */
export type UserAccessPeriodsTableRowData = IProfessionalAccessPeriod;

export const userAccessPeriodsTableColumns: GridColumn<UserAccessPeriodsTableRowData>[] = [
  {
    field: 'active',
    headerName: 'Active',
    flex: 1,
    renderCell: renderDataGridCheckCell,
    sortable: false,
    filterable: false,
  },
  {
    field: 'period_start',
    headerName: 'Period start',
    flex: 1,
    renderCell: renderDateCell(),
    sortable: false,
    filterable: false,
  },
  {
    field: 'period_end',
    headerName: 'Period end',
    flex: 1,
    renderCell: renderDateCell(),
    sortable: false,
    filterable: false,
  },
];
