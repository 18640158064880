import React from 'react';

import { Box, Container, makeStyles, Typography } from '@material-ui/core';
import { SubmitHandler } from 'react-hook-form';
import { Redirect, RouteComponentProps } from 'react-router-dom';

import ChangePasswordForm, { ChangePasswordFormValues } from 'components/forms/ChangePasswordForm';
import Localized from 'components/Localized';
import Page from 'components/Page';
import { IChangeUserPasswordPayload } from 'core/user';
import useRequest from 'hooks/useRequest';
import { routes } from 'services/routing';
import { loggedInSelector } from 'store/session/sessionSelectors';
import { useTypedSelector } from 'store/store';
import { darkBackground } from 'theme/theme';
import { isAxiosError } from 'utils/guard';

import AccountConfirmed from './components/AccountConfirmed';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: darkBackground,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

interface IFormValues extends IChangeUserPasswordPayload {
  token: string;
}

type ErrorResponse = {
  message: string;
};

type Props = RouteComponentProps<{ token: string }, any, undefined>;

const PasswordResetConfirm: React.FC<Props> = (props) => {
  const { token } = props.match.params;
  const loggedIn = useTypedSelector(loggedInSelector);
  const classes = useStyles();
  const [emailSent, setEmailSent] = React.useState<boolean>(false);

  const request = useRequest<unknown, ErrorResponse, IFormValues>({
    method: 'POST',
    url: '/user/reset-password/confirm',
  });

  if (loggedIn) {
    return <Redirect to={routes.Dashboard} />;
  }

  const handleSubmit: SubmitHandler<ChangePasswordFormValues> = async (values) => {
    try {
      await request.request({
        data: {
          password: values.password,
          token: token,
        },
      });

      setEmailSent(true);
    } catch (error) {
      if (isAxiosError<ErrorResponse>(error)) {
        const { message } = error.response!.data;

        if (typeof message === 'string') {
          alert(message);
        }
      }
    }
  };

  let inner: React.ReactNode = null;

  if (emailSent) {
    inner = <AccountConfirmed />;
  } else {
    inner = (
      <ChangePasswordForm onSubmit={handleSubmit}>
        <Localized id='reset-password'>
          <Typography color='textPrimary' variant='h2'>
            Reset password
          </Typography>
        </Localized>
        <Localized id='reset-password-message'>
          <Typography color='textSecondary' gutterBottom variant='body2'>
            Enter your new password
          </Typography>
        </Localized>
      </ChangePasswordForm>
    );
  }

  return (
    <Page className={classes.root} title='Forgot password'>
      <Box display='flex' flexDirection='column' height='100%' justifyContent='center'>
        <Container maxWidth='sm'>{inner}</Container>
      </Box>
    </Page>
  );
};

export default PasswordResetConfirm;
