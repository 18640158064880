import React from 'react';

import { Role } from 'services/roles';
import { matchRoles } from 'store/session/sessionSelectors';
import { useTypedSelector } from 'store/store';

interface IProps {
  allowed: Role[];
}

const RoleBoundary: React.FC<IProps> = ({ allowed, children }) => {
  const visible = useTypedSelector(matchRoles(allowed));

  if (!visible) {
    return null;
  }

  return children as React.ReactElement;
};

export default RoleBoundary;
