import { Tooltip, TooltipProps } from '@material-ui/core';
import { GridColDef } from '@material-ui/data-grid';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';

import Localized from 'components/Localized';
import LuxonDate from 'components/LuxonDate';
import DataGridCheckCell from 'components/mui/DataGrid/DataGridCheckCell';
import { Patient } from 'core/professionalUser';
import { isPlaceholderEmail, IUser } from 'core/user';
import { routeCreator } from 'services/routing';
import { GridColumn, DateCell } from 'utils/mui';
import { sortRows } from 'utils/sorting';
import { stringToFormattedDate } from 'utils/utils';
import { userTableColumns } from './usersTable';

/**
 * The type of data in each table row.
 */
export type PatientsTableRowData = Patient;

const renderNameCell: GridColDef['renderCell'] = (params) => {
  const row = params.row as PatientsTableRowData;

  return (
    <Link
      to={{
        pathname: routeCreator.PatientDetail(row.professional_user.professional_id, row.id),
        state: params.row,
      }}
    >
      {params.value}
    </Link>
  );
};

const renderEmailCell: GridColDef['renderCell'] = (params) => {
  const row = params.row as PatientsTableRowData;
  let inner = params.value;

  if (isPlaceholderEmail(row.email)) {
    inner = <span style={{ opacity: 0.2 }}>No e-mail address set</span>;
  }

  return (
    <Link
      to={{
        pathname: routeCreator.PatientDetail(row.professional_user.professional_id, row.id),
        state: params.row,
      }}
    >
      {inner}
    </Link>
  );
};

/**
 * @param {GridCellParams} params
 *   Grid cell parameters.
 */
const homeUsageCell: GridColDef['renderCell'] = (params) => {
  const row = params.row as PatientsTableRowData;
  const homeUsage = row.professional_user.home_usage;

  return (
    <span style={{ display: 'flex', alignItems: 'center', opacity: 0.7 }}>
      <DataGridCheckCell params={{ ...params, value: homeUsage }} />
    </span>
  );
};

/**
 * @param {GridCellParams} params
 *   Grid cell parameters.
 */
const hasAccessCell: GridColDef['renderCell'] = (params) => {
  const row = params.row as PatientsTableRowData;
  const access = row.professional_user.has_access;

  const inner = <DataGridCheckCell params={{ ...params, value: access }} />;

  if (access) {
    if (row.professional_user.demo_account) {
      return (
        <Tooltip
          title={
            <Localized id='demo_account_access'>
              This patient has access because it's a demo account
            </Localized>
          }
        >
          <span style={{ display: 'flex', alignItems: 'center', opacity: 0.7 }}>{inner}</span>
        </Tooltip>
      );
    }

    return inner;
  }

  const lastExpired = sortRows(row.access_periods, 'period_end', 'desc')[0];

  let title: TooltipProps['title'];

  if (typeof lastExpired === 'undefined') {
    title = <Localized id='patient_never_had_access'>This patient has never had access</Localized>;
  } else {
    title = (
      <Localized
        id='access_expired_on'
        vars={{ expiredOn: stringToFormattedDate(lastExpired.period_end) ?? '' }}
      >
        <>
          Access was expired on&nbsp;
          <LuxonDate date={lastExpired.period_end} format={DateTime.DATETIME_FULL_WITH_SECONDS} />
        </>
      </Localized>
    );
  }

  return (
    <Tooltip title={title}>
      <span style={{ display: 'flex', alignItems: 'center', opacity: 0.7 }}>{inner}</span>
    </Tooltip>
  );
};

/**
 * @param {GridCellParams} params
 *   Grid cell parameters.
 */
const accessUntilCell: GridColDef['renderCell'] = (params) => {
  const row = params.row as PatientsTableRowData;
  const active = row.access_periods.find((period) => period.active);

  if (!active) {
    return <>-</>;
  }

  return <DateCell format={DateTime.DATETIME_MED} value={active.period_end} />;
};

const renderGroupsCell: GridColDef['renderCell'] = (params) => {
  const { groups } = params.row as PatientsTableRowData;

  if (groups.length === 0) {
    return <>-</>;
  }

  const elements = groups.map((group, index) => (
    <Link
      key={group.id}
      to={{
        pathname: routeCreator.ProfessionalGroupDetail(group.professional_id, group.id),
        state: group,
      }}
      style={{ marginRight: 10 }}
    >
      {group.name}
      {index !== groups.length - 1 && ','}
    </Link>
  ));

  return <>{elements}</>;
};

const suffixedUserColumns = userTableColumns.filter((column) =>
  ['last_activity_at'].includes(column.field),
) as GridColumn<IUser>[];

export const patientsTableColumns: GridColumn<PatientsTableRowData>[] = [
  {
    field: 'name',
    headerName: 'Full name',
    sortable: false,
    flex: 0.75,
    hide: true,
    renderCell: renderNameCell,
  },
  {
    field: 'first_name',
    headerName: 'First name',
    flex: 0.5,
    renderCell: renderNameCell,
  },
  {
    field: 'last_name',
    headerName: 'Last name',
    flex: 0.5,
    renderCell: renderNameCell,
  },
  {
    field: 'email',
    flex: 1,
    headerName: 'E-mail',
    renderCell: renderEmailCell,
  },
  {
    field: 'groups',
    sortable: false,
    flex: 0.75,
    filterable: false,
    headerName: 'Groups',
    description: 'The groups of which this patient is part of',
    renderCell: renderGroupsCell,
  },
  {
    field: 'home_usage',
    sortable: false,
    flex: 0.5,
    filterable: false,
    headerName: 'Home usage',
    description: 'This patient is allowed to use Reducept at home',
    renderCell: homeUsageCell,
  },
  {
    field: 'has_access',
    sortable: false,
    flex: 0.5,
    filterable: false,
    headerName: 'Has access',
    description: 'This patient has access to use Reducept',
    renderCell: hasAccessCell,
  },
  {
    field: 'access_until',
    sortable: false,
    flex: 0.5,
    filterable: false,
    headerName: 'Access until',
    description: 'The date until this patient has access',
    renderCell: accessUntilCell,
  },
  ...suffixedUserColumns,
];
