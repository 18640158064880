import { Link } from 'react-router-dom';
import LinkButton from 'components/LinkButton';
import { routeCreator, routes } from 'services/routing';
import { cellRenderer, GridColumn } from 'utils/mui';
import { OverviewProduct } from 'pages/products/ProductsOverview';

export const productsOverviewTable: GridColumn<OverviewProduct>[] = [
  {
    field: 'internal_name',
    headerName: 'Internal name',
    flex: 1,
    renderCell: cellRenderer<OverviewProduct>((row) => {
      return (
        <Link to={{ pathname: routeCreator.ProductDetail(row.id), state: row }}>
          {row.internal_name}
        </Link>
      );
    }),
  },
  {
    field: 'amount_licenses',
    headerName: 'Licenses',
    flex: 1,
  },
  {
    field: 'interval',
    headerName: 'Interval',
    flex: 1,
  },
  {
    field: 'ecurring_subscription_plans',
    headerName: 'Related eCurring plans',
    flex: 1,
    renderCell: cellRenderer<OverviewProduct>(({ ecurring_subscription_plans }) => {
      return <>{ecurring_subscription_plans.length}</>;
    }),
  },
  {
    field: 'stripe_price',
    headerName: 'Related Stripe price',
    flex: 1,
    renderCell: cellRenderer<OverviewProduct>(({ stripe_price }) => {
      return <>{stripe_price === null ? '-' : stripe_price.nickname}</>;
    }),
  },
  {
    field: 'teamleader_products',
    headerName: 'Teamleader products',
    flex: 1,
    renderCell: cellRenderer<OverviewProduct>((row) => {
      if (row.teamleader_products.length === 0) {
        return (
          <LinkButton
            color='primary'
            size='small'
            variant='contained'
            to={routes.TeamleaderProducts.path}
          >
            Attach
          </LinkButton>
        );
      }

      return <>{row.teamleader_products.length}</>;
    }),
  },
];
