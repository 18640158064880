import React from 'react';

import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Link,
  List,
  ListItem,
  ListItemText,
  makeStyles,
} from '@material-ui/core';
import { Mail as MailIcon } from 'react-feather';

import LuxonDate from 'components/LuxonDate';
import RequestBody from 'components/mui/RequestBody';
import { IMailchimpMember } from 'core/mailchimp';
import useFetch from 'hooks/useFetch';
import { useLocalization } from 'services/localization/localization';

interface IProps {
  userId: number | string;
}

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
  },
}));

const CardUserMailchimpMember: React.FC<IProps> = (props) => {
  const request = useFetch<IMailchimpMember>(`/users/${props.userId}/mailchimp-member`);
  const classes = useStyles();
  const { getLocalizedString } = useLocalization();

  const mailchimpMember = request.data as IMailchimpMember;

  return (
    <Card className={classes.root}>
      <CardHeader
        avatar={<MailIcon size={25} />}
        titleTypographyProps={{
          color: 'textPrimary',
          variant: 'h5',
        }}
        title={getLocalizedString('mailchimp_account')}
        subheader={getLocalizedString('mailchimp_account_description')}
      />
      <Divider />
      <CardContent>
        <Box height={455} position='relative'>
          <RequestBody
            request={request}
            message={getLocalizedString('not-found', {
              model: getLocalizedString('mailchimp_account'),
            })}
          >
            <List dense disablePadding>
              <ListItem>
                <ListItemText
                  primary={getLocalizedString('mailchimp_id')}
                  secondary={mailchimpMember?.mailchimp_id}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={getLocalizedString('link_to_mailchimp_website')}
                  secondary={
                    <Link
                      href={`https://us20.admin.mailchimp.com/lists/members/view?id=${mailchimpMember?.mailchimp_web_id}`}
                      target='_blank'
                    >
                      ({getLocalizedString('link_to_mailchimp_website')})
                    </Link>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={getLocalizedString('status')}
                  secondary={getLocalizedString(mailchimpMember?.status)}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={getLocalizedString('language')}
                  secondary={mailchimpMember?.language}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={getLocalizedString('unsubscribe_reason')}
                  secondary={mailchimpMember?.unsubscribe_reason ?? '-'}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={getLocalizedString('timestamp_opt')}
                  secondary={<LuxonDate date={mailchimpMember?.timestamp_opt} fallback='-' />}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={getLocalizedString('last_changed')}
                  secondary={<LuxonDate date={mailchimpMember?.last_changed} fallback='-' />}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={getLocalizedString('synced_at')}
                  secondary={<LuxonDate date={mailchimpMember?.synced_at} fallback='-' />}
                />
              </ListItem>
            </List>
          </RequestBody>
        </Box>
      </CardContent>
    </Card>
  );
};

export default CardUserMailchimpMember;
