import React, { useState } from 'react';

import { TextField } from '@material-ui/core';
import { DateTime } from 'luxon';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import { useLocalization } from 'services/localization/localization';
import './DateRangePicker.css';
import { setMidnight } from 'utils/utils';

type Props = Omit<Partial<ReactDatePickerProps>, 'onChange'> & {
  onChange: (startDate: DateTime | null, endDate: DateTime | null) => void;
};

const DateRangePicker: React.FC<Props> = ({ onChange, ...props }) => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const { getLocalizedString } = useLocalization();

  const customInput = (
    <TextField
      variant='outlined'
      label={getLocalizedString('datepicker_from_to')}
      margin='none'
      size='small'
      fullWidth
    />
  );

  const defaultOptions: Partial<Props> = {
    selected: startDate,
    startDate: startDate,
    endDate: endDate,
    popperPlacement: 'auto',
    selectsRange: true,
    isClearable: true,
    closeOnScroll: true,
    dateFormat: 'dd/MM/yyyy',
    customInput: customInput,
  };

  const handleDatesChanged = (dates: [Date | null, Date | null] | null) => {
    if (dates === null) {
      return;
    }

    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);

    if (start !== null && end !== null) {
      const startDate = setMidnight(DateTime.fromJSDate(start));
      const endDate = setMidnight(DateTime.fromJSDate(end));
      onChange(startDate, endDate);
      return;
    }

    onChange(null, null);
  };

  return <ReactDatePicker {...defaultOptions} {...props} onChange={handleDatesChanged} />;
};

export default DateRangePicker;
