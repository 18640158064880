import React from 'react';

import 'react-perfect-scrollbar/dist/css/styles.css';

import LuxonUtils from '@date-io/luxon';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { Router } from 'react-router';

import GlobalStyles from 'components/mui/GlobalStyles';
import { RequestContext } from 'hooks/useRequest';
import { sentryDsn, sentryEnvironment } from 'services/env';
import httpClient from 'services/http';
import { LocalizationProvider } from 'services/localization/localization';
import { history } from 'services/routing';
import { store } from 'store/store';
import ThemeProvider from 'theme/theme';

import App from './App';
import reportWebVitals from './reportWebVitals';

/**
 * Sentry is used to report error logs happening in the dashboard.
 */

Sentry.init({
  dsn: sentryDsn,
  environment: sentryEnvironment,
  integrations: [
    new Integrations.BrowserTracing(),
    // Ignores all erros created by the console.
    new Sentry.Integrations.Breadcrumbs({ console: false }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.5,
});

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <Provider store={store}>
        <LocalizationProvider>
          <ThemeProvider>
            <MuiPickersUtilsProvider utils={LuxonUtils}>
              <RequestContext.Provider value={httpClient}>
                <Router history={history}>
                  <GlobalStyles />
                  <App />
                </Router>
              </RequestContext.Provider>
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </LocalizationProvider>
      </Provider>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
