import React from 'react';

import { Box, Card, Container, makeStyles, Typography } from '@material-ui/core';

import Page from 'components/Page';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    display: 'flex',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  headerTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerSubtitle: {
    paddingTop: 5,
    marginLeft: theme.spacing(1),
  },
  container: {
    display: 'flex',
    flex: 1,
    flexFlow: 'column nowrap',
  },
  box: {
    flexGrow: 1,
    marginTop: theme.spacing(3),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  gridCard: {
    height: '100%',
  },
}));

interface IProps {
  title: string;
  subtitle?: string | number;
  actions?: React.ReactNode;
}

const DataGridOverviewPage: React.FC<IProps> = (props) => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title={props.title}>
      <Container className={classes.container} maxWidth={false}>
        <Box className={classes.header}>
          <Box className={classes.headerTitle}>
            <Typography color='textPrimary' variant='h2'>
              {props.title}
            </Typography>
            {props.subtitle && (
              <Typography className={classes.headerSubtitle} color='textPrimary' variant='h5'>
                ({props.subtitle})
              </Typography>
            )}
          </Box>

          <Box display='flex' justifyContent='flex-end'>
            {props.actions}
          </Box>
        </Box>

        <Box className={classes.box}>
          <Card className={classes.gridCard}>{props.children}</Card>
        </Box>
      </Container>
    </Page>
  );
};

export default DataGridOverviewPage;
