import React from 'react';

import { Grid } from '@material-ui/core';
import { DateTime } from 'luxon';
import { Edit2 as EditIcon } from 'react-feather';
import { RouteComponentProps } from 'react-router-dom';

import Button from 'components/Button';
import ButtonBar from 'components/ButtonBar';
import DateRangePicker from 'components/daterangepicker/DateRangePicker';
import DetailPage from 'components/DetailPage';
import LinkButton from 'components/LinkButton';
import RoleBoundary from 'components/RoleBoundary';
import { LoadedProfessionalUser } from 'core/backend';
import { IUser } from 'core/user';
import useStateOrFetch from 'hooks/useStateOrFetch';
import { useLocalization } from 'services/localization/localization';
import { Role } from 'services/roles';
import { history, ProfessionalRouteParams, routeCreator, UserRouteParams } from 'services/routing';
import { AppState, useTypedSelector } from 'store/store';

import ErrorPage from 'pages/ErrorPage';
import LoadingPage from 'pages/LoadingPage';
import CardUserDetails from 'pages/users/UserDetail/components/CardUserDetails';
import CardUserDevices from 'pages/users/UserDetail/components/CardUserDevices';
import CardUserEcurringCustomer from 'pages/users/UserDetail/components/CardUserEcurringCustomer';
import CardUserLogs from 'pages/users/UserDetail/components/CardUserLogs';
import CardUserMailchimpMember from 'pages/users/UserDetail/components/CardUserMailchimpMember';
import CardUserMailchimpTags from 'pages/users/UserDetail/components/CardUserMailchimpTags';
import CardUserPainScores from 'pages/users/UserDetail/components/CardUserPainScores';
import CardUserPainScoresImage from 'pages/users/UserDetail/components/CardUserPainScoresImage';
import CardUserPlayerAccounts from 'pages/users/UserDetail/components/CardUserPlayerAccounts';
import CardUserProfessionals from 'pages/users/UserDetail/components/CardUserProfessionals';
import CardUserRoles from 'pages/users/UserDetail/components/CardUserRoles';
import CardUserStripeCustomer from 'pages/users/UserDetail/components/CardUserStripeCustomer';
import CardUserSubscriptions from 'pages/users/UserDetail/components/CardUserSubscriptions';
import DetachStaffDialog from './components/DetachStaffDialog';

type FetchResponse = IUser & LoadedProfessionalUser;
type Props = RouteComponentProps<
  UserRouteParams & ProfessionalRouteParams,
  any,
  FetchResponse | undefined
>;

const StaffDetail: React.FC<Props> = (props) => {
  const { professionalId, userId } = props.match.params;
  const [detachDialogOpen, setDetachDialogOpen] = React.useState(false);
  const [startDate, setStartDate] = React.useState<DateTime | null>(null);
  const [endDate, setEndDate] = React.useState<DateTime | null>(null);
  const { getLocalizedString } = useLocalization();

  const currentUserId = useTypedSelector((state: AppState) => state.session.user!.id).toString();
  const isCurrentUser = currentUserId === userId;

  const request = useStateOrFetch<FetchResponse | null>(
    props.location.state,
    `/professionals/${professionalId}/users/${userId}`,
  );

  if (request.loading && !request.data) {
    return <LoadingPage />;
  }

  if (!request.data) {
    return <ErrorPage />;
  }

  const handleStaffDetached = (): void => {
    /** @todo add Snackbar notification */
    alert(getLocalizedString('detach_staff_success'));

    setDetachDialogOpen(false);

    history.push(routeCreator.ProfessionalStaff(professionalId!));
  };

  const handleDatesChanged = (startDate: DateTime | null, endDate: DateTime | null) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const user = request.data as FetchResponse;

  const actions: JSX.Element[] = !isCurrentUser
    ? [
        <Button
          translationKey='delete'
          color='default'
          variant='contained'
          onClick={() => setDetachDialogOpen(true)}
          disabled={!user}
          style={{ marginRight: 4, marginLeft: 4 }}
        >
          Delete
        </Button>,
        <LinkButton
          translationKey='edit'
          to={routeCreator.EditStaff(professionalId, user.id)}
          state={user}
          startIcon={<EditIcon size={18} />}
          color='primary'
          variant='contained'
          style={{ marginLeft: 4 }}
        >
          Edit
        </LinkButton>,
      ]
    : [
        <LinkButton
          translationKey='edit'
          to={routeCreator.EditStaff(professionalId, user.id)}
          state={user}
          startIcon={<EditIcon size={18} />}
          color='primary'
          variant='contained'
          style={{ marginLeft: 4 }}
        >
          Edit
        </LinkButton>,
      ];

  return (
    <DetailPage
      title={user.name ?? getLocalizedString('therapist').toLowerCase()}
      actions={
        <ButtonBar actions={actions}>
          <DateRangePicker onChange={handleDatesChanged} />
        </ButtonBar>
      }
    >
      <Grid container spacing={3}>
        <RoleBoundary allowed={[Role.Therapist, Role.Owner, Role.Admin]}>
          <Grid item xs={12} md={3} lg={3} xl={2}>
            <CardUserDetails user={user} />
          </Grid>
          <Grid item xs={12} md={9} lg={5} xl={7}>
            <CardUserPainScores user={user} startDate={startDate} endDate={endDate} />
          </Grid>
          <Grid item xs={12} md={4} lg={4} xl={3}>
            <CardUserPainScoresImage user={user} startDate={startDate} endDate={endDate} />
          </Grid>

          <Grid item xs={12} md={8} lg={6} xl={6}>
            <CardUserPlayerAccounts user={user} />
          </Grid>
          <Grid item xs={12} md={6} lg={6} xl={6}>
            <CardUserDevices user={user} />
          </Grid>
        </RoleBoundary>

        <RoleBoundary allowed={[Role.Admin]}>
          <Grid item xs={12} md={6} lg={6} xl={6}>
            <CardUserSubscriptions user={user} />
          </Grid>
          <Grid item xs={12} md={12} lg={6} xl={6}>
            <CardUserProfessionals user={user} />
          </Grid>
          <Grid item lg={6} sm={6} xl={6} xs={12}>
            <CardUserLogs user={user} />
          </Grid>
          <Grid item xs={12} sm={6} lg={3} xl={3}>
            <CardUserMailchimpMember userId={user.id} />
          </Grid>
          <Grid item xs={12} sm={6} lg={3} xl={3}>
            <CardUserMailchimpTags userId={user.id} />
          </Grid>
          <Grid item lg={4} sm={6} xl={4} xs={12}>
            <CardUserStripeCustomer userId={user.id} />
          </Grid>
          <Grid item lg={4} sm={6} xl={4} xs={12}>
            <CardUserEcurringCustomer userId={user.id} />
          </Grid>
          <Grid item lg={4} sm={6} xl={4} xs={12}>
            <CardUserRoles userId={user.id} />
          </Grid>
        </RoleBoundary>
      </Grid>
      <DetachStaffDialog
        open={detachDialogOpen}
        onClose={() => setDetachDialogOpen(false)}
        onStaffDetached={handleStaffDetached}
        professionalId={professionalId}
        userId={userId}
      />
    </DetailPage>
  );
};

export default StaffDetail;
