import React from 'react';

import { Box, Card, CardHeader } from '@material-ui/core';
import { Smartphone as SmartphoneIcon } from 'react-feather';

import PaginatedServerDataGrid from 'containers/PaginatedServerDataGrid';
import { IPaginated, LoadedPlayerAccount } from 'core/backend';
import { IDevice } from 'core/device';
import { IUser } from 'core/user';
import { UseFetchOptions } from 'hooks/useFetch';
import useProfessionalFetch from 'hooks/useProfessionalFetch';
import { useLocalization } from 'services/localization/localization';
import { userDevicesTableColumns } from 'services/tables/userDetail/userDevicesTable';

interface IProps {
  user: IUser;
}

const CardUserDevices: React.FC<IProps> = ({ user }) => {
  const includes: [Partial<UseFetchOptions>] = [
    { includes: ['playerAccount', 'user', 'professional'] },
  ];

  const request = useProfessionalFetch<IPaginated<IDevice & LoadedPlayerAccount>>({
    default: [`/users/${user.id}/devices`, ...includes],
    professional: (professionalId) => [
      `/professionals/${professionalId}/users/${user.id}/devices`,
      ...includes,
    ],
  });

  const { getLocalizedString } = useLocalization();

  return (
    <Card>
      <CardHeader
        avatar={<SmartphoneIcon size={25} />}
        titleTypographyProps={{
          color: 'textPrimary',
          variant: 'h5',
        }}
        title={getLocalizedString('devices')}
        subheader={getLocalizedString('devices_description')}
      />
      <Box height={455}>
        <PaginatedServerDataGrid
          request={request}
          columns={userDevicesTableColumns}
          components={{ Toolbar: undefined }}
        />
      </Box>
    </Card>
  );
};

export default CardUserDevices;
