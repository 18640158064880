import React from 'react';

import { SubmitHandler } from 'react-hook-form';
import { RouteComponentProps } from 'react-router-dom';

import DetailPage from 'components/DetailPage';
import { getServerSelectValue } from 'components/forms/elements/ServerSelect';
import ProfessionalForm, {
  ProfessionalFormValues,
} from 'components/forms/professionals/ProfessionalForm';
import { IProfessionalFull } from 'core/professional';
import useRequest from 'hooks/useRequest';
import { useLocalization } from 'services/localization/localization';
import { routeCreator } from 'services/routing';

type PostProfessionalRequestValues = Pick<IProfessionalFull, 'name' | 'organization_id'> & {
  user_id: number;
};

const CreateProfessional: React.FC<RouteComponentProps> = (props) => {
  const postRequest = useRequest<IProfessionalFull, never, PostProfessionalRequestValues>({
    method: 'POST',
    url: `/professionals`,
  });
  const { getLocalizedString } = useLocalization();

  const handleSubmit: SubmitHandler<ProfessionalFormValues> = async ({
    organization,
    practice_owner,
    ...values
  }) => {
    const response = await postRequest.request({
      data: {
        ...values,
        organization_id: getServerSelectValue(organization)?.id || null,
        user_id: getServerSelectValue(practice_owner)!.id,
      },
    });

    props.history.push(routeCreator.ProfessionalDetail(response.data.id), response.data);
  };

  const pageTitle = getLocalizedString(
    'add-model',
    { model: getLocalizedString('practice').toLowerCase() },
    'New professional',
  );

  return (
    <DetailPage title={pageTitle} maxWidth='sm'>
      <ProfessionalForm onSubmit={handleSubmit} />
    </DetailPage>
  );
};

export default CreateProfessional;
