import React from 'react';

import { Dialog, DialogProps, DialogTitle } from '@material-ui/core';
import { SubmitHandler } from 'react-hook-form';

import { getServerSelectValue } from 'components/forms/elements/ServerSelect';
import LoginUserOnDeviceForm, {
  ILoginUserOnDeviceFormValues,
} from 'components/forms/LoginUserOnDeviceForm';
import Localized from 'components/Localized';
import { ILoginUserOnDevicePayload } from 'core/device';
import useRequest from 'hooks/useRequest';
import { DeviceRouteParams, ProfessionalRouteParams } from 'services/routing';

export type LoginUserOnDeviceDialogProps = Pick<DialogProps, 'open' | 'onClose'> &
  ProfessionalRouteParams &
  DeviceRouteParams & {
    onUserLoggedIn: () => void;
  };

const LoginUserOnDeviceDialog: React.FC<LoginUserOnDeviceDialogProps> = (props) => {
  const loginUserRequest = useRequest<{ message: string }, never, ILoginUserOnDevicePayload>({
    url: `/professionals/${props.professionalId}/devices/${props.deviceId}/login`,
    method: 'POST',
  });

  const handleSubmit: SubmitHandler<ILoginUserOnDeviceFormValues> = async (values) => {
    if (typeof values.patient !== 'object' || values.patient === null) {
      throw new Error();
    }

    await loginUserRequest.request({
      data: {
        user_id: getServerSelectValue(values.patient)!.id,
      },
    });

    props.onUserLoggedIn();
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby='form-dialog-title'
      fullWidth
      maxWidth='sm'
    >
      <DialogTitle id='form-dialog-title'>
        <Localized id='login-patient'>Log in a patient on this device</Localized>
      </DialogTitle>

      <LoginUserOnDeviceForm onSubmit={handleSubmit} professionalId={props.professionalId} />
    </Dialog>
  );
};

export default LoginUserOnDeviceDialog;
