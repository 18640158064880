import React from 'react';

import { RouteComponentProps } from 'react-router';

import DataGridOverviewPage from 'components/DataGridOverviewPage';
import PaginatedServerDataGrid from 'containers/PaginatedServerDataGrid';
import { IPaginated } from 'core/backend';
import { IProfessionalDeviceTableColumnsProps } from 'core/device';
import useFetch from 'hooks/useFetch';
import useRequest from 'hooks/useRequest';
import { useLocalization } from 'services/localization/localization';
import { defaultPaginationOptions } from 'services/pagination';
import { ProfessionalRouteParams } from 'services/routing';
import { DeviceTableRowData } from 'services/tables/devicesTable';
import { professionalDevicesTableColumns } from 'services/tables/professionalDevicesTable';

import LoginUserOnDeviceDialog from './components/LoginUserOnDeviceDialog';

type Props = RouteComponentProps<ProfessionalRouteParams>;

const ProfessionalDevicesOverview: React.FC<Props> = (props) => {
  const { professionalId } = props.match.params;
  const [selectedDeviceId, setSelectedDeviceId] = React.useState<string | number | null>(null);

  const devicesRequest = useFetch<IPaginated<DeviceTableRowData>>(
    `/professionals/${professionalId}/devices`,
    {
      pagination: defaultPaginationOptions,
      includes: ['professional', 'user', 'playerAccount'],
    },
  );

  const logoutDeviceRequest = useRequest<{ message: string }, null, null>({
    method: 'POST',
  });

  const { getLocalizedString } = useLocalization();

  const handleLoginDeviceButtonClick = async (deviceId: string | number) => {
    // Show form from which the user can select a user to log-in on to the device.
    setSelectedDeviceId(deviceId);
  };

  const handleUserLoggedIn = async () => {
    setSelectedDeviceId(null);
    /** @todo add Snackbar notification */
    alert(getLocalizedString('device_user_logged_in'));

    // Reload the page
    await devicesRequest.request()();
  };

  const handleLogoutDeviceButtonClick = async (deviceId: string | number) => {
    try {
      await logoutDeviceRequest.request({
        url: `/professionals/${professionalId}/devices/${deviceId}/logout`,
      });

      alert(getLocalizedString('device_user_logged_out'));

      // Reload the page
      await devicesRequest.request()();
    } catch (error) {
      // This should never happen if the logout button is correctly shown
      alert(getLocalizedString('error_message'));
    }
  };

  const tableColumnProps: IProfessionalDeviceTableColumnsProps = {
    handleLoginDeviceButtonClick,
    handleLogoutDeviceButtonClick,
  };

  return (
    <DataGridOverviewPage
      title={getLocalizedString('devices')}
      subtitle={`${devicesRequest.data?.meta.total || 0}`}
    >
      <PaginatedServerDataGrid
        request={devicesRequest}
        searchable
        columns={professionalDevicesTableColumns(tableColumnProps)}
        componentsProps={{
          toolbar: {
            searchPlaceholder: getLocalizedString('search-model', {
              model: getLocalizedString('devices').toLowerCase(),
            }),
          },
        }}
      />
      {selectedDeviceId && (
        <LoginUserOnDeviceDialog
          deviceId={selectedDeviceId.toString()}
          open={selectedDeviceId !== null}
          onClose={() => setSelectedDeviceId(null)}
          professionalId={professionalId}
          onUserLoggedIn={handleUserLoggedIn}
        />
      )}
    </DataGridOverviewPage>
  );
};

export default ProfessionalDevicesOverview;
