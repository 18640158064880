import React from 'react';

import { Button as MuiButton, ButtonProps } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import Localized from './Localized';

interface IProps {
  /**
   * Adds a spinner to the button.
   */
  loading?: boolean;
  translationKey?: string;
}

export type Props = IProps & Omit<ButtonProps, 'name'>;

const Button: React.FC<Props> = ({
  loading = false,
  disabled,
  children,
  translationKey = '',
  ...props
}) => (
  <MuiButton disabled={disabled || loading} {...props}>
    <Localized id={translationKey}>{children}</Localized>
    {loading && (
      <>
        &ensp;
        <CircularProgress size={15} thickness={5} />
      </>
    )}
  </MuiButton>
);

export default Button;
