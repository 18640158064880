import { MenuItem } from '@material-ui/core';

import { ISubscription, SubscriptionProviderType } from 'core/subscription';
import { isPlaceholderEmail, IUser } from 'core/user';
import { useLocalization } from 'services/localization/localization';

import { FormComponent } from '..';
import Select from '../elements/Select';
import ServerSelect, { ServerSelectValue } from '../elements/ServerSelect';
import BaseSubscriptionForm, {
  IBaseSubscriptionFormProps,
  SubscriptionFormValues,
} from './BaseSubscriptionForm';

export interface IUserSubscriptionFormValues extends SubscriptionFormValues {
  provider_type: ISubscription['provider_type'];
  user: ServerSelectValue<IUser>;
}

const subscriptionProviderTypes: { type: SubscriptionProviderType; disabled: boolean }[] = [
  { type: 'manual', disabled: false },
  { type: 'trial', disabled: false },
  { type: 'ecurring', disabled: true },
  { type: 'stripe', disabled: true },
];

type Props = Pick<IBaseSubscriptionFormProps<IUserSubscriptionFormValues>, 'isEditForm'>;

const UserSubscriptionForm: FormComponent<IUserSubscriptionFormValues, Props> = ({ ...props }) => {
  const RenderPrefixedInner: IBaseSubscriptionFormProps<IUserSubscriptionFormValues>['prefixedChildren'] = (
    form,
  ) => {
    const { getLocalizedString } = useLocalization();
    const { control } = form;

    return (
      <>
        <ServerSelect<IUser>
          label='User'
          controllerProps={{
            control,
            name: 'user',
            rules: {
              required: getLocalizedString('required'),
            },
          }}
          url='/users'
          initialValueUrl='/users/{user}'
          filter={{ column: 'search' }}
          autocompleteProps={{
            disabled: props.isEditForm,
            style: { color: 'aqua' },
            getOptionLabel: (user) => {
              if (user === null || user === undefined) {
                return undefined as any;
              }

              return `${user.name}${isPlaceholderEmail(user.email) ? '' : ` (${user.email})`}`;
            },
          }}
          helperText={getLocalizedString('user_subscription_description')}
          fullWidth
          margin='dense'
          variant={props.isEditForm ? 'filled' : 'outlined'}
        />
        <Select
          label='Subscription type'
          controllerProps={{
            control,
            name: 'provider_type',
          }}
          helperText={getLocalizedString('provider_type_user_description')}
          fullWidth
          margin='normal'
          readOnly={props.isEditForm}
          variant={props.isEditForm ? 'filled' : 'outlined'}
        >
          {subscriptionProviderTypes.map((type) => (
            <MenuItem disabled={type.disabled} value={type.type}>
              {getLocalizedString(type.type)}
            </MenuItem>
          ))}
        </Select>
      </>
    );
  };

  return (
    <BaseSubscriptionForm<IUserSubscriptionFormValues>
      {...props}
      defaultValues={props.defaultValues}
      prefixedChildren={RenderPrefixedInner}
    />
  );
};

export default UserSubscriptionForm;
