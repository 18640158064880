import React from 'react';

import { Box, createStyles, makeStyles, CircularProgress, TableBody } from '@material-ui/core';

import { UseFetchHook } from 'hooks/useFetch';
import { UseRequestHook } from 'hooks/useRequest';

import NoRowsMessage from './NoRowsMessage';

const useStyles = makeStyles((_) =>
  createStyles({
    root: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }),
);

interface IProps {
  request: UseFetchHook<any, any> | UseRequestHook<any, any, any>;
  message: string;
}

const RequestTableBody: React.FC<IProps> = ({ request, children, message }) => {
  const classes = useStyles();
  let inner = children;

  if (request.loading && !request.data) {
    inner = <CircularProgress />;
  }

  if (request.success) {
    if (request.data === null || request.data.length === 0) {
      inner = <NoRowsMessage message={message} />;
    }

    if (request.data !== null && 'data' in request.data!) {
      if (Array.isArray(request.data!.data) && request.data!.data.length === 0) {
        inner = <NoRowsMessage message={message} />;
      }
    }
  }

  if (inner !== children) {
    inner = <Box className={classes.root}>{inner}</Box>;
  }

  return <TableBody>{inner}</TableBody>;
};

export default RequestTableBody;
