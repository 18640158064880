import { IEntityTimestamps, ISyncableEntity } from './core';
import { ITeamleaderSubscription } from './teamleader';

export type SubscriptionProviderType = 'stripe' | 'ecurring' | 'trial' | 'manual' | 'teamleader';

/**
 * @link https://stripe.com/docs/api/subscriptions/object#subscription_object-status
 */
export type StripeSubscriptionStatus =
  | 'active'
  | 'canceled'
  | 'incomplete'
  | 'incomplete_expired'
  | 'past_due'
  | 'trialing'
  | 'unpaid';

/**
 * @link https://docs.ecurring.com/subscriptions/get
 *   For observing the possible statuses. Can be 'suspended' too. This
 *   status is not shown in eCurring's API reference.
 */
export type EcurringSubscriptionStatus =
  | 'active'
  | 'cancelled'
  | 'paused'
  | 'unverified'
  | 'suspended';

export const paymentProviderTypes: SubscriptionProviderType[] = ['stripe', 'ecurring'];
export const providerTypes: SubscriptionProviderType[] = [
  ...paymentProviderTypes,
  'trial',
  'manual',
];

export interface IStripeSubscription extends ISyncableEntity {
  id: number;
  stripe_id: string;
  stripe_customer_id: number;
  stripe_price_id: number;
  status: StripeSubscriptionStatus;
  current_period_start: string;
  current_period_end: string;
  cancel_at: string | null;
  cancelled_at: string | null;
  cancel_at_period_end: boolean;
  start_date: string;
  ended_at: string | null;
  created: string;
}

export interface IEcurringSubscription extends ISyncableEntity {
  id: number;
  ecurring_id: string;
  ecurring_customer_id: number;
  ecurring_subscription_plan_id: number;
  status: EcurringSubscriptionStatus;
  start_date: string;
  cancel_date: string | null;
  resume_date: string | null;
  mandate_accepted_date: string | null;
  archived: boolean;

  /**
   * The created_at field as stored in eCurring.
   */
  created_at: string;

  /**
   * The updated_ field as stored in eCurring.
   */
  updated_at: string;
}

export interface ITrialSubscription extends IEntityTimestamps {
  id: number;
  /**
   * @deprecated
   */
  legacy_id: 998;
  paused_at: string;
  resumed_at: string;
  period_start: string;
  period_end: string;
}

export interface IManualSubscription extends IEntityTimestamps {
  id: number;
  /**
   * @todo - This is not implemented yet.
   */
  product_id: null;
  /**
   * @deprecated
   */
  legacy_id: 1003;
  created_by_user_id: number | null;
  period_start: string;
  period_end: string;
}

export type SubscriptionProviderData =
  | IStripeSubscription
  | IEcurringSubscription
  | ITrialSubscription
  | IManualSubscription
  | ITeamleaderSubscription;

export type LicenseResetInterval = 'year' | 'unlimited' | 'month';
export const licenseResetInterval: LicenseResetInterval[] = ['month', 'unlimited', 'year'];

export interface ISubscription<P = SubscriptionProviderData> extends IEntityTimestamps {
  id: number;
  user_id: number | null;
  professional_id: number | null;
  active: boolean;
  provider_type: SubscriptionProviderType;
  amount_licenses: number | null;
  licenses_reset_interval: LicenseResetInterval | null;
  start_date: string;
  end_date: string | null;
  provider: P;
}

export const subscriptionIsSyncable = (
  subscription: ISubscription,
): subscription is ISubscription<IStripeSubscription | IEcurringSubscription> =>
  paymentProviderTypes.includes(subscription.provider_type);
