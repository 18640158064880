import React, { PropsWithChildren } from 'react';

import { CircularProgress } from '@material-ui/core';
import { Autocomplete, AutocompleteProps } from '@material-ui/lab';
import { Controller, ControllerProps } from 'react-hook-form';
import { useDebounce } from 'react-use';

import TextField from 'components/mui/TextField';
import { isPaginatedResponse } from 'core/backend';
import useFetch, { IncludeOption, UseFetchFilterOption } from 'hooks/useFetch';
import useInput from 'hooks/useInput';
import httpClient from 'services/http';

import { FormControllerProps } from '.';
import BaseFormController from './BaseFormController';

type ServerSelectProps<D> = FormControllerProps &
  Pick<AutocompleteProps<D, boolean | undefined, undefined, undefined>, 'multiple'> & {
    url: string;

    /**
     * The filter that is used to search in our server. The 'value' is filled
     * by the currently typed query.
     */
    filter: Omit<UseFetchFilterOption, 'value'>;

    /**
     * Filtering that is applied to every request.
     */
    requestFilter?: UseFetchFilterOption[];
    includes?: IncludeOption[];
    valueColumn?: keyof D;
    initialValueUrl?: string;
    autocompleteProps?: Partial<
      Omit<AutocompleteProps<D, undefined, undefined, undefined>, 'multiple' | 'onChange'>
    >;
  };

export type ServerSelectValue<D> = D | number | undefined | null;

export function getServerSelectValue<D>(value: ServerSelectValue<D>): D | null {
  if (typeof value !== 'object') {
    return null;
  }

  return value === null ? null : (value as D);
}

function ServerSelect<D, E = never>(props: PropsWithChildren<ServerSelectProps<D>>): JSX.Element {
  const {
    url,
    initialValueUrl,
    filter,
    requestFilter = [],
    includes,
    valueColumn = 'id' as keyof D,
    label,
    controllerProps,
    autocompleteProps,
    helperText,
    multiple,
    ...formControlProps
  } = props;

  const [initialValue, setInitialValue] = React.useState<D | null>(null);
  const [search, setSearch] = useInput<HTMLInputElement | HTMLTextAreaElement>();
  const fieldName = controllerProps.name;

  const request = useFetch<D[], E>(
    url,
    {
      filtering: [{ ...filter, value: '' }, ...requestFilter],
      includes,
    },
    {
      defaultValue: [],
    },
  );

  React.useEffect(() => {
    const fieldValue = controllerProps.control.getValues(fieldName);
    if (fieldValue === null || typeof fieldValue === 'undefined') {
      return;
    }

    // Fetch the initial value if the ServerSelect is not a multi-select
    if (!multiple) {
      fetchInitialObject(fieldValue);
    }
  }, []);

  // Debounce the search input to optimize the amount of requests that are sent.
  useDebounce(
    () => {
      request.request({
        filtering: [{ ...filter, value: search }, ...requestFilter],
        includes,
      })();
    },
    300,
    [search],
  );

  const options: D[] = isPaginatedResponse<D>(request.data)
    ? request.data.data
    : (request.data as D[]);

  // Only applicable if the ServerSelect is not a multi-select
  const fetchInitialObject = async (value: string | number) => {
    if (!initialValueUrl) {
      return;
    }
    const url = initialValueUrl.replace(`{${fieldName}}`, String(value));

    try {
      const response = await httpClient.get<D>(url);
      controllerProps.control.setValue(fieldName, response.data);
      setInitialValue(response.data);
    } catch (error) {
      console.error('Failed to fetch the initial value of field', fieldName);
    }
  };

  if (initialValue !== null) {
    const exists = options.find((option) => option[valueColumn] === initialValue[valueColumn]);

    if (!exists) {
      options.unshift(initialValue);
    }
  }

  const renderAutoComplete: ControllerProps<any>['render'] = (field, _) => {
    const { onChange, ...fieldProps } = field;

    return (
      <Autocomplete<D, boolean | undefined, undefined, undefined>
        {...fieldProps}
        onChange={(_, option) => onChange(option)}
        getOptionSelected={(option, value) => option[valueColumn] === value[valueColumn]}
        filterSelectedOptions
        getOptionLabel={(option) => option[filter.column] ?? ''}
        options={options}
        loading={request.loading}
        multiple={multiple}
        renderInput={(params) => (
          <TextField
            {...params}
            translationKey={fieldName}
            label={label}
            variant={formControlProps.variant}
            fullWidth={formControlProps.fullWidth}
            onChange={setSearch}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {request.loading ? <CircularProgress color='inherit' size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
        {...autocompleteProps}
      />
    );
  };

  return (
    <BaseFormController helperText={helperText} {...formControlProps}>
      <Controller {...controllerProps} render={renderAutoComplete} />
    </BaseFormController>
  );
}

export default ServerSelect;
