import React from 'react';

import { Helmet } from 'react-helmet-async';

interface IProps {
  title: string;
}

type Props = IProps & JSX.IntrinsicElements['div'];

const Page = React.forwardRef<HTMLDivElement, Props>(({ children, title = '', ...rest }, ref) => (
  <div ref={ref} {...rest}>
    <Helmet>
      <title>{title}</title>
    </Helmet>

    {children}
  </div>
));

Page.displayName = 'Page';

export default Page;
