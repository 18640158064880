import React from 'react';

import { createMuiTheme, colors as muiColors } from '@material-ui/core';
import {
  nlNL as coreNL,
  enUS as coreEN,
  deDE as coreDE,
  Localization as CoreLocalization,
} from '@material-ui/core/locale';
import {
  nlNL as gridNL,
  enUS as gridEN,
  deDE as gridDE,
  Localization as DataGridLocalization,
} from '@material-ui/data-grid';
import { ThemeProvider as MuiThemeProvider, ThemeProviderProps } from '@material-ui/styles';
import { Settings } from 'luxon';
import { setDefaultLocale } from 'react-datepicker';

import {
  AvailableLanguage,
  toShortLocale,
  useLocalization,
} from 'services/localization/localization';

import colors from './colors';
import shadows from './shadows';
import typography from './typography';

export const darkBackground = '#F4F6F8';

const MaterialCoreLocalizationMap: Record<AvailableLanguage, CoreLocalization> = {
  'en-GB': coreEN,
  'nl-NL': coreNL,
  'de-DE': coreDE,
};

const MaterialLocalizationMap: Record<AvailableLanguage, DataGridLocalization> = {
  'en-GB': gridEN,
  'nl-NL': gridNL,
  'de-DE': gridDE,
};

const createTheme = (selectedLanguage: AvailableLanguage) => {
  /** Set the locale for react-datepicker */
  setDefaultLocale(toShortLocale(selectedLanguage));

  return createMuiTheme(
    {
      palette: {
        background: {
          default: muiColors.common.white,
          paper: muiColors.common.white,
        },
        primary: {
          main: colors.primary,
          // main: colors.indigo[500],
        },
        secondary: {
          main: colors.secondary,
          // main: colors.indigo[500],
        },
        text: {
          primary: muiColors.blueGrey[900],
          secondary: muiColors.blueGrey[600],
        },
      },
      shadows,
      typography,
    },
    MaterialCoreLocalizationMap[selectedLanguage],
    MaterialLocalizationMap[selectedLanguage],
  );
};

// Export theme to be used by other components
export const theme = createTheme('en-GB');

/**
 *  Custom wrapper around the Mui ThemeProvider to change if the user's selected language changes.
 */
const ThemeProvider: React.FC<Omit<ThemeProviderProps, 'theme'>> = ({ children }) => {
  const { selectedLanguage } = useLocalization();
  Settings.defaultLocale = selectedLanguage.toString();
  const theme = createTheme(selectedLanguage);

  return MuiThemeProvider({ children: children, theme: theme });
};

export default ThemeProvider;
