import { GridCellParams, GridFilterItem, GridFilterOperator } from '@material-ui/data-grid';

import StringInput from './inputComponents/StringInput';

export const ContainsOperator: GridFilterOperator = {
  label: 'contains_filter_label',
  value: 'contains',
  getApplyFilterFn: (filterItem: GridFilterItem, _) => {
    if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
      return null;
    }

    return (params: GridCellParams): boolean => {
      return String(params.value) === String(filterItem.value);
    };
  },
  InputComponent: StringInput,
  InputComponentProps: { type: 'string' },
};
