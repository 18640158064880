import React from 'react';

import { RouteComponentProps } from 'react-router-dom';

import DataGridOverviewPage from 'components/DataGridOverviewPage';
import PaginatedServerDataGrid from 'containers/PaginatedServerDataGrid';
import { IPaginated } from 'core/backend';
import useFetch from 'hooks/useFetch';
import { useLocalization } from 'services/localization/localization';
import { defaultPaginationOptions } from 'services/pagination';
import {
  organizationsTableColumns,
  OrganizationTableRowData,
} from 'services/tables/organizationsTable';

const OrganizationsOverview: React.FC<RouteComponentProps> = (_) => {
  const request = useFetch<IPaginated<OrganizationTableRowData>>('/organizations', {
    pagination: defaultPaginationOptions,
    includes: ['professionals', 'professionalsCount'],
  });

  const { getLocalizedString } = useLocalization();

  return (
    <DataGridOverviewPage
      title={getLocalizedString('partners')}
      subtitle={`${request.data?.meta.total || 0}`}
    >
      <PaginatedServerDataGrid
        request={request}
        searchable
        columns={organizationsTableColumns}
        components={{
          Toolbar: undefined,
        }}
        rowHeight={52}
      />
    </DataGridOverviewPage>
  );
};

export default OrganizationsOverview;
