import React from 'react';

import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import { Cloud as CloudIcon } from 'react-feather';

import { SubscriptionProviderData, SubscriptionProviderType } from 'core/subscription';
import { useLocalization } from 'services/localization/localization';
import { SubscriptionsOverviewRowData } from 'services/tables/subscriptionsTable';

import SubscriptionProviderEcurring from './provider-variants/SubscriptionProviderEcurring';
import SubscriptionProviderStripe from './provider-variants/SubscriptionProviderStripe';
import SubscriptionProviderTeamleader from './provider-variants/SubscriptionProviderTeamleader';
import SubscriptionProviderTrial from './provider-variants/SubscriptionProviderTrial';

interface IProps {
  subscription: SubscriptionsOverviewRowData;
}

export type SubscriptionProviderComponent<T = SubscriptionProviderData> = React.FC<{ data: T }>;

const providerComponentMapping: Record<
  SubscriptionProviderType,
  SubscriptionProviderComponent<any>
> = {
  trial: SubscriptionProviderTrial,
  manual: SubscriptionProviderTrial,
  ecurring: SubscriptionProviderEcurring,
  stripe: SubscriptionProviderStripe,
  teamleader: SubscriptionProviderTeamleader,
};

const CardSubscriptionProvider: React.FC<IProps> = ({ subscription }) => {
  const { getLocalizedString } = useLocalization();
  const ProviderComponent = providerComponentMapping[subscription.provider_type];

  return (
    <Card>
      <CardHeader
        avatar={<CloudIcon size={25} />}
        titleTypographyProps={{
          color: 'textPrimary',
          variant: 'h5',
        }}
        title={getLocalizedString('provider_type')}
        subheader={getLocalizedString('provider_type_information')}
      />
      <Divider />
      <CardContent>
        <Box height={550} position='relative'>
          <List dense disablePadding>
            <ListItem>
              <ListItemText
                primary={getLocalizedString('type')}
                secondary={getLocalizedString(subscription.provider_type)}
              />
            </ListItem>
            <ProviderComponent data={subscription.provider} />
          </List>
        </Box>
      </CardContent>
      <Divider />
    </Card>
  );
};

export default CardSubscriptionProvider;
