import React from 'react';
import { Plus as PlusIcon } from 'react-feather';

import DataGridOverviewPage from 'components/DataGridOverviewPage';
import LinkButton from 'components/LinkButton';
import PaginatedServerDataGrid from 'containers/PaginatedServerDataGrid';
import { IPaginated } from 'core/backend';
import { IEcurringSubscriptionPlan } from 'core/ecurring';
import { IProduct } from 'core/product';
import { IStripePrice } from 'core/stripe';
import { ITeamleaderProduct } from 'core/teamleader';
import useFetch from 'hooks/useFetch';
import { routes } from 'services/routing';
import { productsOverviewTable } from 'services/tables/productsTable';

export type OverviewProduct = IProduct & {
  stripe_price: IStripePrice | null;
  ecurring_subscription_plans: IEcurringSubscriptionPlan[];
  teamleader_products: ITeamleaderProduct[];
};

const ProductsOverview: React.FC = () => {
  const request = useFetch<IPaginated<OverviewProduct>>('/products');

  const actions = (
    <>
      <LinkButton
        to={routes.CreateProduct.path}
        color='primary'
        variant='contained'
        startIcon={<PlusIcon size={20} />}
      >
        New product
      </LinkButton>
    </>
  );

  return (
    <DataGridOverviewPage
      title='Products'
      subtitle={`${request.data?.meta.total || 0}`}
      actions={actions}
    >
      <PaginatedServerDataGrid request={request} searchable columns={productsOverviewTable} />
    </DataGridOverviewPage>
  );
};

export default ProductsOverview;
