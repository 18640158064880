import React from 'react';

import { DateTime } from 'luxon';
import { Control, FieldError, FieldValues } from 'react-hook-form';

import { FormReturn, useReduceptForm } from 'hooks/useReduceptForm';
import { useLocalization } from 'services/localization/localization';

import { FormComponentProps } from '..';
import BaseForm from '../BaseForm';
import DatePicker from '../elements/DatePicker';

export type SubscriptionFormValues = {
  start_date: DateTime;
  end_date: DateTime | null;
};

export interface IBaseSubscriptionFormProps<V> {
  prefixedChildren?(form: FormReturn<V>): React.ReactNode;
  suffixedChildren?(form: FormReturn<V>): React.ReactNode;
  isEditForm?: true;
}

function BaseSubscriptionForm<V extends SubscriptionFormValues>(
  props: FormComponentProps<V> & IBaseSubscriptionFormProps<V>,
) {
  const { prefixedChildren, suffixedChildren, onSubmit, defaultValues, isEditForm } = props;
  const form = useReduceptForm<V>(onSubmit, { defaultValues });
  const [startDate, setStartDate] = React.useState<DateTime | null>(null);
  const [endDate, setEndDate] = React.useState<DateTime | null>(null);
  const { getLocalizedString } = useLocalization();

  const {
    errors,
    control,
    formState: { touched },
  } = form;

  const formValues = control.getValues();
  const subscriptionStarted = formValues.start_date < DateTime.now();
  const startDateReadonly = isEditForm && subscriptionStarted;

  React.useEffect(() => {
    setStartDate(formValues.start_date);
    setEndDate(formValues.end_date);
  }, [formValues.start_date, formValues.end_date]);

  return (
    <BaseForm form={form}>
      {prefixedChildren && prefixedChildren(form)}

      <DatePicker
        error={Boolean(touched.start_date && errors.start_date)}
        helperText={
          (touched.start_date && (errors.start_date as FieldError)?.message) ||
          getLocalizedString('start_date_description')
        }
        label='Start date'
        translationKey='start_date'
        control={control as Control<FieldValues>}
        fullWidth
        margin='normal'
        variant='outlined'
        rules={{
          required: getLocalizedString('required'),
        }}
        datePickerProps={{
          readOnly: startDateReadonly,
          isClearable: !startDateReadonly,
        }}
        onStartDateChanged={(date) => setStartDate(date)}
        maxDate={() => {
          if (endDate) {
            return (endDate.minus({ days: 1 }) as DateTime).toJSDate();
          }

          return undefined;
        }}
      />

      <DatePicker
        helperText={getLocalizedString('end_date_description')}
        label='End date'
        translationKey='end_date'
        control={control as Control<FieldValues>}
        fullWidth
        margin='normal'
        variant='outlined'
        onEndDateChanged={(date) => setEndDate(date)}
        datePickerProps={{ isClearable: true }}
        minDate={() => {
          if (startDate) {
            return (startDate.plus({ days: 1 }) as DateTime).toJSDate();
          }

          return undefined;
        }}
      />

      {suffixedChildren && suffixedChildren(form)}
    </BaseForm>
  );
}

export default BaseSubscriptionForm;
