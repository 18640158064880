import React from 'react';

import DataGridOverviewPage from 'components/DataGridOverviewPage';
import PaginatedServerDataGrid from 'containers/PaginatedServerDataGrid';
import { IPaginated, LoadedSubscription } from 'core/backend';
import { ITeamleaderSubscription, LoadedTeamleaderProducts } from 'core/teamleader';
import useFetch from 'hooks/useFetch';
import { teamleaderSubscriptionsTable } from 'services/tables/teamleaderSubscriptionsTable';

export type TeamleaderSubscriptionsOverviewData = ITeamleaderSubscription &
  LoadedTeamleaderProducts &
  LoadedSubscription;

const TeamleaderSubscriptionsOverview: React.FC = () => {
  const request = useFetch<IPaginated<TeamleaderSubscriptionsOverviewData>>(
    '/teamleader/subscriptions',
    {
      pagination: {
        number: 1,
        size: 100,
      },
      includes: ['teamleaderCompany.professional', 'teamleader_products'],
    },
  );

  return (
    <DataGridOverviewPage
      title='Teamleader subscriptions'
      subtitle={`${request.data?.meta.total || 0}`}
    >
      <PaginatedServerDataGrid
        request={request}
        searchable
        columns={teamleaderSubscriptionsTable}
      />
    </DataGridOverviewPage>
  );
};

export default TeamleaderSubscriptionsOverview;
