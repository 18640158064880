import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import DataGridOverviewPage from 'components/DataGridOverviewPage';
import PaginatedServerDataGrid from 'containers/PaginatedServerDataGrid';
import { IPaginated } from 'core/backend';
import useFetch from 'hooks/useFetch';
import { useLocalization } from 'services/localization/localization';
import {
  professionalInvitesTableColumns,
  ProfessionalInviteTableRowData,
} from 'services/tables/professionalInvitesTable';

const ProfessionalInvitesOverview: React.FC<RouteComponentProps> = () => {
  const professionalInvitesRequest = useFetch<IPaginated<ProfessionalInviteTableRowData>>(
    `/professional-invites`,
    {
      includes: ['professional', 'user', 'creator'],
    },
  );

  const { getLocalizedString } = useLocalization();

  return (
    <DataGridOverviewPage
      title={getLocalizedString('professional_invites')}
      subtitle={`${professionalInvitesRequest.data?.meta.total || 0}`}
    >
      <PaginatedServerDataGrid
        request={professionalInvitesRequest}
        searchable
        columns={professionalInvitesTableColumns}
        componentsProps={{
          toolbar: {
            searchPlaceholder: getLocalizedString('search-model', {
              model: getLocalizedString('professional_invites').toLowerCase(),
            }),
          },
        }}
      />
    </DataGridOverviewPage>
  );
};

export default ProfessionalInvitesOverview;
