import { useDispatch, TypedUseSelectorHook, useSelector } from 'react-redux';
import { applyMiddleware, combineReducers, createStore, Middleware, Store } from 'redux';
import { createLogger } from 'redux-logger';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // This uses localStorage
import createSagaMiddleware from 'redux-saga';

import * as env from 'services/env';
import { readableActionTypes } from 'utils/redux';

import { IAction } from './actions';

/* Redux-saga */
import { persistedSessionReducerKey } from './rehydrate';
import sagas from './sagas';
import sessionReducer from './session/sessionReducer';

const logger = createLogger({
  actionTransformer: readableActionTypes,
  collapsed: false,
});

const sagaMiddleware = createSagaMiddleware();

const appliedMiddleware: Middleware[] = [sagaMiddleware];

if (env.isDev) {
  appliedMiddleware.push(logger);
}

const rootReducer = combineReducers({
  session: persistReducer(
    {
      key: persistedSessionReducerKey,
      storage,
      whitelist: ['token', 'refresh', 'expires'],
    },
    sessionReducer,
  ),
});

export type AppState = ReturnType<typeof rootReducer>;

export const store: Store<AppState, IAction> = createStore<AppState, IAction, any, any>(
  rootReducer,
  applyMiddleware(...appliedMiddleware),
);

/**
 * React-redux's useDispatch hook specifically for our store. This enforces
 * that dispatched actions are of IAction type.
 */
export const useTypedDispatch: () => typeof store.dispatch = useDispatch;

/**
 * React-redux's useSelector hook that always returns our application state.
 */
export const useTypedSelector: TypedUseSelectorHook<AppState> = useSelector;

export const persistor = persistStore(store);

sagaMiddleware.run(sagas);
