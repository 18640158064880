import React from 'react';

import { Tooltip } from '@material-ui/core';
import { GridCellValue, GridColDef } from '@material-ui/data-grid';
import { DateTimeFormatOptions, DateTime } from 'luxon';

import LuxonDate from 'components/LuxonDate';

export interface GridColumn<T> extends Omit<GridColDef, 'field'> {
  field: keyof T | string;
}

export const createDateTooltipTitle = (value: GridCellValue): React.ReactFragment => (
  <LuxonDate date={value as string} format={DateTime.DATETIME_FULL_WITH_SECONDS} />
);

interface IDateCellProps {
  format?: DateTimeFormatOptions;
  value: string;
  fallback?: React.ReactNode;
}

export const DateCell: React.FC<IDateCellProps> = ({
  format = DateTime.DATE_SHORT,
  value,
  fallback,
}) => (
  <Tooltip title={createDateTooltipTitle(value)}>
    <span>
      <LuxonDate date={value} format={format} fallback={fallback} />
    </span>
  </Tooltip>
);

export const renderDateCell = (
  format: DateTimeFormatOptions = DateTime.DATE_SHORT,
  fallback?: React.ReactNode,
): GridColDef['renderCell'] => (params) => (
  <DateCell format={format} value={params.value as string} fallback={fallback} />
);

export const cellRenderer = <T extends object>(
  fn: (row: T) => React.ReactElement,
): GridColDef['renderCell'] => (params) => fn(params.row as T);
