import React from 'react';

import { Box, Container, Card, colors, makeStyles, Typography } from '@material-ui/core';

import Page from 'components/Page';
import { useLocalization } from 'services/localization/localization';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  card: {
    padding: theme.spacing(3),
    borderWidth: 8,
    borderColor: colors.common.white,
    hoverBorderColor: colors.common.white,
  },
}));

type Props = {
  message?: string;
};

const ErrorPage: React.FC<Props> = (props) => {
  const classes = useStyles();
  const { getLocalizedString } = useLocalization();

  const errorMessage = props.message ?? getLocalizedString('error_message');

  return (
    <Page className={classes.root} title='Reducept dashboard'>
      <Box display='flex' flexDirection='column' height='100%' justifyContent='center'>
        <Container maxWidth='xs'>
          <Card className={classes.card}>
            <Typography color='textPrimary' variant='body1' align='center'>
              {errorMessage}
            </Typography>
          </Card>
        </Container>
      </Box>
    </Page>
  );
};

export default ErrorPage;
