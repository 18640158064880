import React from 'react';
import TextField from 'components/mui/TextField';
import { IChangeUserPasswordPayload } from 'core/user';
import { useReduceptForm } from 'hooks/useReduceptForm';
import { useLocalization } from 'services/localization/localization';

import { FormComponent } from '.';
import BaseForm from './BaseForm';

export type ChangePasswordFormValues = IChangeUserPasswordPayload & {
  password_confirmation: string;
};

const ChangePasswordForm: FormComponent<ChangePasswordFormValues> = ({
  defaultValues,
  onSubmit,
  children,
}) => {
  const form = useReduceptForm<ChangePasswordFormValues>(onSubmit, { defaultValues });
  const {
    register,
    errors,
    watch,
    formState: { touched, isSubmitting },
  } = form;

  // https://codesandbox.io/s/react-hook-form-password-match-check-standard-validation-eo6en
  const password = React.useRef({});
  password.current = watch('password', '');

  const { getLocalizedString } = useLocalization();

  const minLengthError = errors.password?.type === 'minLength';

  return (
    <BaseForm form={form}>
      {children}
      <TextField
        error={Boolean(touched.password && errors.password)}
        fullWidth
        helperText={
          (touched.password && errors.password?.message) ||
          (minLengthError ? getLocalizedString('minimum_length', { length: 8 }) : '')
        }
        label='Password'
        margin='normal'
        name='password'
        inputRef={register({
          required: getLocalizedString('required'),
          minLength: 8,
        })}
        translationKey='password'
        type='password'
        variant='outlined'
        disabled={isSubmitting}
      />
      <TextField
        error={Boolean(touched.password_confirmation && errors.password_confirmation)}
        fullWidth
        helperText={touched.password_confirmation && errors.password_confirmation?.message}
        label='Confirm password'
        margin='normal'
        translationKey='password_confirmation'
        inputRef={register({
          required: getLocalizedString('required'),
          minLength: 8,
          validate: (value) =>
            value === password.current || getLocalizedString('password_not_match'),
        })}
        type='password'
        variant='outlined'
        disabled={isSubmitting}
      />
    </BaseForm>
  );
};

export default ChangePasswordForm;
