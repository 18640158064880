import { FieldError } from 'react-hook-form';

import TextField from 'components/mui/TextField';
import { IProfessionalGroup } from 'core/professionalGroup';
import { useReduceptForm } from 'hooks/useReduceptForm';

import { useLocalization } from 'services/localization/localization';
import { FormComponent } from '..';
import BaseForm from '../BaseForm';

export type ProfessionalGroupFormValues = Pick<IProfessionalGroup, 'name'>;

const ProfessionalGroupForm: FormComponent<ProfessionalGroupFormValues> = ({
  defaultValues,
  onSubmit,
}) => {
  const form = useReduceptForm<ProfessionalGroupFormValues>(onSubmit, { defaultValues });
  const {
    register,
    errors,
    formState: { touched },
  } = form;

  const { getLocalizedString } = useLocalization();

  return (
    <BaseForm form={form}>
      <TextField
        error={Boolean(touched.name && errors.name)}
        fullWidth
        helperText={touched.name && (errors.name as FieldError)?.message}
        label='Name'
        margin='normal'
        translationKey='name'
        inputRef={register({ required: getLocalizedString('required') })}
        type='text'
        variant='outlined'
        required
      />
    </BaseForm>
  );
};

export default ProfessionalGroupForm;
