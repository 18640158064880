import React from 'react';

import { Box, Card, CardContent, CardHeader, Divider, List, ListItem } from '@material-ui/core';
import { Settings as GearIcon } from 'react-feather';

import CheckIcon from 'components/CheckIcon';
import ListItemText from 'components/mui/ListItemText';
import { useLocalization } from 'services/localization/localization';
import { DeviceTableRowData } from 'services/tables/devicesTable';

interface IProps {
  device: DeviceTableRowData;
}

const CardDeviceSpecification: React.FC<IProps> = ({ device }) => {
  const { getLocalizedString } = useLocalization();

  return (
    <Card>
      <CardHeader
        avatar={<GearIcon size={25} />}
        titleTypographyProps={{
          color: 'textPrimary',
          variant: 'h5',
        }}
        title={getLocalizedString('specification')}
        subheader={getLocalizedString('device_specification')}
      />
      <Divider />
      <CardContent>
        <Box height={500} position='relative'>
          <List dense disablePadding>
            <ListItem>
              <ListItemText
                primary={getLocalizedString('device_name')}
                secondary={device.device_name}
              />
            </ListItem>
            <ListItem>
              <ListItemText primary={getLocalizedString('model')} secondary={device.device_model} />
            </ListItem>
            <ListItem>
              <ListItemText primary={getLocalizedString('type')} secondary={device.device_type} />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={getLocalizedString('supports_audio')}
                secondary={<CheckIcon condition={device.supports_audio ?? false} />}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={getLocalizedString('operating_system')}
                secondary={device.operating_system}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={getLocalizedString('operating_system_family')}
                secondary={device.operating_system_family}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                style={{ overflowWrap: 'break-word' }}
                primary={getLocalizedString('device_unique_identifier')}
                secondary={device.device_unique_identifier ?? device.hardware_id}
              />
            </ListItem>
          </List>
        </Box>
      </CardContent>
      <Divider />
    </Card>
  );
};

export default CardDeviceSpecification;
