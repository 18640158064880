import React from 'react';
import { makeStyles, colors, Card, Typography } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import DetailPage from 'components/DetailPage';
import BaseForm from 'components/forms/BaseForm';
import ServerSelect from 'components/forms/elements/ServerSelect';
import { IProfessional } from 'core/professional';
import { ITeamleaderCompany } from 'core/teamleader';
import useFetch from 'hooks/useFetch';
import { useReduceptForm } from 'hooks/useReduceptForm';
import httpClient from 'services/http';
import { useLocalization } from 'services/localization/localization';
import { history, routes } from 'services/routing';
import ErrorPage from 'pages/ErrorPage';
import LoadingPage from 'pages/LoadingPage';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(3),
    borderWidth: 8,
    borderColor: colors.common.white,
    hoverBorderColor: colors.common.white,
    marginBottom: theme.spacing(3),
  },
  button: {
    marginTop: 8,
  },
}));
type FormValues = { professional: IProfessional };

const TeamleaderCompanyAttachProfessional: React.FC = () => {
  const classes = useStyles();
  const { teamleaderCompanyId } = useParams<{ teamleaderCompanyId: string }>();
  const { getLocalizedString } = useLocalization();
  const companyRequest = useFetch<ITeamleaderCompany>(
    `/teamleader/companies/${teamleaderCompanyId}`,
  );

  const form = useReduceptForm<FormValues>(async (values) => {
    try {
      await httpClient.post(`/teamleader/companies/${teamleaderCompanyId}/attach-professional`, {
        professional_id: values.professional.id,
      });

      history.push(routes.TeamleaderCompanies.path);
    } catch (error) {
      throw error;
    }
  }, {});

  if (companyRequest.loading && !companyRequest.data) {
    return <LoadingPage />;
  }

  if (!companyRequest.data) {
    return <ErrorPage />;
  }

  const company = companyRequest.data as ITeamleaderCompany;

  return (
    <DetailPage maxWidth='md' title='Attach practice'>
      <Card className={classes.card}>
        <Typography color='textPrimary'>
          Please select a practice below that should be related to teamleader company:&nbsp;
          <a target='_blank' href={company.website_url} rel='noreferrer'>
            {company.name}
          </a>
        </Typography>
      </Card>

      <BaseForm form={form}>
        <ServerSelect<IProfessional>
          label='Practice'
          controllerProps={{
            control: form.control,
            name: 'professional',
            rules: {
              required: getLocalizedString('required'),
            },
          }}
          url='/professionals'
          initialValueUrl='/professionals/{professional}'
          filter={{ column: 'name' }}
          requestFilter={[
            {
              column: 'teamleader_attachable',
              value: 'true',
            },
          ]}
          helperText={`The practice that should be attached to teamleader company ${company.name}`}
          fullWidth
          variant='filled'
          margin='dense'
        />
      </BaseForm>
    </DetailPage>
  );
};
export default TeamleaderCompanyAttachProfessional;
