import React from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import DetailPage from 'components/DetailPage';
import BaseProfessionalInviteForm, {
  BaseProfessionalInviteFormValues,
} from 'components/forms/BaseProfessionalInviteForm';
import { ProfessionalUserRoleType } from 'core/professional';
import { IUser } from 'core/user';
import useProfessionalLicenses from 'hooks/useProfessionalLicenses';
import useRequest from 'hooks/useRequest';
import { useLocalization } from 'services/localization/localization';
import { ProfessionalRouteParams, routeCreator, routes } from 'services/routing';
import { isAdminSelector } from 'store/session/sessionSelectors';
import { useTypedSelector } from 'store/store';

type PostInvitePatientRequestValues = BaseProfessionalInviteFormValues & {
  role: ProfessionalUserRoleType.Patient;
};

const InvitePatient: React.FC = () => {
  const { professionalId } = useParams<ProfessionalRouteParams>();
  const history = useHistory();
  const isAdmin = useTypedSelector(isAdminSelector);
  const { professional, licensesLeft, unlimitedLicenses } = useProfessionalLicenses(professionalId);

  const postRequest = useRequest<IUser, never, PostInvitePatientRequestValues>({
    method: 'POST',
    url: `/professionals/${professionalId}/professional-invites`,
  });

  const { getLocalizedString } = useLocalization();

  if (!unlimitedLicenses && licensesLeft <= 0 && professional !== null) {
    history.push(routes.Dashboard);
  }

  const handleSubmit: SubmitHandler<BaseProfessionalInviteFormValues> = async (values) => {
    await postRequest.request({ data: { ...values, role: ProfessionalUserRoleType.Patient } });

    // Send admins back to where they came from. Other users go to the invites overview
    if (history.action !== 'POP' && isAdmin) {
      history.goBack();
    } else {
      history.push(routeCreator.ProfessionalProfessionalInvites(professionalId));
    }
  };

  return (
    <DetailPage title={getLocalizedString('professional-invite__page-title')} maxWidth='sm'>
      <BaseProfessionalInviteForm<BaseProfessionalInviteFormValues> onSubmit={handleSubmit} />
    </DetailPage>
  );
};

export default InvitePatient;
