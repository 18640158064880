import { PropsWithChildren } from 'react';

import { FieldError } from 'react-hook-form';

import TextField from 'components/mui/TextField';
import { IUser } from 'core/user';
import { FormReturn, useReduceptForm } from 'hooks/useReduceptForm';

import { useLocalization } from 'services/localization/localization';
import { FormComponentProps } from '..';
import BaseForm from '../BaseForm';

export type PatientFormValues = Pick<IUser, 'first_name' | 'middle_name' | 'last_name'>;

export interface IBasePatientFormProps<V> {
  children(form: FormReturn<V>): React.ReactNode;
}

function BasePatientForm<V extends PatientFormValues>(
  props: PropsWithChildren<FormComponentProps<V>> & IBasePatientFormProps<V>,
) {
  const { children, defaultValues, onSubmit } = props;
  const form = useReduceptForm<V>(onSubmit, { defaultValues });

  const { getLocalizedString } = useLocalization();

  const {
    register,
    errors,
    formState: { touched },
  } = form;

  return (
    <BaseForm form={form}>
      <TextField
        error={Boolean(touched.first_name && errors.first_name)}
        fullWidth
        helperText={touched.first_name && (errors.first_name as FieldError)?.message}
        label='First name'
        margin='normal'
        translationKey='first_name'
        required
        aria-required
        inputRef={register({ required: getLocalizedString('required') })}
        type='text'
        variant='outlined'
      />
      <TextField
        error={Boolean(touched.middle_name && errors.middle_name)}
        fullWidth
        helperText={touched.middle_name && (errors.middle_name as FieldError)?.message}
        label='Middle name'
        margin='normal'
        translationKey='middle_name'
        inputRef={register()}
        type='text'
        variant='outlined'
      />
      <TextField
        error={Boolean(touched.last_name && errors.last_name)}
        fullWidth
        helperText={touched.last_name && (errors.last_name as FieldError)?.message}
        label='Last name'
        margin='normal'
        translationKey='last_name'
        inputRef={register()}
        type='text'
        variant='outlined'
      />

      {children(form)}
    </BaseForm>
  );
}

export default BasePatientForm;
