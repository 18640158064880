import { Typography } from '@material-ui/core';

import { ReactComponent as Logo } from 'assets/reducept-logo-no-text-white.svg';

const TopBarTitle: React.FC = (props) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <Logo height={30} />
      <Typography style={{ color: 'white', marginLeft: 16 }} variant='h4'>
        Reducept dashboard
      </Typography>
    </div>
  );
};

export default TopBarTitle;
