import React from 'react';

import MuiListItemText from '@material-ui/core/ListItemText';

const ListItemText: typeof MuiListItemText = ({ secondary, ...rest }) => {
  const fallbackSecondary = typeof secondary !== 'undefined' && !Boolean(secondary);

  return <MuiListItemText {...rest} secondary={fallbackSecondary ? '-' : secondary} />;
};

export default ListItemText;
