import React from 'react';

import { Box, Container, Hidden, makeStyles, Typography, useMediaQuery } from '@material-ui/core';

import Page from 'components/Page';
import { theme } from 'theme/theme';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    display: 'flex',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  headerTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerSubtitle: {
    paddingTop: 5,
    marginLeft: theme.spacing(1),
  },
  headerContainer: {
    display: 'flex',
    flex: 1,
    flexFlow: 'column nowrap',
  },
  contentContainer: {
    flexGrow: 1,
    marginTop: theme.spacing(3),
  },
}));

interface IProps {
  title: string;
  subtitle?: string | number;
  actions?: React.ReactNode;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
}

const DetailPage: React.FC<IProps> = (props) => {
  const classes = useStyles();
  const mobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const actionBar = (
    <Box
      display='flex'
      justifyContent={mobileScreen ? 'space-between' : 'flex-end'}
      alignItems='center'
    >
      {props.actions}
    </Box>
  );

  return (
    <Page className={classes.root} title={props.title}>
      <Container className={classes.headerContainer} maxWidth={props.maxWidth || false}>
        <Box className={classes.header}>
          <Box className={classes.headerTitle}>
            <Typography color='textPrimary' variant='h2'>
              {props.title}
            </Typography>
            {props.subtitle && (
              <Typography className={classes.headerSubtitle} color='textPrimary' variant='h5'>
                ({props.subtitle})
              </Typography>
            )}
          </Box>
          <Hidden xsDown>{actionBar}</Hidden>
        </Box>
        <Hidden smUp>{actionBar}</Hidden>
        <Box className={classes.contentContainer}>{props.children}</Box>
      </Container>
    </Page>
  );
};

export default DetailPage;
