import { GridColDef } from '@material-ui/data-grid';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';
import Localized from 'components/Localized';
import LuxonDate from 'components/LuxonDate';
import { renderDataGridCheckCell } from 'components/mui/DataGrid/DataGridCheckCell';
import { LoadedProfessional, LoadedUser } from 'core/backend';
import { IProfessionalInvite } from 'core/professional';
import { LoadedCreator } from 'core/professionalAccessPeriod';
import { routeCreator } from 'services/routing';
import { GridColumn, renderDateCell } from 'utils/mui';

export type ProfessionalInviteTableRowData = IProfessionalInvite &
  LoadedUser &
  LoadedProfessional &
  LoadedCreator;

/**
 * @param {GridCellParams} params
 *   Grid cell parameters.
 */
const renderUserCell: GridColDef['renderCell'] = (params) => {
  const row = params.row as ProfessionalInviteTableRowData;

  if (row.user === null) {
    return <span>(deleted)</span>;
  }

  return (
    <Link
      to={{
        pathname: routeCreator.UserDetail(row.user.id),
        state: row.user,
      }}
    >
      {row.user?.name}
    </Link>
  );
};

/**
 * @param {GridCellParams} params
 *   Grid cell parameters.
 */
const renderProfessionalCell: GridColDef['renderCell'] = (params) => {
  const row = params.row as ProfessionalInviteTableRowData;

  if (row.professional === null) {
    return <>(deleted)</>;
  }

  return (
    <Link
      to={{
        pathname: routeCreator.ProfessionalDetail(row.professional.id),
        state: row.professional,
      }}
    >
      {row.professional.name}
    </Link>
  );
};

/**
 * @param {GridCellParams} params
 *   Grid cell parameters.
 */
const renderEmailCell: GridColDef['renderCell'] = (params) => {
  const row = params.row as ProfessionalInviteTableRowData;

  if (row.user_id === null) {
    return <span>(deleted) {row.email}</span>;
  }

  return (
    <Link
      to={{
        pathname: routeCreator.UserDetail(row.user_id),
        state: row,
      }}
    >
      {row.email}
    </Link>
  );
};

/**
 * @param {GridCellParams} params
 *   Grid cell parameters.
 */
const renderRoleCell: GridColDef['renderCell'] = (params) => {
  const row = params.row as ProfessionalInviteTableRowData;

  return (
    <Localized id={row.role}>
      <span>{row.role}</span>
    </Localized>
  );
};

/**
 * @param {GridCellParams} params
 *   Grid cell parameters.
 */
const renderRespondedAtDateCell: GridColDef['renderCell'] = (params) => {
  const row = params.row as ProfessionalInviteTableRowData;

  if (row.responded_at === null) {
    return <span>-</span>;
  }

  return <LuxonDate date={row.responded_at} format={DateTime.DATETIME_SHORT} />;
};

/**
 * @param {GridCellParams} params
 *   Grid cell parameters.
 */
const renderCreatedByCell: GridColDef['renderCell'] = (params) => {
  const row = params.row as ProfessionalInviteTableRowData;

  if (!row.creator) {
    return <span>-</span>;
  }

  const inner = (
    <Link
      to={{
        pathname: routeCreator.UserDetail(row.creator.id),
      }}
    >
      {row.creator.name}
    </Link>
  );

  return <span>{inner}</span>;
};

export const professionalInvitesTableColumns: GridColumn<ProfessionalInviteTableRowData>[] = [
  {
    field: 'professional',
    headerName: 'Practice',
    flex: 1,
    renderCell: renderProfessionalCell,
    sortable: false,
    filterable: false,
  },
  {
    field: 'invited_user',
    headerName: 'Invited user',
    flex: 1,
    renderCell: renderUserCell,
    sortable: false,
    filterable: false,
  },
  {
    field: 'invited_user_email',
    headerName: 'Email',
    flex: 0.75,
    renderCell: renderEmailCell,
    sortable: false,
    filterable: false,
  },
  {
    field: 'role',
    headerName: 'Role',
    flex: 0.5,
    renderCell: renderRoleCell,
    sortable: false,
    filterable: false,
  },
  {
    field: 'responded_at',
    headerName: 'Redeemed',
    flex: 0.5,
    renderCell: renderDataGridCheckCell,
    filterable: false,
    sortable: false,
  },
  {
    field: 'responded_at_date',
    headerName: 'Redeemed at',
    flex: 0.5,
    renderCell: renderRespondedAtDateCell,
    filterable: false,
    sortable: false,
  },
  {
    field: 'last_sent_at',
    headerName: 'Last sent at',
    flex: 0.5,
    renderCell: renderDateCell(DateTime.DATETIME_SHORT, '-'),
    filterable: false,
  },
  {
    field: 'created_by',
    headerName: 'Creator',
    flex: 0.75,
    renderCell: renderCreatedByCell,
    sortable: false,
    filterable: false,
  },
  {
    field: 'created_at',
    headerName: 'Creation',
    flex: 0.5,
    renderCell: renderDateCell(DateTime.DATETIME_SHORT),
    filterable: false,
  },
];
