import React from 'react';

import { SubmitHandler } from 'react-hook-form';
import { RouteComponentProps } from 'react-router-dom';

import DetailPage from 'components/DetailPage';
import ChangePasswordForm from 'components/forms/ChangePasswordForm';
import { IChangeUserPasswordPayload, IUser } from 'core/user';
import useRequest from 'hooks/useRequest';
import useStateOrFetch from 'hooks/useStateOrFetch';
import { useLocalization } from 'services/localization/localization';
import { history, routeCreator, UserRouteParams } from 'services/routing';

import ErrorPage from 'pages/ErrorPage';
import LoadingPage from 'pages/LoadingPage';

type FetchResponse = IUser;
type Props = RouteComponentProps<UserRouteParams, any, FetchResponse>;

const ChangeUserPassword: React.FC<Props> = (props) => {
  const { userId } = props.match.params;

  const request = useStateOrFetch<FetchResponse | null>(props.location.state, `/users/${userId}`);
  const postRequest = useRequest<{ message: string }, never, IChangeUserPasswordPayload>({
    url: `/users/${userId}/change-password`,
    method: 'POST',
  });

  const { getLocalizedString } = useLocalization();

  if (request.loading && !request.data) {
    return <LoadingPage />;
  }

  if (!request.data) {
    return <ErrorPage />;
  }

  const user = request.data as FetchResponse;

  const handleSubmit: SubmitHandler<IChangeUserPasswordPayload> = async (values) => {
    try {
      await postRequest.request({
        data: values,
      });

      /** @todo add Snackbar notification */
      alert(getLocalizedString('password_changed_success'));

      history.push(routeCreator.UserDetail(userId), user);
    } catch (error) {
      alert(getLocalizedString('error_message'));
      throw error;
    }
  };

  const pageTitle = getLocalizedString('change-password');

  return (
    <DetailPage title={pageTitle} subtitle={user.name} maxWidth='xs'>
      <ChangePasswordForm onSubmit={handleSubmit} />
    </DetailPage>
  );
};

export default ChangeUserPassword;
