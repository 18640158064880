import React from 'react';

import { SubmitHandler } from 'react-hook-form';
import { RouteComponentProps } from 'react-router-dom';

import DetailPage from 'components/DetailPage';
import UserForm, { UserFormValues } from 'components/forms/users/UserForm';
import { IUser } from 'core/user';
import useRequest from 'hooks/useRequest';
import useStateOrFetch from 'hooks/useStateOrFetch';
import { useLocalization } from 'services/localization/localization';
import { routeCreator, UserRouteParams } from 'services/routing';

import ErrorPage from 'pages/ErrorPage';
import LoadingPage from 'pages/LoadingPage';

type FetchResponse = IUser;
type Props = RouteComponentProps<UserRouteParams, any, FetchResponse | undefined>;

const EditUser: React.FC<Props> = (props) => {
  const { userId } = props.match.params;

  const request = useStateOrFetch<FetchResponse | null>(props.location.state, `/users/${userId}`);
  const putRequest = useRequest<FetchResponse, never, UserFormValues>({
    url: `/users/${userId}`,
    method: 'PUT',
  });

  const { getLocalizedString } = useLocalization();

  if (request.loading && !request.data) {
    return <LoadingPage />;
  }

  if (!request.data) {
    return <ErrorPage />;
  }

  const user = request.data as FetchResponse;

  const handleSubmit: SubmitHandler<UserFormValues> = async (values) => {
    const response = await putRequest.request({
      data: values,
    });

    props.history.push(routeCreator.UserDetail(userId), response.data);
  };

  return (
    <DetailPage
      title={getLocalizedString('update-model', {
        model: getLocalizedString('user').toLowerCase(),
      })}
      subtitle={user.name}
      maxWidth='xs'
    >
      <UserForm isEditForm onSubmit={handleSubmit} defaultValues={user} />
    </DetailPage>
  );
};

export default EditUser;
