import React from 'react';

import { Tab } from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { DateTime } from 'luxon';
import { SubmitHandler } from 'react-hook-form';
import { RouteComponentProps } from 'react-router-dom';

import DetailPage from 'components/DetailPage';
import { getServerSelectValue } from 'components/forms/elements/ServerSelect';
import ProfessionalSubscriptionForm, {
  IProfessionalSubscriptionFormValues,
} from 'components/forms/subscriptions/ProfessionalSubscriptionForm';
import UserSubscriptionForm, {
  IUserSubscriptionFormValues,
} from 'components/forms/subscriptions/UserSubscriptionForm';
import { ISubscription } from 'core/subscription';
import useRequest from 'hooks/useRequest';
import { useLocalization } from 'services/localization/localization';
import { routeCreator } from 'services/routing';
import { setMidnight } from 'utils/utils';

type PostProfessionalSubscriptionRequestValues = PostUserSubscriptionRequestValues &
  Pick<ISubscription, 'professional_id' | 'amount_licenses' | 'licenses_reset_interval'>;

type PostUserSubscriptionRequestValues = Pick<
  ISubscription,
  'user_id' | 'start_date' | 'end_date' | 'provider_type'
>;

enum TabValues {
  Professional = '1',
  User = '2',
}

const CreateSubscription: React.FC<RouteComponentProps> = (props) => {
  const [tabValue, setTabValue] = React.useState<TabValues>(TabValues.Professional);
  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: TabValues) => {
    setTabValue(newValue);
  };

  const postRequest = useRequest<
    ISubscription,
    never,
    PostProfessionalSubscriptionRequestValues | PostUserSubscriptionRequestValues
  >({
    url: `/subscriptions`,
    method: 'POST',
  });

  const { getLocalizedString } = useLocalization();

  const handleProfessionalSubmit: SubmitHandler<IProfessionalSubscriptionFormValues> = async ({
    start_date,
    end_date,
    user,
    professional,
    ...values
  }) => {
    if (typeof professional !== 'object' || professional === null) {
      throw new Error();
    }

    const response = await postRequest.request({
      data: {
        user_id: getServerSelectValue(user)?.id || null,
        professional_id: getServerSelectValue(professional)!.id,
        provider_type: values.provider_type,
        licenses_reset_interval: values.licenses_reset_interval,
        amount_licenses: values.amount_licenses || null,
        start_date: start_date.toISO(),
        end_date: end_date?.toISO() || null,
      },
    });

    props.history.push(routeCreator.SubscriptionDetail(response.data.id));
  };

  const handleUserSubmit: SubmitHandler<IUserSubscriptionFormValues> = async ({
    start_date,
    end_date,
    user,
    ...values
  }) => {
    if (typeof user !== 'object' || user === null) {
      throw new Error();
    }

    const response = await postRequest.request({
      data: {
        user_id: getServerSelectValue(user)!.id,
        provider_type: values.provider_type,
        start_date: start_date.toISO(),
        end_date: end_date?.toISO() || null,
      },
    });

    props.history.push(routeCreator.SubscriptionDetail(response.data.id));
  };

  const pageTitle = getLocalizedString('add-model', {
    model: getLocalizedString('subscription').toLowerCase(),
  });

  return (
    <DetailPage title={pageTitle} maxWidth='xs'>
      <TabContext value={tabValue}>
        <TabList onChange={handleTabChange} centered variant='fullWidth'>
          <Tab label={getLocalizedString('practice')} value={TabValues.Professional} />
          <Tab label={getLocalizedString('user')} value={TabValues.User} />
        </TabList>

        <TabPanel value={TabValues.Professional}>
          <ProfessionalSubscriptionForm
            onSubmit={handleProfessionalSubmit}
            defaultValues={{
              professional: null,
              licenses_reset_interval: 'month',
              amount_licenses: 5,
              provider_type: 'manual',
              start_date: setMidnight(DateTime.now()),
              end_date: setMidnight(DateTime.now().plus({ months: 3 })),
            }}
          />
        </TabPanel>

        <TabPanel value={TabValues.User}>
          <UserSubscriptionForm
            onSubmit={handleUserSubmit}
            defaultValues={{
              user: null,
              provider_type: 'manual',
              start_date: setMidnight(DateTime.now()),
              end_date: setMidnight(DateTime.now().plus({ months: 3 })),
            }}
          />
        </TabPanel>
      </TabContext>
    </DetailPage>
  );
};

export default CreateSubscription;
