import React from 'react';

import { LocationState } from 'history';
import { useHistory } from 'react-router-dom';

import Button, { Props as ButtonProps } from './Button';

interface IProps extends ButtonProps {
  /**
   * Path to navigate to.
   */
  to: string;

  state?: LocationState;
}

const LinkButton: React.FC<IProps> = ({ to, state, onClick, ...rest }) => {
  const history = useHistory();

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    if (typeof onClick === 'function') {
      onClick(event);
    }

    history.push(to, state);
  };

  return <Button onClick={handleClick} {...rest} />;
};

export default LinkButton;
