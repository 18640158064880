import React from 'react';

import { Box, Card, CardContent, CardHeader, Divider } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { Activity as ActivityIcon } from 'react-feather';
import { Link } from 'react-router-dom';

import CheckIcon from 'components/CheckIcon';
import LuxonDate from 'components/LuxonDate';
import ListItemText from 'components/mui/ListItemText';
import RoleBoundary from 'components/RoleBoundary';
import { useLocalization } from 'services/localization/localization';
import { Role } from 'services/roles';
import { routeCreator } from 'services/routing';

import { PlayerAccountFetchResponse } from '../PlayerAccountDetail';

interface IProps {
  playerAccount: PlayerAccountFetchResponse;
}

const CardPlayerAccountDetails: React.FC<IProps> = ({ playerAccount }) => {
  const { getLocalizedString } = useLocalization();

  // An attempt was done to load the professional within the request.
  const includesProfessional =
    playerAccount.professional !== undefined && playerAccount.professional !== null;

  return (
    <Card>
      <CardHeader
        avatar={<ActivityIcon size={25} />}
        titleTypographyProps={{
          color: 'textPrimary',
          variant: 'h5',
        }}
        title={getLocalizedString('information')}
        subheader={getLocalizedString('general-data', {
          model: getLocalizedString('player_account').toLowerCase(),
        })}
      />
      <Divider />
      <CardContent>
        <Box height={555} position='relative'>
          <List dense disablePadding>
            <ListItem>
              <ListItemText
                primary={getLocalizedString('associated_user')}
                secondary={
                  playerAccount.user === null || playerAccount.user === undefined ? (
                    '-'
                  ) : (
                    <>
                      <RoleBoundary allowed={[Role.Therapist, Role.Owner]}>
                        <Link
                          to={{
                            pathname: routeCreator.PatientDetail(
                              playerAccount.professional_id!,
                              playerAccount.user_id,
                            ),
                          }}
                        >
                          {playerAccount.user.name}
                        </Link>
                      </RoleBoundary>
                      <RoleBoundary allowed={[Role.Admin]}>
                        <Link
                          to={{
                            pathname: routeCreator.UserDetail(playerAccount.user_id),
                          }}
                        >
                          {playerAccount.user.name}
                        </Link>
                      </RoleBoundary>
                    </>
                  )
                }
              />
            </ListItem>
            {includesProfessional && (
              <ListItem>
                <ListItemText
                  primary={getLocalizedString('associated_practice')}
                  secondary={
                    playerAccount.professional === null ||
                    playerAccount.professional === undefined ? (
                      '-'
                    ) : (
                      <>
                        <RoleBoundary allowed={[Role.Therapist, Role.Owner]}>
                          {playerAccount.professional.name}
                        </RoleBoundary>
                        <RoleBoundary allowed={[Role.Admin]}>
                          <Link
                            to={{
                              pathname: routeCreator.ProfessionalDetail(
                                playerAccount.professional_id!,
                              ),
                            }}
                          >
                            {playerAccount.professional.name}
                          </Link>
                        </RoleBoundary>
                      </>
                    )
                  }
                />
              </ListItem>
            )}
            <ListItem>
              <ListItemText
                primary={getLocalizedString('has_finished_introduction')}
                secondary={
                  <CheckIcon condition={playerAccount.has_finished_introduction ?? false} />
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={getLocalizedString('last_brain_pattern_length')}
                secondary={playerAccount.last_brain_pattern_length}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={getLocalizedString('brain_level')}
                secondary={playerAccount.brain_level}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={getLocalizedString('nerve_level')}
                secondary={playerAccount.nerve_level}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={getLocalizedString('nerve_highscore')}
                secondary={playerAccount.nerve_highscore}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={getLocalizedString('created_at')}
                secondary={<LuxonDate date={playerAccount.created_at} fallback='-' />}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={getLocalizedString('updated_at')}
                secondary={<LuxonDate date={playerAccount.updated_at} fallback='-' />}
              />
            </ListItem>
          </List>
        </Box>
      </CardContent>
    </Card>
  );
};

export default CardPlayerAccountDetails;
