import React from 'react';
import { SubmitHandler } from 'react-hook-form';
import { RouteComponentProps } from 'react-router-dom';
import DetailPage from 'components/DetailPage';

import { getServerSelectValue } from 'components/forms/elements/ServerSelect';
import ProfessionalForm, {
  ProfessionalFormValues,
} from 'components/forms/professionals/ProfessionalForm';
import { LoadedOrganization } from 'core/backend';
import { IProfessional, IProfessionalFull } from 'core/professional';
import useRequest from 'hooks/useRequest';
import useStateOrFetch from 'hooks/useStateOrFetch';
import { useLocalization } from 'services/localization/localization';
import { ProfessionalRouteParams, routeCreator } from 'services/routing';
import ErrorPage from 'pages/ErrorPage';
import LoadingPage from 'pages/LoadingPage';

type FetchResponse = IProfessional & LoadedOrganization;
type EditProfessionalRequestValues = Pick<IProfessionalFull, 'name' | 'organization_id'>;

type Props = RouteComponentProps<ProfessionalRouteParams, never, FetchResponse | undefined>;

const EditProfessional: React.FC<Props> = (props) => {
  const { professionalId } = props.match.params;

  const request = useStateOrFetch<FetchResponse | null>(
    props.location.state,
    `/professionals/${professionalId}`,
    { includes: ['organization'] },
  );

  const putRequest = useRequest<FetchResponse, never, EditProfessionalRequestValues>({
    url: `/professionals/${professionalId}`,
    method: 'PUT',
  });

  const { getLocalizedString } = useLocalization();

  if (request.loading && !request.data) {
    return <LoadingPage />;
  }
  if (!request.data) {
    return <ErrorPage />;
  }

  const professional = request.data as FetchResponse;

  const handleSubmit: SubmitHandler<ProfessionalFormValues> = async ({
    organization,
    ...values
  }) => {
    await putRequest.request({
      data: {
        ...values,
        organization_id: getServerSelectValue(organization)?.id || null,
      },
    });

    props.history.push(routeCreator.ProfessionalDetail(professionalId));
  };

  const pageTitle = getLocalizedString('update-model', {
    model: getLocalizedString('practice').toLowerCase(),
  });

  return (
    <DetailPage title={pageTitle} subtitle={professional.name} maxWidth='xs'>
      <ProfessionalForm
        isEditForm
        onSubmit={handleSubmit}
        defaultValues={{ ...professional, organization: professional.organization }}
      />
    </DetailPage>
  );
};

export default EditProfessional;
