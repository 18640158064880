import React, { FormEventHandler } from 'react';

import {
  Card,
  colors,
  Dialog,
  DialogContentText,
  DialogProps,
  makeStyles,
} from '@material-ui/core';

import Button from 'components/Button';
import useRequest from 'hooks/useRequest';
import { useLocalization } from 'services/localization/localization';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(3),
    borderWidth: 8,
    borderColor: colors.common.white,
    hoverBorderColor: colors.common.white,
  },
  button: {
    marginTop: 8,
  },
}));

interface IProps {
  userId: string;
  professionalId: string;
  onPatientDeleted: () => void;
}

export type DeletePatientDialogProps = IProps & Pick<DialogProps, 'open' | 'onClose'>;

const DeletePatientDialog: React.FC<DeletePatientDialogProps> = (props) => {
  const classes = useStyles();

  const deletePatientRequest = useRequest<{ message: string }, never, null>({
    url: `/professionals/${props.professionalId}/users/${props.userId}`,
    method: 'DELETE',
  });

  const { getLocalizedString } = useLocalization();

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    try {
      await deletePatientRequest.request();
      props.onPatientDeleted();
    } catch (error) {
      /**
       * @todo - Add a pop-up to show the error to users.
       */
      console.error('Error while submitting form', error);
    }
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby='form-dialog-title'
      fullWidth
      maxWidth='sm'
    >
      <form onSubmit={handleSubmit} noValidate={true}>
        <Card className={classes.card}>
          <DialogContentText>{getLocalizedString('delete_patient_confirm')}</DialogContentText>
          <Button
            translationKey='delete'
            className={classes.button}
            color='primary'
            loading={deletePatientRequest.loading}
            disabled={deletePatientRequest.loading}
            fullWidth
            size='large'
            type='submit'
            variant='contained'
          >
            Delete
          </Button>
        </Card>
      </form>
    </Dialog>
  );
};

export default DeletePatientDialog;
