import { MenuItem } from '@material-ui/core';
import { FieldError } from 'react-hook-form';

import TextField from 'components/mui/TextField';
import { IProfessionalUser, ProfessionalUserRoleType } from 'core/professional';
import { IUser } from 'core/user';
import { useReduceptForm } from 'hooks/useReduceptForm';
import { AvailableLanguages, useLocalization } from 'services/localization/localization';
import { isAdminSelector, isOwnerSelector } from 'store/session/sessionSelectors';
import { useTypedSelector } from 'store/store';
import { emailValidationPattern } from 'utils/forms';

import { FormComponent } from '..';
import BaseForm from '../BaseForm';
import Select from '../elements/Select';

export type StaffFormValues = Pick<
  IUser,
  'first_name' | 'middle_name' | 'last_name' | 'email' | 'language'
> &
  Pick<IProfessionalUser, 'role'>;

interface IProps {
  professionalId: string | number;
  isEditForm?: true;
}

const StaffForm: FormComponent<StaffFormValues, IProps> = (props) => {
  const { getLocalizedString } = useLocalization();

  const defaultValues: Partial<StaffFormValues> = {
    language: 'nl-NL',
    ...props.defaultValues,
  };

  const isOwner = useTypedSelector(isOwnerSelector);
  const isAdmin = useTypedSelector(isAdminSelector);
  const isOwnerOrAdmin = isOwner || isAdmin;
  const form = useReduceptForm<StaffFormValues>(props.onSubmit, { defaultValues });

  const {
    register,
    errors,
    control,
    formState: { touched, submitCount },
  } = form;

  return (
    <BaseForm form={form}>
      <TextField
        error={Boolean(touched.first_name && errors.first_name)}
        fullWidth
        helperText={touched.first_name && (errors.first_name as FieldError)?.message}
        label='First name'
        margin='normal'
        translationKey='first_name'
        required
        aria-required
        inputRef={register({ required: getLocalizedString('required') })}
        type='text'
        variant='outlined'
      />
      <TextField
        error={Boolean(touched.middle_name && errors.middle_name)}
        fullWidth
        helperText={touched.middle_name && (errors.middle_name as FieldError)?.message}
        label='Middle name'
        margin='normal'
        translationKey='middle_name'
        inputRef={register()}
        type='text'
        variant='outlined'
      />
      <TextField
        error={Boolean(touched.last_name && errors.last_name)}
        fullWidth
        helperText={touched.last_name && (errors.last_name as FieldError)?.message}
        label='Last name'
        margin='normal'
        translationKey='last_name'
        inputRef={register()}
        type='text'
        variant='outlined'
      />
      <TextField
        error={Boolean((touched.email || submitCount > 0) && errors.email)}
        fullWidth
        helperText={(touched.email || submitCount > 0) && (errors.email as FieldError)?.message}
        label='E-mail'
        margin='normal'
        translationKey='email'
        required={!props.isEditForm}
        inputRef={register({
          pattern: emailValidationPattern(getLocalizedString),
          required: true,
        })}
        InputProps={{
          readOnly: props.isEditForm,
        }}
        type='email'
        variant={props.isEditForm ? 'filled' : 'outlined'}
      />
      <Select
        label='Language'
        controllerProps={{
          control,
          name: 'language',
          rules: {
            required: getLocalizedString('required'),
          },
        }}
        variant='outlined'
        fullWidth
        margin='normal'
        required
      >
        {Object.entries(AvailableLanguages).map(([key, value]) => (
          <MenuItem selected value={key}>
            {value}
          </MenuItem>
        ))}
      </Select>
      <Select
        error={Boolean(touched.role && errors.role)}
        label='Role'
        controllerProps={{
          control,
          name: 'role',
          rules: {
            required: true,
          },
          defaultValue: ProfessionalUserRoleType.Therapist,
        }}
        readOnly={!isOwnerOrAdmin}
        variant={isOwnerOrAdmin ? 'outlined' : 'filled'}
        fullWidth
        margin='normal'
        required
      >
        <MenuItem selected value={ProfessionalUserRoleType.Therapist}>
          {getLocalizedString('therapist')}
        </MenuItem>
        {isOwnerOrAdmin && (
          <MenuItem value={ProfessionalUserRoleType.Owner}>{getLocalizedString('owner')}</MenuItem>
        )}
      </Select>
    </BaseForm>
  );
};

export default StaffForm;
