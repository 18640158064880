export type SortDirection = 'asc' | 'desc';

export function sortRows<T = object>(objects: T[], column: keyof T, direction: SortDirection): T[] {
  const clonedObjects = objects.slice();

  const sortedObjects = clonedObjects.sort((objA, objB) => {
    if (objA[column] < objB[column]) {
      return 1;
    }

    if (objA[column] > objB[column]) {
      return -1;
    }

    return 0;
  });

  if (direction === 'asc') {
    sortedObjects.reverse();
  }

  return sortedObjects;
}
