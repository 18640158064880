import React from 'react';

import { Redirect, Route, Switch } from 'react-router-dom';

import PrivateRoute from 'components/PrivateRoute';
import DashboardLayout from 'layouts/DashboardLayout/DashboardLayout';
import MainLayout from 'layouts/MainLayout/MainLayout';
import { routes } from 'services/routing';
import { loggedInSelector } from 'store/session/sessionSelectors';
import { useTypedSelector } from 'store/store';

import Login from 'pages/authentication/Login';
import PasswordReset from 'pages/authentication/PasswordReset';
import PasswordResetConfirm from 'pages/authentication/PasswordResetConfirm';
import RegisterConfirm from 'pages/authentication/RegisterConfirm';
import Dashboard from 'pages/Dashboard';
import DeviceDetail from 'pages/devices/DeviceDetail/DeviceDetail';
import DevicesOverview from 'pages/devices/DevicesOverview';
import EditDevice from 'pages/devices/EditDevice/EditDevice';
import OrganizationDetail from 'pages/organizations/OrganizationDetail/OrganizationDetail';
import OrganizationsOverview from 'pages/organizations/OrganizationsOverview';
import ChangePatientPassword from 'pages/patients/ChangePatientPassword/ChangePatientPassword';
import CreatePatient from 'pages/patients/CreatePatient/CreatePatient';
import EditPatient from 'pages/patients/EditPatient/EditPatient';
import InvitePatient from 'pages/patients/InvitePatient/InvitePatient';
import PatientDetail from 'pages/patients/PatientDetail/PatientDetail';
import ProfessionalPatientsOverview from 'pages/patients/ProfessionalPatientsOverview';
import PlayerAccountDetail from 'pages/playerAccounts/PlayerAccountDetail/PlayerAccountDetail';
import PlayerAccountsOverview from 'pages/playerAccounts/PlayerAccountsOverview';
import CreateProduct from 'pages/products/CreateProduct';
import EditProduct from 'pages/products/EditProduct';
import ProductDetail from 'pages/products/ProductDetail';
import ProductsOverview from 'pages/products/ProductsOverview';
import ProfessionalInviteConfirm from 'pages/ProfessionalInviteConfirm';
import CreateProfessional from 'pages/professionals/CreateProfessional/CreateProfessional';
import EditProfessional from 'pages/professionals/EditProfessional/EditProfessional';
import ProfessionalDetail from 'pages/professionals/ProfessionalDetail/ProfessionalDetail';
import ProfessionalDevicesOverview from 'pages/professionals/ProfessionalDevices/ProfessionalDevicesOverview';
import ProfessionalInvitesOverview from 'pages/professionals/ProfessionalInvitesOverview';
import CreateProfessionalGroup from 'pages/professionals/ProfessionalProfessionalGroups/CreateProfessionalGroup/CreateProfessionalGroup';
import EditProfessionalGroup from 'pages/professionals/ProfessionalProfessionalGroups/EditProfessionalGroup/EditProfessionalGroup';
import ProfessionalGroupDetail from 'pages/professionals/ProfessionalProfessionalGroups/ProfessionalGroupDetail/ProfessionalGroupDetail';
import ProfessionalProfessionalGroupsOverview from 'pages/professionals/ProfessionalProfessionalGroups/ProfessionalProfessionalGroupsOverview';
import ProfessionalProfessionalInvitesOverview from 'pages/professionals/ProfessionalProfessionalInvites/ProfessionalProfessionalInvitesOverview';
import ProfessionalsOverview from 'pages/professionals/ProfessionalsOverview';
import AdministratorsOverview from 'pages/roles/AdministratorsOverview/AdministratorsOverview';
import RolesOverview from 'pages/roles/RolesOverview';
import CreateStaff from 'pages/staff/CreateStaff/CreateStaff';
import EditStaff from 'pages/staff/EditStaff/EditStaff';
import InviteStaff from 'pages/staff/InviteStaff/InviteStaff';
import ProfessionalStaffOverview from 'pages/staff/ProfessionalStaffOverview';
import StaffDetail from 'pages/staff/StaffDetail/StaffDetail';
import CreateSubscription from 'pages/subscriptions/CreateSubscription';
import EditSubscription from 'pages/subscriptions/EditSubscription';
import SubscriptionDetail from 'pages/subscriptions/SubscriptionDetail/SubscriptionDetail';
import SubscriptionsOverview from 'pages/subscriptions/SubscriptionsOverview';
import TeamleaderCompaniesOverview from 'pages/teamleader/TeamleaderCompaniesOverview';
import TeamleaderCompanyAttachOrganization from 'pages/teamleader/TeamleaderCompanyAttachOrganization';
import TeamleaderCompanyAttachProfessional from 'pages/teamleader/TeamleaderCompanyAttachProfessional';
import TeamleaderCompanyManageOrganizationSubscriptions from 'pages/teamleader/TeamleaderCompanyManageOrganizationSubscriptions';
import TeamleaderOrganizationsOverview from 'pages/teamleader/TeamleaderOrganizationsOverview';
import TeamleaderProductsAttach from 'pages/teamleader/TeamleaderProductsAttach';
import TeamleaderProductsOverview from 'pages/teamleader/TeamleaderProductsOverview';
import TeamleaderStatus from 'pages/teamleader/TeamleaderStatus/TeamleaderStatus';
import TeamleaderSubscriptionsOverview from 'pages/teamleader/TeamleaderSubscriptionsOverview';
import ChangeUserPassword from 'pages/users/ChangeUserPassword/ChangeUserPassword';
import CreateUser from 'pages/users/CreateUser/CreateUser';
import EditUser from 'pages/users/EditUser/EditUser';
import UserDetail from 'pages/users/UserDetail/UserDetail';
import UsersOverview from 'pages/users/UsersOverview';

const App: React.FC = () => {
  const loggedIn = useTypedSelector(loggedInSelector);

  const LayoutComponent = loggedIn ? DashboardLayout : MainLayout;

  return (
    <Route
      path='/'
      render={(props) => (
        <LayoutComponent {...props}>
          <Switch>
            <Route exact path={routes.Login} component={Login} />
            <Route exact path={routes.RegisterConfirm} component={RegisterConfirm} />
            <Route exact path={routes.PasswordReset} component={PasswordReset} />
            <Route exact path={routes.PasswordResetConfirm} component={PasswordResetConfirm} />
            <Route
              exact
              path={routes.ProfessionalInviteConfirm}
              component={ProfessionalInviteConfirm}
            />

            <PrivateRoute exact path={routes.Dashboard} component={Dashboard} />

            <PrivateRoute exact {...routes.Users} component={UsersOverview} />
            <PrivateRoute exact {...routes.CreateUser} component={CreateUser} />
            <PrivateRoute exact {...routes.EditUser} component={EditUser} />
            <PrivateRoute exact {...routes.UserDetail} component={UserDetail} />
            <PrivateRoute exact {...routes.ChangeUserPassword} component={ChangeUserPassword} />

            <PrivateRoute exact {...routes.Subscriptions} component={SubscriptionsOverview} />
            <PrivateRoute exact {...routes.CreateSubscription} component={CreateSubscription} />
            <PrivateRoute exact {...routes.EditSubscription} component={EditSubscription} />
            <PrivateRoute exact {...routes.SubscriptionDetail} component={SubscriptionDetail} />

            <PrivateRoute exact {...routes.DeviceDetail} component={DeviceDetail} />
            <PrivateRoute exact {...routes.EditDevice} component={EditDevice} />

            <PrivateRoute exact {...routes.CreatePatient} component={CreatePatient} />
            <PrivateRoute exact {...routes.InvitePatient} component={InvitePatient} />
            <PrivateRoute exact {...routes.EditPatient} component={EditPatient} />
            <PrivateRoute exact {...routes.PatientDetail} component={PatientDetail} />
            <PrivateRoute
              exact
              {...routes.ChangePatientPassword}
              component={ChangePatientPassword}
            />

            <PrivateRoute exact {...routes.CreateStaff} component={CreateStaff} />
            <PrivateRoute exact {...routes.InviteStaff} component={InviteStaff} />
            <PrivateRoute exact {...routes.EditStaff} component={EditStaff} />
            <PrivateRoute exact {...routes.StaffDetail} component={StaffDetail} />

            <PrivateRoute
              exact
              {...routes.CreateProfessionalGroup}
              component={CreateProfessionalGroup}
            />
            <PrivateRoute
              exact
              {...routes.EditProfessionalGroup}
              component={EditProfessionalGroup}
            />

            <PrivateRoute exact {...routes.CreateProfessional} component={CreateProfessional} />

            <PrivateRoute exact {...routes.CreateProduct} component={CreateProduct} />
            <PrivateRoute exact {...routes.ProductDetail} component={ProductDetail} />
            <PrivateRoute exact {...routes.EditProduct} component={EditProduct} />

            <PrivateRoute exact {...routes.EditProfessional} component={EditProfessional} />
            <PrivateRoute exact {...routes.ProfessionalDetail} component={ProfessionalDetail} />
            <PrivateRoute exact {...routes.Professionals} component={ProfessionalsOverview} />
            <PrivateRoute
              exact
              {...routes.ProfessionalPatients}
              component={ProfessionalPatientsOverview}
            />
            <PrivateRoute
              exact
              {...routes.ProfessionalGroupDetail}
              component={ProfessionalGroupDetail}
            />
            <PrivateRoute
              exact
              {...routes.ProfessionalProfessionalGroups}
              component={ProfessionalProfessionalGroupsOverview}
            />
            <PrivateRoute
              exact
              {...routes.ProfessionalStaff}
              component={ProfessionalStaffOverview}
            />
            <PrivateRoute
              exact
              {...routes.ProfessionalDevices}
              component={ProfessionalDevicesOverview}
            />
            <PrivateRoute
              exact
              {...routes.ProfessionalProfessionalInvites}
              component={ProfessionalProfessionalInvitesOverview}
            />

            <PrivateRoute exact {...routes.OrganizationDetail} component={OrganizationDetail} />

            <PrivateRoute exact {...routes.PlayerAccountDetail} component={PlayerAccountDetail} />
            <PrivateRoute {...routes.PlayerAccounts} component={PlayerAccountsOverview} />

            <PrivateRoute exact {...routes.Devices} component={DevicesOverview} />
            <PrivateRoute exact {...routes.Organizations} component={OrganizationsOverview} />
            <PrivateRoute exact {...routes.Administrators} component={AdministratorsOverview} />
            <PrivateRoute exact {...routes.Roles} component={RolesOverview} />
            <PrivateRoute exact {...routes.Products} component={ProductsOverview} />
            <PrivateRoute exact {...routes.TeamleaderStatus} component={TeamleaderStatus} />

            <PrivateRoute
              exact
              {...routes.TeamleaderProductAttach}
              component={TeamleaderProductsAttach}
            />

            <PrivateRoute
              exact
              {...routes.TeamleaderCompanyAttachOrganization}
              component={TeamleaderCompanyAttachOrganization}
            />
            <PrivateRoute
              exact
              {...routes.TeamleaderCompanyManageOrganizationSubscriptions}
              component={TeamleaderCompanyManageOrganizationSubscriptions}
            />
            <PrivateRoute
              exact
              {...routes.TeamleaderCompanyAttachProfessional}
              component={TeamleaderCompanyAttachProfessional}
            />
            <PrivateRoute
              exact
              {...routes.TeamleaderProducts}
              component={TeamleaderProductsOverview}
            />
            <PrivateRoute
              exact
              {...routes.TeamleaderSubscriptions}
              component={TeamleaderSubscriptionsOverview}
            />
            <PrivateRoute
              exact
              {...routes.TeamleaderCompanies}
              component={TeamleaderCompaniesOverview}
            />
            <PrivateRoute
              exact
              {...routes.TeamleaderOrganizations}
              component={TeamleaderOrganizationsOverview}
            />
            <PrivateRoute
              exact
              {...routes.ProfessionalInvites}
              component={ProfessionalInvitesOverview}
            />

            <Redirect to={routes.Login} />
          </Switch>
        </LayoutComponent>
      )}
    />
  );
};

export default App;
