import React from 'react';

import { Box, Card, CardContent, CardHeader, Typography } from '@material-ui/core';
import { Datum, Point, PointTooltip, ResponsiveLineCanvas, Serie } from '@nivo/line';
import { TooltipWrapper } from '@nivo/tooltip';
import { DateTime } from 'luxon';
import { Activity as ActivityIcon } from 'react-feather';

import ProgressDivider from 'components/ProgressDivider';
import { IUser, IUserPainScore } from 'core/user';
import useProfessionalFetch from 'hooks/useProfessionalFetch';
import { useLocalization } from 'services/localization/localization';
import colors from 'theme/colors';
import { theme } from 'theme/theme';

export interface IDateRangeFilterProps {
  startDate: DateTime | null;
  endDate: DateTime | null;
}

type Props = {
  user: IUser;
} & Partial<IDateRangeFilterProps>;

const ToolTip: PointTooltip = ({ point }: { point: Point }) => {
  const { getLocalizedString } = useLocalization();

  return (
    <TooltipWrapper anchor='top' position={[0, 0]}>
      <div
        style={{
          background: 'rgba(255, 255, 255, 0.8)',
          whiteSpace: 'nowrap',
          padding: '9px 12px',
          fontSize: 14,
          border: '1px solid ' + theme.palette.secondary.main,
          fontFamily: theme.typography.fontFamily ? theme.typography.fontFamily : 'inherit',
        }}
      >
        <div key={point.serieId}>
          <div
            style={{
              textAlign: 'center',
              borderBottom: '1px solid' + theme.palette.primary.main,
              padding: 5,
            }}
          >
            <strong>{point.serieId}</strong>
          </div>
          <table style={{ border: 0 }}>
            <thead>
              <tr>
                <td style={{ paddingRight: 10, paddingLeft: 10 }}>
                  <strong>{getLocalizedString('date')}</strong>
                </td>
                <td style={{ paddingRight: 10, paddingLeft: 10 }}>
                  <strong>{getLocalizedString('pain_score')}</strong>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr key={point.id}>
                <td style={{ paddingRight: 10, paddingLeft: 10 }}>{point.data.xFormatted}</td>
                <td style={{ paddingRight: 10, paddingLeft: 10 }}>
                  {point.data.y.toString().substring(0, 4)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </TooltipWrapper>
  );
};

const CardUserPainScores: React.FC<Props> = ({ startDate, endDate, user }) => {
  const request = useProfessionalFetch<IUserPainScore[]>({
    default: [
      `/users/${user.id}/pain-scores?startDate=${
        startDate !== null ? startDate?.toISODate() : ''
      }&endDate=${endDate !== null ? endDate?.toISODate() : ''}`,
      undefined,
      { defaultValue: [] },
    ],
    professional: (professionalId) => [
      `/professionals/${professionalId}/users/${user.id}/pain-scores?startDate=${
        startDate !== null ? startDate?.toISODate() : ''
      }&endDate=${endDate !== null ? endDate?.toISODate() : ''}`,
      undefined,
      { defaultValue: [] },
    ],
  });

  const { getLocalizedString } = useLocalization();

  const series: Serie[] = [];

  const userPainScores = request.data;

  if (userPainScores !== null) {
    const hasPainScores = userPainScores.some(
      (userPainScore) => userPainScore.chapters.length !== 0,
    );

    if (hasPainScores) {
      for (let i = 0; i < userPainScores.length; i++) {
        const dataSet = userPainScores[i];

        if (dataSet.chapters.length === 0) {
          continue;
        }

        const serie: Serie = {
          id:
            dataSet.professional === null
              ? `${getLocalizedString('personal')} (${dataSet.player_account_id})`
              : dataSet.professional.name,
          data: [],
        };

        for (let i = 0; i < dataSet.chapters.length; i++) {
          const format = DateTime.fromMillis(dataSet.chapters[i].started_at * 1000)
            .toFormat('yyyy-MM-dd')
            .toString();

          const painScore = dataSet.chapters[i].pain_score * 10;

          serie.data.push({
            x: format,
            y: painScore,
            startDate: dataSet.chapters[i].started_at,
          });
        }

        series.push(serie);
      }

      const averageSerie: Serie = {
        id: getLocalizedString('average'),
        data: [],
      };

      const data = series.reduce<Datum[]>((total, serie) => [...total, ...serie.data], []);

      for (let i = 0; i < data.length; i++) {
        const previous = data.slice(0, i);

        if (previous.length === 0) {
          continue;
        }

        const total = previous.reduce((total, record, index): number => {
          if (index === 0) {
            return record.y as number;
          }

          return (total as number) + (record.y as number);
        }, 0);

        averageSerie.data.push({ ...data[i], y: total / previous.length });
      }

      series.push(averageSerie);
    }
  }

  const username = user.first_name || user.name;

  return (
    <Card style={{ overflow: 'visible', position: 'relative' }}>
      <CardHeader
        avatar={<ActivityIcon size={25} />}
        titleTypographyProps={{
          color: 'textPrimary',
          variant: 'h5',
        }}
        title={getLocalizedString('pain_scores')}
        subheader={getLocalizedString('measured_pain_scores', {
          username: username ?? getLocalizedString('user'),
        })}
      />
      <ProgressDivider inProgress={request.loading} />
      <CardContent>
        {!request.loading && (
          <Box
            display={series.length === 0 ? 'inline-block' : 'none'}
            height={455}
            width='100%'
            style={{
              backgroundColor: 'rgba(0, 0, 0, 0.1)',
              position: 'absolute',
              right: 0,
            }}
          >
            <Card
              style={{
                position: 'absolute',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%)',
                textAlign: 'center',
                zIndex: 20,
                width: 250,
              }}
            >
              <CardContent style={{ paddingBottom: 16 }}>
                <Typography color='textPrimary' variant='body1'>
                  {getLocalizedString('no_data_graph')}
                </Typography>
              </CardContent>
            </Card>
          </Box>
        )}
        <Box height={455} position='relative'>
          <ResponsiveLineCanvas
            data={series}
            margin={{ top: 35, right: 30, bottom: 50, left: 35 }}
            xScale={{
              type: 'time',
              format: '%Y-%m-%d',
            }}
            xFormat='time:%Y-%m-%d'
            yScale={{
              type: 'linear',
              min: 0,
              max: 10,
              stacked: false,
              reverse: false,
            }}
            axisTop={null}
            axisRight={null}
            axisLeft={{
              orient: 'left',
              tickValues: [0.0, 2.0, 4.0, 6.0, 8.0, 10.0],
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: getLocalizedString('pain_score'),
              legendOffset: -30,
              legendPosition: 'middle',
            }}
            axisBottom={{
              format: '%b %d',
              tickValues: 'every 2 weeks',
              tickRotation: -90,
            }}
            colors={[
              colors.graph.primary,
              colors.graph.secondary,
              colors.graph.tertiary,
              colors.graph.quaternary,
            ]}
            pointSize={5}
            lineWidth={2}
            pointColor={{ theme: 'background' }}
            pointBorderWidth={2}
            pointBorderColor={{ from: 'serieColor' }}
            enableSlices='x'
            enableArea
            areaOpacity={0.07}
            tooltip={ToolTip}
            legends={[
              {
                anchor: 'top-left',
                direction: 'row',
                justify: false,
                translateX: -20,
                translateY: -35,
                itemsSpacing: 0,
                itemDirection: 'left-to-right',
                itemWidth: 150,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: 'circle',
                symbolBorderColor: 'rgba(0, 0, 0, .5)',
                effects: [
                  {
                    on: 'hover',
                    style: {
                      itemBackground: 'rgba(0, 0, 0, .03)',
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

export default CardUserPainScores;
