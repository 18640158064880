import React from 'react';

import { Box, Card, CardHeader, Table, TableCell, TableHead, TableRow } from '@material-ui/core';
import { AtSign as AtSignIcon } from 'react-feather';
import PerfectScrollbar from 'react-perfect-scrollbar';

import LuxonDate from 'components/LuxonDate';
import RequestTableBody from 'components/mui/RequestTableBody';
import ProgressDivider from 'components/ProgressDivider';
import { IPaginated } from 'core/backend';
import { IMailchimpTag } from 'core/mailchimp';
import useFetch from 'hooks/useFetch';
import { useLocalization } from 'services/localization/localization';

interface IProps {
  userId: number | string;
}

const CardUserMailchimpTags: React.FC<IProps> = (props) => {
  const request = useFetch<IPaginated<IMailchimpTag<true>>>(
    `/users/${props.userId}/mailchimp-tags`,
  );

  const { getLocalizedString } = useLocalization();

  const data = request.data?.data || [];

  return (
    <Card>
      <CardHeader
        avatar={<AtSignIcon size={25} />}
        titleTypographyProps={{
          color: 'textPrimary',
          variant: 'h5',
        }}
        title={getLocalizedString('mailchimp_tags')}
        subheader={getLocalizedString('mailchimp_tags_description')}
      />
      <ProgressDivider inProgress={request.loading} />
      <PerfectScrollbar>
        <Box height={495}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{getLocalizedString('title')}</TableCell>
                <TableCell>{getLocalizedString('created_at')}</TableCell>
              </TableRow>
            </TableHead>

            <RequestTableBody
              request={request}
              message={getLocalizedString('not-found', {
                model: getLocalizedString('mailchimp_tags'),
              })}
            >
              {data.map((tag) => (
                <TableRow hover key={tag.id}>
                  <TableCell>{tag.title}</TableCell>
                  <TableCell>
                    <LuxonDate date={tag.created_at} />
                  </TableCell>
                </TableRow>
              ))}
            </RequestTableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

export default CardUserMailchimpTags;
