import React from 'react';

import { Box, Card, CardContent, CardHeader, Divider } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { Users as UsersIcon } from 'react-feather';
import { Link } from 'react-router-dom';

import LuxonDate from 'components/LuxonDate';
import ListItemText from 'components/mui/ListItemText';
import RoleBoundary from 'components/RoleBoundary';
import { LoadedProfessional } from 'core/backend';
import { IProfessionalGroup } from 'core/professionalGroup';
import { useLocalization } from 'services/localization/localization';
import { Role } from 'services/roles';
import { routeCreator } from 'services/routing';

interface IProps {
  professionalGroup: IProfessionalGroup & LoadedProfessional;
}

const CardProfessionalGroupDetails: React.FC<IProps> = ({ professionalGroup }) => {
  const { getLocalizedString } = useLocalization();

  return (
    <Card>
      <CardHeader
        avatar={<UsersIcon size={25} />}
        titleTypographyProps={{
          color: 'textPrimary',
          variant: 'h5',
        }}
        title={getLocalizedString('information')}
        subheader={getLocalizedString('general-data', {
          model: getLocalizedString('group').toLowerCase(),
        })}
      />
      <Divider />
      <CardContent>
        <Box height={415} position='relative'>
          <List dense disablePadding>
            <ListItem>
              <ListItemText
                primary={getLocalizedString('name')}
                secondary={professionalGroup.name}
              />
            </ListItem>
            <RoleBoundary allowed={[Role.Admin]}>
              <ListItem>
                <ListItemText
                  primary={getLocalizedString('associated_professional')}
                  secondary={
                    <Link
                      to={{
                        pathname: routeCreator.ProfessionalDetail(
                          professionalGroup.professional_id,
                        ),
                        state: professionalGroup.professional,
                      }}
                    >
                      {professionalGroup.professional?.name}
                    </Link>
                  }
                />
              </ListItem>
            </RoleBoundary>
            <ListItem>
              <ListItemText
                primary={getLocalizedString('created_at')}
                secondary={<LuxonDate date={professionalGroup.created_at} fallback='-' />}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={getLocalizedString('updated_at')}
                secondary={<LuxonDate date={professionalGroup.updated_at} fallback='-' />}
              />
            </ListItem>
          </List>
        </Box>
      </CardContent>
    </Card>
  );
};

export default CardProfessionalGroupDetails;
