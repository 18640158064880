import React from 'react';

import { Grid } from '@material-ui/core';
import { RouteComponentProps } from 'react-router-dom';

import DetailPage from 'components/DetailPage';
import { LoadedProfessional, LoadedUser } from 'core/backend';
import { IPlayerAccount } from 'core/playerAccount';
import useStateOrFetch from 'hooks/useStateOrFetch';
import { useLocalization } from 'services/localization/localization';

import ErrorPage from 'pages/ErrorPage';
import LoadingPage from 'pages/LoadingPage';

import CardPlayerAccountDetails from './components/CardPlayerAccountDetails';

export type PlayerAccountFetchResponse = IPlayerAccount & LoadedUser & LoadedProfessional;
type Props = RouteComponentProps<
  { playerAccountId: string },
  any,
  PlayerAccountFetchResponse | undefined
>;

const PlayerAccountDetail: React.FC<Props> = (props) => {
  const request = useStateOrFetch<PlayerAccountFetchResponse | null>(
    props.location.state,
    `/player-accounts/${props.match.params.playerAccountId}`,
    { includes: ['user', 'professional'] },
  );

  const { getLocalizedString } = useLocalization();

  if (request.loading && !request.data) {
    return <LoadingPage />;
  }

  if (!request.data) {
    return <ErrorPage />;
  }

  const playerAccount = request.data as PlayerAccountFetchResponse;

  const pageTitle = getLocalizedString('player_account_for');

  return (
    <DetailPage title={`${pageTitle} ${playerAccount.user?.name}`} maxWidth={'sm'}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12} xl={12}>
          <CardPlayerAccountDetails playerAccount={playerAccount} />
        </Grid>
      </Grid>
    </DetailPage>
  );
};

export default PlayerAccountDetail;
