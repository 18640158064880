import React from 'react';

import {
  UseFormOptions,
  UseFormMethods,
  useForm,
  SubmitHandler,
  SubmitErrorHandler,
} from 'react-hook-form';

import { setUnprocessableEntityErrors } from 'utils/forms';

export type FormOptions<V> = UseFormOptions<V>;

export type FormReturn<V> = Omit<UseFormMethods<V>, 'handleSubmit'> & {
  /**
   * The default handleSubmit function of react-hook-form is proxied so that
   * we can extract potential status 422 errors or a global 'message' response.
   */
  handleSubmit: ReturnType<UseFormMethods<V>['handleSubmit']>;

  /**
   * Global error message for the complete form. This is typically a response
   * message from an external API.
   */
  formError: string | null;
};

export const useReduceptForm = <V extends object>(
  onValidHandler: SubmitHandler<V>,
  options: FormOptions<V>,
  onInvalidHandler?: SubmitErrorHandler<V>,
): FormReturn<V> => {
  const [formError, setFormError] = React.useState<string | null>(null);
  const { handleSubmit, setError, ...form } = useForm<V>({ mode: 'onChange', ...options });

  const onSubmit: FormReturn<V>['handleSubmit'] = async (e) => {
    try {
      return await handleSubmit(onValidHandler, onInvalidHandler)(e);
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      if (error.response.status === 422) {
        setUnprocessableEntityErrors<V>(error, setError);
      }

      if (error.response.data.message) {
        setFormError(error.response.data.message);
      }
    }
  };

  return { ...form, handleSubmit: onSubmit, setError, formError };
};
