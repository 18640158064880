import React from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { useAsyncFn } from 'react-use';
import CheckIcon from 'components/CheckIcon';
import ExternalLink from 'components/ExternalLink';
import { LoadedSubscription } from 'core/backend';
import { IProfessional } from 'core/professional';
import { ITeamleaderSubscription, LoadedTeamleaderProducts } from 'core/teamleader';
import httpClient from 'services/http';

interface IProps {
  teamleaderSubscription: ITeamleaderSubscription & LoadedTeamleaderProducts & LoadedSubscription;
  options: IProfessional[];
  onAssignSuccess(): void;
}

const AssignSubscriptionRow: React.FC<IProps> = ({
  teamleaderSubscription: sub,
  options,
  onAssignSuccess,
}) => {
  const [selected, setSelected] = React.useState<number | undefined>(undefined);
  const [state, handleChange] = useAsyncFn(
    async (event: React.ChangeEvent<HTMLSelectElement>) => {
      try {
        await httpClient.post(
          `/teamleader/companies/${sub.teamleader_company_id}/link-organization-subscription`,
          {
            teamleader_subscription_id: sub.id,
            professional_id: event.target.value,
            teamleader_product_id: selected,
          },
        );

        onAssignSuccess();
      } catch (error) {
        window.alert(`Request failed: ${JSON.stringify(error)}`);
        throw error;
      }
    },
    [selected],
  );

  const professionalId = sub.subscription?.professional_id;

  return (
    <TableRow>
      <TableCell>
        <ExternalLink href={sub.website_url}>{sub.title}</ExternalLink>
      </TableCell>
      <TableCell>{sub.status}</TableCell>

      <TableCell>
        {!professionalId && (
          <>
            {sub.teamleader_products.length === 0 ? (
              <CheckIcon condition={false} />
            ) : (
              <select
                onChange={(event) => setSelected(Number(event.target.value))}
                disabled={state.loading}
                value={selected}
              >
                <option value=''>-</option>
                {sub.teamleader_products
                  .filter((p) => !p.ignore)
                  .map((tlProd) => (
                    <option key={tlProd.id} value={tlProd.id}>
                      {tlProd.name}
                    </option>
                  ))}
              </select>
            )}
          </>
        )}
      </TableCell>

      <TableCell>
        <select
          onChange={handleChange}
          disabled={state.loading || selected === undefined || Boolean(professionalId)}
          value={professionalId ?? undefined}
        >
          <option value=''>-</option>
          {options.map((professional) => (
            <option key={professional.id} value={professional.id}>
              {professional.name}
            </option>
          ))}
        </select>
      </TableCell>
    </TableRow>
  );
};
export default AssignSubscriptionRow;
