import React from 'react';

import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import { SubmitHandler } from 'react-hook-form';

import UserForm, { UserFormValues } from 'components/forms/users/UserForm';
import { IUser } from 'core/user';
import useRequest from 'hooks/useRequest';
import { useLocalization } from 'services/localization/localization';

export interface ICreateUserDialogProps {
  dialogIsOpen: boolean;
  closeDialog: (user: IUser) => void;
}

type PostResponse = IUser;

const CreateUserDialog: React.FC<ICreateUserDialogProps> = ({ dialogIsOpen, ...props }) => {
  const postRequest = useRequest<PostResponse, never, UserFormValues>({
    url: `/users`,
    method: 'POST',
  });
  const { getLocalizedString } = useLocalization();

  const handleSubmit: SubmitHandler<UserFormValues> = async (values) => {
    const response = await postRequest.request({
      data: values,
    });

    props.closeDialog(response.data);
  };

  return (
    <Dialog
      open={dialogIsOpen}
      onClose={props.closeDialog}
      aria-labelledby='form-dialog-title'
      fullWidth
      maxWidth='md'
    >
      <DialogTitle id='form-dialog-title'>
        {getLocalizedString('add-model', {
          model: getLocalizedString('user').toLowerCase(),
        })}
      </DialogTitle>
      <DialogContent>
        <UserForm onSubmit={handleSubmit} />
      </DialogContent>
    </Dialog>
  );
};

export default CreateUserDialog;
