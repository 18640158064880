import React from 'react';

import { GridCellParams, GridColDef } from '@material-ui/data-grid';
import { Link } from 'react-router-dom';

import Button from 'components/Button';
import { IProfessionalDeviceTableColumnsProps } from 'core/device';
import { ProfessionalUserRoleType } from 'core/professional';
import { routeCreator } from 'services/routing';
import { GridColumn } from 'utils/mui';

import { devicesTableColumns, DeviceTableRowData } from './devicesTable';

const renderLoggedInPlayerCell: GridColDef['renderCell'] = (params) => {
  const row = params.row as DeviceTableRowData;

  if (row.player_account_id === null) {
    return <>-</>;
  }

  switch (row.player_account?.professional_user?.role) {
    case ProfessionalUserRoleType.Patient:
      return (
        <Link to={routeCreator.PatientDetail(row.professional!.id, row.player_account!.user_id)}>
          {row.player_account?.user?.name}
        </Link>
      );

    case ProfessionalUserRoleType.Owner:
    case ProfessionalUserRoleType.Therapist:
      return (
        <Link to={routeCreator.StaffDetail(row.professional!.id, row.player_account!.user_id)}>
          {row.player_account?.user?.name}
        </Link>
      );

    default:
      return (
        <Link to={routeCreator.UserDetail(row.player_account!.user_id)}>
          {row.player_account?.user?.name}
        </Link>
      );
  }
};

const renderLoginLogoutCell = (
  params: GridCellParams,
  columnProps: IProfessionalDeviceTableColumnsProps,
): React.ReactElement => {
  const row = params.row as DeviceTableRowData;

  if (row.player_account_id === null) {
    return (
      <Button
        translationKey='login'
        color='primary'
        size='small'
        variant='contained'
        onClick={() => columnProps.handleLoginDeviceButtonClick(row.id)}
      >
        Login
      </Button>
    );
  }

  return (
    <Button
      translationKey='logout'
      color='default'
      size='small'
      variant='contained'
      onClick={() => columnProps.handleLogoutDeviceButtonClick(row.id.toString())}
    >
      Logout
    </Button>
  );
};

const prefixedDeviceColumns = devicesTableColumns.filter((column) =>
  ['name', 'device_model', 'game_version', 'device_type', 'operating_system'].includes(
    column.field,
  ),
) as GridColumn<DeviceTableRowData>[];

const suffixedDeviceColumns = devicesTableColumns.filter((column) =>
  ['created_at', 'updated_at'].includes(column.field),
) as GridColumn<DeviceTableRowData>[];

export const professionalDevicesTableColumns = (
  props: IProfessionalDeviceTableColumnsProps,
): GridColumn<DeviceTableRowData>[] => {
  return [
    ...prefixedDeviceColumns,
    {
      field: 'logged_in_player',
      headerName: 'Logged in player',
      flex: 1,
      renderCell: renderLoggedInPlayerCell,
      sortable: false,
    },
    {
      field: 'login_logout',
      headerName: 'Log in/out',
      flex: 1,
      renderCell: (params) => renderLoginLogoutCell(params, props),
      sortable: false,
      filterable: false,
    },
    ...suffixedDeviceColumns,
  ];
};
