import React from 'react';

import { Card, Typography } from '@material-ui/core';
import { AlertTriangle as WarningIcon } from 'react-feather';

import { IProfessional } from 'core/professional';
import { useLocalization } from 'services/localization/localization';
import { Role } from 'services/roles';
import { matchRoles } from 'store/session/sessionSelectors';
import { useTypedSelector } from 'store/store';

interface IProps {
  professional: IProfessional;
}

const SubscriptionExpiredCard: React.FC<IProps> = ({ professional }) => {
  const { getLocalizedString } = useLocalization();
  const isTherapist = useTypedSelector(matchRoles([Role.Therapist]));
  const isOwner = useTypedSelector(matchRoles([Role.Owner]));

  let key = '';

  if (isOwner) {
    key = 'subscription_professional_owner_expired';
  } else if (isTherapist) {
    key = 'subscription_professional_therapist_expired';
  }

  if (key.length === 0) {
    console.error('Subscription was expired, but got no translation key');

    return null;
  }

  return (
    <Card
      style={{
        width: '100%',
        display: 'flex',
        padding: 5,
        borderRadius: 0,
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#FF6464',
      }}
    >
      <WarningIcon style={{ marginRight: 5 }} />
      <Typography color='textPrimary' variant='body1'>
        {getLocalizedString(key, {
          professional_name: professional.name,
        })}
      </Typography>
    </Card>
  );
};

export default SubscriptionExpiredCard;
