import React from 'react';
import {
  Card,
  colors,
  makeStyles,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { SelectInputProps } from '@material-ui/core/Select/SelectInput';
import { Redirect, useParams } from 'react-router-dom';
import Button from 'components/Button';
import CheckIcon from 'components/CheckIcon';
import DetailPage from 'components/DetailPage';
import LinkButton from 'components/LinkButton';
import { IPaginated } from 'core/backend';
import { IProduct } from 'core/product';
import { ITeamleaderCompany, ITeamleaderProduct } from 'core/teamleader';
import useFetch from 'hooks/useFetch';
import useRequest from 'hooks/useRequest';
import { history, routeCreator, routes } from 'services/routing';
import ErrorPage from 'pages/ErrorPage';
import LoadingPage from 'pages/LoadingPage';

interface AttachProposal {
  data: Array<{
    uuid: string;
    website_url: string;
    status: string;
    starts_on: string;
    ends_on: string | null;
    teamleader_company: ITeamleaderCompany;
  }>;
  product: IProduct;
}

const proposalIsAttachable = (proposal: AttachProposal): boolean =>
  proposal.data.findIndex((d) => d.teamleader_company.professional_id === null) === -1;

const ProposalTableRow: React.FC<{ data: AttachProposal['data'][number]; product: IProduct }> = ({
  data,
  product,
}) => (
  <TableRow key={data.uuid}>
    <TableCell>
      <a target='_blank' href={data.website_url} rel='noreferrer'>
        {data.teamleader_company.name}
      </a>
    </TableCell>
    <TableCell>
      {data.teamleader_company.professional !== null ? (
        <a
          target='_blank'
          href={routeCreator.ProfessionalDetail(data.teamleader_company.professional.id)}
          rel='noreferrer'
        >
          {data.teamleader_company.professional.name}
        </a>
      ) : (
        <LinkButton
          to={routeCreator.TeamleaderCompanyAttachProfessional(data.teamleader_company.id)}
          color='secondary'
          size='small'
          variant='contained'
        >
          Attach practice
        </LinkButton>
      )}
    </TableCell>
    <TableCell>
      <CheckIcon condition={data.status === 'active'} />
    </TableCell>
    <TableCell>
      {product.amount_licenses} per&nbsp;
      {product.interval}
    </TableCell>
  </TableRow>
);

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(3),
    borderWidth: 8,
    borderColor: colors.common.white,
    hoverBorderColor: colors.common.white,
    '&:nth-child(n+2)': {
      marginTop: theme.spacing(3),
    },
  },
  button: {
    marginTop: 8,
  },
  spaced: {
    marginTop: theme.spacing(2),
    display: 'block',
  },
  errorMessage: {
    color: 'red',
  },
}));

const TeamleaderProductsAttach: React.FC = () => {
  const classes = useStyles();
  const [selectedProduct, setSelectedProduct] = React.useState<number>(-1);
  const { teamleaderProductId } = useParams<{ teamleaderProductId: string }>();

  const tlProductRequest = useFetch<ITeamleaderProduct>(
    `/teamleader/products/${teamleaderProductId}`,
  );

  const productsRequest = useFetch<IPaginated<IProduct>>('/products', {
    pagination: { number: 0, size: 9999 },
  });

  const proposedAttachRequest = useRequest<AttachProposal, never, { product_id: number }>({
    url: `/teamleader/products/${teamleaderProductId}/attach/propose`,
    method: 'POST',
  });

  const commitAttachRequest = useRequest<AttachProposal, never, { product_id: number }>({
    url: `/teamleader/products/${teamleaderProductId}/attach/commit`,
    method: 'POST',
  });

  for (const req of [tlProductRequest, productsRequest]) {
    if (req.loading && !req.data) {
      return <LoadingPage />;
    }

    if (!req.data) {
      return <ErrorPage />;
    }
  }

  /**
   * handleSelectProduct sets the currently selected product and fetches
   * a proposal for the given product.
   */
  const handleSelectProduct: SelectInputProps['onChange'] = async (event) => {
    setSelectedProduct(Number(event.target.value));

    if (event.target.value === '') {
      return;
    }

    await proposedAttachRequest.request({ data: { product_id: Number(event.target.value) } });
  };

  /**
   * handleAttachProduct commits the selected product to attach to all teamleader
   * products.
   */
  const handleAttachProduct: React.MouseEventHandler<HTMLButtonElement> = async () => {
    try {
      await commitAttachRequest.request({ data: { product_id: selectedProduct } });
      history.push(routes.TeamleaderProducts.path);
    } catch (error) {
      alert(error);
    }
  };

  // Navigate users away if a product is already attached.
  if (tlProductRequest.data?.product_id !== null) {
    alert(`${tlProductRequest.data?.name} is already attached to a product`);
    return <Redirect to={routes.TeamleaderProducts.path} />;
  }

  return (
    <DetailPage maxWidth='md' title='Attach product'>
      <Card className={classes.card}>
        <div>
          Select a Reducept product to attach to Teamleader product:&nbsp;
          <a target='_blank' href={tlProductRequest.data.website_url} rel='noreferrer'>
            {tlProductRequest.data.name}
          </a>
        </div>
        <br />

        <Select
          fullWidth
          variant='outlined'
          displayEmpty
          value={selectedProduct}
          onChange={handleSelectProduct}
        >
          <MenuItem value=''>
            <em>Select a product..</em>
          </MenuItem>
          {productsRequest.data!.data.map((p) => (
            <MenuItem key={p.id} value={p.id}>
              {p.internal_name} ({p.amount_licenses} per {p.interval})
            </MenuItem>
          ))}
        </Select>
      </Card>

      <Card className={classes.card}>
        <Typography color='textPrimary' variant='h4'>
          The following subscriptions will be created:
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Teamleader company</TableCell>
              <TableCell>Reducept practice</TableCell>
              <TableCell>Active</TableCell>
              <TableCell>Licenses</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {proposedAttachRequest.data?.data.map((proposal) => (
              <ProposalTableRow
                key={proposal.uuid}
                data={proposal}
                product={proposedAttachRequest.data?.product!}
              />
            ))}
          </TableBody>
        </Table>

        {proposedAttachRequest.data?.data && (
          <TableFooter className={classes.spaced}>
            {proposalIsAttachable(proposedAttachRequest.data) ? (
              <Button
                loading={commitAttachRequest.loading}
                variant='contained'
                size='large'
                color='primary'
                onClick={handleAttachProduct}
              >
                Create subscriptions
              </Button>
            ) : (
              <Typography color='error'>
                We cannot link an internal product to this teamleader product, because not all
                teamleader companies are attached to a Reducept practice yet. This is required so we
                can link the to-be-created subscriptions to the correct practice.
                <br />
                <LinkButton
                  to={routes.TeamleaderCompanies.path}
                  className={classes.spaced}
                  color='primary'
                  size='medium'
                  variant='contained'
                >
                  Attach teamleader companies
                </LinkButton>
              </Typography>
            )}
          </TableFooter>
        )}
      </Card>
    </DetailPage>
  );
};

export default TeamleaderProductsAttach;
