import React from 'react';

import { RouteComponentProps } from 'react-router-dom';

import DataGridOverviewPage from 'components/DataGridOverviewPage';
import PaginatedServerDataGrid from 'containers/PaginatedServerDataGrid';
import { IPaginated } from 'core/backend';
import useFetch from 'hooks/useFetch';
import { useLocalization } from 'services/localization/localization';
import {
  playerAccountsTableColumns,
  PlayerAccountTableRowData,
} from 'services/tables/playerAccountsTable';

const PlayerAccountsOverview: React.FC<RouteComponentProps> = (_) => {
  const request = useFetch<IPaginated<PlayerAccountTableRowData>>('/player-accounts', {
    includes: ['user', 'professional'],
  });

  const { getLocalizedString } = useLocalization();

  return (
    <DataGridOverviewPage
      title={getLocalizedString('player_accounts')}
      subtitle={`${request.data?.meta.total || 0}`}
    >
      <PaginatedServerDataGrid
        request={request}
        searchable
        columns={playerAccountsTableColumns}
        rowHeight={52}
        componentsProps={{
          toolbar: {
            searchPlaceholder: getLocalizedString('search-model', {
              model: getLocalizedString('player_accounts').toLowerCase(),
            }),
          },
        }}
      />
    </DataGridOverviewPage>
  );
};

export default PlayerAccountsOverview;
