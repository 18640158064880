import React from 'react';

type Elements = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;

const useInput = <T extends Elements>(defaultValue = ''): [string, React.ChangeEventHandler<T>] => {
  const [value, setValue] = React.useState(defaultValue);

  const handleChange: React.ChangeEventHandler<T> = (event) => {
    setValue(event.target.value);
  };

  return [value, handleChange];
};

export default useInput;
