import React from 'react';

import { SvgIcon, Tooltip } from '@material-ui/core';
import { GridColDef } from '@material-ui/data-grid';
import { AllInclusive } from '@material-ui/icons';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';

import DataGridCheckCell from 'components/mui/DataGrid/DataGridCheckCell';
import { LoadedOrganization, LoadedUserCount } from 'core/backend';
import { hasUnlimitedLicenses, IProfessionalFull } from 'core/professional';
import { useLocalization } from 'services/localization/localization';
import { routeCreator } from 'services/routing';
import { GridColumn, renderDateCell } from 'utils/mui';

/**
 * The type of data in each table row.
 */
export type ProfessionalTableRowData = IProfessionalFull & LoadedUserCount & LoadedOrganization;

/**
 * Cell renderer for the 'name' column. This adds a link to the professional's
 * detail page.
 *
 * @param {GridCellParams} params
 *   Grid cell parameters.
 */
const renderNameCell: GridColDef['renderCell'] = (params) => {
  const row = params.row as ProfessionalTableRowData;

  return (
    <Link to={{ pathname: routeCreator.ProfessionalDetail(row.id), state: row }}>
      {params.value}
    </Link>
  );
};

/**
 * Cell renderer for the 'licenses' column. This merges several data fields
 * from the API to provide information about the current licensing period.
 *
 * @param {GridCellParams} params
 *   Grid cell parameters.
 */
const RenderLicensesCell: GridColDef['renderCell'] = (params) => {
  const { getLocalizedString } = useLocalization();

  const row = params.row as ProfessionalTableRowData;
  // Show nothing if the professional has no licenses per period. This is
  // typically when the professional has no active subscription.
  if (row.licenses_per_period === 0) {
    return <>-</>;
  }

  if (hasUnlimitedLicenses(row)) {
    return (
      <Tooltip title={getLocalizedString('practice_table_tooltip')}>
        <span style={{ display: 'flex', alignItems: 'center', opacity: 0.7 }}>
          <SvgIcon component={AllInclusive} />
        </span>
      </Tooltip>
    );
  }

  // The 'current_licensing_period' should always be filled if the user has
  // an active subscription which is not unlimited.
  if (row.current_licensing_period === null) {
    console.error(
      'No current licensing period while having a subscription different from unlimited',
    );

    return <>?</>;
  }

  const periodStart = DateTime.fromISO(row.current_licensing_period.period_start);
  const periodEnd = DateTime.fromISO(row.current_licensing_period.period_end);

  const title = `This professional has redeemed ${
    row.licenses_redeemed_in_current_period
  } licenses out of ${
    row.licenses_per_period
  } in their current period (${periodStart.toLocaleString(
    DateTime.DATE_FULL,
  )} until ${periodEnd.toLocaleString(DateTime.DATE_FULL)})`;

  return (
    <Tooltip title={title}>
      <span style={{ display: 'flex', alignItems: 'center' }}>
        {row.licenses_available_in_current_period} / {row.licenses_per_period}
      </span>
    </Tooltip>
  );
};

const renderOrganizationCell: GridColDef['renderCell'] = (params) => {
  const row = params.row as ProfessionalTableRowData;

  if (row.organization === null) {
    return <>-</>;
  }

  return (
    <Link to={routeCreator.OrganizationDetail(row.organization.id)}>{row.organization.name}</Link>
  );
};

const renderActiveSubscriptionCell: GridColDef['renderCell'] = (params) => {
  const row = params.row as ProfessionalTableRowData;

  const inner = (
    <DataGridCheckCell params={{ ...params, value: row.active_subscription_id !== null }} />
  );

  if (row.active_subscription_id === null) {
    return inner;
  }

  return (
    <Link
      style={{ display: 'flex', alignItems: 'center' }}
      to={routeCreator.SubscriptionDetail(row.active_subscription_id)}
    >
      {inner}
    </Link>
  );
};

export const professionalsTableColumns: GridColumn<ProfessionalTableRowData>[] = [
  {
    field: 'name',
    headerName: 'Name',
    flex: 1.5,
    renderCell: renderNameCell,
  },
  {
    field: 'users_count',
    headerName: 'Users count',
    flex: 0.75,
    filterable: false,
  },
  {
    field: 'active_subscription_id',
    headerName: 'Active',
    description: 'This professional has an active subscription',
    renderCell: renderActiveSubscriptionCell,
    flex: 0.5,
    filterable: false,
    sortable: false,
  },
  {
    field: 'licenses_available_in_current_period',
    description: "The amount of licenses available for the professional's current period",
    headerName: 'Licenses available',
    renderCell: RenderLicensesCell,
    flex: 0.85,
    filterable: false,
    sortable: false,
  },
  {
    field: 'partner_id',
    headerName: 'Organization',
    renderCell: renderOrganizationCell,
    flex: 0.75,
    filterable: false,
  },
  {
    field: 'created_at',
    headerName: 'Creation',
    flex: 0.75,
    renderCell: renderDateCell(),
    filterable: false,
  },
  {
    field: 'updated_at',
    headerName: 'Updated',
    flex: 0.75,
    renderCell: renderDateCell(),
    filterable: false,
  },
];
