import React from 'react';

import { SubmitHandler } from 'react-hook-form';
import { RouteComponentProps } from 'react-router-dom';

import DetailPage from 'components/DetailPage';
import { getServerSelectValue } from 'components/forms/elements/ServerSelect';
import EditPatientForm, { IEditPatientFormValues } from 'components/forms/patients/EditPatientForm';
import { LoadedProfessionalUserFull } from 'core/backend';
import { IUser } from 'core/user';
import useRequest from 'hooks/useRequest';
import useStateOrFetch from 'hooks/useStateOrFetch';
import { useLocalization } from 'services/localization/localization';
import { ProfessionalRouteParams, routeCreator, UserRouteParams } from 'services/routing';

import ErrorPage from 'pages/ErrorPage';
import LoadingPage from 'pages/LoadingPage';

type RouteParams = ProfessionalRouteParams & UserRouteParams;
type FetchResponse = IUser & LoadedProfessionalUserFull;
type Props = RouteComponentProps<RouteParams, any, FetchResponse>;

type EditPatientRequestValues = Omit<IEditPatientFormValues, 'professional_groups'> & {
  professional_group_ids: number[];
};

const EditPatient: React.FC<Props> = (props) => {
  const { professionalId, userId } = props.match.params;

  // API-url for reaching this professional user.
  const url = `/professionals/${professionalId}/users/${userId}`;

  const request = useStateOrFetch<FetchResponse | null>(props.location.state, url, {
    includes: ['groups'],
  });
  const putRequest = useRequest<FetchResponse, never, EditPatientRequestValues>({
    url,
    method: 'PUT',
  });

  const { getLocalizedString } = useLocalization();

  if (request.loading && !request.data) {
    return <LoadingPage />;
  }

  if (!request.data) {
    return <ErrorPage />;
  }

  const user = request.data as FetchResponse;

  const handleSubmit: SubmitHandler<IEditPatientFormValues> = async ({
    professional_groups,
    ...values
  }) => {
    const response = await putRequest.request({
      data: {
        ...values,
        professional_group_ids:
          getServerSelectValue(professional_groups)?.map((group) => group.id) ?? [],
      },
    });

    props.history.push(routeCreator.PatientDetail(professionalId, userId), response.data);
  };

  const defaultValues: Partial<IEditPatientFormValues> = {
    ...user,
    home_usage: user.professional_user.home_usage,
    professional_groups: user.professional_user.groups || [],
  };

  const pageTitle = getLocalizedString('update-model', {
    model: getLocalizedString('patient').toLowerCase(),
  });

  return (
    <DetailPage title={pageTitle} subtitle={user.name} maxWidth='sm'>
      <EditPatientForm
        onSubmit={handleSubmit}
        defaultValues={defaultValues}
        initialValues={user}
        professionalId={professionalId}
      />
    </DetailPage>
  );
};

export default EditPatient;
