import React from 'react';

import { Plus as PlusIcon } from 'react-feather';
import { RouteComponentProps } from 'react-router-dom';

import Button from 'components/Button';
import DataGridOverviewPage from 'components/DataGridOverviewPage';
import PaginatedServerDataGrid from 'containers/PaginatedServerDataGrid';
import { IPaginated } from 'core/backend';
import { adminRoleId } from 'core/role';
import { IUser } from 'core/user';
import useFetch from 'hooks/useFetch';
import { useLocalization } from 'services/localization/localization';
import { defaultPaginationOptions } from 'services/pagination';
import { administratorTableColumns } from 'services/tables/administratorsTable';

import AdminFormDialog, { IAdminFormDialogProps } from './components/AdminFormDialog';

enum DialogVariant {
  Assign,
  Detach,
}

const AdministratorsOverview: React.FC<RouteComponentProps> = () => {
  const request = useFetch<IPaginated<IUser>>(`/roles/${adminRoleId}/users`, {
    pagination: defaultPaginationOptions,
    includes: ['professionals'],
  });

  const [dialogOpen, setDialogOpen] = React.useState<DialogVariant | null>(null);
  const { getLocalizedString } = useLocalization();

  const handleOpenModal = (
    variant: DialogVariant,
  ): React.MouseEventHandler<HTMLButtonElement> => () => {
    setDialogOpen(variant);
  };

  const handleCloseModal = () => {
    setDialogOpen(null);
  };

  const handleSubmitSuccess: IAdminFormDialogProps['onSubmitSuccess'] = async (_) => {
    setDialogOpen(null);

    try {
      // Reload table when one or multiple admins are added succesfully.
      await request.request()();
    } catch (error) {
      /**
       * @todo - Add a pop-up to show the error to users.
       */
      console.error(getLocalizedString('error_message'), error);
    }
  };

  const actions = (
    <>
      <Button
        translationKey='add'
        color='primary'
        variant='contained'
        onClick={handleOpenModal(DialogVariant.Assign)}
        disabled={!request.data}
        startIcon={<PlusIcon size={20} />}
        style={{ margin: 5 }}
      >
        Add administrator
      </Button>
      <Button
        translationKey='delete'
        color='primary'
        variant='outlined'
        onClick={handleOpenModal(DialogVariant.Detach)}
        disabled={!request.data}
        startIcon={<PlusIcon size={20} />}
        style={{ margin: 5 }}
      >
        Delete Administrator
      </Button>
    </>
  );

  return (
    <DataGridOverviewPage
      title={getLocalizedString('administrators')}
      subtitle={`${request.data?.meta.total || 0}`}
      actions={actions}
    >
      <PaginatedServerDataGrid
        request={request}
        searchable
        columns={administratorTableColumns}
        componentsProps={{
          toolbar: {
            searchPlaceholder: getLocalizedString('search-model', {
              model: getLocalizedString('administrators').toLowerCase(),
            }),
          },
        }}
      />
      {request.data && (
        <AdminFormDialog
          open={dialogOpen !== null}
          onClose={handleCloseModal}
          detachMode={dialogOpen === DialogVariant.Detach}
          onSubmitSuccess={handleSubmitSuccess}
        />
      )}
    </DataGridOverviewPage>
  );
};

export default AdministratorsOverview;
