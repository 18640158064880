import React from 'react';

import { Grid } from '@material-ui/core';
import { Plus as PlusIcon } from 'react-feather';
import { FieldError } from 'react-hook-form';

import Button from 'components/Button';
import TextField from 'components/mui/TextField';
import { IOrganization } from 'core/organization';
import { IProfessionalFull } from 'core/professional';
import { isPlaceholderEmail, IUser } from 'core/user';
import { useReduceptForm } from 'hooks/useReduceptForm';
import { useLocalization } from 'services/localization/localization';

import CreateUserDialog from 'pages/users/CreateUser/CreateUserDialog';

import { FormComponent } from '..';
import BaseForm from '../BaseForm';
import ServerSelect, { ServerSelectValue } from '../elements/ServerSelect';

export type ProfessionalFormValues = Pick<IProfessionalFull, 'name'> & {
  organization: ServerSelectValue<IOrganization>;
  practice_owner: ServerSelectValue<IUser>;
};

interface IProps {
  isEditForm?: true;
}

const ProfessionalForm: FormComponent<ProfessionalFormValues, IProps> = ({
  defaultValues,
  onSubmit,
  isEditForm,
}) => {
  const form = useReduceptForm<ProfessionalFormValues>(onSubmit, { defaultValues });
  const {
    register,
    errors,
    control,
    setValue,
    formState: { touched },
  } = form;

  const { getLocalizedString } = useLocalization();

  const [dialogOpen, setDialogOpen] = React.useState(false);

  const handleClickOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = (user: IUser) => {
    setValue('practice_owner', user, { shouldDirty: true, shouldValidate: true });
    setDialogOpen(false);
  };

  return (
    <BaseForm form={form}>
      <TextField
        error={Boolean(touched.name && errors.name)}
        fullWidth
        helperText={touched.name && (errors.name as FieldError)?.message}
        label='Name'
        margin='normal'
        translationKey='name'
        inputRef={register({ required: getLocalizedString('required') })}
        type='text'
        variant='outlined'
        required
      />
      <ServerSelect<IOrganization>
        label='Partner (optional)'
        controllerProps={{
          control,
          name: 'organization',
        }}
        url='/organizations'
        initialValueUrl='/organizations/{organization}'
        filter={{ column: 'name' }}
        helperText={getLocalizedString('partner_description')}
        fullWidth
        margin='dense'
        variant='outlined'
      />
      {!isEditForm && (
        <Grid container spacing={1}>
          <Grid item xs={9} md={9} lg={9} xl={9}>
            <ServerSelect<IUser>
              label='Practice owner'
              controllerProps={{
                control,
                name: 'practice_owner',
                rules: {
                  required: getLocalizedString('required'),
                },
                defaultValue: null,
              }}
              url='/users'
              initialValueUrl='/users/{practice_owner}'
              filter={{ column: 'search' }}
              autocompleteProps={{
                style: { color: 'aqua' },
                getOptionLabel: (user) => {
                  if (user === null || user === undefined) {
                    return '';
                  }

                  return `${user.name}${isPlaceholderEmail(user.email) ? '' : ` (${user.email})`}`;
                },
              }}
              helperText={getLocalizedString('practice_owner_description')}
              fullWidth
              margin='dense'
              variant='outlined'
            />
          </Grid>
          <Grid item xs={3} md={3} lg={3} xl={3}>
            <CreateUserDialog dialogIsOpen={dialogOpen} closeDialog={handleDialogClose} />
            <Button
              translationKey='new'
              style={{ marginTop: 15, padding: 5 }}
              color='primary'
              variant='contained'
              fullWidth
              onClick={handleClickOpen}
              startIcon={<PlusIcon size={20} />}
            >
              New
            </Button>
          </Grid>
        </Grid>
      )}
    </BaseForm>
  );
};

export default ProfessionalForm;
