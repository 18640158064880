import React from 'react';

import { Box, Card, CardHeader, Table, TableCell, TableHead, TableRow } from '@material-ui/core';
import { Users as UsersIcon } from 'react-feather';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link } from 'react-router-dom';

import LuxonDate from 'components/LuxonDate';
import RequestTableBody from 'components/mui/RequestTableBody';
import ProgressDivider from 'components/ProgressDivider';
import { IPaginated, LoadedProfessional } from 'core/backend';
import { IProfessionalGroup } from 'core/professionalGroup';
import { IUser } from 'core/user';
import useProfessionalFetch from 'hooks/useProfessionalFetch';
import { useLocalization } from 'services/localization/localization';
import { routeCreator } from 'services/routing';

interface IProps {
  user: IUser;
}

const CardUserProfessionalGroups: React.FC<IProps> = ({ user }) => {
  const request = useProfessionalFetch<
    IPaginated<IProfessionalGroup & Partial<LoadedProfessional>>
  >({
    default: [
      `/users/${user.id}/groups`,
      {
        includes: ['professional'],
      },
    ],
    professional: (professionalId) => [`/professionals/${professionalId}/users/${user.id}/groups`],
  });

  const { getLocalizedString } = useLocalization();

  const data = request.data?.data || [];

  // An attempt was done to load the professional within the request.
  const includesProfessional = request.options.includes?.includes('professional');

  return (
    <Card>
      <CardHeader
        avatar={<UsersIcon size={25} />}
        titleTypographyProps={{
          color: 'textPrimary',
          variant: 'h5',
        }}
        title={getLocalizedString('groups')}
        subheader={getLocalizedString('user_groups')}
      />
      <ProgressDivider inProgress={request.loading} />
      <PerfectScrollbar>
        <Box height={455}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{getLocalizedString('name')}</TableCell>
                {includesProfessional && <TableCell>{getLocalizedString('practice')}</TableCell>}
                <TableCell>{getLocalizedString('since')}</TableCell>
              </TableRow>
            </TableHead>

            <RequestTableBody
              request={request}
              message={getLocalizedString('not-found', {
                model: getLocalizedString('groups').toLowerCase(),
              })}
            >
              {data.map((group) => (
                <TableRow hover key={group.id}>
                  <TableCell>
                    <Link
                      to={{
                        pathname: routeCreator.ProfessionalGroupDetail(
                          group.professional_id,
                          group.id,
                        ),
                        state: group,
                      }}
                    >
                      {group.name}
                    </Link>
                  </TableCell>

                  {includesProfessional && (
                    <TableCell>
                      {group.professional === null ? (
                        '-'
                      ) : (
                        <Link
                          to={{
                            pathname: routeCreator.ProfessionalDetail(group.professional!.id),
                            state: group.professional,
                          }}
                        >
                          {group.professional!.name}
                        </Link>
                      )}
                    </TableCell>
                  )}
                  <TableCell>
                    <LuxonDate date={group.created_at} />
                  </TableCell>
                </TableRow>
              ))}
            </RequestTableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

export default CardUserProfessionalGroups;
