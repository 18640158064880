import React from 'react';

import { Link } from 'react-router-dom';
import DataGridOverviewPage from 'components/DataGridOverviewPage';
import LinkButton from 'components/LinkButton';
import PaginatedServerDataGrid from 'containers/PaginatedServerDataGrid';
import { IPaginated, LoadedSubscription } from 'core/backend';
import { ITeamleaderCompany, ITeamleaderSubscription } from 'core/teamleader';
import useFetch from 'hooks/useFetch';
import { routeCreator } from 'services/routing';
import { GridColumn, cellRenderer } from 'utils/mui';

export type TeamleaderOrganizationOverviewData = ITeamleaderCompany & {
  teamleader_subscriptions: Array<ITeamleaderSubscription & LoadedSubscription>;
};

/**
 * This overview contains teamleader companies that are attached to an organization (partner) such as SyncVR.
 */
const TeamleaderOrganizationsOverview: React.FC = () => {
  const request = useFetch<IPaginated<TeamleaderOrganizationOverviewData>>(
    '/teamleader/companies',
    {
      includes: ['teamleaderSubscriptions.subscription'],
      filtering: [
        {
          column: 'is_partner',
          value: '1',
        },
      ],
    },
  );

  return (
    <DataGridOverviewPage title='Teamleader partners' subtitle={`${request.data?.meta.total || 0}`}>
      <PaginatedServerDataGrid request={request} searchable columns={tableColumns} />
    </DataGridOverviewPage>
  );
};

const tableColumns: GridColumn<TeamleaderOrganizationOverviewData>[] = [
  {
    field: 'name',
    flex: 1,
    renderCell: cellRenderer<TeamleaderOrganizationOverviewData>((row) => (
      <a target='_blank' href={row.website_url} rel='noreferrer'>
        {row.name}
      </a>
    )),
  },
  {
    field: 'subscriptions',
    headerName: 'subscriptions',
    flex: 0.75,
    sortable: false,
    renderCell: cellRenderer<TeamleaderOrganizationOverviewData>((row) => {
      const attached = row.teamleader_subscriptions.filter((sub) => sub.subscription !== null);

      return (
        <span
          style={{
            color: attached.length === row.teamleader_subscriptions.length ? 'green' : 'red',
          }}
        >
          {attached.length} attached out of {row.teamleader_subscriptions.length}
        </span>
      );
    }),
  },
  {
    field: ' ',
    headerName: '',
    flex: 0.5,
    sortable: false,
    renderCell: cellRenderer<TeamleaderOrganizationOverviewData>(({ id, professional }) => {
      if (professional === null) {
        return (
          <LinkButton
            to={routeCreator.TeamleaderCompanyManageOrganizationSubscriptions(id)}
            color='primary'
            size='small'
            variant='contained'
          >
            Manage subscriptions
          </LinkButton>
        );
      }

      return <Link to={routeCreator.ProfessionalDetail(professional.id)}>{professional.name}</Link>;
    }),
  },
];

export default TeamleaderOrganizationsOverview;
