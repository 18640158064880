import { GridColDef } from '@material-ui/data-grid';
import { Link } from 'react-router-dom';

import Localized from 'components/Localized';
import { renderDataGridCheckCell } from 'components/mui/DataGrid/DataGridCheckCell';
import { ISubscription } from 'core/subscription';
import { routeCreator } from 'services/routing';
import { GridColumn, renderDateCell } from 'utils/mui';

/**
 * The type of data in each table row.
 */
export type ProfessionalSubscriptionTableDataRow = ISubscription;

/**
 * @param {GridCellParams} params
 *   Grid cell parameters.
 */
const renderIdCell: GridColDef['renderCell'] = (params) => {
  const row = params.row as ProfessionalSubscriptionTableDataRow;

  return (
    <Link
      to={{
        pathname: routeCreator.SubscriptionDetail(row.id),
      }}
    >
      {`Subscription #${row.id}`}
    </Link>
  );
};

/**
 * @param {GridCellParams} params
 *   Grid cell parameters.
 */
const renderTypeCell: GridColDef['renderCell'] = (params) => {
  const row = params.row as ProfessionalSubscriptionTableDataRow;

  const inner = (
    <Link to={routeCreator.SubscriptionDetail(row.id)}>
      <Localized id={row.provider_type}>{row.provider_type}</Localized>
    </Link>
  );

  return <span>{inner}</span>;
};

/**
 * @param {GridCellParams} params
 *   Grid cell parameters.
 */
const renderLicensesResetIntervalCell: GridColDef['renderCell'] = (params) => {
  const row = params.row as ProfessionalSubscriptionTableDataRow;

  return (
    <Localized id={row.licenses_reset_interval ?? ''}>{row.licenses_reset_interval}</Localized>
  );
};

export const professionalSubscriptionsTableColumns: GridColumn<ProfessionalSubscriptionTableDataRow>[] = [
  {
    field: 'id',
    headerName: 'Id',
    flex: 1,
    renderCell: renderIdCell,
    sortable: false,
    filterable: false,
  },
  {
    field: 'active',
    headerName: 'Active',
    flex: 0.5,
    renderCell: renderDataGridCheckCell,
    sortable: false,
    filterable: false,
  },
  {
    field: 'type',
    headerName: 'Type',
    flex: 0.75,
    renderCell: renderTypeCell,
    sortable: false,
    filterable: false,
  },
  {
    field: 'amount_licenses',
    headerName: 'Amount of licenses',
    flex: 0.75,
    sortable: false,
    filterable: false,
  },
  {
    field: 'licenses_reset_interval',
    headerName: 'Licenses reset period',
    renderCell: renderLicensesResetIntervalCell,
    flex: 0.75,
    sortable: false,
    filterable: false,
  },
  {
    field: 'start_date',
    headerName: 'Start date',
    flex: 0.75,
    renderCell: renderDateCell(),
    sortable: false,
    filterable: false,
  },
  {
    field: 'end_date',
    headerName: 'End date',
    flex: 0.75,
    renderCell: renderDateCell(),
    sortable: false,
    filterable: false,
  },
  {
    field: 'created_at',
    headerName: 'Created at',
    flex: 0.75,
    renderCell: renderDateCell(),
    sortable: false,
    filterable: false,
  },
  {
    field: 'updated_at',
    headerName: 'Updated at',
    flex: 0.75,
    renderCell: renderDateCell(),
    sortable: false,
    filterable: false,
  },
];
