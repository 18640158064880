import React from 'react';

import { RouteComponentProps } from 'react-router-dom';

import DataGridOverviewPage from 'components/DataGridOverviewPage';
import PaginatedServerDataGrid from 'containers/PaginatedServerDataGrid';
import { IPaginated } from 'core/backend';
import { IRole } from 'core/role';
import useFetch from 'hooks/useFetch';
import { useLocalization } from 'services/localization/localization';
import { rolesTableColumns } from 'services/tables/rolesTable';

const RolesOverview: React.FC<RouteComponentProps> = (_) => {
  const request = useFetch<IPaginated<IRole>>('/roles');

  const { getLocalizedString } = useLocalization();

  return (
    <DataGridOverviewPage
      title={getLocalizedString('roles')}
      subtitle={`${request.data?.meta.total || 0}`}
    >
      <PaginatedServerDataGrid
        request={request}
        searchable
        columns={rolesTableColumns}
        components={{
          Toolbar: undefined,
        }}
        rowHeight={52}
      />
    </DataGridOverviewPage>
  );
};

export default RolesOverview;
