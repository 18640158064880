import { GridColDef } from '@material-ui/data-grid';
import { Link } from 'react-router-dom';

import { LoadedPlayerAccount } from 'core/backend';
import { IDevice } from 'core/device';
import { routeCreator } from 'services/routing';
import { GridColumn } from 'utils/mui';

import { devicesTableColumns } from '../devicesTable';

/**
 * The type of data in each table row.
 */
export type UserDevicesTableRowData = IDevice & LoadedPlayerAccount;

/**
 * @param {GridCellParams} params
 *   Grid cell parameters.
 */
const renderPlayerAccountCell: GridColDef['renderCell'] = (params) => {
  const row = params.row as UserDevicesTableRowData;

  if (row.player_account === null) {
    return <>-</>;
  }

  const inner = (
    <Link
      to={{
        pathname: routeCreator.PlayerAccountDetail(row.player_account.id),
        state: row.player_account,
      }}
    >
      {row.player_account.id}
    </Link>
  );

  return <span>{inner}</span>;
};

const prefixedDeviceColumns = devicesTableColumns.filter((column) =>
  ['name'].includes(column.field),
) as GridColumn<UserDevicesTableRowData>[];

const suffixedDeviceColumns = devicesTableColumns.filter((column) =>
  ['device_model', 'game_version', 'operating_system'].includes(column.field),
) as GridColumn<UserDevicesTableRowData>[];

export const userDevicesTableColumns: GridColumn<UserDevicesTableRowData>[] = [
  ...prefixedDeviceColumns,
  {
    field: 'player_account',
    headerName: 'Player account',
    flex: 1,
    renderCell: renderPlayerAccountCell,
    sortable: false,
    filterable: false,
  },
  ...suffixedDeviceColumns,
];
