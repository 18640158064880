import React from 'react';

import { Box, Card, CardContent, CardHeader, Divider, List, ListItem } from '@material-ui/core';
import { Smartphone as SmartphoneIcon } from 'react-feather';
import { Link } from 'react-router-dom';

import LuxonDate from 'components/LuxonDate';
import ListItemText from 'components/mui/ListItemText';
import RoleBoundary from 'components/RoleBoundary';
import { useLocalization } from 'services/localization/localization';
import { Role } from 'services/roles';
import { routeCreator } from 'services/routing';
import { DeviceTableRowData } from 'services/tables/devicesTable';

interface IProps {
  device: DeviceTableRowData;
}

const CardDeviceDetails: React.FC<IProps> = ({ device }) => {
  const { getLocalizedString } = useLocalization();

  return (
    <Card>
      <CardHeader
        avatar={<SmartphoneIcon size={25} />}
        titleTypographyProps={{
          color: 'textPrimary',
          variant: 'h5',
        }}
        title={getLocalizedString('information')}
        subheader={getLocalizedString('general-data', {
          model: getLocalizedString('device').toLowerCase(),
        })}
      />
      <Divider />
      <CardContent>
        <Box height={500} position='relative'>
          <List dense disablePadding>
            <ListItem>
              <ListItemText
                primary={getLocalizedString('given_device_name')}
                secondary={device.name}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={getLocalizedString('game_version')}
                secondary={device.game_version}
              />
            </ListItem>
            <RoleBoundary allowed={[Role.Admin]}>
              <ListItem>
                <ListItemText
                  primary={getLocalizedString('associated_user')}
                  secondary={
                    !device.user ? (
                      '-'
                    ) : (
                      <Link
                        to={{
                          pathname: routeCreator.UserDetail(device.user!.id),
                          state: device.user,
                        }}
                      >
                        {device.user!.name}
                      </Link>
                    )
                  }
                />
              </ListItem>
            </RoleBoundary>
            <ListItem>
              <ListItemText
                primary={getLocalizedString('associated_practice')}
                secondary={
                  !device.professional ? (
                    '-'
                  ) : (
                    <>
                      <RoleBoundary allowed={[Role.Admin]}>
                        <Link
                          to={{
                            pathname: routeCreator.ProfessionalDetail(device.professional!.id),
                            state: device.professional,
                          }}
                        >
                          {device.professional!.name}
                        </Link>
                      </RoleBoundary>
                      <RoleBoundary allowed={[Role.Therapist, Role.Owner]}>
                        {device.professional!.name}
                      </RoleBoundary>
                    </>
                  )
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={getLocalizedString('updated_at')}
                secondary={<LuxonDate date={device.updated_at} />}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={getLocalizedString('created_at')}
                secondary={<LuxonDate date={device.created_at} />}
              />
            </ListItem>
          </List>
        </Box>
      </CardContent>
      <Divider />
    </Card>
  );
};

export default CardDeviceDetails;
