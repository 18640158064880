import React from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import {
  Activity as ActivityIcon,
  ShoppingBag as ShoppingBagIcon,
  Edit2 as EditIcon,
} from 'react-feather';

import { RouteComponentProps } from 'react-router-dom';

import Button from 'components/Button';
import ButtonBar from 'components/ButtonBar';
import DetailPage from 'components/DetailPage';
import LinkButton from 'components/LinkButton';
import LuxonDate from 'components/LuxonDate';
import useStateOrFetch from 'hooks/useStateOrFetch';
import httpClient from 'services/http';
import { useLocalization } from 'services/localization/localization';

import { history, routeCreator, routes } from 'services/routing';
import ErrorPage from 'pages/ErrorPage';
import LoadingPage from 'pages/LoadingPage';
import { OverviewProduct } from './ProductsOverview';

type Props = RouteComponentProps<{ productId: string }, never, OverviewProduct | undefined>;

const ProductDetail: React.FC<Props> = (props) => {
  const url = `/products/${props.match.params.productId}`;
  const request = useStateOrFetch<OverviewProduct | null>(props.location.state, url);

  const { getLocalizedString } = useLocalization();

  if (request.loading && !request.data) {
    return <LoadingPage />;
  }

  if (!request.data) {
    return <ErrorPage />;
  }

  const handleDelete: React.MouseEventHandler<HTMLButtonElement> = async (event) => {
    event.preventDefault();

    if (window.confirm('Are you sure that you want to delete this product?')) {
      await httpClient.delete(url);
      history.push(routes.Products.path);
    }
  };

  const product = request.data;
  const hasRelations = Boolean(
    product.ecurring_subscription_plans.length === 0 &&
      product.teamleader_products.length === 0 &&
      product.stripe_price === null,
  );

  return (
    <DetailPage
      title={product.internal_name}
      actions={
        <ButtonBar
          actions={[
            <LinkButton
              key='edit'
              translationKey='edit'
              to={routeCreator.EditProduct(product.id)}
              state={product}
              disabled={!hasRelations}
              startIcon={<EditIcon size={18} />}
              color='primary'
              variant='contained'
              style={{ marginLeft: 4 }}
            >
              Edit
            </LinkButton>,
            <Button
              key='delete'
              translationKey='delete'
              onClick={handleDelete}
              disabled={!hasRelations}
              startIcon={<Delete />}
              color='primary'
              variant='contained'
              style={{ marginLeft: 4 }}
            >
              Delete
            </Button>,
          ]}
        />
      }
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Card>
            <CardHeader
              avatar={<ActivityIcon size={25} />}
              titleTypographyProps={{
                color: 'textPrimary',
                variant: 'h5',
              }}
              title={getLocalizedString('information')}
            />
            <Divider />
            <CardContent>
              <Box position='relative'>
                <List dense disablePadding>
                  <ListItem>
                    <ListItemText primary='Name' secondary={product.name} />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary='Internal name' secondary={product.internal_name} />
                  </ListItem>

                  <ListItem>
                    <ListItemText primary='Description' secondary={product.description} />
                  </ListItem>

                  <ListItem>
                    <ListItemText
                      primary='Amount of licenses'
                      secondary={product.amount_licenses}
                    />
                  </ListItem>

                  <ListItem>
                    <ListItemText
                      primary='Amount of licenses per interval'
                      secondary={product.interval}
                    />
                  </ListItem>

                  <ListItem>
                    <ListItemText
                      primary={getLocalizedString('created_at')}
                      secondary={<LuxonDate date={product.created_at} fallback='-' />}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={getLocalizedString('updated_at')}
                      secondary={<LuxonDate date={product.updated_at} fallback='-' />}
                    />
                  </ListItem>
                </List>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card>
            <CardHeader
              avatar={<ShoppingBagIcon size={25} />}
              titleTypographyProps={{
                color: 'textPrimary',
                variant: 'h5',
              }}
              title='Teamleader products'
            />
            <Divider />
            <CardContent>
              <Box position='relative'>
                {product.teamleader_products.length === 0 ? (
                  'There are no Teamleader products attached'
                ) : (
                  <ul>
                    {product.teamleader_products.map((tlProduct) => (
                      <li key={tlProduct.id}>
                        <a target='_blank' href={tlProduct.website_url} rel='noreferrer'>
                          {tlProduct.name} ({tlProduct.description})
                        </a>
                      </li>
                    ))}
                  </ul>
                )}
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card>
            <CardHeader
              avatar={<ShoppingBagIcon size={25} />}
              titleTypographyProps={{
                color: 'textPrimary',
                variant: 'h5',
              }}
              title='eCurring subscription plans'
            />
            <Divider />
            <CardContent>
              <Box position='relative'>
                {product.ecurring_subscription_plans.length === 0 ? (
                  'There are no eCurring subscription plans attached'
                ) : (
                  <ul>
                    {product.ecurring_subscription_plans.map((subPlan) => (
                      <li key={subPlan.id}>
                        {subPlan.name} ({subPlan.description})
                      </li>
                    ))}
                  </ul>
                )}
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card>
            <CardHeader
              avatar={<ActivityIcon size={25} />}
              titleTypographyProps={{
                color: 'textPrimary',
                variant: 'h5',
              }}
              title='Stripe price'
            />
            <Divider />
            <CardContent>
              <Box position='relative'>
                {product.stripe_price === null ? (
                  'There is no Stripe subscription plan attached'
                ) : (
                  <List dense disablePadding>
                    <ListItem>
                      <ListItemText primary='Name' secondary={product.stripe_price.nickname} />
                    </ListItem>
                  </List>
                )}
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </DetailPage>
  );
};

export default ProductDetail;
