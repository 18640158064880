import useFetch, { defaultFetchOptions, UseFetchHook, UseFetchOptions } from './useFetch';

/**
 * Hook that fetches data from the location state if possible. If this does not
 * exist, it uses the useFetch hook to fetch the resource.
 *
 * This is useful in situations where you navigate to a detail page of a resource
 * that you already fetched in a list. This resource can then be passed via
 * react-router's location state.
 */
const useStateOrFetch = <D>(
  state: D | undefined,
  url: string,
  fetchOptions?: Partial<UseFetchOptions>,
): UseFetchHook<D, never> => {
  const useState = typeof state !== 'undefined';

  const options = {
    ...defaultFetchOptions,
    ...fetchOptions,
  };

  const request = useFetch<D>(url, {
    ...options,
    ignore: options.ignore || useState,
  });

  return {
    ...request,
    success: useState || request.success,
    state: useState ? 'success' : request.state,
    data: useState ? (state as D) : request.data,
  };
};

export default useStateOrFetch;
