enum EventType {
  ReloadProfessionalInformation = 'reloadProfessionalInformation',
}

/**
 * Add an event listener which listens for a certain event type to be dispatched.
 */
const on = (eventType: EventType, listener: EventListener) => {
  document.addEventListener(eventType, listener);
};

/**
 * Remove an existing event listener.
 */
const off = (eventType: EventType, listener: EventListener) => {
  document.removeEventListener(eventType, listener);
};

/**
 * Add an event listener which listens for a certain event type once.
 * The event listener gets removed after executing once.
 */
const once = (eventType: EventType, listener: EventListener) => {
  document.addEventListener(eventType, listener, { once: true });
};

/**
 * Dispatch a certain event.
 */
const dispatchEvent = (eventType: EventType) => {
  const event = new CustomEvent(eventType);
  document.dispatchEvent(event);
};

export { EventType, on, off, once, dispatchEvent };
