import { AxiosError } from 'axios';
import { FieldName, FieldValues, UseFormMethods, ValidationRule } from 'react-hook-form';
import { IUnprocessableEntity } from 'core/api';
import { useLocalization } from 'services/localization/localization';

/**
 * Uses react-hook-form's setError on a 422 unprocessable entity response.
 *
 * @param {AxiosError<T>} error
 *   The error response as received by the backend.
 * @param {UseFormMethods<T>['setError']} setError
 *   react-hook-form's setError handler.
 */
export const setUnprocessableEntityErrors = <T extends FieldValues>(
  error: AxiosError<IUnprocessableEntity<Extract<keyof T, string>>>,
  setError: UseFormMethods<T>['setError'],
): void => {
  if (!error.response) {
    console.error('Unable to set unprocessable entity errors due to receiving no response data.');
    return;
  }

  const { errors } = error.response.data;

  for (const key in errors) {
    const message = errors[key][0];

    if (error) {
      setError(key as FieldName<T>, { message });
    }
  }
};

export const emailValidationPattern = (
  getLocalizedString: ReturnType<typeof useLocalization>['getLocalizedString'],
): ValidationRule<RegExp> => ({
  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
  message: getLocalizedString('invalid-email-format'),
});
