import { Dialog, DialogActions, DialogContent, DialogProps } from '@material-ui/core';
import Button from 'components/Button';
import { IComment } from 'core/comment';

interface IProps {
  comment: IComment | null;
  onClose(): void;
}

export type AddCommentDialogProps = IProps & Pick<DialogProps, 'open'>;

const CommentDetailDialog: React.FC<AddCommentDialogProps> = ({ comment, ...dialogProps }) => {
  if (comment === null) {
    return null;
  }
  return (
    <Dialog {...dialogProps} aria-labelledby='form-dialog-title' fullWidth maxWidth='sm'>
      <DialogContent>{comment.comment}</DialogContent>
      <DialogActions>
        <Button
          translationKey='close'
          color='secondary'
          variant='contained'
          onClick={dialogProps.onClose}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CommentDetailDialog;
