import React from 'react';

import { Badge, IconButton, MenuItem, Menu } from '@material-ui/core';
import { Globe as GlobeIcon } from 'react-feather';

import {
  AvailableLanguage,
  AvailableLanguages,
  toShortLocale,
  useLocalization,
} from 'services/localization/localization';

const LanguageSelector: React.FC = () => {
  const [open, setOpen] = React.useState(false);
  const languageAnchorRef = React.useRef<HTMLButtonElement>(null);
  const { selectedLanguage, setSelectedLanguage } = useLocalization();

  const handleLanguageButtonClicked: React.MouseEventHandler<HTMLButtonElement> = (_) => {
    setOpen((prevValue) => !prevValue);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (
      languageAnchorRef.current &&
      languageAnchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const handleOptionSelected: React.MouseEventHandler<HTMLLIElement> = (event) => {
    const { value } = event.currentTarget.dataset;
    setSelectedLanguage(value as AvailableLanguage);
    setOpen(false);
  };

  /** Get a more user-friendly language text. For example 'nl' instead of 'nl-NL' */
  const badgeContentText =
    selectedLanguage.indexOf('-') === -1
      ? selectedLanguage
      : toShortLocale(selectedLanguage).toString();

  return (
    <>
      <IconButton
        ref={languageAnchorRef}
        onClick={handleLanguageButtonClicked}
        aria-controls='simple-menu'
        aria-haspopup='true'
        color='inherit'
      >
        <Badge badgeContent={badgeContentText} color='secondary' variant='standard'>
          <GlobeIcon />
        </Badge>
      </IconButton>
      <Menu
        id='language-select-menu'
        anchorEl={languageAnchorRef.current}
        open={open}
        onClose={handleClose}
      >
        {Object.entries(AvailableLanguages).map(([key, value]) => {
          return (
            <MenuItem key={key} data-value={key} onClick={handleOptionSelected}>
              {value}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default LanguageSelector;
