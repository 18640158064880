import React from 'react';
import { Tooltip, TooltipProps } from '@material-ui/core';
import { Plus as PlusIcon, UserPlus as InviteUserIcon } from 'react-feather';

import { useParams } from 'react-router-dom';

import DataGridOverviewPage from 'components/DataGridOverviewPage';
import LinkButton from 'components/LinkButton';
import PaginatedServerDataGrid from 'containers/PaginatedServerDataGrid';
import { IPaginated } from 'core/backend';
import { Patient } from 'core/professionalUser';
import useFetch from 'hooks/useFetch';
import useProfessionalLicenses from 'hooks/useProfessionalLicenses';
import { useLocalization } from 'services/localization/localization';
import { defaultPaginationOptions } from 'services/pagination';
import { ProfessionalRouteParams, routeCreator } from 'services/routing';
import { patientsTableColumns } from 'services/tables/patientsTable';
import { stringToFormattedDate } from 'utils/utils';

const ProfessionalPatientsOverview: React.FC = () => {
  const { professionalId } = useParams<ProfessionalRouteParams>();
  const { noLicensesLeft, professional } = useProfessionalLicenses(professionalId);
  const { getLocalizedString } = useLocalization();

  const request = useFetch<IPaginated<Patient>>(`/professionals/${professionalId}/patients`, {
    pagination: defaultPaginationOptions,
  });

  const tooltipTitle = (): TooltipProps['title'] => {
    if (!noLicensesLeft) {
      return '';
    }

    if (professional?.current_licensing_period?.period_end) {
      return getLocalizedString('no_licenses_starts_at', {
        start: stringToFormattedDate(professional.current_licensing_period.period_end) ?? '',
      });
    }

    if (professional?.active_subscription_id === null) {
      return getLocalizedString('no_subscription_found');
    }

    return getLocalizedString('no_licenses');
  };

  const actions = (
    <>
      <Tooltip
        enterDelay={300}
        title={tooltipTitle()}
        style={{ marginRight: 4, marginLeft: 4, whiteSpace: 'nowrap' }}
      >
        <div>
          <LinkButton
            disabled={noLicensesLeft}
            translationKey='invite-patient-button'
            to={routeCreator.InvitePatient(professionalId)}
            color='secondary'
            variant='contained'
            startIcon={<InviteUserIcon size={20} />}
          >
            Invite patient
          </LinkButton>
        </div>
      </Tooltip>
      <Tooltip
        enterDelay={300}
        title={tooltipTitle()}
        style={{ marginLeft: 4, whiteSpace: 'nowrap' }}
      >
        <div>
          <LinkButton
            disabled={noLicensesLeft}
            translationKey='add'
            to={routeCreator.CreatePatient(professionalId)}
            color='primary'
            variant='contained'
            startIcon={<PlusIcon size={20} />}
          >
            Add patient
          </LinkButton>
        </div>
      </Tooltip>
    </>
  );

  return (
    <DataGridOverviewPage
      title={getLocalizedString('patients')}
      subtitle={`${request.data?.meta.total || 0}`}
      actions={actions}
    >
      <PaginatedServerDataGrid
        request={request}
        searchable
        columns={patientsTableColumns}
        componentsProps={{
          toolbar: {
            searchPlaceholder: getLocalizedString('search-model', {
              model: getLocalizedString('patients').toLowerCase(),
            }),
          },
        }}
      />
    </DataGridOverviewPage>
  );
};

export default ProfessionalPatientsOverview;
