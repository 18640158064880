import { GridColDef } from '@material-ui/data-grid';

import { IRole } from 'core/role';
import { GridColumn, renderDateCell } from 'utils/mui';
import { capitalizeFirstLetter } from 'utils/utils';

/**
 * The type of data in each table row.
 */
export type RoleTableRowData = IRole;

const renderTitleCell: GridColDef['renderCell'] = (params) => {
  const row = params.row as RoleTableRowData;

  return <span>{capitalizeFirstLetter(row.title)}</span>;
};

export const rolesTableColumns: GridColumn<RoleTableRowData>[] = [
  {
    field: 'title',
    headerName: 'Title',
    flex: 1,
    renderCell: renderTitleCell,
  },
  {
    field: 'created_at',
    headerName: 'Creation',
    flex: 0.75,
    renderCell: renderDateCell(),
  },
  {
    field: 'updated_at',
    headerName: 'Updated',
    flex: 0.75,
    renderCell: renderDateCell(),
  },
];
