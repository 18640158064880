import React from 'react';

import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  List,
  ListItem,
  ListItemText,
  makeStyles,
} from '@material-ui/core';
import { Repeat as RepeatIcon } from 'react-feather';

import CheckIcon from 'components/CheckIcon';
import LuxonDate from 'components/LuxonDate';
import RequestBody from 'components/mui/RequestBody';
import { IEcurringCustomer } from 'core/ecurring';
import useFetch from 'hooks/useFetch';
import { useLocalization } from 'services/localization/localization';

interface IProps {
  userId: number | string;
}

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
  },
}));

const CardUserEcurringCustomer: React.FC<IProps> = (props) => {
  const request = useFetch<IEcurringCustomer | null>(
    `/users/${props.userId}/ecurring-customer`,
    undefined,
    {
      defaultValue: null,
    },
  );

  const { getLocalizedString } = useLocalization();

  const classes = useStyles();

  const ecurringCustomer = request.data as IEcurringCustomer;

  return (
    <Card className={classes.root}>
      <CardHeader
        avatar={<RepeatIcon size={25} />}
        titleTypographyProps={{
          color: 'textPrimary',
          variant: 'h5',
        }}
        title={getLocalizedString('ecurring_customer')}
        subheader={getLocalizedString('ecurring_customer_description')}
      />
      <Divider />
      <CardContent>
        <Box height={455} position='relative'>
          <RequestBody
            request={request}
            message={getLocalizedString('not-found', {
              model: getLocalizedString('ecurring_customer'),
            })}
          >
            <List dense disablePadding>
              <ListItem>
                <ListItemText
                  primary={getLocalizedString('ecurring_id')}
                  secondary={ecurringCustomer?.ecurring_id}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={getLocalizedString('archived')}
                  secondary={<CheckIcon condition={ecurringCustomer?.archived} />}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={getLocalizedString('created_at')}
                  secondary={<LuxonDate date={ecurringCustomer?.created_at} fallback='-' />}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={getLocalizedString('updated_at')}
                  secondary={<LuxonDate date={ecurringCustomer?.updated_at} fallback='-' />}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={getLocalizedString('synced_at')}
                  secondary={<LuxonDate date={ecurringCustomer?.synced_at} fallback='-' />}
                />
              </ListItem>
            </List>
          </RequestBody>
        </Box>
      </CardContent>
    </Card>
  );
};

export default CardUserEcurringCustomer;
