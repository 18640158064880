import React from 'react';

import { Box, CircularProgress, Typography } from '@material-ui/core';
import { DateTime } from 'luxon';
import Localized from 'components/Localized';
import { IProfessionalStatistics } from 'core/professional';
import useFetch from 'hooks/useFetch';
import { EventType, off, on } from 'services/events';
import { useTypedSelector } from 'store/store';

const ProfessionalInformation: React.VFC = () => {
  const professionalScope = useTypedSelector((state) => state.session.professionalScope);
  const professionals = useTypedSelector((state) => state.session.professionals);

  const request = useFetch<IProfessionalStatistics>(
    `/professionals/${professionalScope}/statistics`,
  );

  const handleReloadProfessionalInformation = () => {
    request.request()();
  };

  React.useEffect(() => {
    /** Add an event listener for when another component wants to reset the professional information. */
    on(EventType.ReloadProfessionalInformation, handleReloadProfessionalInformation);

    return () => {
      off(EventType.ReloadProfessionalInformation, handleReloadProfessionalInformation);
    };
  }, []);

  if ((request.idle || request.loading) && !request.data) {
    return <CircularProgress />;
  }

  if (!request.data || !professionalScope || !professionals) {
    console.error('Something went wrong loading professional information.', [
      `Current professional id: ${professionalScope}`,
      'All loaded professionals: ',
      professionals,
    ]);

    return (
      <Localized id='navbar_professional_information_error_message'>
        Something went wrong loading information about your practice
      </Localized>
    );
  }

  const professionalStatistics = request.data;

  const licensesAvailable = professionalStatistics.licenses_available_in_current_period ?? 0;
  const licensesPerPeriod = professionalStatistics.licenses_per_period ?? 0;
  const periodEnd = professionalStatistics.current_licensing_period
    ? DateTime.fromISO(professionalStatistics.current_licensing_period?.period_end)
    : null;

  return (
    <>
      <Typography color='textSecondary' variant='h6' align='center'>
        {professionals && professionals.length > 0 && professionals[0].name}
      </Typography>

      {periodEnd && (
        <Box alignItems='center' display='flex' flexDirection='column' p={2} pb={1}>
          <Localized id='navbar_license_info'>
            <Typography color='textPrimary' variant='h6' align='center'>
              License info
            </Typography>
          </Localized>
          <Localized
            id='navbar_license_available'
            vars={{
              current_amount: licensesAvailable,
              maximum_amount: licensesPerPeriod,
            }}
          >
            <Typography color='textSecondary' variant='h6' align='center'>
              Available: {licensesAvailable} / {licensesPerPeriod}
            </Typography>
          </Localized>
          <Localized
            id='navbar_period_refreshes_at'
            vars={{ date: periodEnd.toLocaleString(DateTime.DATE_FULL) }}
          >
            <Typography color='textSecondary' variant='h6' align='center'>
              Period end: {periodEnd.toLocaleString(DateTime.DATE_FULL)}
            </Typography>
          </Localized>
        </Box>
      )}
    </>
  );
};

export default ProfessionalInformation;
