import React from 'react';

import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  AppBarProps,
} from '@material-ui/core';
import InputIcon from '@material-ui/icons/Input';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';

import LanguageSelector from 'layouts/components/LanguageSelector';
import TopBarTitle from 'layouts/components/TopBarTitle';
import { doLogout } from 'store/session/sessionActions';
import { useTypedDispatch } from 'store/store';

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    width: 60,
    height: 60,
  },
}));

interface IProps {
  onMobileNavOpen(): void;
}

type Props = IProps & AppBarProps;

const DashboardLayoutTopBar: React.FC<Props> = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();

  const dispatch = useTypedDispatch();

  /**
   * Logout event handler.
   *
   * @param {React.MouseEvent<HTMLButtonElement>} event
   * The triggered click event.
   *
   * @return {void}
   */
  const handleLogout = React.useCallback<React.MouseEventHandler<HTMLButtonElement>>(
    () => dispatch(doLogout),
    [dispatch],
  );

  return (
    <AppBar className={clsx(classes.root, className)} elevation={0} {...rest}>
      <Toolbar>
        <RouterLink to='/'>
          <TopBarTitle />
        </RouterLink>
        <Box flexGrow={1} />
        <>
          <LanguageSelector />
          <IconButton color='inherit' onClick={handleLogout} style={{ marginLeft: 10 }}>
            <InputIcon />
          </IconButton>
        </>
        <Hidden mdUp>
          <IconButton color='inherit' onClick={onMobileNavOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

export default DashboardLayoutTopBar;
