import { GridColDef } from '@material-ui/data-grid';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';

import { IComment } from 'core/comment';
import { LoadedCreator } from 'core/professionalAccessPeriod';
import { routeCreator } from 'services/routing';
import { GridColumn, renderDateCell } from 'utils/mui';

/**
 * The type of data in each table row.
 */
export type CommentTableRowData = IComment & LoadedCreator;

/**
 * @param {GridCellParams} params
 *   Grid cell parameters.
 */
const renderCreatedByCell: GridColDef['renderCell'] = (params) => {
  const row = params.row as CommentTableRowData;

  if (!row.creator) {
    return <span>-</span>;
  }

  const inner = (
    <Link
      to={{
        pathname: routeCreator.UserDetail(row.creator.id),
      }}
    >
      {row.creator.name}
    </Link>
  );

  return <span>{inner}</span>;
};

export const commentsTableColumns: GridColumn<CommentTableRowData>[] = [
  {
    field: 'comment',
    headerName: 'Comment',
    flex: 5,
  },
  {
    field: 'created_by',
    headerName: 'Creator',
    flex: 1,
    renderCell: renderCreatedByCell,
    sortable: false,
    filterable: false,
  },
  {
    field: 'created_at',
    headerName: 'Date',
    flex: 1,
    renderCell: renderDateCell(DateTime.DATETIME_SHORT_WITH_SECONDS),
  },
];
