import { DateTime, DateTimeFormatOptions } from 'luxon';

/**
 * Capitalizes the first letter of a string.
 *
 * @param {string} str
 *   string to transform
 *
 * @return {string}
 *   String with an uppercased first letter
 */
export const capitalizeFirstLetter = (str: string): string =>
  str.charAt(0).toUpperCase() + str.slice(1);

export const stringToDateTime = (str: string | null | undefined): DateTime | null =>
  typeof str === 'string' ? DateTime.fromISO(str) : null;

export const stringToFormattedDate = (
  str: string | null | undefined,
  format: DateTimeFormatOptions = DateTime.DATETIME_MED_WITH_SECONDS,
): string | null => (typeof str === 'string' ? DateTime.fromISO(str).toLocaleString(format) : null);

export const setMidnight = (dateTime: DateTime): DateTime => {
  return dateTime.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
};
