import React from 'react';

import { Box, Card, CardContent, CardHeader, Divider } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { Folder as FolderIcon } from 'react-feather';

import LuxonDate from 'components/LuxonDate';
import ListItemText from 'components/mui/ListItemText';
import { IOrganization } from 'core/organization';
import { useLocalization } from 'services/localization/localization';

interface IProps {
  organization: IOrganization;
}

const CardOrganizationDetails: React.FC<IProps> = ({ organization }) => {
  const { getLocalizedString } = useLocalization();

  return (
    <Card>
      <CardHeader
        avatar={<FolderIcon size={25} />}
        titleTypographyProps={{
          color: 'textPrimary',
          variant: 'h5',
        }}
        title={getLocalizedString('information')}
        subheader={getLocalizedString(
          'general-data',
          { model: getLocalizedString('partner').toLowerCase() },
          'General partner data',
        )}
      />
      <Divider />
      <CardContent>
        <Box height={415} position='relative'>
          <List dense disablePadding>
            <ListItem>
              <ListItemText primary={getLocalizedString('name')} secondary={organization.name} />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={getLocalizedString('created_at')}
                secondary={<LuxonDate date={organization.created_at} fallback='-' />}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={getLocalizedString('updated_at')}
                secondary={<LuxonDate date={organization.updated_at} fallback='-' />}
              />
            </ListItem>
          </List>
        </Box>
      </CardContent>
    </Card>
  );
};

export default CardOrganizationDetails;
