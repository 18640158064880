import { PropsWithChildren } from 'react';
import { FieldError } from 'react-hook-form';
import TextField from 'components/mui/TextField';
import { IUser } from 'core/user';
import { FormReturn, useReduceptForm } from 'hooks/useReduceptForm';
import { useLocalization } from 'services/localization/localization';
import { emailValidationPattern } from 'utils/forms';
import { FormComponentProps } from '.';
import BaseForm from './BaseForm';

export type BaseProfessionalInviteFormValues = Pick<IUser, 'email'>;

export interface IBaseProfessionalInviteFormProps<V> {
  children?(form: FormReturn<V>): React.ReactNode;
}

function BaseProfessionalInviteForm<V extends BaseProfessionalInviteFormValues>(
  props: PropsWithChildren<FormComponentProps<V>> & IBaseProfessionalInviteFormProps<V>,
) {
  const { defaultValues, onSubmit, children } = props;
  const form = useReduceptForm<V>(onSubmit, { defaultValues });

  const { getLocalizedString } = useLocalization();

  const {
    register,
    errors,
    formState: { touched, submitCount },
  } = form;

  const hasEmailError = touched.email && errors.email && submitCount > 0;

  return (
    <BaseForm form={form} showFormError={false}>
      <TextField
        error={Boolean(hasEmailError && errors.email)}
        fullWidth
        helperText={
          hasEmailError
            ? (errors.email as FieldError)?.message
            : getLocalizedString('professional-invite__email-description')
        }
        label='E-mail'
        margin='normal'
        translationKey='email'
        inputRef={register({
          pattern: emailValidationPattern(getLocalizedString),
          required: getLocalizedString('required'),
        })}
        type='email'
        variant='outlined'
      />

      {children && children(form)}
    </BaseForm>
  );
}

export default BaseProfessionalInviteForm;
