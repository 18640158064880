import { select } from 'redux-saga/effects';

import { sync } from './session/sessionSagas';
import { AppState } from './store';

export const persistedSessionReducerKey = 'reducept.session';

export function* loadTokens(): Generator<unknown, void, string | null> {
  // Load token from state and set it in Axios
  // TODO: Validate expiry of token here.
  const token = yield select((state: AppState) => state.session.token);

  if (token === null) {
    return;
  }

  yield sync(token);
}
