import React from 'react';

import { SubmitHandler } from 'react-hook-form';
import { RouteComponentProps } from 'react-router-dom';

import DetailPage from 'components/DetailPage';
import ProfessionalGroupForm from 'components/forms/professionalGroups/ProfessionalGroupForm';
import { IProfessionalGroup } from 'core/professionalGroup';
import useRequest from 'hooks/useRequest';
import useStateOrFetch from 'hooks/useStateOrFetch';
import { useLocalization } from 'services/localization/localization';
import {
  ProfessionalGroupRouteParams,
  ProfessionalRouteParams,
  routeCreator,
} from 'services/routing';

import ErrorPage from 'pages/ErrorPage';
import LoadingPage from 'pages/LoadingPage';

import { ProfessionalGroupRequestValues } from '../CreateProfessionalGroup/CreateProfessionalGroup';

type FetchResponse = IProfessionalGroup;
type RouteParams = ProfessionalRouteParams & ProfessionalGroupRouteParams;
type Props = RouteComponentProps<RouteParams, any, FetchResponse>;

const EditProfessionalGroup: React.FC<Props> = (props) => {
  const { professionalId, professionalGroupId } = props.match.params;

  const request = useStateOrFetch<FetchResponse | null>(
    props.location.state,
    `/professionals/${professionalId}/groups/${professionalGroupId}`,
  );

  const postRequest = useRequest<IProfessionalGroup, never, ProfessionalGroupRequestValues>({
    method: 'PUT',
    url: `/professionals/${professionalId}/groups/${professionalGroupId}`,
  });

  const { getLocalizedString } = useLocalization();

  if (request.loading && !request.data) {
    return <LoadingPage />;
  }

  if (!request.data) {
    return <ErrorPage />;
  }

  const group = request.data;

  const handleSubmit: SubmitHandler<ProfessionalGroupRequestValues> = async (values) => {
    const response = await postRequest.request({
      data: values,
    });

    props.history.push(routeCreator.ProfessionalGroupDetail(professionalId, response.data.id));
  };

  return (
    <DetailPage
      title={getLocalizedString('update-model', {
        model: getLocalizedString('group').toLowerCase(),
      })}
      subtitle={group.name}
      maxWidth='sm'
    >
      <ProfessionalGroupForm defaultValues={group} onSubmit={handleSubmit} />
    </DetailPage>
  );
};

export default EditProfessionalGroup;
