import { MenuItem } from '@material-ui/core';

import TextField from 'components/mui/TextField';
import { IProfessional } from 'core/professional';
import { ISubscription, LicenseResetInterval, SubscriptionProviderType } from 'core/subscription';
import { IUser } from 'core/user';
import { useLocalization } from 'services/localization/localization';

import { FormComponent } from '..';
import Select from '../elements/Select';
import ServerSelect, { ServerSelectValue } from '../elements/ServerSelect';
import BaseSubscriptionForm, {
  IBaseSubscriptionFormProps,
  SubscriptionFormValues,
} from './BaseSubscriptionForm';

export interface IProfessionalSubscriptionFormValues extends SubscriptionFormValues {
  provider_type: ISubscription['provider_type'];
  amount_licenses: ISubscription['amount_licenses'];
  licenses_reset_interval: ISubscription['licenses_reset_interval'];
  professional: ServerSelectValue<IProfessional>;
  user: ServerSelectValue<IUser>;
}

const subscriptionProviderTypes: { type: SubscriptionProviderType; disabled: boolean }[] = [
  { type: 'manual', disabled: false },
  { type: 'trial', disabled: true },
  { type: 'ecurring', disabled: true },
  { type: 'stripe', disabled: true },
];

type Props = Pick<IBaseSubscriptionFormProps<IProfessionalSubscriptionFormValues>, 'isEditForm'>;

const ProfessionalSubscriptionForm: FormComponent<IProfessionalSubscriptionFormValues, Props> = ({
  ...props
}) => {
  const RenderPrefixedInner: IBaseSubscriptionFormProps<IProfessionalSubscriptionFormValues>['prefixedChildren'] = (
    form,
  ) => {
    const { getLocalizedString } = useLocalization();
    const { control } = form;

    return (
      <>
        <ServerSelect<IProfessional>
          label='Practice'
          controllerProps={{
            control,
            name: 'professional',
            rules: {
              required: getLocalizedString('required'),
            },
          }}
          url='/professionals'
          initialValueUrl='/professionals/{professional}'
          filter={{ column: 'name' }}
          autocompleteProps={{
            disabled: props.isEditForm,
          }}
          helperText={getLocalizedString('practice_subscription_description')}
          fullWidth
          variant={props.isEditForm ? 'filled' : 'outlined'}
          margin='dense'
        />

        <Select
          label='Subscription type'
          controllerProps={{
            control,
            name: 'provider_type',
          }}
          helperText={getLocalizedString('provider_type_practice_description')}
          fullWidth
          margin='normal'
          readOnly
          variant={'filled'}
        >
          {subscriptionProviderTypes.map((type) => (
            <MenuItem disabled={type.disabled} value={type.type}>
              {getLocalizedString(type.type)}
            </MenuItem>
          ))}
        </Select>
      </>
    );
  };

  const licenseResetIntervals: LicenseResetInterval[] = ['month', 'year', 'unlimited'];

  const RenderSuffixedInner: IBaseSubscriptionFormProps<IProfessionalSubscriptionFormValues>['suffixedChildren'] = (
    form,
  ) => {
    const { getLocalizedString } = useLocalization();
    const {
      errors,
      control,
      watch,
      register,
      formState: { touched },
    } = form;

    const resetInterval = watch('licenses_reset_interval', null) as LicenseResetInterval;
    const requireAmountLicenses = (['month', 'year'] as LicenseResetInterval[]).includes(
      resetInterval,
    );

    return (
      <>
        <Select
          label='License reset interval'
          helperText={getLocalizedString('licenses_reset_interval_description')}
          controllerProps={{
            control,
            name: 'licenses_reset_interval',
          }}
          variant='outlined'
          fullWidth
          margin='normal'
        >
          {licenseResetIntervals.map((interval) => (
            <MenuItem value={interval}>{getLocalizedString(interval)}</MenuItem>
          ))}
        </Select>

        {requireAmountLicenses && (
          <TextField
            error={Boolean(touched.amount_licenses && errors.amount_licenses)}
            fullWidth
            helperText={getLocalizedString('amount_licenses_description')}
            label='Amount of licenses'
            margin='normal'
            translationKey='amount_licenses'
            inputRef={register({
              min: 0,
              required: 'Required for this license reset interval',
            })}
            type='number'
            variant='outlined'
          />
        )}
      </>
    );
  };

  return (
    <BaseSubscriptionForm<IProfessionalSubscriptionFormValues>
      {...props}
      defaultValues={props.defaultValues}
      prefixedChildren={RenderPrefixedInner}
      suffixedChildren={RenderSuffixedInner}
    />
  );
};

export default ProfessionalSubscriptionForm;
