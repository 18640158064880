export interface IDecodedJwt {
  /**
   * ? - Maybe the auth client ID.
   */
  aud: string;
  exp: number;
  iat: number;
  /**
   * ?
   */
  jti: string;
  /**
   * ?
   */
  nbf: number;

  /**
   * ?
   */
  scopes: any[];

  /**
   * The user ID as string.
   */
  sub: string;
}

/**
 * @param jwt JSON Web Token
 */
export const decodeJwt = (token: string): IDecodedJwt => {
  const decodedToken = JSON.parse(atob(token.split('.')[1]));

  return decodedToken;
};
