import React, { FormEventHandler } from 'react';
import {
  Card,
  colors,
  Dialog,
  DialogContentText,
  DialogProps,
  makeStyles,
} from '@material-ui/core';
import Button from 'components/Button';
import Localized from 'components/Localized';
import { PasswordResetOrigin } from 'core/passwordReset';
import { IUser } from 'core/user';
import useRequest from 'hooks/useRequest';
import { useLocalization } from 'services/localization/localization';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(3),
    borderWidth: 8,
    borderColor: colors.common.white,
    hoverBorderColor: colors.common.white,
  },
  button: {
    marginTop: 8,
  },
}));

interface IProps {
  user: IUser;
  onEmailSent: () => void;
}

interface IFormValues {
  email: string;
  origin: PasswordResetOrigin.ORIGIN_DASHBOARD;
}

export type SendPasswordResetDialogProps = IProps & Pick<DialogProps, 'open' | 'onClose'>;

const SendPasswordResetEmailDialog: React.FC<SendPasswordResetDialogProps> = (props) => {
  const classes = useStyles();

  const request = useRequest<unknown, never, IFormValues>({
    method: 'POST',
    url: '/user/reset-password',
  });

  const { getLocalizedString } = useLocalization();

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    try {
      await request.request({
        data: { email: props.user.email, origin: PasswordResetOrigin.ORIGIN_DASHBOARD },
      });

      alert(getLocalizedString('reset-password-email-sent'));
    } catch (error) {
      // This should never happen if the button is correctly disabled
      alert(getLocalizedString('error_message'));
    }

    props.onEmailSent();
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby='form-dialog-title'
      fullWidth
      maxWidth='sm'
    >
      <form onSubmit={handleSubmit} noValidate={true}>
        <Card className={classes.card}>
          <DialogContentText>
            <Localized id='reset-password-dialog-description'>
              Send this user an email containing a password reset link
            </Localized>
          </DialogContentText>
          <Button
            translationKey='send-reset-password-link'
            className={classes.button}
            color='primary'
            loading={request.loading}
            disabled={request.loading}
            fullWidth
            size='large'
            type='submit'
            variant='contained'
          >
            Send
          </Button>
        </Card>
      </form>
    </Dialog>
  );
};

export default SendPasswordResetEmailDialog;
