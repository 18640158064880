import { Box, MenuItem, Typography } from '@material-ui/core';
import { FieldError } from 'react-hook-form';

import TextField from 'components/mui/TextField';
import { IProfessionalUser } from 'core/professional';
import { IProfessionalGroup } from 'core/professionalGroup';
import { IUser } from 'core/user';
import { AvailableLanguages, useLocalization } from 'services/localization/localization';
import { emailValidationPattern } from 'utils/forms';

import { FormComponent } from '..';
import Checkbox from '../elements/Checkbox';
import Select from '../elements/Select';
import ServerSelect, { ServerSelectValue } from '../elements/ServerSelect';
import BasePatientForm, { IBasePatientFormProps, PatientFormValues } from './BasePatientForm';

export interface INewPatientFormValues extends PatientFormValues {
  email: IUser['email'];
  language: IUser['language'];
  professional_groups: ServerSelectValue<IProfessionalGroup[]>;
  home_usage: IProfessionalUser['home_usage'];
}

interface IProps {
  professionalId: string | number;
  hasUnlimitedLicenses: boolean;
  licenseAmount: number;
}

const NewPatientForm: FormComponent<INewPatientFormValues, IProps> = ({
  professionalId,
  licenseAmount,
  hasUnlimitedLicenses,
  ...props
}) => {
  const { getLocalizedString } = useLocalization();

  let defaultValues: Partial<INewPatientFormValues> = {
    ...props.defaultValues,
    professional_groups: [],
    language: 'nl-NL',
    home_usage: false,
  };

  const renderInner: IBasePatientFormProps<INewPatientFormValues>['children'] = (form) => {
    const {
      errors,
      register,
      control,
      watch,
      formState: { touched },
    } = form;
    const emailValue = watch('email', '');

    return (
      <>
        <TextField
          error={Boolean(touched.email && errors.email)}
          fullWidth
          helperText={touched.email && (errors.email as FieldError)?.message}
          label='E-mail'
          margin='normal'
          translationKey='email'
          inputRef={register({
            pattern: emailValidationPattern(getLocalizedString),
          })}
          type='email'
          variant='outlined'
        />

        <Select
          label='Language'
          controllerProps={{
            control,
            name: 'language',
          }}
          variant='outlined'
          fullWidth
          margin='normal'
        >
          {Object.entries(AvailableLanguages).map(([key, value]) => (
            <MenuItem selected value={key}>
              {value}
            </MenuItem>
          ))}
        </Select>

        <ServerSelect<[IProfessionalGroup[]]>
          multiple
          error={Boolean(touched.professional_groups && errors.professional_groups)}
          label='Groups'
          controllerProps={{
            control,
            name: 'professional_groups',
            defaultValue: [],
          }}
          url={`/professionals/${professionalId}/groups`}
          filter={{ column: 'name' }}
          helperText={getLocalizedString('professional_groups_description')}
          fullWidth
          margin='normal'
          variant='outlined'
        />

        <Checkbox
          helperText={getLocalizedString('home_usage_description')}
          label='Home usage enabled'
          controllerProps={{
            control,
            name: 'home_usage',
          }}
          margin='dense'
          disabled={emailValue.length === 0 || typeof errors.email !== 'undefined'}
        />

        <Box borderTop='1px solid' marginTop={2} padding={2}>
          <Typography color='textPrimary' variant='body1' align='center'>
            {hasUnlimitedLicenses
              ? getLocalizedString('patient_license_note_unlimited')
              : getLocalizedString('patient_license_note', { model: licenseAmount })}
          </Typography>
        </Box>
      </>
    );
  };

  return (
    <BasePatientForm<INewPatientFormValues> {...props} defaultValues={defaultValues}>
      {renderInner}
    </BasePatientForm>
  );
};

export default NewPatientForm;
