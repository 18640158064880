import React from 'react';

import { TextField } from '@material-ui/core';
import { DateTime } from 'luxon';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { Control, Controller, UseControllerOptions } from 'react-hook-form';

import { ITranslatable } from 'core/core';
import { useLocalization } from 'services/localization/localization';
import { setMidnight } from 'utils/utils';

import BaseFormController, { BaseFormControllerProps } from './BaseFormController';

interface IProps extends BaseFormControllerProps {
  control: Control;
  label: string;
  rules?: UseControllerOptions['rules'];
  helperText?: string;
}

interface CustomDatePickerProps {
  onStartDateChanged?: (date: DateTime | null) => void;
  onEndDateChanged?: (date: DateTime | null) => void;
  minDate?: () => Date | undefined;
  maxDate?: () => Date | undefined;
  datePickerProps?: Partial<ReactDatePickerProps>;
}

const convertToDateTimeMidnight = (date: Date | null) => {
  if (date === null) {
    return null;
  }

  return setMidnight(DateTime.fromJSDate(date));
};

type Props = IProps & ITranslatable & CustomDatePickerProps;

const DatePicker: React.FC<Props> = ({
  translationKey,
  control,
  rules,
  helperText,
  onStartDateChanged,
  onEndDateChanged,
  minDate,
  maxDate,
  datePickerProps,
  ...props
}) => {
  const { getLocalizedString } = useLocalization();
  const translatedLabel = getLocalizedString(translationKey);

  const customInput = (
    <TextField
      variant={datePickerProps?.readOnly ? 'filled' : 'outlined'}
      label={translatedLabel}
      margin='none'
      size='medium'
      fullWidth
      inputProps={{
        readOnly: datePickerProps?.readOnly,
      }}
    />
  );

  return (
    <BaseFormController helperText={helperText} {...props}>
      <Controller
        name={translationKey}
        control={control}
        rules={rules}
        placeholderText='Select date'
        defaultValue={undefined}
        render={({ value, onChange }) => {
          const selected = value ? (value as DateTime).toJSDate() : null;

          return (
            <ReactDatePicker
              {...datePickerProps}
              selected={selected}
              withPortal
              dateFormat='dd/MM/yyyy'
              minDate={minDate ? minDate() : null}
              maxDate={maxDate ? maxDate() : null}
              onChange={(date: Date | null) => {
                const dateTime = convertToDateTimeMidnight(date);

                if (onStartDateChanged) {
                  onStartDateChanged(dateTime);
                }
                if (onEndDateChanged) {
                  onEndDateChanged(dateTime);
                }

                return onChange(dateTime);
              }}
              customInput={customInput}
            />
          );
        }}
      />
    </BaseFormController>
  );
};

export default DatePicker;
