import React from 'react';

import { Box, Card, CardHeader, colors, makeStyles } from '@material-ui/core';
import { MessageCircle as CommentIcon, Plus as PlusIcon } from 'react-feather';

import Button from 'components/Button';
import PaginatedServerDataGrid from 'containers/PaginatedServerDataGrid';
import { IPaginated } from 'core/backend';
import { IComment } from 'core/comment';
import useFetch from 'hooks/useFetch';
import { useLocalization } from 'services/localization/localization';
import { commentsTableColumns } from 'services/tables/commentsTable';

import AddCommentDialog, { AddCommentDialogProps } from './components/AddCommentDialog';
import CommentDetailDialog from './components/CommentDetailDialog';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(3),
    borderWidth: 8,
    borderColor: colors.common.white,
    hoverBorderColor: colors.red,
  },
  button: {
    marginTop: 8,
  },
  cardHeaderAction: {
    alignSelf: 'center',
  },
}));

interface IProps {
  endpointUrl: string;
  entity: 'user' | 'subscription';
}

const CommentsCard: React.FC<IProps> = ({ endpointUrl, entity }) => {
  const classes = useStyles();

  const [addDialogOpen, setAddDialogOpen] = React.useState<boolean>(false);
  const [detailDialogOpen, setDetailDialogOpen] = React.useState<IComment | null>(null);
  const request = useFetch<IPaginated<IComment>>(endpointUrl, { includes: ['creator'] });
  const { getLocalizedString } = useLocalization();

  const handleSubmitSuccess: AddCommentDialogProps['onSubmitSuccess'] = async (_) => {
    setAddDialogOpen(false);

    try {
      // Reload table when comment is succesfully added.
      await request.request()();
    } catch (error) {
      /**
       * @todo - Add a pop-up to show the error to users.
       */
      console.error(getLocalizedString('error_message'), error);
    }
  };

  return (
    <>
      <Card>
        <CardHeader
          classes={{ action: classes.cardHeaderAction }}
          avatar={<CommentIcon size={25} />}
          titleTypographyProps={{
            color: 'textPrimary',
            variant: 'h5',
          }}
          title={getLocalizedString('comments')}
          subheader={getLocalizedString('comments_for_model', {
            model: getLocalizedString(entity).toLowerCase(),
          })}
          action={
            <Button
              color='secondary'
              variant='contained'
              startIcon={<PlusIcon size={16} />}
              translationKey='add'
              onClick={() => setAddDialogOpen(true)}
            >
              Add
            </Button>
          }
        />
        <Box height={455}>
          <PaginatedServerDataGrid
            getRowId={(row) => (row as IComment).uuid}
            disableSelectionOnClick
            onRowClick={(row) => setDetailDialogOpen(row.row as IComment)}
            request={request}
            columns={commentsTableColumns}
            components={{ Toolbar: undefined }}
          />
        </Box>
      </Card>

      <AddCommentDialog
        endpointUrl={endpointUrl}
        open={addDialogOpen}
        onClose={() => setAddDialogOpen(false)}
        onSubmitSuccess={handleSubmitSuccess}
      />

      <CommentDetailDialog
        comment={detailDialogOpen}
        open={detailDialogOpen !== null}
        onClose={() => setDetailDialogOpen(null)}
      />
    </>
  );
};

export default CommentsCard;
