import { GridColDef } from '@material-ui/data-grid';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';

import Localized from 'components/Localized';
import DataGridCheckCell from 'components/mui/DataGrid/DataGridCheckCell';
import { LoadedProfessionalUser } from 'core/backend';
import { IProfessionalFull } from 'core/professional';
import { routeCreator } from 'services/routing';
import { DateCell, GridColumn } from 'utils/mui';

/**
 * The type of data in each table row.
 */
export type UserProfessionalsTableDataRow = IProfessionalFull & LoadedProfessionalUser;

/**
 * @param {GridCellParams} params
 *   Grid cell parameters.
 */
const renderProfessionalCell: GridColDef['renderCell'] = (params) => {
  const row = params.row as UserProfessionalsTableDataRow;

  if (row === null) {
    return <>-</>;
  }

  return (
    <Link
      to={{
        pathname: routeCreator.ProfessionalDetail(row.id),
        state: row,
      }}
    >
      {row.name}
    </Link>
  );
};

/**
 * @param {GridCellParams} params
 *   Grid cell parameters.
 */
const renderRoleCell: GridColDef['renderCell'] = (params) => {
  const row = params.row as UserProfessionalsTableDataRow;

  return <Localized id={row.professional_user.role}>{row.professional_user.role}</Localized>;
};

/**
 * @param {GridCellParams} params
 *   Grid cell parameters.
 */
const renderHasAccessCell: GridColDef['renderCell'] = (params) => {
  const row = params.row as UserProfessionalsTableDataRow;

  return <DataGridCheckCell params={{ ...params, value: row.professional_user.has_access }} />;
};

/**
 * @param {GridCellParams} params
 *   Grid cell parameters.
 */
const renderHomeUsageCell: GridColDef['renderCell'] = (params) => {
  const row = params.row as UserProfessionalsTableDataRow;

  return <DataGridCheckCell params={{ ...params, value: row.professional_user.home_usage }} />;
};

/**
 * @param {GridCellParams} params
 *   Grid cell parameters.
 */
const renderContactCell: GridColDef['renderCell'] = (params) => {
  const row = params.row as UserProfessionalsTableDataRow;

  return <DataGridCheckCell params={{ ...params, value: row.professional_user.contact }} />;
};

/**
 * @param {GridCellParams} params
 *   Grid cell parameters.
 */
const renderSinceAtCell: GridColDef['renderCell'] = (params) => {
  const row = params.row as UserProfessionalsTableDataRow;

  return (
    <DateCell format={DateTime.DATE_SHORT} value={row.professional_user.created_at as string} />
  );
};

export const userProfessionalsTableColumns: GridColumn<UserProfessionalsTableDataRow>[] = [
  {
    field: 'name',
    headerName: 'Name',
    flex: 1.25,
    renderCell: renderProfessionalCell,
    sortable: false,
    filterable: false,
  },
  {
    field: 'role',
    headerName: 'role',
    flex: 0.75,
    renderCell: renderRoleCell,
    sortable: false,
    filterable: false,
  },
  {
    field: 'access',
    headerName: 'Has access',
    description: 'access_description',
    flex: 0.75,
    renderCell: renderHasAccessCell,
    sortable: false,
    filterable: false,
  },
  {
    field: 'home_usage',
    headerName: 'Home usage',
    flex: 0.75,
    renderCell: renderHomeUsageCell,
    sortable: false,
    filterable: false,
  },
  {
    field: 'contact',
    headerName: 'Contact',
    description: 'contact_description',
    flex: 0.75,
    renderCell: renderContactCell,
    sortable: false,
    filterable: false,
  },
  {
    field: 'since',
    headerName: 'Since',
    flex: 0.75,
    renderCell: renderSinceAtCell,
    sortable: false,
    filterable: false,
  },
];

export const userProfessionalsTableColumnsWithoutHomeUsage = userProfessionalsTableColumns.filter(
  (column) => ['name', 'role', 'access', 'contact', 'since'].includes(column.field),
) as GridColumn<UserProfessionalsTableDataRow>[];
