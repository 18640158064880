import { ListItem } from '@material-ui/core';

import LuxonDate from 'components/LuxonDate';
import ListItemText from 'components/mui/ListItemText';
import { IEcurringSubscription } from 'core/subscription';
import { useLocalization } from 'services/localization/localization';

import { SubscriptionProviderComponent } from '../CardSubscriptionProvider';

const SubscriptionProviderEcurring: SubscriptionProviderComponent<IEcurringSubscription> = ({
  data,
}) => {
  const { getLocalizedString } = useLocalization();

  return (
    <>
      <ListItem>
        <ListItemText primary={getLocalizedString('ecurring_id')} secondary={data.ecurring_id} />
      </ListItem>
      <ListItem>
        <ListItemText
          primary={getLocalizedString('ecurring_customer_id')}
          secondary={data.ecurring_customer_id}
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary={getLocalizedString('ecurring_plan_id')}
          secondary={data.ecurring_subscription_plan_id}
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary={getLocalizedString('status')}
          secondary={getLocalizedString(data.status)}
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary={getLocalizedString('mandate_accepted_date')}
          secondary={<LuxonDate date={data.mandate_accepted_date} fallback='-' />}
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary={getLocalizedString('start_date')}
          secondary={<LuxonDate date={data.start_date} fallback='-' />}
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary={getLocalizedString('cancel_date')}
          secondary={<LuxonDate date={data.cancel_date} fallback='-' />}
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary={getLocalizedString('resume_date')}
          secondary={<LuxonDate date={data.resume_date} fallback='-' />}
        />
      </ListItem>
    </>
  );
};

export default SubscriptionProviderEcurring;
