import { Link } from 'react-router-dom';

import CheckIcon from 'components/CheckIcon';
import ExternalLink from 'components/ExternalLink';
import { renderDataGridCheckCell } from 'components/mui/DataGrid/DataGridCheckCell';
import { routeCreator } from 'services/routing';
import { cellRenderer, GridColumn, renderDateCell } from 'utils/mui';
import { TeamleaderSubscriptionsOverviewData } from 'pages/teamleader/TeamleaderSubscriptionsOverview';

type TableData = TeamleaderSubscriptionsOverviewData;

export const teamleaderSubscriptionsTable: GridColumn<TableData>[] = [
  {
    field: 'title',
    headerName: '#',
    flex: 0.4,
    renderCell: cellRenderer<TableData>((row) => (
      <a target='_blank' href={row.website_url} rel='noreferrer'>
        {row.title}
      </a>
    )),
  },
  {
    field: 'teamleader_company_id',
    headerName: 'Company',
    flex: 0.75,
    renderCell: cellRenderer<TableData>((row) => (
      <a target='_blank' href={row.teamleader_company.website_url} rel='noreferrer'>
        {row.teamleader_company.name}
      </a>
    )),
  },
  {
    field: 'teamleader_products',
    flex: 1,
    renderCell: cellRenderer<TableData>((row) => {
      const elements = row.teamleader_products
        .filter((p) => !p.ignore)
        .reduce((components, product) => {
          const nextComponents = [...components];
          if (components.length) {
            nextComponents.push(<>,&nbsp;</>);
          }

          nextComponents.push(
            <ExternalLink key={product.id} href={product.website_url}>
              {product.name}
            </ExternalLink>,
          );

          return nextComponents;
        }, [] as JSX.Element[]);

      if (elements.length === 0) {
        return <CheckIcon condition={false} />;
      }

      return <>{elements}</>;
    }),
  },
  {
    field: 'status',
    headerName: 'status',
    flex: 0.25,
    renderCell: renderDataGridCheckCell,
    sortable: false,
    filterable: false,
  },
  {
    field: 'subscription',
    sortable: false,
    flex: 0.4,
    renderCell: cellRenderer<TableData>((row) => {
      if (row.subscription === null) {
        return <CheckIcon condition={false} />;
      }

      return (
        <Link to={routeCreator.SubscriptionDetail(row.subscription.id)}>
          Subscription #{row.subscription.id}
        </Link>
      );
    }),
  },
  {
    field: 'synced_at',
    headerName: 'Synced at',
    flex: 0.4,
    renderCell: renderDateCell(),
  },
];
