import React from 'react';

import { Checkbox as MuiCheckbox, FormControlLabel } from '@material-ui/core';
import { Controller } from 'react-hook-form';

import { useLocalization } from 'services/localization/localization';

import { FormControllerProps } from '.';
import BaseFormController from './BaseFormController';

const Checkbox: React.FC<FormControllerProps> = ({
  label,
  controllerProps,
  helperText,
  ...props
}) => {
  const { getLocalizedString } = useLocalization();
  return (
    <BaseFormController helperText={helperText} {...props}>
      <FormControlLabel
        label={getLocalizedString(controllerProps.name)}
        control={
          <Controller
            {...controllerProps}
            render={(fieldProps) => (
              <MuiCheckbox
                {...fieldProps}
                checked={Boolean(fieldProps.value)}
                onChange={(e) => fieldProps.onChange(e.target.checked)}
              />
            )}
          />
        }
      />
    </BaseFormController>
  );
};

export default Checkbox;
