import TextField from 'components/mui/TextField';
import { IDevice } from 'core/device';
import { useReduceptForm } from 'hooks/useReduceptForm';

import { useLocalization } from 'services/localization/localization';
import { FormComponent } from '..';
import BaseForm from '../BaseForm';

export type DeviceFormValues = Pick<IDevice, 'name'>;

const DeviceForm: FormComponent<DeviceFormValues> = ({ defaultValues, onSubmit }) => {
  const form = useReduceptForm<DeviceFormValues>(onSubmit, { defaultValues });
  const {
    register,
    errors,
    formState: { touched },
  } = form;

  const { getLocalizedString } = useLocalization();

  return (
    <BaseForm form={form}>
      <TextField
        error={Boolean(touched.name && errors.name)}
        fullWidth
        helperText={touched.name && errors.name?.message}
        label='Name'
        margin='normal'
        translationKey='name'
        inputRef={register({ required: getLocalizedString('required') })}
        type='text'
        variant='outlined'
      />
    </BaseForm>
  );
};

export default DeviceForm;
