import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core';
import { RouteComponentProps } from 'react-router';

import { IPaginated, LoadedProfessionalUser } from 'core/backend';
import { IProfessionalFull } from 'core/professional';
import useFetch from 'hooks/useFetch';
import { userSelector } from 'store/session/sessionSelectors';
import { useTypedSelector } from 'store/store';
import { darkBackground } from 'theme/theme';

import DashboardLayoutTopBar from './components/DashboardLayoutTopBar';
import NavBar from './components/NavBar/NavBar';
import SubscriptionExpiredCard from './components/SubscriptionExpiredCard';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: darkBackground,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 48,
    [theme.breakpoints.up('md')]: {
      paddingLeft: 192,
    },
    [theme.breakpoints.up('sm')]: {
      paddingTop: 64,
    },
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
  },
}));

type FetchResponse = IProfessionalFull & LoadedProfessionalUser;

const DashboardLayout: React.FC<RouteComponentProps> = (props) => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const user = useTypedSelector(userSelector);

  const request = useFetch<IPaginated<FetchResponse>>(`/users/${user.id}/professionals`);

  const data = request.data?.data || [];

  const openMobileNav = React.useCallback(() => {
    setMobileNavOpen(true);
  }, []);

  const closeMobileNav = React.useCallback(() => {
    setMobileNavOpen(false);
  }, []);

  return (
    <div className={classes.root}>
      <DashboardLayoutTopBar onMobileNavOpen={openMobileNav} />
      <NavBar onMobileClose={closeMobileNav} openMobile={isMobileNavOpen} />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          {data
            .filter((professional) => !professional.professional_user.has_access)
            .map((professional) => (
              <SubscriptionExpiredCard professional={professional} />
            ))}
          <div className={classes.content}>{props.children}</div>
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
