import React from 'react';

import { Grid, Tooltip } from '@material-ui/core';
import { SportsEsportsOutlined as ExtendAccessIcon } from '@material-ui/icons';
import { DateTime } from 'luxon';
import { Edit2 as EditIcon, Key as PasswordIcon, Trash as DeleteIcon } from 'react-feather';
import { RouteComponentProps } from 'react-router-dom';

import Button from 'components/Button';
import ButtonBar from 'components/ButtonBar';
import CommentsCard from 'components/comments/CommentsCard';
import DateRangePicker from 'components/daterangepicker/DateRangePicker';
import DetailPage from 'components/DetailPage';
import LinkButton from 'components/LinkButton';
import RoleBoundary from 'components/RoleBoundary';
import { LoadedProfessionalUser } from 'core/backend';
import {
  hasUnlimitedLicenses,
  IProfessionalFull,
  ProfessionalUserRoleType,
} from 'core/professional';
import { isFullyManagedByProfessional, IUser } from 'core/user';
import useFetch from 'hooks/useFetch';
import useStateOrFetch from 'hooks/useStateOrFetch';
import { dispatchEvent, EventType } from 'services/events';
import { useLocalization } from 'services/localization/localization';
import { Role } from 'services/roles';
import {
  history,
  ProfessionalRouteParams,
  routeCreator,
  routes,
  UserRouteParams,
} from 'services/routing';
import { stringToFormattedDate } from 'utils/utils';

import ErrorPage from 'pages/ErrorPage';
import LoadingPage from 'pages/LoadingPage';
import DeletePatientDialog from 'pages/patients/PatientDetail/components/DeletePatientDialog';
import CardUserAccessPeriods from 'pages/users/UserDetail/components/CardUserAccessPeriods';
import CardUserDetails from 'pages/users/UserDetail/components/CardUserDetails';
import CardUserDevices from 'pages/users/UserDetail/components/CardUserDevices';
import CardUserEcurringCustomer from 'pages/users/UserDetail/components/CardUserEcurringCustomer';
import CardUserLogs from 'pages/users/UserDetail/components/CardUserLogs';
import CardUserMailchimpMember from 'pages/users/UserDetail/components/CardUserMailchimpMember';
import CardUserMailchimpTags from 'pages/users/UserDetail/components/CardUserMailchimpTags';
import CardUserMetaData from 'pages/users/UserDetail/components/CardUserMetaData';
import CardUserPainScores from 'pages/users/UserDetail/components/CardUserPainScores';
import CardUserPainScoresImage from 'pages/users/UserDetail/components/CardUserPainScoresImage';
import CardUserPlayerAccounts from 'pages/users/UserDetail/components/CardUserPlayerAccounts';
import CardUserProfessionalGroups from 'pages/users/UserDetail/components/CardUserProfessionalGroups';
import CardUserProfessionals from 'pages/users/UserDetail/components/CardUserProfessionals';
import CardUserRoles from 'pages/users/UserDetail/components/CardUserRoles';
import CardUserStripeCustomer from 'pages/users/UserDetail/components/CardUserStripeCustomer';
import CardUserSubscriptions from 'pages/users/UserDetail/components/CardUserSubscriptions';
import ExtendPatientAccessPeriodDialog from './components/ExtendPatientAccessPeriodDialog';

type FetchResponse = IUser & LoadedProfessionalUser;
type Props = RouteComponentProps<
  UserRouteParams & ProfessionalRouteParams,
  any,
  FetchResponse | undefined
>;

const PatientDetail: React.FC<Props> = (props) => {
  const { professionalId, userId } = props.match.params;
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [extendAccessPeriodDialogOpen, setExtendAccessPeriodDialogOpen] = React.useState(false);

  const [startDate, setStartDate] = React.useState<DateTime | null>(null);
  const [endDate, setEndDate] = React.useState<DateTime | null>(null);

  const request = useStateOrFetch<FetchResponse | null>(
    props.location.state,
    `/professionals/${professionalId}/users/${userId}`,
  );
  const professionalRequest = useFetch<IProfessionalFull | null>(
    `/professionals/${professionalId}`,
  );

  const { getLocalizedString } = useLocalization();

  if (request.loading && !request.data) {
    return <LoadingPage />;
  }

  if (!request.data) {
    return <ErrorPage />;
  }

  const user = request.data as FetchResponse;
  const professional = professionalRequest.data as IProfessionalFull;
  const licensesLeft = professional?.licenses_available_in_current_period ?? 0;

  // User is not a patient, redirect back to dashboard
  if (user !== null && user.professional_user!.role !== ProfessionalUserRoleType.Patient) {
    history.push(routes.Dashboard);
    return null;
  }

  const unlimitedLicenses = hasUnlimitedLicenses(professional);
  const extendAccessButtonDisabled = (!unlimitedLicenses && licensesLeft <= 0) || !user;

  const handlePatientDeleted = (): void => {
    /** @todo add Snackbar notification */
    alert(getLocalizedString('delete_patient_success'));

    setDeleteDialogOpen(false);

    history.push(routeCreator.ProfessionalPatients(professionalId!));
  };

  const handlePatientAccessPeriodExtended = (statusCode: number): void => {
    /** @todo add Snackbar notification */
    if (statusCode.toString().startsWith('2')) {
      alert(getLocalizedString('extend_access_success'));
      dispatchEvent(EventType.ReloadProfessionalInformation);
    } else {
      // This should never happen if the button is correctly disabled
      alert(getLocalizedString('error_message'));
    }

    setExtendAccessPeriodDialogOpen(false);

    // Fetch the user and professional again to get the updated access periods
    request.request()();
    professionalRequest.request()();
  };

  const getExtendAccessButtonDisabledMessage = () => {
    if (professional?.current_licensing_period?.period_end) {
      return getLocalizedString('no_licenses_starts_at', {
        start: stringToFormattedDate(professional.current_licensing_period.period_end) ?? '',
      });
    }

    if (professional?.active_subscription_id === null) {
      return getLocalizedString('no_subscription_found');
    }

    return getLocalizedString('no_licenses_starts_at');
  };

  const handleDatesChanged = (startDate: DateTime | null, endDate: DateTime | null) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const actions: JSX.Element[] = [
    <Button
      translationKey='delete'
      color='default'
      variant='contained'
      startIcon={<DeleteIcon size={18} />}
      onClick={() => setDeleteDialogOpen(true)}
      disabled={!user}
      style={{ marginRight: 4, marginLeft: 4, height: 35, whiteSpace: 'nowrap' }}
    >
      Delete
    </Button>,
    <Tooltip
      enterDelay={300}
      title={extendAccessButtonDisabled ? getExtendAccessButtonDisabledMessage() : ''}
    >
      <div>
        <Button
          translationKey='extend-access'
          color='secondary'
          variant='contained'
          startIcon={<ExtendAccessIcon fontSize={'large'} />}
          onClick={() => setExtendAccessPeriodDialogOpen(true)}
          disabled={extendAccessButtonDisabled}
          style={{ marginRight: 4, marginLeft: 4, height: 35, whiteSpace: 'nowrap' }}
        >
          Extend access
        </Button>
      </div>
    </Tooltip>,
    professionalId && isFullyManagedByProfessional(user) ? (
      <LinkButton
        translationKey='change-password'
        to={routeCreator.ChangePatientPassword(professionalId, user.id)}
        state={user}
        startIcon={<PasswordIcon size={18} />}
        color='primary'
        variant='outlined'
        style={{ marginRight: 4, marginLeft: 4, height: 35, whiteSpace: 'nowrap' }}
      >
        Change password
      </LinkButton>
    ) : (
      <></>
    ),
    <LinkButton
      translationKey='edit'
      to={routeCreator.EditPatient(professionalId, user.id)}
      state={user}
      startIcon={<EditIcon size={18} />}
      color='primary'
      variant='contained'
      style={{ marginLeft: 4, height: 35, whiteSpace: 'nowrap' }}
    >
      Edit
    </LinkButton>,
  ];

  return (
    <DetailPage
      title={user.name ?? getLocalizedString('patient').toLowerCase()}
      actions={
        <ButtonBar actions={actions}>
          <DateRangePicker onChange={handleDatesChanged} />
        </ButtonBar>
      }
    >
      <Grid container spacing={3}>
        <RoleBoundary allowed={[Role.Admin]}>
          <Grid item xs={12}>
            <CommentsCard endpointUrl={`/users/${user.id}/comments`} entity='user' />
          </Grid>
        </RoleBoundary>

        <RoleBoundary allowed={[Role.Therapist, Role.Owner, Role.Admin]}>
          <Grid item xs={12} md={3} lg={3} xl={2}>
            <CardUserDetails user={user} />
          </Grid>
          <Grid item xs={12} md={9} lg={9} xl={7}>
            <CardUserPainScores user={user} startDate={startDate} endDate={endDate} />
          </Grid>
          <Grid item xs={12} md={4} lg={4} xl={3}>
            <CardUserPainScoresImage user={user} startDate={startDate} endDate={endDate} />
          </Grid>

          <Grid item xs={12} md={8} lg={8} xl={6}>
            <CardUserPlayerAccounts user={user} />
          </Grid>
          <Grid item xs={12} md={6} lg={6} xl={6}>
            <CardUserAccessPeriods user={user} />
          </Grid>
          <Grid item xs={12} md={6} lg={6} xl={6}>
            <CardUserProfessionalGroups user={user} />
          </Grid>
          <Grid item xs={12} md={6} lg={6} xl={6}>
            <CardUserDevices user={user} />
          </Grid>
        </RoleBoundary>

        <RoleBoundary allowed={[Role.Admin]}>
          <Grid item xs={12} md={6} lg={6} xl={4}>
            <CardUserSubscriptions user={user} />
          </Grid>
          <Grid item xs={12} md={6} lg={6} xl={8}>
            <CardUserProfessionals user={user} />
          </Grid>
          <Grid item xs={12} md={6} lg={6} xl={6}>
            <CardUserLogs user={user} />
          </Grid>
          <Grid item xs={12} md={4} lg={4} xl={3}>
            <CardUserMetaData user={user} />
          </Grid>
          <Grid item xs={12} md={4} lg={4} xl={3}>
            <CardUserMailchimpMember userId={user.id} />
          </Grid>
          <Grid item xs={12} md={4} lg={4} xl={4}>
            <CardUserMailchimpTags userId={user.id} />
          </Grid>
          <Grid item xs={12} md={4} lg={4} xl={4}>
            <CardUserStripeCustomer userId={user.id} />
          </Grid>
          <Grid item xs={12} md={4} lg={4} xl={4}>
            <CardUserEcurringCustomer userId={user.id} />
          </Grid>
          <Grid item xs={12} md={4} lg={4} xl={6}>
            <CardUserRoles userId={user.id} />
          </Grid>
        </RoleBoundary>
      </Grid>
      <DeletePatientDialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onPatientDeleted={handlePatientDeleted}
        professionalId={professionalId}
        userId={userId}
      />
      <ExtendPatientAccessPeriodDialog
        open={extendAccessPeriodDialogOpen}
        onClose={() => setExtendAccessPeriodDialogOpen(false)}
        onAccessPeriodExtended={handlePatientAccessPeriodExtended}
        professionalId={professionalId}
        userId={userId}
        licensesLeft={licensesLeft}
        hasUnlimitedLicenses={unlimitedLicenses}
      />
    </DetailPage>
  );
};

export default PatientDetail;
