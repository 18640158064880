import React from 'react';

import { Box, Card, CardHeader } from '@material-ui/core';
import { ShoppingBag as ShoppingBagIcon } from 'react-feather';

import PaginatedServerDataGrid from 'containers/PaginatedServerDataGrid';
import { IPaginated, LoadedProfessional } from 'core/backend';
import { ISubscription } from 'core/subscription';
import { IUser } from 'core/user';
import useFetch from 'hooks/useFetch';
import { useLocalization } from 'services/localization/localization';
import { userSubscriptionsTableColumns } from 'services/tables/userDetail/userSubscriptionsTable';

interface IProps {
  user: IUser;
}

const CardUserSubscriptions: React.FC<IProps> = ({ user }) => {
  const request = useFetch<IPaginated<ISubscription & LoadedProfessional>>(
    `/users/${user.id}/subscriptions`,
    {
      sorting: [{ column: 'id', direction: 'desc' }],
      includes: ['professional'],
    },
  );
  const { getLocalizedString } = useLocalization();

  return (
    <Card>
      <CardHeader
        avatar={<ShoppingBagIcon size={25} />}
        titleTypographyProps={{
          color: 'textPrimary',
          variant: 'h5',
        }}
        title={getLocalizedString('subscriptions')}
        subheader={getLocalizedString('subscriptions_for_model', {
          model: getLocalizedString('user').toLowerCase(),
        })}
      />
      <Box height={455}>
        <PaginatedServerDataGrid
          request={request}
          columns={userSubscriptionsTableColumns}
          components={{ Toolbar: undefined }}
        />
      </Box>
    </Card>
  );
};

export default CardUserSubscriptions;
