import React from 'react';

import { SubmitHandler } from 'react-hook-form';
import { RouteComponentProps } from 'react-router-dom';

import DetailPage from 'components/DetailPage';
import ProfessionalGroupForm from 'components/forms/professionalGroups/ProfessionalGroupForm';
import { IProfessionalGroup } from 'core/professionalGroup';
import useRequest from 'hooks/useRequest';
import { useLocalization } from 'services/localization/localization';
import { ProfessionalRouteParams, routeCreator } from 'services/routing';

export type ProfessionalGroupRequestValues = Pick<IProfessionalGroup, 'name'>;
type Props = RouteComponentProps<ProfessionalRouteParams>;

const CreateProfessionalGroup: React.FC<Props> = (props) => {
  const { professionalId } = props.match.params;
  const postRequest = useRequest<IProfessionalGroup, never, ProfessionalGroupRequestValues>({
    method: 'POST',
    url: `/professionals/${professionalId}/groups`,
  });

  const { getLocalizedString } = useLocalization();

  const handleSubmit: SubmitHandler<ProfessionalGroupRequestValues> = async (values) => {
    const response = await postRequest.request({
      data: values,
    });

    props.history.push(routeCreator.ProfessionalGroupDetail(professionalId, response.data.id));
  };

  return (
    <DetailPage
      title={getLocalizedString('add-model', { model: getLocalizedString('group').toLowerCase() })}
      maxWidth='sm'
    >
      <ProfessionalGroupForm onSubmit={handleSubmit} />
    </DetailPage>
  );
};

export default CreateProfessionalGroup;
