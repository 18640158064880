import React from 'react';

import { Box, Container, makeStyles, Typography } from '@material-ui/core';
import { SubmitHandler } from 'react-hook-form';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { useSearchParam } from 'react-use';

import ChangePasswordForm from 'components/forms/ChangePasswordForm';
import LinkButton from 'components/LinkButton';
import Localized from 'components/Localized';
import Page from 'components/Page';
import { IChangeUserPasswordPayload } from 'core/user';
import useRequest from 'hooks/useRequest';
import { toShortLocale, useLocalization } from 'services/localization/localization';
import { routes } from 'services/routing';
import { loggedInSelector } from 'store/session/sessionSelectors';
import { useTypedSelector } from 'store/store';
import { darkBackground } from 'theme/theme';
import { isAxiosError } from 'utils/guard';

import AccountConfirmed from './components/AccountConfirmed';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: darkBackground,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

interface IFormValues extends IChangeUserPasswordPayload {
  token: string;
}

type ErrorResponse = {
  message: string;
};

type Props = RouteComponentProps;

const RegisterConfirm: React.FC<Props> = (_) => {
  const loggedIn = useTypedSelector(loggedInSelector);
  const classes = useStyles();
  const [emailSent, setEmailSent] = React.useState(false);
  const { selectedLanguage, getLocalizedString } = useLocalization();
  const selectedShortLocale = toShortLocale(selectedLanguage);

  const confirmationToken = useSearchParam('token');

  const request = useRequest<never, ErrorResponse, IFormValues>({
    method: 'POST',
    url: '/user/confirm',
  });

  const handleSubmit: SubmitHandler<IChangeUserPasswordPayload> = async (values) => {
    try {
      await request.request({
        data: {
          password: values.password,
          token: confirmationToken!,
        },
      });

      setEmailSent(true);
    } catch (error) {
      if (isAxiosError<ErrorResponse>(error)) {
        const { message } = error.response!.data;

        if (typeof message === 'string') {
          alert(getLocalizedString('register_submit_general_error'));
        }
      }
    }
  };

  if (loggedIn) {
    return <Redirect to={routes.Dashboard} />;
  }

  let inner: React.ReactNode = null;

  if (emailSent) {
    inner = <AccountConfirmed />;
  } else {
    inner = (
      <ChangePasswordForm onSubmit={handleSubmit}>
        <Localized id='set-password'>
          <Typography color='textPrimary' variant='h2'>
            Set your password
          </Typography>
        </Localized>
        <Localized id='set-password-message'>
          <Typography color='textSecondary' gutterBottom variant='body2'>
            Enter your new password
          </Typography>
        </Localized>
      </ChangePasswordForm>
    );
  }

  /** @todo Add check to see if confirmationtoken is actually valid */
  if (!confirmationToken) {
    inner = (
      <>
        <Localized id='invalid_token_part_1'>
          <Typography color='textPrimary' gutterBottom variant='h4' align='center'>
            Invalid confirmation token.
          </Typography>
        </Localized>
        <Localized id='invalid_token_part_2'>
          <Typography color='textPrimary' gutterBottom variant='h4' align='center'>
            If you got here by clicking on the link in your email, please contact support.
          </Typography>
        </Localized>
        <Typography color='textPrimary' gutterBottom variant='h4' align='center'>
          <Localized id='invalid_token_part_3'>See</Localized>
          &nbsp;
          <a
            href={getLocalizedString('website_support_link', {
              locale: selectedShortLocale,
            })}
            target='#'
          >
            <Localized id='website_support_link' vars={{ locale: selectedShortLocale }}>
              https://reducept.com/support
            </Localized>
          </a>
        </Typography>
        <Box my={2} display='flex' justifyContent='center'>
          <LinkButton
            translationKey='back-to-sign-in'
            to={routes.Login}
            color='secondary'
            size='small'
            type='button'
            variant='text'
          >
            Back to sign in
          </LinkButton>
        </Box>
      </>
    );
  }

  return (
    <Page className={classes.root} title='Set password'>
      <Box display='flex' flexDirection='column' height='100%' justifyContent='center'>
        <Container maxWidth='sm'>
          <Box p={4} display='flex' flexDirection='column' alignItems='center'>
            {inner}
          </Box>
        </Container>
      </Box>
    </Page>
  );
};

export default RegisterConfirm;
