import { GridColDef } from '@material-ui/data-grid';
import { Link } from 'react-router-dom';

import { LoadedPlayerAccount, LoadedProfessional, LoadedUser } from 'core/backend';
import { IDevice } from 'core/device';
import { routeCreator } from 'services/routing';
import { GridColumn, renderDateCell } from 'utils/mui';

/**
 * The type of data in each table row.
 */
export type DeviceTableRowData = IDevice & LoadedProfessional & LoadedUser & LoadedPlayerAccount;

const renderNameCell: GridColDef['renderCell'] = (params) => {
  const row = params.row as DeviceTableRowData;

  return (
    <Link to={{ pathname: routeCreator.DeviceDetail(row.id), state: row }}>{params.value}</Link>
  );
};

const renderGameVersionCell: GridColDef['renderCell'] = (params) => {
  const row = params.row as DeviceTableRowData;

  if (row.game_version === null) {
    return <>-</>;
  }

  return <>{row.game_version}</>;
};

const renderGameApiVersionCell: GridColDef['renderCell'] = (params) => {
  const row = params.row as DeviceTableRowData;

  return <>v{row.game_api_version}</>;
};

const renderUserCell: GridColDef['renderCell'] = (params) => {
  const row = params.row as DeviceTableRowData;

  if (row.user === null) {
    return <>-</>;
  }

  return (
    <Link to={{ pathname: routeCreator.UserDetail(row.user.id), state: row.user }}>
      {row.user.name}
    </Link>
  );
};

const renderProfessionalCell: GridColDef['renderCell'] = (params) => {
  const row = params.row as DeviceTableRowData;

  if (row.professional === null) {
    return <>-</>;
  }

  return (
    <Link
      to={{
        pathname: routeCreator.ProfessionalDetail(row.professional.id),
        state: row.professional,
      }}
    >
      {row.professional.name}
    </Link>
  );
};

export const devicesTableColumns: GridColumn<DeviceTableRowData>[] = [
  {
    field: 'name',
    headerName: 'Name',
    flex: 1,
    renderCell: renderNameCell,
  },
  {
    field: 'device_model',
    headerName: 'Model',
    flex: 0.75,
  },
  {
    field: 'game_version',
    headerName: 'Reducept version',
    flex: 0.75,
    renderCell: renderGameVersionCell,
    filterable: false,
  },
  {
    field: 'game_api_version',
    headerName: 'Game API',
    flex: 0.5,
    renderCell: renderGameApiVersionCell,
    filterable: false,
  },
  {
    field: 'device_type',
    headerName: 'Type',
    flex: 0.5,
  },
  {
    field: 'operating_system',
    headerName: 'OS',
    flex: 0.75,
  },
  {
    field: 'associated_user',
    headerName: 'Associated user',
    flex: 1,
    renderCell: renderUserCell,
    sortable: false,
  },
  {
    field: 'professional',
    headerName: 'Practice',
    flex: 1,
    renderCell: renderProfessionalCell,
    sortable: false,
  },
  {
    field: 'created_at',
    headerName: 'Creation',
    flex: 0.75,
    renderCell: renderDateCell(),
    filterable: false,
  },
  {
    field: 'updated_at',
    headerName: 'Updated',
    flex: 0.75,
    renderCell: renderDateCell(),
    filterable: false,
  },
];
