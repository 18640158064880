export interface ILog {
  id: number;
  entity_id: number;
  entity_type: string;
  type: LogType;
  severity: LogSeverity;
  message: string | null;
  context: any | null;
  date: string;
}

export enum LogType {
  CREATE = 0,
  IMPORT = 1,
  LOGIN = 2,
  LOGOUT = 3,
  TOKEN_REFRESH = 4,
  CREATED_FROM_STRIPE_WEBHOOK = 5,
  SYNC = 6,
  GOOGLE_ANALYTICS = 7,
  BAD_REQUEST = 8,
  PASSWORD_RESET = 9,
  SUBSCRIPTION = 10,
  MAILCHIMP = 11,
  ACCESS_PERIOD = 12,
  RESEND_RECONFIRM_ACCOUNT = 13,
  ACCOUNT_CONFIRMED = 14,
  PASSWORD_CHANGED = 15,
  DETACHED_FROM_PROFESSIONAL = 16,
  ROLE_ADDED = 17,
  ROLE_REMOVED = 18,
  DEVICE_DELETED = 19,
  PROFESSIONAL_INVITE_SENT = 20,
  PROFESSIONAL_INVITE_ACCEPTED = 21,
}

export enum LogSeverity {
  INFO = 0,
  ERROR = 1,
  WARNING = 2,
}

const logTypeDictionary = [
  { key: LogType.CREATE, value: 'create' },
  { key: LogType.IMPORT, value: 'import' },
  { key: LogType.LOGIN, value: 'login' },
  { key: LogType.LOGOUT, value: 'logout' },
  { key: LogType.TOKEN_REFRESH, value: 'token_refresh' },
  { key: LogType.CREATED_FROM_STRIPE_WEBHOOK, value: 'created_from_stripe_webhook' },
  { key: LogType.SYNC, value: 'synchronization' },
  { key: LogType.GOOGLE_ANALYTICS, value: 'google_analytics' },
  { key: LogType.BAD_REQUEST, value: 'bad_request' },
  { key: LogType.PASSWORD_RESET, value: 'password_reset' },
  { key: LogType.SUBSCRIPTION, value: 'subscription' },
  { key: LogType.MAILCHIMP, value: 'mailchimp' },
  { key: LogType.ACCESS_PERIOD, value: 'access_period' },
  { key: LogType.RESEND_RECONFIRM_ACCOUNT, value: 'resend_confirm_account' },
  { key: LogType.ACCOUNT_CONFIRMED, value: 'account_confirmed' },
  { key: LogType.PASSWORD_CHANGED, value: 'password_changed' },
  { key: LogType.DETACHED_FROM_PROFESSIONAL, value: 'detached_from_professional' },
  { key: LogType.ROLE_ADDED, value: 'role_added' },
  { key: LogType.ROLE_REMOVED, value: 'role_removed' },
  { key: LogType.DEVICE_DELETED, value: 'device_deleted' },
  { key: LogType.PROFESSIONAL_INVITE_SENT, value: 'professional_invite_sent' },
  { key: LogType.PROFESSIONAL_INVITE_ACCEPTED, value: 'professional_invite_accepted' },
];

export const mapLogTypeToTranslatableTag = (type: LogType) => {
  return logTypeDictionary[type] ? logTypeDictionary[type].value : type.toString();
};
