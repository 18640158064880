import React from 'react';
import {
  Card,
  CardHeader,
  CircularProgress,
  colors,
  Divider,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableRow,
} from '@material-ui/core';
import { Activity as ActivityIcon } from 'react-feather';
import Button from 'components/Button';
import CheckIcon from 'components/CheckIcon';
import LuxonDate from 'components/LuxonDate';
import { TeamleaderSyncResponse, TeamleaderSyncStatus, TeamleaderUser } from 'core/teamleader';
import useFetch from 'hooks/useFetch';
import useRequest from 'hooks/useRequest';
import { isAxiosError } from 'utils/guard';
import ErrorPage from 'pages/ErrorPage';
import LoadingPage from 'pages/LoadingPage';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(3),
    borderWidth: 8,
    borderColor: colors.common.white,
    hoverBorderColor: colors.common.white,
    marginTop: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
  },
}));

const TeamleaderSync: React.FC = () => {
  const classes = useStyles();

  const request = useFetch<TeamleaderSyncStatus>('/teamleader/sync/status');
  const syncNowRequest = useRequest<TeamleaderSyncResponse, never, never>({
    url: '/teamleader/sync',
    method: 'POST',
  });

  const handleSyncClick: React.MouseEventHandler<HTMLButtonElement> = async () => {
    try {
      const res = await syncNowRequest.request();
      await request.request()();

      const message = Object.entries(res.data.results).reduce((msg, [k, v]) => {
        const m = `${k}: synced: ${v.synced.length} and deleted: ${v.deleted.length}`;
        if (msg.length === 0) {
          return m;
        }

        return `${msg}, ${m}`;
      }, '');

      window.alert(`Ran successful sync: ${message}`);
    } catch (error) {
      if (isAxiosError(error) && error.response) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const data = error.response.data as any;

        if (data.message) {
          alert(data.message);
        }

        return;
      }

      console.error(error);
    }
  };

  if (request.loading && !request.data) {
    return <CircularProgress />;
  }

  if (!request.data) {
    return <ErrorPage />;
  }

  return (
    <Card className={classes.card}>
      <CardHeader
        avatar={<ActivityIcon size={25} />}
        titleTypographyProps={{
          color: 'textPrimary',
          variant: 'h5',
        }}
        title='Teamleader synchronisation'
      />
      <Divider />
      <Table>
        <TableBody>
          {Object.entries(request.data.results).map(([key, value]) => (
            <TableRow key={key}>
              <TableCell>{key}</TableCell>
              <TableCell>
                {value === null ? <CheckIcon condition={false} /> : <LuxonDate date={value} />}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter></TableFooter>
      </Table>

      <Button
        className={classes.button}
        color='primary'
        variant='contained'
        size='medium'
        loading={syncNowRequest.loading}
        onClick={handleSyncClick}
      >
        Sync now
      </Button>
    </Card>
  );
};

export default TeamleaderSync;
