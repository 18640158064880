import { Tooltip } from '@material-ui/core';
import { GridColDef } from '@material-ui/data-grid';
import { Link } from 'react-router-dom';

import Localized from 'components/Localized';
import { renderDataGridCheckCell } from 'components/mui/DataGrid/DataGridCheckCell';
import { LoadedUser } from 'core/backend';
import { IProfessionalAccessPeriod, LoadedCreator } from 'core/professionalAccessPeriod';
import { useLocalization } from 'services/localization/localization';
import { routeCreator } from 'services/routing';
import { GridColumn, renderDateCell } from 'utils/mui';

/**
 * The type of data in each table row.
 */
export type ProfessionalAccessPeriodsTableDataRow = IProfessionalAccessPeriod &
  LoadedUser &
  LoadedCreator;

/**
 * @param {GridCellParams} params
 *   Grid cell parameters.
 */
const RenderUserCell: GridColDef['renderCell'] = (params) => {
  const { getLocalizedString } = useLocalization();
  const row = params.row as ProfessionalAccessPeriodsTableDataRow;

  const inner =
    row.user_id === null ? (
      <Tooltip enterDelay={300} title={getLocalizedString('access_period_user_deleted_tooltip')}>
        <span>
          {'('}
          <Localized id='deleted'>deleted</Localized>
          {')'}
        </span>
      </Tooltip>
    ) : (
      <Link
        to={{
          pathname: routeCreator.PatientDetail(row.professional_id, row.user_id),
        }}
      >
        {row.user!.name}
      </Link>
    );

  return inner;
};

/**
 * @param {GridCellParams} params
 *   Grid cell parameters.
 */
const renderCreatedByCell: GridColDef['renderCell'] = (params) => {
  const row = params.row as ProfessionalAccessPeriodsTableDataRow;

  if (!row.creator) {
    return <span>-</span>;
  }

  const inner = (
    <Link
      to={{
        pathname: routeCreator.UserDetail(row.creator.id),
      }}
    >
      {row.creator.name}
    </Link>
  );

  return <span>{inner}</span>;
};

export const professionalAccessPeriodsTableColumns: GridColumn<ProfessionalAccessPeriodsTableDataRow>[] = [
  {
    field: 'user',
    headerName: 'User',
    flex: 1,
    renderCell: RenderUserCell,
    sortable: false,
    filterable: false,
  },
  {
    field: 'created_by',
    headerName: 'Created by',
    flex: 1,
    renderCell: renderCreatedByCell,
    sortable: false,
    filterable: false,
  },
  {
    field: 'active',
    headerName: 'Active',
    flex: 0.5,
    renderCell: renderDataGridCheckCell,
    sortable: false,
    filterable: false,
  },
  {
    field: 'period_start',
    headerName: 'Period start',
    flex: 0.75,
    renderCell: renderDateCell(),
    sortable: false,
    filterable: false,
  },
  {
    field: 'period_end',
    headerName: 'Period end',
    flex: 0.75,
    renderCell: renderDateCell(),
    sortable: false,
    filterable: false,
  },
];
