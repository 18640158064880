import React from 'react';
import { SubmitHandler } from 'react-hook-form';
import { RouteComponentProps } from 'react-router-dom';
import DetailPage from 'components/DetailPage';

import { IProduct } from 'core/product';
import useRequest from 'hooks/useRequest';
import useStateOrFetch from 'hooks/useStateOrFetch';
import { ProductRouteParams, routeCreator } from 'services/routing';
import ErrorPage from 'pages/ErrorPage';
import LoadingPage from 'pages/LoadingPage';
import ProductForm, { FormValues as ProductFormValues } from './forms/ProductForm';
import { OverviewProduct } from './ProductsOverview';

type Props = RouteComponentProps<ProductRouteParams, never, OverviewProduct | undefined>;

const EditProduct: React.FC<Props> = (props) => {
  const { productId } = props.match.params;

  const request = useStateOrFetch<OverviewProduct | null>(
    props.location.state,
    `/products/${productId}`,
  );

  const putRequest = useRequest<IProduct, never, ProductFormValues>({
    url: `/products/${productId}`,
    method: 'PUT',
  });

  if (request.loading && !request.data) {
    return <LoadingPage />;
  }
  if (!request.data) {
    return <ErrorPage />;
  }

  const product = request.data;

  const handleSubmit: SubmitHandler<ProductFormValues> = async (values) => {
    await putRequest.request({ data: values });
    props.history.push(routeCreator.ProductDetail(productId));
  };

  return (
    <DetailPage title='Edit product' subtitle={product.name} maxWidth='xs'>
      <ProductForm isEditForm onSubmit={handleSubmit} defaultValues={product} />
    </DetailPage>
  );
};

export default EditProduct;
