import React from 'react';

import { Tooltip } from '@material-ui/core';
import { GridColDef } from '@material-ui/data-grid';
import { Link } from 'react-router-dom';

import { LoadedProfessionals, LoadedProfessionalsCount } from 'core/backend';
import { IOrganization } from 'core/organization';
import { routeCreator } from 'services/routing';
import { GridColumn, renderDateCell } from 'utils/mui';

/**
 * The type of data in each table row.
 */
export type OrganizationTableRowData = IOrganization &
  LoadedProfessionals &
  LoadedProfessionalsCount;

/**
 * Cell renderer for the 'name' column. This adds a link to the professional's
 * detail page.
 *
 * @param {GridCellParams} params
 *   Grid cell parameters.
 */
const renderNameCell: GridColDef['renderCell'] = (params) => {
  const row = params.row as OrganizationTableRowData;

  return (
    <Link to={{ pathname: routeCreator.OrganizationDetail(row.id), state: row }}>
      {params.value}
    </Link>
  );
};

/**
 * Cell renderer for the 'active subscriptions' column.
 *
 * @param {GridCellParams} params
 *   Grid cell parameters.
 */
const renderActiveSubscriptions: GridColDef['renderCell'] = (params) => {
  const row = params.row as OrganizationTableRowData;

  const inactives = row.professionals.filter(
    (professional) => professional.active_subscription_id === null,
  );

  const tooltipTitle = (
    <>
      <span>The following practices have no active subscription:</span>
      <br />
      <br />
      <ul>
        {inactives.map((professional) => (
          <li key={professional.id}>- {professional.name}</li>
        ))}
      </ul>
    </>
  );

  return (
    <Tooltip title={tooltipTitle}>
      <span style={{ display: 'flex', alignItems: 'center' }}>
        {row.professionals.length - inactives.length} / {row.professionals.length}
      </span>
    </Tooltip>
  );
};

export const organizationsTableColumns: GridColumn<OrganizationTableRowData>[] = [
  {
    field: 'name',
    headerName: 'Name',
    flex: 1.5,
    renderCell: renderNameCell,
  },
  {
    field: 'professionals_count',
    headerName: 'Practices count',
    flex: 0.75,
  },
  {
    field: 'active_subscriptions',
    headerName: 'Active subscriptions',
    description: 'The amount of practices with an active subscription',
    flex: 1,
    renderCell: renderActiveSubscriptions,
  },
  {
    field: 'created_at',
    headerName: 'Creation',
    flex: 0.75,
    renderCell: renderDateCell(),
  },
  {
    field: 'updated_at',
    headerName: 'Updated',
    flex: 0.75,
    renderCell: renderDateCell(),
  },
];
