import React from 'react';

import { Grid } from '@material-ui/core';
import { Edit2 as EditIcon } from 'react-feather';
import { RouteComponentProps } from 'react-router-dom';

import Button from 'components/Button';
import DetailPage from 'components/DetailPage';
import LinkButton from 'components/LinkButton';
import { LoadedProfessional } from 'core/backend';
import { IProfessionalGroup } from 'core/professionalGroup';
import useProfessionalFetch from 'hooks/useProfessionalFetch';
import { useLocalization } from 'services/localization/localization';
import {
  history,
  ProfessionalGroupRouteParams,
  ProfessionalRouteParams,
  routeCreator,
} from 'services/routing';

import ErrorPage from 'pages/ErrorPage';
import LoadingPage from 'pages/LoadingPage';

import CardProfessionalGroupDetails from './components/CardProfessionalGroupDetails';
import CardProfessionalGroupUsers from './components/CardProfessionalGroupUsers';
import DeleteProfessionalGroupDialog from './components/DeleteProfessionalGroupDialog';

type FetchResponse = IProfessionalGroup & LoadedProfessional;
type RouteParams = ProfessionalRouteParams & ProfessionalGroupRouteParams;
type Props = RouteComponentProps<RouteParams, any, FetchResponse | undefined>;

const ProfessionalGroupDetail: React.FC<Props> = (props) => {
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const { getLocalizedString } = useLocalization();

  const { professionalId, professionalGroupId } = props.match.params;

  const request = useProfessionalFetch<FetchResponse | null>({
    default: [
      `/professionals/${professionalId}/groups/${professionalGroupId}`,
      { includes: ['professional'] },
    ],
    professional: (professionalId) => [
      `/professionals/${professionalId}/groups/${professionalGroupId}`,
    ],
  });

  if (request.loading && !request.data) {
    return <LoadingPage />;
  }

  if (!request.data) {
    return <ErrorPage />;
  }

  const group = request.data as FetchResponse;

  const handleGroupDeleted = (): void => {
    /** @todo add Snackbar notification */
    alert(getLocalizedString('delete_group_success'));

    setDeleteDialogOpen(false);

    history.push(routeCreator.ProfessionalProfessionalGroups(professionalId!));
  };

  const actions = (
    <>
      <Button
        translationKey='delete'
        color='default'
        variant='contained'
        onClick={() => setDeleteDialogOpen(true)}
        disabled={!group}
        style={{ marginRight: 4, marginLeft: 4 }}
      >
        Delete
      </Button>
      <LinkButton
        translationKey='edit'
        to={routeCreator.EditProfessionalGroup(professionalId, professionalGroupId)}
        state={group}
        startIcon={<EditIcon size={18} />}
        color='primary'
        variant='contained'
        style={{ marginLeft: 4 }}
      >
        Edit
      </LinkButton>
    </>
  );

  return (
    <DetailPage title={group.name} actions={actions}>
      <>
        <Grid container spacing={3}>
          <Grid item xs={12} md={3} lg={3} xl={3}>
            <CardProfessionalGroupDetails professionalGroup={group} />
          </Grid>
          <Grid item xs={12} md={9} lg={9} xl={9}>
            <CardProfessionalGroupUsers
              professionalId={professionalId}
              professionalGroupId={professionalGroupId}
            />
          </Grid>
        </Grid>
        <DeleteProfessionalGroupDialog
          open={deleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
          onGroupDeleted={handleGroupDeleted}
          professionalId={professionalId}
          professionalGroupId={professionalGroupId}
        />
      </>
    </DetailPage>
  );
};

export default ProfessionalGroupDetail;
