import { ListItem } from '@material-ui/core';

import LuxonDate from 'components/LuxonDate';
import ListItemText from 'components/mui/ListItemText';
import { IStripeSubscription } from 'core/subscription';
import { useLocalization } from 'services/localization/localization';

import { SubscriptionProviderComponent } from '../CardSubscriptionProvider';

const SubscriptionProviderStripe: SubscriptionProviderComponent<IStripeSubscription> = ({
  data,
}) => {
  const { getLocalizedString } = useLocalization();

  return (
    <>
      <ListItem>
        <ListItemText
          primary={getLocalizedString('stripe_id')}
          secondary={
            <div>
              <a
                target='_blank'
                rel='noreferrer'
                href={`https://dashboard.stripe.com/subscriptions/${data.stripe_id}`}
              >
                {data.stripe_id}
              </a>
            </div>
          }
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary={getLocalizedString('status')}
          secondary={getLocalizedString(data.status)}
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary={getLocalizedString('current_period_start')}
          secondary={<LuxonDate date={data.current_period_start} fallback='-' />}
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary={getLocalizedString('current_period_end')}
          secondary={<LuxonDate date={data.current_period_end} fallback='-' />}
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary={getLocalizedString('start_date')}
          secondary={<LuxonDate date={data.start_date} fallback='-' />}
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary={getLocalizedString('cancelled_at')}
          secondary={<LuxonDate date={data.cancelled_at} fallback='-' />}
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary={getLocalizedString('cancel_at')}
          secondary={<LuxonDate date={data.cancel_at} fallback='-' />}
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary={getLocalizedString('ended_at')}
          secondary={<LuxonDate date={data.ended_at} fallback='-' />}
        />
      </ListItem>
    </>
  );
};

export default SubscriptionProviderStripe;
