import React from 'react';

/**
 * A polyfill for Intl-Pluralrules (used by @fluent/bundle)
 * See link below for more information
 * @link https://www.npmjs.com/package/intl-pluralrules
 */
import 'intl-pluralrules';

import { FluentBundle, FluentResource } from '@fluent/bundle';
import { negotiateLanguages } from '@fluent/langneg';
import {
  LocalizationProvider as FluentLocalizationProvider,
  ReactLocalization,
  useLocalization as useFluentLocalization,
} from '@fluent/react';
import de from 'date-fns/locale/de';
import en from 'date-fns/locale/en-GB';
import nl from 'date-fns/locale/nl';
import { registerLocale } from 'react-datepicker';

import * as storageKeys from 'constants/storageKeys';
import { useTypedSelector } from 'store/store';

import deDE from './languages/de-DE';
import enGB from './languages/en-GB';
import nlNL from './languages/nl-NL';

/**
 * This application uses Fluent (Project Fluent) for localization.
 *
 * Some good resources to get started:
 *   - https://github.com/projectfluent/fluent/wiki
 *   - https://projectfluent.org/fluent/guide/
 */

// Registers the date-fns locales. The react-datepicker library uses date-fns for it's localization.
registerLocale('en', en);
registerLocale('nl', nl);
registerLocale('de', de);

// NOTE: When extending this type, also add a new 'registerLocale'.
type AvailableLanguage = 'en-GB' | 'nl-NL' | 'de-DE';
type AvailableLanguageShort = 'en' | 'nl' | 'de';

/**
 * Get the default language.
 */
const getDefaultLocale = (): AvailableLanguage => {
  const fallback: AvailableLanguage = 'en-GB';
  const locale = new URLSearchParams(window.location.search).get('locale');

  if (locale === null) {
    // No language found in query params, check if there's a stored value in localStorage.
    const storedLanguage = localStorage.getItem(storageKeys.dashboardLanguage);
    if (storedLanguage !== null) {
      return storedLanguage as AvailableLanguage;
    }

    return fallback;
  }

  // Invalid language, use fallback language.
  if (!Object.keys(AvailableLanguages).includes(locale)) {
    return fallback;
  }

  localStorage.setItem(storageKeys.dashboardLanguage, locale);
  return locale as AvailableLanguage;
};

const AvailableLanguages: Record<AvailableLanguage, string> = {
  'en-GB': 'English',
  'nl-NL': 'Nederlands',
  'de-DE': 'Deutsch',
};

const LanguageToShortLocaleMap: Record<AvailableLanguage, AvailableLanguageShort> = {
  'en-GB': 'en',
  'nl-NL': 'nl',
  'de-DE': 'de',
};

const toShortLocale = (locale: AvailableLanguage) => LanguageToShortLocaleMap[locale];

const RESOURCES: Record<AvailableLanguage, FluentResource> = {
  'en-GB': enGB,
  'nl-NL': nlNL,
  'de-DE': deDE,
};

function* generateBundles(userLocales: AvailableLanguage[]) {
  // Choose locales that are best for the user.
  const currentLocales = negotiateLanguages(
    userLocales,
    Object.keys(AvailableLanguages).map((key) => key.toString()),
    {
      defaultLocale: getDefaultLocale(),
    },
  );

  for (const locale of currentLocales) {
    const bundle = new FluentBundle(locale, {
      useIsolating: false,
    });
    bundle.addResource(RESOURCES[locale]);
    yield bundle;
  }
}

interface LocalizationContextType {
  selectedLanguage: AvailableLanguage;
  setSelectedLanguage: (language: AvailableLanguage) => void;
}

const initialContext: LocalizationContextType = {
  selectedLanguage: getDefaultLocale(),
  setSelectedLanguage: () =>
    console.error(
      'No LocalizationProvider supplied. Wrap this component with a LocalizationProvider to use this functionality.',
    ),
};

const LocalizationContext = React.createContext<LocalizationContextType>(initialContext);

const useLocalization = () => {
  const { l10n } = useFluentLocalization();
  const { selectedLanguage, setSelectedLanguage } = React.useContext(LocalizationContext);

  return {
    selectedLanguage,
    setSelectedLanguage,

    /** Same as l10n.getString(), but wrapped in a function with a better name */
    getLocalizedString: (...props: Parameters<ReactLocalization['getString']>) =>
      l10n.getString(...props),
  };
};

const LocalizationProvider: React.FC = ({ children }) => {
  const user = useTypedSelector((state) => state.session.user);
  const [selectedLanguage, innerSetSelectedLanguage] = React.useState<AvailableLanguage>(
    (localStorage.getItem(storageKeys.dashboardLanguage) as AvailableLanguage) ||
      getDefaultLocale(),
  );

  const setSelectedLanguage = (language: AvailableLanguage) => {
    if (!Object.keys(AvailableLanguages).includes(language)) {
      // Corrupt value stored in localStorage, remove it
      innerSetSelectedLanguage(getDefaultLocale());
      localStorage.removeItem(storageKeys.dashboardLanguage);
      return;
    }

    localStorage.setItem(storageKeys.dashboardLanguage, language.toString());
    innerSetSelectedLanguage(language);
  };

  /**
   * Initialize the user's prefered language if user's logged in.
   *
   * First try to get the language from localStorage.
   * Then try to get the user's language.
   * If the user has no language, fall back to default language. */
  React.useEffect(() => {
    if (user !== null) {
      const storedLanguage = localStorage.getItem(storageKeys.dashboardLanguage);

      if (storedLanguage !== null) {
        setSelectedLanguage(storedLanguage as AvailableLanguage);
        return;
      }

      if (user.language === null || user.language === undefined) {
        setSelectedLanguage(getDefaultLocale());
      } else {
        setSelectedLanguage(user.language);
      }
    }
  }, [user]);

  let l10n = new ReactLocalization(generateBundles([selectedLanguage]));

  return (
    <FluentLocalizationProvider l10n={l10n}>
      <LocalizationContext.Provider value={{ selectedLanguage, setSelectedLanguage }}>
        {children}
      </LocalizationContext.Provider>
    </FluentLocalizationProvider>
  );
};

export type { AvailableLanguage, AvailableLanguageShort };
export {
  LocalizationProvider,
  useLocalization,
  getDefaultLocale,
  AvailableLanguages,
  toShortLocale,
};
