import React from 'react';

import { Box, Card, CardHeader } from '@material-ui/core';
import { Calendar as CalendarIcon } from 'react-feather';

import PaginatedServerDataGrid from 'containers/PaginatedServerDataGrid';
import { IPaginated } from 'core/backend';
import { IProfessionalAccessPeriod } from 'core/professionalAccessPeriod';
import { IUser } from 'core/user';
import useProfessionalFetch from 'hooks/useProfessionalFetch';
import { useLocalization } from 'services/localization/localization';
import { userAccessPeriodsTableColumns } from 'services/tables/userDetail/userAccessPeriodsTable';

interface IProps {
  user: IUser;
}

/**
 * @todo - Add more contextual information for administrators such as:
 *  - user_id
 *  - user_belongs_to_professional
 *  - professional_id
 *  - created_by_user_id
 *  - created_at
 *  - updated_at
 */
const CardUserAccessPeriods: React.FC<IProps> = ({ user }) => {
  const request = useProfessionalFetch<IPaginated<IProfessionalAccessPeriod>>({
    default: [`/users/${user.id}/access-periods`],
    professional: (professionalId) => [
      `/professionals/${professionalId}/users/${user.id}/access-periods`,
    ],
  });
  const { getLocalizedString } = useLocalization();

  return (
    <Card>
      <CardHeader
        avatar={<CalendarIcon size={25} />}
        titleTypographyProps={{
          color: 'textPrimary',
          variant: 'h5',
        }}
        title={getLocalizedString('access_periods')}
        subheader={getLocalizedString('access_periods_used_by', {
          model: getLocalizedString('user').toLowerCase(),
        })}
      />
      <Box height={455}>
        <PaginatedServerDataGrid
          request={request}
          columns={userAccessPeriodsTableColumns}
          components={{ Toolbar: undefined }}
        />
      </Box>
    </Card>
  );
};

export default CardUserAccessPeriods;
