import React from 'react';

import { CircularProgress, makeStyles } from '@material-ui/core';

import Page from 'components/Page';

const useStyles = makeStyles((_) => ({
  root: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const LoadingPage: React.FC = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title='Reducept dashboard'>
      <CircularProgress />
    </Page>
  );
};

export default LoadingPage;
