import { IRole } from 'core/role';
import { ITokenResponse } from 'core/session';
import { IUser } from 'core/user';
import { ActionCreator, ActionType, IActionCreator } from 'store/actions';

import { ISessionState, SessionStateProfessionals } from './sessionReducer';

export const doLogin: ActionCreator<ActionType.LOGIN, ITokenResponse> = (data) => ({
  type: ActionType.LOGIN,
  payload: data,
});

export const doLogout: IActionCreator<ActionType.LOGOUT, void> = {
  type: ActionType.LOGOUT,
  payload: undefined,
};

export const setUser: ActionCreator<ActionType.SET_USER, IUser> = (user) => ({
  type: ActionType.SET_USER,
  payload: user,
});

export const setProfessionals: ActionCreator<
  ActionType.SET_PROFESSIONAL_USERS,
  SessionStateProfessionals[]
> = (professionalUsers) => ({
  type: ActionType.SET_PROFESSIONAL_USERS,
  payload: professionalUsers,
});

export const setRoles: ActionCreator<ActionType.SET_ROLES, IRole[]> = (roles) => ({
  type: ActionType.SET_ROLES,
  payload: roles,
});

export const setLoginError: ActionCreator<
  ActionType.SET_LOGIN_ERROR,
  ISessionState['loginError']
> = (error) => ({
  type: ActionType.SET_LOGIN_ERROR,
  payload: error,
});
