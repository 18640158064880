import React from 'react';

import { Localized as FluentLocalized, LocalizedProps } from '@fluent/react';

type Props = LocalizedProps;

/**
 * This is a wrapper around the @fluent/react Localized component.
 *
 * This is done so in the future we can easily swap out localization providers by
 * only editing this component's provider.
 *
 * Note: The @fluent/react Localized component can only accept 1 child element. Don't supply this component with more than 1 child.
 */
const Localized: React.FC<Props> = (props) => {
  if (React.Children.count(props.children) > 1) {
    console.warn(
      'More than 1 child component is supplied to this Localized component. This is not accepted by the fluent Localized component.',
    );
    return <>{props.children}</>;
  }

  return <FluentLocalized {...props} />;
};

export default Localized;
