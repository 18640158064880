import React from 'react';

import { Box, Card, CardHeader } from '@material-ui/core';
import { Info as InfoIcon } from 'react-feather';

import PaginatedServerDataGrid from 'containers/PaginatedServerDataGrid';
import { IPaginated } from 'core/backend';
import { ILog } from 'core/log';
import { IUser } from 'core/user';
import useFetch from 'hooks/useFetch';
import { useLocalization } from 'services/localization/localization';
import { logsTableColumns } from 'services/tables/logsTable';

interface IProps {
  user: IUser;
}

const CardUserLogs: React.FC<IProps> = ({ user }) => {
  const request = useFetch<IPaginated<ILog>>(`/users/${user.id}/logs`);
  const { getLocalizedString } = useLocalization();

  return (
    <Card>
      <CardHeader
        avatar={<InfoIcon size={25} />}
        titleTypographyProps={{
          color: 'textPrimary',
          variant: 'h5',
        }}
        title={getLocalizedString('logs')}
        subheader={getLocalizedString('logs_for_model', {
          model: getLocalizedString('user').toLowerCase(),
        })}
      />
      <Box height={455}>
        <PaginatedServerDataGrid
          request={request}
          columns={logsTableColumns}
          components={{ Toolbar: undefined }}
        />
      </Box>
    </Card>
  );
};

export default CardUserLogs;
