import { MenuItem, PropTypes } from '@material-ui/core';
import { FieldError } from 'react-hook-form';

import { FormComponent } from 'components/forms';
import BaseForm from 'components/forms/BaseForm';
import Select from 'components/forms/elements/Select';
import TextField from 'components/mui/TextField';
import { IProduct } from 'core/product';
import { licenseResetInterval } from 'core/subscription';
import { useReduceptForm } from 'hooks/useReduceptForm';
import { useLocalization } from 'services/localization/localization';

export type FormValues = Pick<
  IProduct,
  'name' | 'description' | 'amount_licenses' | 'interval' | 'internal_name'
>;

interface IProps {
  isEditForm?: true;
}

const ProductForm: FormComponent<FormValues, IProps> = ({
  defaultValues,
  onSubmit,
  isEditForm,
}) => {
  const form = useReduceptForm<FormValues>(onSubmit, { defaultValues });
  const {
    register,
    errors,
    control,
    formState: { touched },
  } = form;
  const { getLocalizedString } = useLocalization();

  const fields = [
    { name: 'name', label: 'Name' },
    { name: 'internal_name', label: 'Internal name' },
    { name: 'description', label: 'Description' },
    { name: 'amount_licenses', label: 'Amount of licensees', type: 'number' },
  ];

  // Casted to any because MUI has weird props.
  const baseProps: any = {
    variant: 'outlined',
    fullWidth: true,
    margin: 'normal' as PropTypes.Margin,
    required: true,
  };
  console.log('errors', errors);

  return (
    <BaseForm form={form}>
      {fields.map((field) => (
        <TextField
          key={field.name}
          error={Boolean(touched[field.name] && errors[field.name])}
          helperText={touched[field.label] && (errors[field.name] as FieldError)?.message}
          label={field.label}
          translationKey={field.name}
          inputRef={register({ required: getLocalizedString('required') })}
          {...baseProps}
          type={field.type ?? 'text'}
        />
      ))}
      <Select
        label='Interval'
        controllerProps={{
          control,
          name: 'interval',
          rules: {
            required: getLocalizedString('required'),
          },
        }}
        {...baseProps}
      >
        {licenseResetInterval.map((interval) => (
          <MenuItem key={interval} selected value={interval}>
            {interval}
          </MenuItem>
        ))}
      </Select>
    </BaseForm>
  );
};

export default ProductForm;
