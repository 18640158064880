import React from 'react';

import { Box, Card, colors, makeStyles } from '@material-ui/core';

import Button from 'components/Button';
import { FormReturn } from 'hooks/useReduceptForm';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(3),
    borderWidth: 8,
    borderColor: colors.common.white,
    hoverBorderColor: colors.common.white,
  },
  button: {
    marginTop: 8,
  },
}));

type Props = JSX.IntrinsicElements['form'] & {
  /**
   * Any type of returned value of the useReduceptForm hook.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: FormReturn<any>;

  /**
   * Hide the generic form error from appearing. This is often 'The given data was invalid'
   */
  showFormError?: boolean;
};

const BaseForm: React.FC<Props> = ({ form, children, showFormError = true, ...rest }) => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState<boolean>(true);

  // Disable the form for 1.5 seconds on initial load.
  // This is done to prevent a bug where you could submit a form which contained a ServerSelect before
  // the ServerSelect was done 'loading the options', so the user would submit an invalid value.
  setTimeout(() => setLoading(false), 1500);

  const {
    formError,
    formState: { isSubmitting, isValid },
  } = form;

  return (
    <form {...rest} onSubmit={form.handleSubmit}>
      <Card className={classes.card}>
        <Box>{children}</Box>

        {(showFormError && formError) ?? null}
        <Button
          translationKey='submit'
          className={classes.button}
          color='primary'
          loading={loading || isSubmitting}
          disabled={!isValid || loading}
          fullWidth
          size='large'
          type='submit'
          variant='contained'
        >
          Submit
        </Button>
      </Card>
    </form>
  );
};

export default BaseForm;
