import { Action, Reducer, AnyAction } from 'redux';

import { IAction, ActionType } from 'store/actions';

export type Mutator<S, P> = (state: S, payload: P) => S;

export interface IReducerHandlers<S> {
  [index: number]: Mutator<S, any>;
}

/**
 * Creates a reducer.
 *
 * @param initialState Initial state of the reducer.
 * @param handlers State mutator handlers.
 */
export function createReducer<S>(initialState: S, handlers: IReducerHandlers<S>): Reducer<S> {
  return (state: S = initialState, action: IAction): S => {
    if (handlers[action.type]) {
      return handlers[action.type](state, action.payload);
    }

    return state;
  };
}

/**
 * Type-guard to check if a dispatched action is an IAction object.
 *
 * @param action Any redux action.
 */
const actionHasPayload = (action: Action): action is IAction =>
  Boolean((action as object).hasOwnProperty('payload') && ActionType[action.type]);

/**
 * Action transformer for redux-logger. Changes action type nums to human-readable
 * strings.
 *
 * @param action Any dispatched redux action.
 */
export function readableActionTypes(action: Action): AnyAction {
  if (actionHasPayload(action)) {
    return {
      type: ActionType[action.type],
      payload: action.payload,
    };
  }

  return action;
}
