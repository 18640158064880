import { ListItem } from '@material-ui/core';

import LuxonDate from 'components/LuxonDate';
import ListItemText from 'components/mui/ListItemText';
import { ITrialSubscription } from 'core/subscription';
import { useLocalization } from 'services/localization/localization';

import { SubscriptionProviderComponent } from '../CardSubscriptionProvider';

const SubscriptionProviderTrial: SubscriptionProviderComponent<ITrialSubscription> = ({ data }) => {
  const { getLocalizedString } = useLocalization();

  return (
    <>
      <ListItem>
        <ListItemText
          primary={getLocalizedString('period_start')}
          secondary={<LuxonDate date={data.period_start} fallback='-' />}
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary={getLocalizedString('paused_at')}
          secondary={<LuxonDate date={data.paused_at} fallback='-' />}
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary={getLocalizedString('resumed_at')}
          secondary={<LuxonDate date={data.resumed_at} fallback='-' />}
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary={getLocalizedString('period_end')}
          secondary={<LuxonDate date={data.period_end} fallback='-' />}
        />
      </ListItem>
    </>
  );
};

export default SubscriptionProviderTrial;
