import React from 'react';

import { SubmitHandler } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';

import DetailPage from 'components/DetailPage';
import { getServerSelectValue } from 'components/forms/elements/ServerSelect';
import NewPatientForm, { INewPatientFormValues } from 'components/forms/patients/NewPatientForm';
import { IUser } from 'core/user';
import useProfessionalLicenses from 'hooks/useProfessionalLicenses';
import useRequest from 'hooks/useRequest';
import { dispatchEvent, EventType } from 'services/events';
import { useLocalization } from 'services/localization/localization';
import { ProfessionalRouteParams, routeCreator, routes } from 'services/routing';

type PostPatientRequestValues = Omit<INewPatientFormValues, 'professional_groups'> & {
  professional_group_ids: number[];
};

const CreatePatient: React.FC = () => {
  const { professionalId } = useParams<ProfessionalRouteParams>();
  const history = useHistory();
  const { professional, licensesLeft, unlimitedLicenses } = useProfessionalLicenses(professionalId);
  const { getLocalizedString } = useLocalization();

  const postRequest = useRequest<IUser, never, PostPatientRequestValues>({
    method: 'POST',
    url: `/professionals/${professionalId}/patients`,
  });

  if (!unlimitedLicenses && licensesLeft <= 0 && professional !== null) {
    history.push(routes.Dashboard);
  }

  const handleSubmit: SubmitHandler<INewPatientFormValues> = async ({
    professional_groups,
    ...values
  }) => {
    const response = await postRequest.request({
      data: {
        professional_group_ids:
          getServerSelectValue(professional_groups)?.map((group) => group.id) ?? [],
        ...values,
      },
    });

    dispatchEvent(EventType.ReloadProfessionalInformation);
    history.push(routeCreator.PatientDetail(professionalId, response.data.id), response.data);
  };

  const pageTitle = getLocalizedString('add-model', {
    model: getLocalizedString('patient').toLowerCase(),
  });

  return (
    <DetailPage title={pageTitle} maxWidth='sm'>
      <NewPatientForm
        onSubmit={handleSubmit}
        hasUnlimitedLicenses={unlimitedLicenses}
        licenseAmount={licensesLeft}
        professionalId={professionalId}
      />
    </DetailPage>
  );
};

export default CreatePatient;
