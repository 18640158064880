import React from 'react';

import { GridColDef } from '@material-ui/data-grid';
import { Link } from 'react-router-dom';

import { LoadedProfessional, LoadedUser } from 'core/backend';
import { IProfessionalUser } from 'core/professional';
import { useLocalization } from 'services/localization/localization';
import { routeCreator } from 'services/routing';
import { GridColumn, renderDateCell } from 'utils/mui';

/**
 * The type of data in each table row.
 */
export type ProfessionalUsersTableRowData = IProfessionalUser & LoadedUser & LoadedProfessional;

/**
 * Cell renderer to render the full name of the staff user.
 *
 * @param {GridCellParams} params
 *   Grid cell parameters.
 */
const renderUserFullNameCell: GridColDef['renderCell'] = (params) => {
  const row = params.row as ProfessionalUsersTableRowData;

  return <span style={{ display: 'flex', alignItems: 'center' }}>{row.user?.name}</span>;
};

/**
 * Cell renderer to render the first name of the staff user.
 *
 * @param {GridCellParams} params
 *   Grid cell parameters.
 */
const renderUserFirstNameCell: GridColDef['renderCell'] = (params) => {
  const row = params.row as ProfessionalUsersTableRowData;

  return <span style={{ display: 'flex', alignItems: 'center' }}>{row.user?.first_name}</span>;
};

/**
 * Cell renderer to render the last name of the staff user.
 *
 * @param {GridCellParams} params
 *   Grid cell parameters.
 */
const renderUserLastNameCell: GridColDef['renderCell'] = (params) => {
  const row = params.row as ProfessionalUsersTableRowData;

  return <span style={{ display: 'flex', alignItems: 'center' }}>{row.user?.last_name}</span>;
};

/**
 * Cell renderer to render the email of the staff user.
 *
 * @param {GridCellParams} params
 *   Grid cell parameters.
 */
const renderUserEmailCell: GridColDef['renderCell'] = (params) => {
  const row = params.row as ProfessionalUsersTableRowData;

  if (row.professional_id && row.user) {
    return (
      <Link to={routeCreator.StaffDetail(row.professional_id, row.user.id)}>
        <span style={{ display: 'flex', alignItems: 'center' }}>{row.user.email}</span>
      </Link>
    );
  }

  return <>-</>;
};

/**
 * Cell renderer to render the role of the staff user.
 *
 * Renders a pretty role title if it can be found. Otherwise it just renders the
 * default role title.
 *
 * @param {GridCellParams} params
 *   Grid cell parameters.
 */
const RenderRoleCell: GridColDef['renderCell'] = (params) => {
  const { getLocalizedString } = useLocalization();
  const row = params.row as ProfessionalUsersTableRowData;

  return (
    <span style={{ display: 'flex', alignItems: 'center' }}>{getLocalizedString(row.role)}</span>
  );
};

export const professionalUsersTableColumns: GridColumn<ProfessionalUsersTableRowData>[] = [
  {
    field: 'name',
    headerName: 'Full name',
    flex: 0.5,
    renderCell: renderUserFullNameCell,
    sortable: false,
    hide: true,
  },
  {
    field: 'first_name',
    headerName: 'First name',
    flex: 0.5,
    filterable: false,
    renderCell: renderUserFirstNameCell,
  },
  {
    field: 'last_name',
    headerName: 'Last name',
    flex: 0.5,
    filterable: false,
    renderCell: renderUserLastNameCell,
  },
  {
    field: 'email',
    headerName: 'Email',
    flex: 1,
    renderCell: renderUserEmailCell,
    sortable: false,
    filterable: false,
  },
  {
    field: 'role',
    headerName: 'Role',
    flex: 0.5,
    renderCell: RenderRoleCell,
  },
  {
    field: 'created_at',
    headerName: 'Creation',
    flex: 0.5,
    renderCell: renderDateCell(),
  },
  {
    field: 'updated_at',
    headerName: 'Updated',
    flex: 0.5,
    renderCell: renderDateCell(),
  },
];
