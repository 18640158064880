import React from 'react';

import { SubmitHandler } from 'react-hook-form';
import { RouteComponentProps } from 'react-router-dom';

import DetailPage from 'components/DetailPage';
import { getServerSelectValue } from 'components/forms/elements/ServerSelect';
import ProfessionalSubscriptionForm, {
  IProfessionalSubscriptionFormValues,
} from 'components/forms/subscriptions/ProfessionalSubscriptionForm';
import UserSubscriptionForm, {
  IUserSubscriptionFormValues,
} from 'components/forms/subscriptions/UserSubscriptionForm';
import { ISubscription } from 'core/subscription';
import useRequest from 'hooks/useRequest';
import useStateOrFetch from 'hooks/useStateOrFetch';
import { useLocalization } from 'services/localization/localization';
import { history, routeCreator, SubscriptionRouteParams } from 'services/routing';
import { stringToDateTime } from 'utils/utils';

import ErrorPage from 'pages/ErrorPage';
import LoadingPage from 'pages/LoadingPage';

type Props = RouteComponentProps<SubscriptionRouteParams, any, ISubscription | undefined>;

type PutProfessionaSubscriptionRequestValues = PutUserSubscriptionRequestValues &
  Pick<ISubscription, 'professional_id' | 'amount_licenses' | 'licenses_reset_interval'>;

type PutUserSubscriptionRequestValues = Pick<ISubscription, 'user_id' | 'start_date' | 'end_date'>;

const EditSubscription: React.FC<Props> = (props) => {
  const { subscriptionId } = props.match.params;

  const request = useStateOrFetch<ISubscription | null>(
    props.location.state,
    `/subscriptions/${subscriptionId}`,
  );

  const putRequest = useRequest<
    ISubscription,
    never,
    PutProfessionaSubscriptionRequestValues | PutUserSubscriptionRequestValues
  >({
    url: `/subscriptions/${subscriptionId}`,
    method: 'PUT',
  });

  const { getLocalizedString } = useLocalization();

  if (request.loading && !request.data) {
    return <LoadingPage />;
  }

  if (!request.data) {
    return <ErrorPage />;
  }

  const subscription = request.data as ISubscription;

  if (subscription.provider_type !== 'manual') {
    console.error('Editing subscriptions is only available for the manual provider type');
    history.push(routeCreator.SubscriptionDetail(subscription.id));
  }

  const handleProfessionalSubmit: SubmitHandler<IProfessionalSubscriptionFormValues> = async (
    values,
  ) => {
    await putRequest.request({
      data: {
        professional_id: getServerSelectValue(values.professional)!.id,
        user_id: getServerSelectValue(values.user)?.id || null,
        licenses_reset_interval: values.licenses_reset_interval,
        amount_licenses: values.amount_licenses,
        start_date: values.start_date.toISO(),
        end_date: values.end_date?.toISO() || null,
      },
    });

    // Don't add a state in the transition as the subscription detail does extra
    // includes with its request.
    history.push(routeCreator.SubscriptionDetail(subscriptionId));
  };

  const handleUserSubmit: SubmitHandler<IUserSubscriptionFormValues> = async (values) => {
    await putRequest.request({
      data: {
        user_id: getServerSelectValue(values.user)!.id,
        start_date: values.start_date.toISO(),
        end_date: values.end_date?.toISO() || null,
      },
    });

    // Don't add a state in the transition as the subscription detail does extra
    // includes with its request.
    history.push(routeCreator.SubscriptionDetail(subscriptionId));
  };

  const formComponent = subscription.professional_id ? (
    <ProfessionalSubscriptionForm
      isEditForm
      onSubmit={handleProfessionalSubmit}
      defaultValues={{
        ...subscription,
        user: subscription.user_id,
        professional: subscription.professional_id,

        // Modify the dates to cast them to DateTime instances.
        start_date: stringToDateTime(subscription.start_date) ?? undefined,
        end_date: stringToDateTime(subscription.end_date),
      }}
    />
  ) : (
    <UserSubscriptionForm
      isEditForm
      onSubmit={handleUserSubmit}
      defaultValues={{
        ...subscription,
        user: subscription.user_id,

        // Modify the dates to cast them to DateTime instances.
        start_date: stringToDateTime(subscription.start_date) ?? undefined,
        end_date: stringToDateTime(subscription.end_date),
      }}
    />
  );

  const pageTitle = getLocalizedString('update-model', {
    model: getLocalizedString('subscription').toLowerCase(),
  });

  return (
    <DetailPage title={pageTitle} maxWidth='xs'>
      {formComponent}
    </DetailPage>
  );
};

export default EditSubscription;
