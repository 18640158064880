import React from 'react';
import {
  Card,
  colors,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import Button from 'components/Button';
import DetailPage from 'components/DetailPage';
import { TeamleaderUser } from 'core/teamleader';
import useFetch from 'hooks/useFetch';
import useRequest from 'hooks/useRequest';
import { isAxiosError } from 'utils/guard';
import ErrorPage from 'pages/ErrorPage';
import LoadingPage from 'pages/LoadingPage';
import TeamleaderSync from './components/TeamleaderSync';

type FetchResponse = { signed_in: false } | TeamleaderUser;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const loggedInResponse = (body: any): body is TeamleaderUser => {
  return typeof body.id === 'string';
};

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(3),
    borderWidth: 8,
    borderColor: colors.common.white,
    hoverBorderColor: colors.common.white,
  },
  button: {
    marginTop: 8,
  },
}));

const TeamleaderStatus: React.FC = () => {
  const classes = useStyles();

  const request = useFetch<FetchResponse>('/teamleader/auth/status', {});
  const requestLoginLink = useRequest<{ url: string }, never, { redirect_url: string }>({
    url: '/teamleader/auth',
    method: 'POST',
  });

  const handleLoginBtn: React.MouseEventHandler<HTMLButtonElement> = async () => {
    try {
      const res = await requestLoginLink.request({
        data: {
          redirect_url: window.location.href,
        },
      });

      window.location.replace(decodeURIComponent(res.data.url));
    } catch (error) {
      if (isAxiosError(error) && error.response) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const data = error.response.data as any;

        if (data.message) {
          alert(data.message);
        }

        return;
      }

      console.error(error);
    }
  };

  if (request.loading && !request.data) {
    return <LoadingPage />;
  }

  if (!request.data) {
    return <ErrorPage />;
  }

  if (!loggedInResponse(request.data)) {
    return (
      <DetailPage maxWidth='xs' title='Teamleader status'>
        <Card className={classes.card}>
          <Typography align='center' gutterBottom variant='h3'>
            Action required!
          </Typography>
          <Typography align='center' gutterBottom>
            Our system is not authenticated with Teamleader. This is needed to synchronise our
            application. Log in with an administrative user:
            <br />
            <br />
            <Button
              color='primary'
              variant='contained'
              size='large'
              translationKey='login'
              loading={requestLoginLink.loading}
              onClick={handleLoginBtn}
            >
              Login
            </Button>
          </Typography>
        </Card>
      </DetailPage>
    );
  }

  return (
    <DetailPage maxWidth='xs' title='Teamleader status'>
      <Card className={classes.card}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Logged in:</TableCell>
              <TableCell>{request.data.email}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Role:</TableCell>
              <TableCell>{request.data.function}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Card>

      <TeamleaderSync />
    </DetailPage>
  );
};

export default TeamleaderStatus;
