import React from 'react';

import { Plus as PlusIcon } from 'react-feather';
import { RouteComponentProps } from 'react-router-dom';

import DataGridOverviewPage from 'components/DataGridOverviewPage';
import LinkButton from 'components/LinkButton';
import PaginatedServerDataGrid from 'containers/PaginatedServerDataGrid';
import { IPaginated } from 'core/backend';
import { IProfessional } from 'core/professional';
import useProfessionalFetch from 'hooks/useProfessionalFetch';
import { useLocalization } from 'services/localization/localization';
import { ProfessionalRouteParams, routeCreator } from 'services/routing';
import {
  professionalGroupTableColumns,
  ProfessionalGroupTableRowData,
} from 'services/tables/professionalGroupTable';

type Props = RouteComponentProps<ProfessionalRouteParams, any, IProfessional | undefined>;

const ProfessionalProfessionalGroupsOverview: React.FC<Props> = (props) => {
  const { professionalId } = props.match.params;
  const request = useProfessionalFetch<IPaginated<ProfessionalGroupTableRowData>>({
    default: [
      `/professionals/${professionalId}/groups`,
      { includes: ['professionalGroupUsersCount', 'professional'] },
    ],
    professional: (professionalId) => [
      `/professionals/${professionalId}/groups`,
      { includes: ['professionalGroupUsersCount'] },
    ],
  });

  const actions = [
    <>
      <LinkButton
        translationKey='add'
        to={routeCreator.CreateProfessionalGroup(professionalId)}
        color='primary'
        variant='contained'
        startIcon={<PlusIcon size={20} />}
      >
        Add group
      </LinkButton>
    </>,
  ];

  const { getLocalizedString } = useLocalization();

  return (
    <DataGridOverviewPage
      title={getLocalizedString('groups')}
      subtitle={`${request.data?.meta.total || 0}`}
      actions={actions}
    >
      <PaginatedServerDataGrid
        request={request}
        searchable
        columns={professionalGroupTableColumns}
        componentsProps={{
          toolbar: {
            searchPlaceholder: getLocalizedString('search-model', {
              model: getLocalizedString('groups').toLowerCase(),
            }),
            searchColumnField: 'name',
          },
        }}
      />
    </DataGridOverviewPage>
  );
};

export default ProfessionalProfessionalGroupsOverview;
