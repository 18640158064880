import React from 'react';

import { Box, Card, CardHeader, Table, TableCell, TableHead, TableRow } from '@material-ui/core';
import { Calendar as CalendarIcon } from 'react-feather';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link } from 'react-router-dom';

import LuxonDate from 'components/LuxonDate';
import RequestTableBody from 'components/mui/RequestTableBody';
import ProgressDivider from 'components/ProgressDivider';
import { LoadedProfessionalUserFull } from 'core/backend';
import { IProfessionalGroupUser } from 'core/professionalGroup';
import { isPlaceholderEmail } from 'core/user';
import useFetch from 'hooks/useFetch';
import { useLocalization } from 'services/localization/localization';
import { routeCreator } from 'services/routing';

interface IProps {
  professionalId: number | string;
  professionalGroupId: number | string;
}

type FetchResponse = IProfessionalGroupUser & LoadedProfessionalUserFull;

const CardProfessionalGroupUsers: React.FC<IProps> = ({ professionalId, professionalGroupId }) => {
  const request = useFetch<FetchResponse[]>(
    `/professionals/${professionalId}/groups/${professionalGroupId}/users`,
  );

  const { getLocalizedString } = useLocalization();

  const data = request.data || [];

  return (
    <Card>
      <CardHeader
        avatar={<CalendarIcon size={25} />}
        titleTypographyProps={{
          color: 'textPrimary',
          variant: 'h5',
        }}
        title={getLocalizedString('professional_group_users')}
        subheader={getLocalizedString('associated_group_users')}
      />
      <ProgressDivider inProgress={request.loading} />
      <PerfectScrollbar>
        <Box height={455}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{getLocalizedString('user')}</TableCell>
                <TableCell>{getLocalizedString('email')}</TableCell>
                <TableCell>{getLocalizedString('added_at')}</TableCell>
              </TableRow>
            </TableHead>

            <RequestTableBody
              request={request}
              message={getLocalizedString('not-found', { model: getLocalizedString('users') })}
            >
              {data.map((groupUser) => (
                <TableRow hover key={groupUser.id}>
                  <TableCell>
                    <Link
                      to={routeCreator.PatientDetail(
                        professionalId,
                        groupUser.professional_user.user_id,
                      )}
                    >
                      {groupUser.professional_user.user.name}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Link
                      to={routeCreator.PatientDetail(
                        professionalId,
                        groupUser.professional_user.user_id,
                      )}
                    >
                      {isPlaceholderEmail(groupUser.professional_user.user.email) ? (
                        <span style={{ opacity: 0.2 }}>
                          {getLocalizedString('no_email_set', null)}
                        </span>
                      ) : (
                        groupUser.professional_user.user.email
                      )}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <LuxonDate date={groupUser.created_at} />
                  </TableCell>
                </TableRow>
              ))}
            </RequestTableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

export default CardProfessionalGroupUsers;
