import { REHYDRATE } from 'redux-persist';
import { takeEvery } from 'redux-saga/effects';

import { ActionType, IAction } from './actions';

/* Sagas */
import { loadTokens, persistedSessionReducerKey } from './rehydrate';
import * as sessionSagas from './session/sessionSagas';

/**
 * redux-saga doesn't support enum values by default,
 * so we're changing it to a boolean instead.
 *
 * @param type ActionType to check for.
 */
const match = (type: ActionType) => (action: IAction): boolean => action.type === type;

/**
 * Specific check for when the sessionreducer is being rehydrated.
 */
const hydratingSessionReducer = (action: any): boolean =>
  action.type === REHYDRATE &&
  action.hasOwnProperty('key') &&
  action.key === persistedSessionReducerKey;

function* mySaga(): Generator {
  yield takeEvery(hydratingSessionReducer, loadTokens);
  yield takeEvery(match(ActionType.LOGIN), sessionSagas.uponLogin);
  yield takeEvery(match(ActionType.LOGOUT), sessionSagas.uponLogout);
}

export default mySaga;
