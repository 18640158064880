import React from 'react';

import { Box, Card, CardContent, CardHeader, Divider } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { User as UserIcon } from 'react-feather';

import CheckIcon from 'components/CheckIcon';
import LuxonDate from 'components/LuxonDate';
import ListItemText from 'components/mui/ListItemText';
import { getTranslatableRegistrationOriginName, IUser } from 'core/user';
import { useLocalization } from 'services/localization/localization';

interface IProps {
  user: IUser;
}

const CardUserMetaData: React.FC<IProps> = ({ user }) => {
  const { getLocalizedString } = useLocalization();

  return (
    <Card>
      <CardHeader
        avatar={<UserIcon size={25} />}
        titleTypographyProps={{
          color: 'textPrimary',
          variant: 'h5',
        }}
        title={getLocalizedString('meta_data', null, 'Meta data')}
        subheader={getLocalizedString('meta_data_description', null, 'Administrative data')}
      />
      <Divider />
      <CardContent>
        <Box height={415}>
          <List dense disablePadding>
            <ListItem>
              <ListItemText
                primary={getLocalizedString('ga_cid', null, 'Google Analytics ID')}
                secondary={user.ga_cid}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={getLocalizedString('exclude_reports', null, 'Excluded from reports')}
                secondary={<CheckIcon condition={user.exclude_reports} />}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={getLocalizedString('appsflyer_cuid', null, 'Appsflyer ID')}
                secondary={user.appsflyer_cuid}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={getLocalizedString('unclaimed', null, 'Managed account')}
                secondary={<CheckIcon condition={user.unclaimed} />}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={getLocalizedString('registration_origin', null, 'Registration origin')}
                secondary={getLocalizedString(
                  getTranslatableRegistrationOriginName(user.registration_origin),
                )}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={getLocalizedString('created_at', null, 'Created at')}
                secondary={<LuxonDate date={user.created_at} />}
              />
            </ListItem>
          </List>
        </Box>
      </CardContent>
    </Card>
  );
};

export default CardUserMetaData;
