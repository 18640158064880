import React from 'react';

import { DataGrid as MuiDataGrid, DataGridProps, GridColDef } from '@material-ui/data-grid';

import { useLocalization } from 'services/localization/localization';

import { ContainsOperator } from './filterOperators/ContainsOperator';

export const defaultDataGridProps = {
  rowsPerPageOptions: [10, 20, 25, 50, 100],

  /**
   * The default page size must be an option in 'rowsPerPageOptions' to be
   * able to be shown in the footer.
   */
  pageSize: 20,

  rowHeight: 44,
};

interface IProps {
  /**
   * Adds a 'search' column at the start of the columns prop. This is done to
   * solve a bug in Material-UI which causes the internal DataGrid API to send
   * empty filter events to the 'onFilterModelChange' handler of the first
   * column available.
   *
   * @todo - Make an issue on Material-UI's GitHub
   */
  searchable?: boolean;
}

/**
 * The identifier to use for the general search column.
 */
const defaultSearchColumnId = 'search';

export type Props = IProps & DataGridProps;

const DataGrid: React.FC<Props> = ({ searchable = false, ...props }) => {
  const { getLocalizedString } = useLocalization();

  let columns = props.columns;

  if (searchable) {
    const exists = columns.find((column) => column.field === defaultSearchColumnId);

    // It's possible for this component to re-render with the previous
    // props. This ensures that the search column is only added once.
    if (!exists) {
      columns.unshift({
        field: defaultSearchColumnId,
        headerName: 'Search',
        hide: true,
      });
    }
  }

  /**
   * By default the MUI DataGrid component contains a few filters like ‘ends with’, starts with, etc…
   * which are used to filter data on the client-side. We are using de server-side filtering
   * option of the DataGrid, which means we don’t use these default client-side filters.
   *
   * However these filters were still being displayed in the front end, but weren't implemented in the backend.
   * Becuase of this we created our own filter operators which are also implemented in the backend.
   */
  const customFilterOperators: GridColDef['filterOperators'] = [ContainsOperator];

  // Localize the labels of the custom filter operators
  customFilterOperators.map((operator) => {
    if (operator.label !== undefined) {
      operator.label = getLocalizedString(operator.label);
    }
    return operator;
  });

  // Localize all column headers and descriptions
  columns = columns.map((column) => {
    return {
      ...column,
      headerName: getLocalizedString(column.field),
      description: column.description
        ? getLocalizedString(`${column.field}_description`)
        : undefined,
      filterOperators: customFilterOperators,
    } as GridColDef;
  });

  return <MuiDataGrid {...defaultDataGridProps} {...props} columns={columns} />;
};

export default DataGrid;
