import { DateTime, Duration } from 'luxon';

import { ProfessionalUserRoleType } from 'core/professional';
import { IUser } from 'core/user';
import { Role } from 'services/roles';
import { Mutator } from 'utils/redux';

import * as sessionActions from './sessionActions';
import { ISessionState } from './sessionReducer';

type SessionMutator<P = void> = Mutator<ISessionState, P>;

export const mutateLogin: SessionMutator<ReturnType<typeof sessionActions.doLogin>['payload']> = (
  state,
  payload,
) => ({
  ...state,
  token: payload.token,
  refresh: payload.refresh_token,
  expires: DateTime.local()
    .plus(Duration.fromMillis(payload.expires_in * 1000))
    .toISO(),
  loginError: null,
});

export const mutateUser: SessionMutator<IUser> = (state, user) => ({
  ...state,
  user,
});

export const mutateProfessionals: SessionMutator<
  ReturnType<typeof sessionActions.setProfessionals>['payload']
> = (state, professionals) => {
  let scope: number | null = null;

  if (professionals.length === 1) {
    scope = professionals[0].id;
  } else if (professionals.length > 1) {
    const match = professionals.filter((p) =>
      [ProfessionalUserRoleType.Owner, ProfessionalUserRoleType.Therapist].includes(p.role),
    );

    if (match.length === 1) {
      scope = match[0].id;
    } else if (match.length > 1) {
      console.error('User is therapist/owner at multiple practices. Choosing first');
      scope = match[0].id;
    }
  }

  return {
    ...state,
    professionals,
    professionalScope: scope,
  };
};

export const mutateRoles: SessionMutator<ReturnType<typeof sessionActions.setRoles>['payload']> = (
  state,
  roles,
) => ({
  ...state,
  roles,
});

export const mutateLoginError: SessionMutator<
  ReturnType<typeof sessionActions.setLoginError>['payload']
> = (state, error) => ({
  ...state,
  loginError: error,
});
