import React from 'react';

import { Plus as PlusIcon } from 'react-feather';
import { RouteComponentProps } from 'react-router-dom';

import DataGridOverviewPage from 'components/DataGridOverviewPage';
import LinkButton from 'components/LinkButton';
import PaginatedServerDataGrid from 'containers/PaginatedServerDataGrid';
import { IPaginated } from 'core/backend';
import { IUser } from 'core/user';
import useFetch from 'hooks/useFetch';
import { useLocalization } from 'services/localization/localization';
import { defaultPaginationOptions } from 'services/pagination';
import { routes } from 'services/routing';
import { userTableColumns } from 'services/tables/usersTable';

type Props = RouteComponentProps;

const UsersOverview: React.FC<Props> = (_) => {
  const request = useFetch<IPaginated<IUser>>('/users', {
    pagination: defaultPaginationOptions,
    includes: ['professionals'],
  });

  const { getLocalizedString } = useLocalization();

  const actions = (
    <>
      <LinkButton
        translationKey='add'
        to={routes.CreateUser.path}
        color='primary'
        variant='contained'
        startIcon={<PlusIcon size={20} />}
      >
        Add user
      </LinkButton>
    </>
  );

  return (
    <DataGridOverviewPage
      title={getLocalizedString('users')}
      subtitle={`${request.data?.meta.total || 0}`}
      actions={actions}
    >
      <PaginatedServerDataGrid
        request={request}
        searchable
        columns={userTableColumns}
        componentsProps={{
          toolbar: {
            searchPlaceholder: getLocalizedString('search-model', {
              model: getLocalizedString('users').toLowerCase(),
            }),
          },
        }}
      />
    </DataGridOverviewPage>
  );
};

export default UsersOverview;
