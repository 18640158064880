import React from 'react';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogProps,
} from '@material-ui/core';
import { SubmitHandler } from 'react-hook-form';

import AdministratorForm, {
  IFormValues,
} from 'components/forms/administratorOverview/AdministratorForm';
import { EditRoleResponse, IEditRolePayload, adminRoleId } from 'core/role';
import useRequest from 'hooks/useRequest';
import { useLocalization } from 'services/localization/localization';

export interface IAdminFormDialogProps {
  detachMode?: boolean;
  onSubmitSuccess: (response: EditRoleResponse) => void;
}

type Props = IAdminFormDialogProps & Pick<DialogProps, 'open' | 'onClose'>;

const AdminFormDialog: React.FC<Props> = ({ detachMode, ...props }) => {
  const { getLocalizedString } = useLocalization();

  const assignRequest = useRequest<EditRoleResponse, unknown, IEditRolePayload>({
    method: 'POST',
  });

  const handleAttachRole: SubmitHandler<IFormValues> = async ({ users }) => {
    const payload: IEditRolePayload = {
      user_ids: users.map((user) => user.id),
    };

    try {
      const response = await assignRequest.request({
        url: `/roles/${adminRoleId}/assign`,
        data: payload,
      });

      props.onSubmitSuccess(response.data);
    } catch (error) {
      /**
       * @todo - Add a pop-up to show the error to users.
       */
      console.error(getLocalizedString('error_message'), error);
    }
  };

  const handleDetachRole: SubmitHandler<IFormValues> = async ({ users }) => {
    const payload: IEditRolePayload = {
      user_ids: users.map((user) => user.id),
    };

    try {
      const response = await assignRequest.request({
        url: `/roles/${adminRoleId}/detach`,
        data: payload,
      });

      props.onSubmitSuccess(response.data);
    } catch (error) {
      /**
       * @todo - Add a pop-up to show the error to users.
       */
      console.error(getLocalizedString('error_message'), error);
    }
  };

  let inner: React.ReactNode = null;

  if (detachMode) {
    inner = (
      <>
        <DialogTitle id='form-dialog-title'>
          {getLocalizedString('delete-model', {
            model: getLocalizedString('administrator').toLowerCase(),
          })}
        </DialogTitle>

        <DialogContent>
          <DialogContentText>{getLocalizedString('delete-admin-help-text')}</DialogContentText>
          <AdministratorForm
            userApiUrl={`roles/${adminRoleId}/users`}
            onSubmit={handleDetachRole}
          />
        </DialogContent>
      </>
    );
  } else {
    inner = (
      <>
        <DialogTitle id='form-dialog-title'>
          {getLocalizedString('add-model', {
            model: getLocalizedString('administrator').toLowerCase(),
          })}
        </DialogTitle>

        <DialogContent>
          <DialogContentText>{getLocalizedString('add-admin-help-text')}</DialogContentText>
          <AdministratorForm onSubmit={handleAttachRole} />
        </DialogContent>
      </>
    );
  }

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby='form-dialog-title'
      fullWidth
      maxWidth='sm'
    >
      {inner}
    </Dialog>
  );
};

export default AdminFormDialog;
