import React from 'react';

import { Box, Card, CardContent, CardHeader, Divider } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { Clipboard as ClipboardIcon } from 'react-feather';
import { Link } from 'react-router-dom';

import LuxonDate from 'components/LuxonDate';
import ListItemText from 'components/mui/ListItemText';
import { hasUnlimitedLicenses } from 'core/professional';
import { useLocalization } from 'services/localization/localization';
import { routeCreator } from 'services/routing';
import { ProfessionalTableRowData } from 'services/tables/professionalsTable';

interface IProps {
  professional: ProfessionalTableRowData;
}

const CardProfessionalDetails: React.FC<IProps> = ({ professional }) => {
  const unlimitedLicenses = hasUnlimitedLicenses(professional);
  const { getLocalizedString } = useLocalization();

  return (
    <Card>
      <CardHeader
        avatar={<ClipboardIcon size={25} />}
        titleTypographyProps={{
          color: 'textPrimary',
          variant: 'h5',
        }}
        title={getLocalizedString('information')}
        subheader={getLocalizedString('general-data', {
          model: getLocalizedString('practice').toLowerCase(),
        })}
      />
      <Divider />
      <CardContent>
        <Box height={585} position='relative'>
          <List dense disablePadding>
            <ListItem>
              <ListItemText primary={getLocalizedString('name')} secondary={professional.name} />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={getLocalizedString('partner')}
                secondary={
                  professional.organization !== null && professional.organization !== undefined ? (
                    <Link
                      to={{
                        pathname: routeCreator.OrganizationDetail(professional.organization.id),
                      }}
                    >
                      {professional.organization.name}
                    </Link>
                  ) : (
                    '-'
                  )
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={getLocalizedString('active_subscription')}
                secondary={
                  professional.active_subscription_id !== null ? (
                    <Link
                      to={{
                        pathname: routeCreator.SubscriptionDetail(
                          professional.active_subscription_id,
                        ),
                      }}
                    >
                      {getLocalizedString('subscription')} #{professional.active_subscription_id}
                    </Link>
                  ) : (
                    ''
                  )
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={getLocalizedString('licenses_per_period')}
                secondary={
                  unlimitedLicenses
                    ? getLocalizedString('unlimited')
                    : `${professional.licenses_per_period}`
                }
              />
            </ListItem>
            {!unlimitedLicenses && (
              <>
                <ListItem>
                  <ListItemText
                    primary={getLocalizedString('licenses_redeemed_in_current_period')}
                    secondary={`${professional.licenses_redeemed_in_current_period ?? '-'}`}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={getLocalizedString('licenses_available_in_current_period')}
                    secondary={`${professional.licenses_available_in_current_period ?? '-'}`}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={getLocalizedString('current_licensing_period')}
                    secondary={
                      professional.current_licensing_period === null ? (
                        '-'
                      ) : (
                        <>
                          {getLocalizedString('start_date')}:&nbsp;
                          <LuxonDate date={professional.current_licensing_period.period_start} />
                          <br />
                          {getLocalizedString('end_date')}:&nbsp;
                          <LuxonDate date={professional.current_licensing_period.period_end} />
                        </>
                      )
                    }
                  />
                </ListItem>
              </>
            )}
            <ListItem>
              <ListItemText
                primary={getLocalizedString('created_at')}
                secondary={<LuxonDate date={professional.created_at} fallback='-' />}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={getLocalizedString('updated_at')}
                secondary={<LuxonDate date={professional.updated_at} fallback='-' />}
              />
            </ListItem>
          </List>
        </Box>
      </CardContent>
    </Card>
  );
};

export default CardProfessionalDetails;
