import { ListItem } from '@material-ui/core';
import ExternalLink from 'components/ExternalLink';

import LuxonDate from 'components/LuxonDate';
import ListItemText from 'components/mui/ListItemText';
import { ITeamleaderSubscription } from 'core/teamleader';
import { useLocalization } from 'services/localization/localization';

import { SubscriptionProviderComponent } from '../CardSubscriptionProvider';

const SubscriptionProviderTeamleader: SubscriptionProviderComponent<ITeamleaderSubscription> = ({
  data,
}) => {
  const { getLocalizedString } = useLocalization();

  return (
    <>
      <ListItem>
        <ListItemText
          primary={getLocalizedString('teamleader_id')}
          secondary={
            <div>
              <ExternalLink href={data.website_url}>{data.teamleader_id}</ExternalLink>
            </div>
          }
        />
      </ListItem>

      <ListItem>
        <ListItemText
          primary={getLocalizedString('teamleader_id')}
          secondary={data.teamleader_id}
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary={getLocalizedString('teamleader_company_id')}
          secondary={data.teamleader_company_id}
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary={getLocalizedString('status')}
          secondary={getLocalizedString(data.status)}
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary={getLocalizedString('start_date')}
          secondary={<LuxonDate date={data.starts_on} fallback='-' />}
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary={getLocalizedString('end_date')}
          secondary={<LuxonDate date={data.ends_on} fallback='-' />}
        />
      </ListItem>
    </>
  );
};

export default SubscriptionProviderTeamleader;
