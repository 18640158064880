import React from 'react';

import { FormControl, FormHelperText } from '@material-ui/core';

import { FormControllerProps } from '.';

export type BaseFormControllerProps = Omit<FormControllerProps, 'controllerProps' | 'label'>;
/**
 * Default component for a field that has a FormControl and a translated Helper text.
 */
const BaseFormController: React.FC<BaseFormControllerProps> = ({
  children,
  helperText,
  ...formControlProps
}) => {
  return (
    <FormControl {...formControlProps}>
      {children}
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};

export default BaseFormController;
