import { IPaginated } from './backend';
import { IEntityTimestamps } from './core';
import { IUser } from './user';

export interface IUserRole {
  role_id: number;
  user_id: number;
  role_added_at: string;
}

/**
 * U implies that a user role is loaded.
 */
export interface IRole extends IEntityTimestamps {
  id: number;
  title: 'admin';
}

export interface IEditRolePayload {
  user_ids: number[];
}

export type EditRoleResponse = IPaginated<IUser>;

/**
 * Created the get role ID of administrators.
 */
export const adminRoleId = 1;
