import React from 'react';

import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  List,
  ListItem,
  ListItemText,
  makeStyles,
} from '@material-ui/core';
import { CreditCard as CreditCardIcon } from 'react-feather';

import LuxonDate from 'components/LuxonDate';
import RequestBody from 'components/mui/RequestBody';
import { IStripeCustomer } from 'core/stripe';
import useFetch from 'hooks/useFetch';
import { useLocalization } from 'services/localization/localization';

interface IProps {
  userId: number | string;
}

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
  },
}));

const CardUserStripeCustomer: React.FC<IProps> = (props) => {
  const request = useFetch<IStripeCustomer | null>(
    `/users/${props.userId}/stripe-customer`,
    undefined,
    {
      defaultValue: null,
    },
  );

  const { getLocalizedString } = useLocalization();

  const classes = useStyles();

  const stripeCustomer = request.data as IStripeCustomer;

  return (
    <Card className={classes.root}>
      <CardHeader
        avatar={<CreditCardIcon size={25} />}
        titleTypographyProps={{
          color: 'textPrimary',
          variant: 'h5',
        }}
        title={getLocalizedString('stripe_account')}
        subheader={getLocalizedString('stripe_account_description')}
      />
      <Divider />
      <CardContent>
        <Box height={455} position='relative'>
          <RequestBody
            request={request}
            message={getLocalizedString('not-found', {
              model: getLocalizedString('stripe_account'),
            })}
          >
            <List dense disablePadding>
              <ListItem>
                <ListItemText
                  primary={getLocalizedString('stripe_id')}
                  secondary={stripeCustomer?.stripe_id}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={getLocalizedString('created_at')}
                  secondary={<LuxonDate date={stripeCustomer?.created} fallback='-' />}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={getLocalizedString('synced_at')}
                  secondary={<LuxonDate date={stripeCustomer?.synced_at} fallback='-' />}
                />
              </ListItem>
            </List>
          </RequestBody>
        </Box>
      </CardContent>
    </Card>
  );
};

export default CardUserStripeCustomer;
