import { IEntityTimestamps } from './core';
import { IProfessionalGroup } from './professionalGroup';
import { IUser } from './user';

export enum ProfessionalUserRoleType {
  Patient = 'patient',
  Therapist = 'therapist',
  Owner = 'owner',
}

export interface IProfessionalUser extends IEntityTimestamps {
  id: number;
  user_id: number;
  professional_id: number;
  role: ProfessionalUserRoleType;
  has_access: boolean;
  home_usage: boolean;
  contact: boolean;
  demo_account: boolean;
}

export interface IProfessionalUserFull extends IProfessionalUser {
  user: IUser;
  professional: IProfessionalFull;
  groups: IProfessionalGroup[];
}

/**
 * Basic data object of a professional. This is what users without
 * administrative permissions can retrieve from the API.
 */
export interface IProfessional extends IEntityTimestamps {
  id: number;
  name: string;
}

export interface IProfessionalStatistics {
  /**
   * Amount of licenses for each period. -1 if they have unlimited licenses.
   */
  licenses_per_period: number;

  /**
   * The amount of licenses that were redeemed in the current period as
   * integer. Can be null if there is no active subscription, or if there
   * is an active subscription with an unlimited amount of licenses.
   */
  licenses_redeemed_in_current_period: number | null;

  /**
   * The amount of available licenses to redeem. Can be '-1' if the
   * subscription has an unlimited amount of licenses. Can be null if
   * the licenses redeemed is 'null' too.
   */
  licenses_available_in_current_period: number | null;

  /**
   * The current licensing period information. Can be null if there is no
   * current licensing period.
   */
  current_licensing_period: {
    period_start: string;
    period_end: string;
  } | null;
}

/**
 * Full data object of a professional. This is obtainable by users with more
 * permissions such as administrators or therapists.
 */
export type IProfessionalFull = IProfessional &
  IProfessionalStatistics & {
    organization_id: number | null;

    /**
     * The subscription id of the current active subscription.
     */
    active_subscription_id: number | null;
  };

export type Professional = IProfessional | IProfessionalFull;

export interface IProfessionalInvite extends IEntityTimestamps {
  id: number;
  professional_id: number;
  user_id: number | null;
  role: ProfessionalUserRoleType;
  token: string | null;
  email: string;
  responded_at: string | null;
  last_sent_at: string | null;
  created_by_user_id: number | null;
}

export type SimpleProfessionalInvite = Pick<IProfessionalInvite, 'id' | 'token' | 'role'> & {
  professional_name: string;
};

/**
 * Indicates that a professional has an unlimited amount of licenses available.
 */
export const hasUnlimitedLicenses = (professional: IProfessionalFull | null) => {
  if (professional === null) {
    return false;
  }

  return professional.licenses_per_period === -1;
};
