import React from 'react';

import { GridColDef } from '@material-ui/data-grid';
import { Link } from 'react-router-dom';

import { LoadedProfessional, LoadedProfessionalGroupUserCount } from 'core/backend';
import { IProfessionalGroup } from 'core/professionalGroup';
import { routeCreator } from 'services/routing';
import { GridColumn, renderDateCell } from 'utils/mui';

/**
 * The type of data in each table row.
 */
export type ProfessionalGroupTableRowData = IProfessionalGroup &
  LoadedProfessional &
  LoadedProfessionalGroupUserCount;

/**
 * Cell renderer for the 'name' column. This adds a link to the professionalGroup's detail page.
 *
 * @param {GridCellParams} params
 *   Grid cell parameters.
 */
const renderNameCell: GridColDef['renderCell'] = (params) => {
  const row = params.row as ProfessionalGroupTableRowData;

  return (
    <Link
      to={{
        pathname: routeCreator.ProfessionalGroupDetail(row.professional_id, row.id),
        state: row,
      }}
    >
      {params.value}
    </Link>
  );
};

export const professionalGroupTableColumns: GridColumn<ProfessionalGroupTableRowData>[] = [
  {
    field: 'name',
    headerName: 'Name',
    flex: 1,
    renderCell: renderNameCell,
  },
  {
    field: 'professional_group_users_count',
    headerName: 'Users count',
    flex: 0.25,
    sortable: true,
    filterable: false,
  },
  {
    field: 'created_at',
    headerName: 'Creation',
    flex: 0.25,
    renderCell: renderDateCell(),
  },
  {
    field: 'updated_at',
    headerName: 'Updated',
    flex: 0.25,
    renderCell: renderDateCell(),
  },
];
