import React from 'react';

import { Grid } from '@material-ui/core';
import { Edit2 as EditIcon } from 'react-feather';
import { RouteComponentProps } from 'react-router-dom';

import CommentsCard from 'components/comments/CommentsCard';
import DetailPage from 'components/DetailPage';
import LinkButton from 'components/LinkButton';
import RoleBoundary from 'components/RoleBoundary';
import useStateOrFetch from 'hooks/useStateOrFetch';
import { useLocalization } from 'services/localization/localization';
import { Role } from 'services/roles';
import { routeCreator } from 'services/routing';
import { SubscriptionsOverviewRowData } from 'services/tables/subscriptionsTable';

import ErrorPage from 'pages/ErrorPage';
import LoadingPage from 'pages/LoadingPage';

import CardSubscriptionDetails from './components/CardSubscriptionDetails';
import CardSubscriptionLicenseInfo from './components/CardSubscriptionLicenseInfo';
import CardSubscriptionProvider from './components/CardSubscriptionProvider';

type Props = RouteComponentProps<
  { subscriptionId: string },
  any,
  SubscriptionsOverviewRowData | undefined
>;

const SubscriptionDetail: React.FC<Props> = (props) => {
  const request = useStateOrFetch<SubscriptionsOverviewRowData | null>(
    props.location.state,
    `/subscriptions/${props.match.params.subscriptionId}`,
    {
      includes: ['professional', 'user'],
    },
  );

  const { getLocalizedString } = useLocalization();

  if (request.loading && !request.data) {
    return <LoadingPage />;
  }

  if (!request.data) {
    return <ErrorPage />;
  }

  const subscription = request.data as SubscriptionsOverviewRowData;

  // Disable the edit button
  const editDisabled = subscription.provider_type !== 'manual';
  const translatedEdit = getLocalizedString('edit');

  const actions = (
    <>
      <LinkButton
        translationKey='Editing is only available for manual subscriptions'
        to={routeCreator.EditSubscription(subscription.id)}
        disabled={editDisabled}
        state={subscription}
        startIcon={<EditIcon size={18} />}
        color='primary'
        variant='contained'
      >
        {editDisabled
          ? ` ${translatedEdit} (${getLocalizedString('manual_only')})`
          : translatedEdit}
      </LinkButton>
    </>
  );

  const subscriptionHolder =
    subscription.professional !== null ? subscription.professional!.name : subscription.user!.name;
  const pageTitle = `${getLocalizedString('subscription_of_model', {
    model: subscriptionHolder ?? '?',
  })}`;

  return (
    <DetailPage title={pageTitle} actions={actions} maxWidth='xl'>
      <Grid container spacing={3}>
        <RoleBoundary allowed={[Role.Admin]}>
          <Grid item xs={12}>
            <CommentsCard
              endpointUrl={`/subscriptions/${subscription.id}/comments`}
              entity='subscription'
            />
          </Grid>
        </RoleBoundary>
        <Grid item xs={12} sm={6} lg={4} xl={4}>
          <CardSubscriptionDetails subscription={subscription} />
        </Grid>
        <Grid item xs={12} sm={6} lg={4} xl={4}>
          <CardSubscriptionProvider subscription={subscription} />
        </Grid>
        <Grid item xs={12} sm={6} lg={4} xl={4}>
          <CardSubscriptionLicenseInfo subscription={subscription} />
        </Grid>
      </Grid>
    </DetailPage>
  );
};

export default SubscriptionDetail;
