import React from 'react';

import { Box, Card, colors, Container, makeStyles, Typography } from '@material-ui/core';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Redirect } from 'react-router-dom';

import Button from 'components/Button';
import LinkButton from 'components/LinkButton';
import Localized from 'components/Localized';
import TextField from 'components/mui/TextField';
import Page from 'components/Page';
import { PasswordResetOrigin } from 'core/passwordReset';
import useRequest from 'hooks/useRequest';
import { useLocalization } from 'services/localization/localization';
import { routes } from 'services/routing';
import { loggedInSelector } from 'store/session/sessionSelectors';
import { useTypedSelector } from 'store/store';
import { darkBackground } from 'theme/theme';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: darkBackground,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  card: {
    padding: theme.spacing(3),
    borderWidth: 8,
    borderColor: colors.common.white,
    hoverBorderColor: colors.common.white,
  },
}));

interface IFormValues {
  email: string;
  origin: PasswordResetOrigin.ORIGIN_DASHBOARD;
}

const PasswordReset: React.FC = () => {
  const loggedIn = useTypedSelector(loggedInSelector);
  const classes = useStyles();
  const [emailSent, setEmailSent] = React.useState<boolean>(false);

  const { getLocalizedString } = useLocalization();

  const request = useRequest<unknown, never, IFormValues>({
    method: 'POST',
    url: '/user/reset-password',
  });

  const {
    register,
    handleSubmit,
    errors,
    formState: { touched, isSubmitting, isValid },
  } = useForm<Omit<IFormValues, 'origin'>>({ mode: 'onChange' });

  if (loggedIn) {
    return <Redirect to={routes.Dashboard} />;
  }

  const onSubmit: SubmitHandler<IFormValues> = async (values) => {
    try {
      await request.request({
        data: {
          email: values.email,
          origin: PasswordResetOrigin.ORIGIN_DASHBOARD,
        },
      });

      setEmailSent(true);
    } catch (error) {
      // This should never happen. The endpoint only returns status 204 - No Content
      alert(getLocalizedString('error_message'));
    }
  };

  if (emailSent) {
    return (
      <Page className={classes.root} title='Forgot password'>
        <Box display='flex' flexDirection='column' height='100%' justifyContent='center'>
          <Container maxWidth='sm'>
            <Card className={classes.card}>
              <Box p={4} display='flex' flexDirection='column' alignItems='center'>
                <Typography color='textPrimary' gutterBottom variant='h4' align='center'>
                  <Localized id='reset-password-sent-message'>
                    We've sent you an email with instructions on how to reset your password.
                  </Localized>
                </Typography>
              </Box>
              <Box my={2} display='flex' justifyContent='center'>
                <LinkButton
                  translationKey='back-to-sign-in'
                  to={routes.Login}
                  color='secondary'
                  disabled={isSubmitting}
                  size='small'
                  type='button'
                  variant='text'
                >
                  Back to sign in
                </LinkButton>
              </Box>
            </Card>
          </Container>
        </Box>
      </Page>
    );
  }

  return (
    <Page className={classes.root} title='Reset password'>
      <Box display='flex' flexDirection='column' height='100%' justifyContent='center'>
        <Container maxWidth='sm'>
          <Card className={classes.card}>
            <Typography color='textPrimary' variant='h2'>
              <Localized id='reset-password'>Reset password</Localized>
            </Typography>
            <Typography color='textSecondary' gutterBottom variant='body2'>
              <Localized id='reset-password-message'>
                Enter your email and we will send you a password reset link.
              </Localized>
            </Typography>

            <Box mt={2}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email?.message}
                  label='Email Address'
                  margin='normal'
                  translationKey='email'
                  inputRef={register({ required: getLocalizedString('required') })}
                  type='email'
                  variant='outlined'
                  disabled={isSubmitting}
                />

                <Box my={2}>
                  <Button
                    translationKey='send-reset-password-link'
                    color='primary'
                    loading={isSubmitting}
                    disabled={!isValid}
                    fullWidth
                    size='large'
                    type='submit'
                    variant='contained'
                  >
                    Send password reset link
                  </Button>
                </Box>
                <Box my={2} display='flex' justifyContent='center'>
                  <LinkButton
                    translationKey='back-to-sign-in'
                    to={routes.Login}
                    color='secondary'
                    disabled={isSubmitting}
                    size='small'
                    type='button'
                    variant='text'
                  >
                    Back to sign in
                  </LinkButton>
                </Box>
              </form>
            </Box>
          </Card>
        </Container>
      </Box>
    </Page>
  );
};

export default PasswordReset;
