import React from 'react';

import { Box, createStyles, makeStyles, CircularProgress } from '@material-ui/core';
import clsx from 'clsx';

import { UseFetchHook } from 'hooks/useFetch';
import { UseRequestHook } from 'hooks/useRequest';

import NoRowsMessage from './NoRowsMessage';

const useStyles = makeStyles((_) =>
  createStyles({
    root: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      display: 'flex',
    },
    centerContent: {
      alignItems: 'center',
      justifyContent: 'center',
    },
  }),
);

interface IProps {
  request: UseFetchHook<any, any> | UseRequestHook<any, any, any>;
  message: string;
}

const RequestBody: React.FC<IProps> = ({ request, children, message }) => {
  const classes = useStyles();
  let inner = children;
  let loadingOrNoData = false;

  if (request.loading && !request.data) {
    inner = <CircularProgress />;
    loadingOrNoData = true;
  }

  if (request.success) {
    if (request.data === null) {
      inner = <NoRowsMessage message={message} />;
      loadingOrNoData = true;
    }

    if (request.data !== null && 'data' in request.data!) {
      if (Array.isArray(request.data!.data) && request.data!.data.length === 0) {
        inner = <NoRowsMessage message={message} />;
        loadingOrNoData = true;
      }
    }
  }

  return (
    <Box className={clsx(classes.root, loadingOrNoData ? classes.centerContent : '')}>{inner}</Box>
  );
};

export default RequestBody;
