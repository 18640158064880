import { Link } from 'react-router-dom';

import LinkButton from 'components/LinkButton';
import { attachable, ITeamleaderCompany } from 'core/teamleader';
import { routeCreator } from 'services/routing';
import { cellRenderer, GridColumn, renderDateCell } from 'utils/mui';

export const teamleaderCompaniesTable: GridColumn<ITeamleaderCompany>[] = [
  {
    field: 'name',
    flex: 1,
    renderCell: cellRenderer<ITeamleaderCompany>((row) => (
      <a target='_blank' href={row.website_url} rel='noreferrer'>
        {row.name}
      </a>
    )),
  },
  {
    field: 'professional_id',
    headerName: 'practice',
    flex: 0.75,
    renderCell: cellRenderer<ITeamleaderCompany>((company) => {
      const { professional, id } = company;

      if (attachable(company)) {
        return (
          <LinkButton
            to={routeCreator.TeamleaderCompanyAttachProfessional(id)}
            color='primary'
            size='small'
            variant='contained'
          >
            Attach practice
          </LinkButton>
        );
      }

      if (professional === null) {
        return <>-</>;
      }

      return <Link to={routeCreator.ProfessionalDetail(professional.id)}>{professional.name}</Link>;
    }),
  },
  {
    field: 'organization_id',
    headerName: 'partner',
    flex: 0.75,
    renderCell: cellRenderer<ITeamleaderCompany>((company) => {
      const { id, organization } = company;

      if (attachable(company)) {
        return (
          <LinkButton
            to={routeCreator.TeamleaderCompanyAttachOrganization(id)}
            color='primary'
            size='small'
            variant='contained'
          >
            Attach partner
          </LinkButton>
        );
      }

      if (organization === null) {
        return <>-</>;
      }

      return <Link to={routeCreator.OrganizationDetail(organization.id)}>{organization.name}</Link>;
    }),
  },
  {
    field: 'synced_at',
    headerName: 'Synced at',
    flex: 0.5,
    renderCell: renderDateCell(),
  },
];
