import React from 'react';

import { SubmitHandler } from 'react-hook-form';
import { RouteComponentProps } from 'react-router-dom';

import DetailPage from 'components/DetailPage';
import StaffForm, { StaffFormValues } from 'components/forms/staff/StaffForm';
import { LoadedProfessionalUser } from 'core/backend';
import { IUser } from 'core/user';
import useRequest from 'hooks/useRequest';
import useStateOrFetch from 'hooks/useStateOrFetch';
import { useLocalization } from 'services/localization/localization';
import { ProfessionalRouteParams, routeCreator, UserRouteParams } from 'services/routing';

import { StaffRequestValues } from '../CreateStaff/CreateStaff';

type RouteParams = ProfessionalRouteParams & UserRouteParams;
type FetchResponse = IUser & LoadedProfessionalUser;
type Props = RouteComponentProps<RouteParams, any, FetchResponse>;

const EditStaff: React.FC<Props> = (props) => {
  const { professionalId, userId } = props.match.params;

  // API-url for reaching this professional user.
  const url = `/professionals/${professionalId}/staff/${userId}`;

  const request = useStateOrFetch<FetchResponse | null>(props.location.state, url, {
    includes: ['user'],
  });
  const putRequest = useRequest<FetchResponse, never, StaffRequestValues>({
    url,
    method: 'PUT',
  });

  const { getLocalizedString } = useLocalization();

  const user = request.data as FetchResponse;

  const handleSubmit: SubmitHandler<StaffFormValues> = async (values) => {
    await putRequest.request({
      data: {
        ...values,
      },
    });

    props.history.push(routeCreator.StaffDetail(professionalId, userId));
  };

  const defaultValues: Partial<StaffFormValues> = {
    ...user,
    role: user.professional_user.role,
  };

  const pageTitle = getLocalizedString('update-model', {
    model: getLocalizedString('staff').toLowerCase(),
  });

  return (
    <DetailPage title={pageTitle} subtitle={user.name} maxWidth='sm'>
      <StaffForm
        isEditForm
        onSubmit={handleSubmit}
        defaultValues={defaultValues}
        professionalId={professionalId}
      />
    </DetailPage>
  );
};

export default EditStaff;
