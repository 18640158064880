import { GridColDef } from '@material-ui/data-grid';

import Localized from 'components/Localized';
import { ILog, mapLogTypeToTranslatableTag } from 'core/log';
import { GridColumn, renderDateCell } from 'utils/mui';

/**
 * The type of data in each table row.
 */
export type LogTableRowData = ILog;

/**
 * @param {GridCellParams} params
 *   Grid cell parameters.
 */
const renderLogTypeCell: GridColDef['renderCell'] = (params) => {
  const row = params.row as LogTableRowData;

  return <Localized id={mapLogTypeToTranslatableTag(row.type)}>Type</Localized>;
};

/**
 * @param {GridCellParams} params
 *   Grid cell parameters.
 */
const renderContextCell: GridColDef['renderCell'] = (params) => {
  const row = params.row as LogTableRowData;

  return <span>{row.context !== null ? JSON.stringify(row.context) : ''}</span>;
};

export const logsTableColumns: GridColumn<LogTableRowData>[] = [
  {
    field: 'type',
    headerName: 'Type',
    flex: 1,
    renderCell: renderLogTypeCell,
  },
  {
    field: 'message',
    headerName: 'Message',
    flex: 1,
  },
  {
    field: 'context',
    headerName: 'Context',
    flex: 1,
    renderCell: renderContextCell,
    sortable: false,
    filterable: false,
  },
  {
    field: 'date',
    headerName: 'Date',
    flex: 1,
    renderCell: renderDateCell(),
  },
];
