import React from 'react';

import { Box, Card, CardHeader } from '@material-ui/core';
import { Users as UsersIcon } from 'react-feather';

import ProgressDivider from 'components/ProgressDivider';
import PaginatedServerDataGrid from 'containers/PaginatedServerDataGrid';
import { IPaginated, LoadedProfessional } from 'core/backend';
import { IPlayerAccount } from 'core/playerAccount';
import { IUser } from 'core/user';
import useProfessionalFetch from 'hooks/useProfessionalFetch';
import { useLocalization } from 'services/localization/localization';
import {
  userPlayerAccountsTable,
  userPlayerAccountsTableWithoutProfessional,
} from 'services/tables/userDetail/userPlayerAccountsTable';

interface IProps {
  user: IUser;
}

const CardUserPlayerAccounts: React.FC<IProps> = ({ user }) => {
  const request = useProfessionalFetch<IPaginated<IPlayerAccount & Partial<LoadedProfessional>>>({
    default: [
      `/users/${user.id}/player-accounts`,
      {
        includes: ['professional', 'user'],
      },
    ],
    professional: (professionalId) => [
      `/professionals/${professionalId}/users/${user.id}/player-accounts`,
      {
        includes: ['user'],
      },
    ],
  });

  const { getLocalizedString } = useLocalization();

  // An attempt was done to load the professional within the request.
  const includesProfessional = request.options.includes?.includes('professional');

  const columns = includesProfessional
    ? userPlayerAccountsTable
    : userPlayerAccountsTableWithoutProfessional;

  return (
    <Card>
      <CardHeader
        avatar={<UsersIcon size={25} />}
        titleTypographyProps={{
          color: 'textPrimary',
          variant: 'h5',
        }}
        title={getLocalizedString('player_accounts')}
        subheader={getLocalizedString(
          'player_accounts_description',
          null,
          'The player accounts of this user',
        )}
      />
      <ProgressDivider inProgress={request.loading} />

      <Box height={455}>
        <PaginatedServerDataGrid
          request={request}
          columns={columns}
          components={{ Toolbar: undefined }}
        />
      </Box>
    </Card>
  );
};

export default CardUserPlayerAccounts;
