import { defaultDataGridProps } from 'components/mui/DataGrid/DataGrid';

/**
 * The default options to use when fetching resources
 *
 * @var {UseFetchOptions['pagination']}
 */
export const defaultPaginationOptions = {
  number: 1,
  size: defaultDataGridProps.pageSize,
};
