import { GridColDef } from '@material-ui/data-grid';
import { Link } from 'react-router-dom';

import Localized from 'components/Localized';
import { renderDataGridCheckCell } from 'components/mui/DataGrid/DataGridCheckCell';
import { LoadedProfessional } from 'core/backend';
import { ISubscription } from 'core/subscription';
import { routeCreator } from 'services/routing';
import { GridColumn, renderDateCell } from 'utils/mui';

/**
 * The type of data in each table row.
 */
export type UserSubscriptionTableDataRow = ISubscription & LoadedProfessional;

/**
 * @param {GridCellParams} params
 *   Grid cell parameters.
 */
const renderTypeCell: GridColDef['renderCell'] = (params) => {
  const row = params.row as UserSubscriptionTableDataRow;

  const inner = (
    <Link to={routeCreator.SubscriptionDetail(row.id)}>
      <Localized id={row.provider_type}>{row.provider_type}</Localized>
    </Link>
  );

  return <span>{inner}</span>;
};

/**
 * @param {GridCellParams} params
 *   Grid cell parameters.
 */
const renderProfessionalCell: GridColDef['renderCell'] = (params) => {
  const row = params.row as UserSubscriptionTableDataRow;

  if (row.professional === null) {
    return <>-</>;
  }

  return (
    <Link
      to={{
        pathname: routeCreator.ProfessionalDetail(row.professional.id),
        state: row.professional,
      }}
    >
      {row.professional.name}
    </Link>
  );
};

export const userSubscriptionsTableColumns: GridColumn<UserSubscriptionTableDataRow>[] = [
  {
    field: 'type',
    headerName: 'Type',
    flex: 0.75,
    renderCell: renderTypeCell,
    sortable: false,
    filterable: false,
  },
  {
    field: 'active',
    headerName: 'Active',
    flex: 0.5,
    renderCell: renderDataGridCheckCell,
    sortable: false,
    filterable: false,
  },
  {
    field: 'start_date',
    headerName: 'Start date',
    flex: 0.75,
    renderCell: renderDateCell(),
    sortable: false,
    filterable: false,
  },
  {
    field: 'end_date',
    headerName: 'End date',
    flex: 0.75,
    renderCell: renderDateCell(),
    sortable: false,
    filterable: false,
  },
  {
    field: 'professional',
    headerName: 'Practice',
    flex: 1,
    renderCell: renderProfessionalCell,
    sortable: false,
    filterable: false,
  },
];
