import React from 'react';

import { Box, Card, CardHeader } from '@material-ui/core';
import { Clipboard as ClipboardIcon } from 'react-feather';

import PaginatedServerDataGrid from 'containers/PaginatedServerDataGrid';
import { IPaginated } from 'core/backend';
import useFetch from 'hooks/useFetch';
import { useLocalization } from 'services/localization/localization';
import { organizationProfessionalsTableColumns } from 'services/tables/organizationDetail/organizationProfessionals';
import { ProfessionalTableRowData } from 'services/tables/professionalsTable';

interface IProps {
  organizationId: number | string;
}

const CardOrganizationProfessionals: React.FC<IProps> = (props) => {
  const request = useFetch<IPaginated<ProfessionalTableRowData>>(
    `/organizations/${props.organizationId}/professionals`,
    { includes: ['organization'] },
  );
  const { getLocalizedString } = useLocalization();

  return (
    <Card>
      <CardHeader
        avatar={<ClipboardIcon size={25} />}
        titleTypographyProps={{
          color: 'textPrimary',
          variant: 'h5',
        }}
        title={getLocalizedString('practices')}
        subheader={getLocalizedString('associated_practices_description', {
          model: getLocalizedString('partner').toLowerCase(),
        })}
      />
      <Box height={455}>
        <PaginatedServerDataGrid
          request={request}
          columns={organizationProfessionalsTableColumns}
          searchable
          componentsProps={{
            toolbar: {
              searchPlaceholder: getLocalizedString('search-model', {
                model: getLocalizedString('professionals').toLowerCase(),
              }),
            },
          }}
        />
      </Box>
    </Card>
  );
};

export default CardOrganizationProfessionals;
