import React from 'react';

import { Box, Card, CardContent, CardHeader, Divider, List, ListItem } from '@material-ui/core';
import { User as UserIcon } from 'react-feather';
import { Link } from 'react-router-dom';

import ListItemText from 'components/mui/ListItemText';
import RoleBoundary from 'components/RoleBoundary';
import { useLocalization } from 'services/localization/localization';
import { Role } from 'services/roles';
import { routeCreator } from 'services/routing';
import { DeviceTableRowData } from 'services/tables/devicesTable';

interface IProps {
  device: DeviceTableRowData;
}

const CardDeviceLoginInformation: React.FC<IProps> = ({ device }) => {
  const { getLocalizedString } = useLocalization();

  return (
    <Card>
      <CardHeader
        avatar={<UserIcon size={25} />}
        titleTypographyProps={{
          color: 'textPrimary',
          variant: 'h5',
        }}
        title={getLocalizedString('login')}
        subheader={getLocalizedString('device_login_information')}
      />
      <Divider />
      <CardContent>
        <Box height={500} position='relative'>
          <List dense disablePadding>
            <ListItem>
              <ListItemText
                primary={getLocalizedString('logged_in_player')}
                secondary={
                  device.player_account === null ? (
                    '-'
                  ) : (
                    <>
                      <RoleBoundary allowed={[Role.Admin]}>
                        <Link
                          to={{
                            pathname: routeCreator.UserDetail(device.player_account.user_id),
                            state: device.player_account.user,
                          }}
                        >
                          {device.player_account.user?.name ?? '-'}
                        </Link>
                      </RoleBoundary>
                      <RoleBoundary allowed={[Role.Therapist, Role.Owner]}>
                        <Link
                          to={routeCreator.PatientDetail(
                            device.player_account.professional_id!,
                            device.player_account.user_id,
                          )}
                        >
                          {device.player_account.user?.name ?? '-'}
                        </Link>
                      </RoleBoundary>
                    </>
                  )
                }
              />
            </ListItem>
          </List>
        </Box>
      </CardContent>
      <Divider />
    </Card>
  );
};

export default CardDeviceLoginInformation;
