import { IProfessional, IProfessionalUser } from 'core/professional';
import { IRole } from 'core/role';
import { IUser } from 'core/user';
import { ActionType } from 'store/actions';
import { createReducer, IReducerHandlers } from 'utils/redux';

import * as sessionMutators from './sessionMutators';

export type SessionStateProfessionals = Pick<IProfessional, 'id' | 'name'> &
  Pick<IProfessionalUser, 'role'>;

type LoginErrorTranslationKeyType = 'incorrect_credentials' | 'login_incorrect_role_error';

export interface ISessionState {
  user: IUser | null;
  professionals: SessionStateProfessionals[] | null;

  /**
   * The professional scope of what is viewed. This is for professional users.
   */
  professionalScope: number | null;
  roles: IRole[] | null;
  token: string | null;
  refresh: string | null;
  expires: string | null;
  loginError: LoginErrorTranslationKeyType | null;
}

const initialState: ISessionState = {
  user: null,
  professionals: null,
  professionalScope: null,
  roles: null,
  token: null,
  refresh: null,
  expires: null,
  loginError: null,
};

const handlers: IReducerHandlers<ISessionState> = {
  [ActionType.LOGIN]: sessionMutators.mutateLogin,
  [ActionType.LOGOUT]: () => initialState,

  [ActionType.SET_USER]: sessionMutators.mutateUser,
  [ActionType.SET_PROFESSIONAL_USERS]: sessionMutators.mutateProfessionals,
  [ActionType.SET_ROLES]: sessionMutators.mutateRoles,
  [ActionType.SET_LOGIN_ERROR]: sessionMutators.mutateLoginError,
};

export default createReducer<ISessionState>(initialState, handlers);
