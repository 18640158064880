import React from 'react';

import { TextField as MuiTextField, TextFieldProps } from '@material-ui/core';

import { ITranslatable } from 'core/core';
import { useLocalization } from 'services/localization/localization';

type Props = Omit<TextFieldProps, 'label'> &
  ITranslatable & {
    label?: string;
  };

const TextField: React.FC<Props> = ({ translationKey, label, ...props }) => {
  const { getLocalizedString } = useLocalization();
  const localizedLabel = getLocalizedString(translationKey);

  return (
    <MuiTextField label={label ? localizedLabel : undefined} name={translationKey} {...props} />
  );
};

export default TextField;
