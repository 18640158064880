import { Box, Card, colors, makeStyles, Typography } from '@material-ui/core';

import LinkButton from 'components/LinkButton';
import { useLocalization } from 'services/localization/localization';
import { routes } from 'services/routing';
import { darkBackground } from 'theme/theme';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: darkBackground,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  card: {
    padding: theme.spacing(3),
    borderWidth: 8,
    borderColor: colors.common.white,
    hoverBorderColor: colors.common.white,
  },
}));

const AccountConfirmed: React.FC = (_) => {
  const classes = useStyles();
  const { getLocalizedString } = useLocalization();

  return (
    <Card className={classes.card}>
      <Box p={4} display='flex' flexDirection='column' alignItems='center'>
        <Typography color='textPrimary' gutterBottom variant='h1' align='center'>
          {getLocalizedString('account_confirmed_part_1', null)}
        </Typography>
        <Typography color='textPrimary' gutterBottom variant='h3' align='center'>
          {getLocalizedString('account_confirmed_part_2', null)}
        </Typography>
        <Typography color='textPrimary' gutterBottom variant='body1' align='center'>
          {getLocalizedString('account_confirmed_part_3', null)}
        </Typography>
        <Typography color='textPrimary' gutterBottom variant='body1' align='center'>
          {getLocalizedString('account_confirmed_part_4', null)}
        </Typography>
        <Typography color='textPrimary' gutterBottom variant='body1' align='center'>
          {getLocalizedString('account_confirmed_part_5', null)}
        </Typography>
      </Box>
      <Box my={2} display='flex' justifyContent='center'>
        <LinkButton
          translationKey='back-to-sign-in'
          to={routes.Login}
          color='secondary'
          size='small'
          type='button'
          variant='text'
        >
          {getLocalizedString('account_confirmed_back_to_sign_in', null)}
        </LinkButton>
      </Box>
    </Card>
  );
};

export default AccountConfirmed;
