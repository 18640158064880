// The env injected by Webpack. The pre-defined strings here
// must be equal to the 'mode' set in the webpack config files.
enum EnvironmentMode {
  Development = 'development',
  Test = 'test',
  Production = 'production',
}

export const isDev = process.env.NODE_ENV === EnvironmentMode.Development;
export const isTest = process.env.NODE_ENV === EnvironmentMode.Test;
export const isProd = process.env.NODE_ENV === EnvironmentMode.Production;

export const backendURL = process.env.REACT_APP_BACKEND_URL as string;
export const sentryDsn = process.env.REACT_APP_SENTRY_DSN as string;
export const sentryEnvironment = process.env.REACT_APP_SENTRY_ENVIRONMENT as string;

console.log('backend url:', backendURL);
console.log('sentry url:', sentryDsn);
