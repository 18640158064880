import React from 'react';

import DataGridOverviewPage from 'components/DataGridOverviewPage';
import PaginatedServerDataGrid from 'containers/PaginatedServerDataGrid';
import { IPaginated } from 'core/backend';
import { ITeamleaderProduct } from 'core/teamleader';
import useFetch from 'hooks/useFetch';
import { defaultPaginationOptions } from 'services/pagination';
import { teamleaderProductsTable } from 'services/tables/teamleaderProductsTable';

export type TeamleaderProductOverviewData = ITeamleaderProduct & {
  teamleader_subscriptions_count: number;
};

const TeamleaderProductsOverview: React.FC = () => {
  const request = useFetch<IPaginated<TeamleaderProductOverviewData>>('/teamleader/products', {
    pagination: defaultPaginationOptions,
    includes: ['teamleaderSubscriptionsCount'],
    filtering: [
      {
        column: 'ignore',
        value: '0',
      },
    ],
  });

  return (
    <DataGridOverviewPage title='Teamleader products' subtitle={`${request.data?.meta.total || 0}`}>
      <PaginatedServerDataGrid
        request={request}
        searchable
        columns={teamleaderProductsTable(request.request())}
      />
    </DataGridOverviewPage>
  );
};

export default TeamleaderProductsOverview;
