import React from 'react';

import { Box, Hidden, IconButton, Menu, MenuItem } from '@material-ui/core';
import { Apps as AppsIcon } from '@material-ui/icons';

type Props = {
  actions?: JSX.Element[];
};

const ButtonBar: React.FC<Props> = ({ actions, children }) => {
  const [open, setOpen] = React.useState(false);
  const menuItemRef = React.useRef<HTMLButtonElement>(null);

  const handleMenuClicked: React.MouseEventHandler<HTMLButtonElement> = () => {
    setOpen((prevValue) => !prevValue);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (menuItemRef.current && menuItemRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <Hidden lgUp>
        {children}

        {actions && actions.length !== 0 && (
          <>
            <IconButton
              ref={menuItemRef}
              onClick={handleMenuClicked}
              aria-controls='simple-menu'
              aria-haspopup='true'
              color='inherit'
            >
              <AppsIcon fontSize={'large'} />
            </IconButton>
            <Menu id='button-menu' anchorEl={menuItemRef.current} open={open} onClose={handleClose}>
              {actions.map(({ key, ...item }: JSX.Element) => {
                return (
                  <MenuItem key={key} style={{ padding: 0 }}>
                    <item.type {...item.props} color='transparent' variant='contain' fullWidth />
                  </MenuItem>
                );
              })}
            </Menu>
          </>
        )}
      </Hidden>

      <Hidden mdDown>
        <Box marginRight={4}>{children}</Box>
        {actions}
      </Hidden>
    </>
  );
};

export default ButtonBar;
