import React from 'react';

import { Grid } from '@material-ui/core';
import { RouteComponentProps } from 'react-router-dom';

import DetailPage from 'components/DetailPage';
import useStateOrFetch from 'hooks/useStateOrFetch';
import { OrganizationTableRowData } from 'services/tables/organizationsTable';

import ErrorPage from 'pages/ErrorPage';
import LoadingPage from 'pages/LoadingPage';

import CardOrganizationDetails from './components/CardOrganizationDetails';
import CardOrganizationProfessionals from './components/CardOrganizationProfessionals';

type Props = RouteComponentProps<
  { organizationId: string },
  any,
  OrganizationTableRowData | undefined
>;

const OrganizationDetail: React.FC<Props> = (props) => {
  const { organizationId } = props.match.params;
  const request = useStateOrFetch<OrganizationTableRowData | null>(
    props.location.state,
    `/organizations/${organizationId}`,
  );

  if (request.loading && !request.data) {
    return <LoadingPage />;
  }

  if (!request.data) {
    return <ErrorPage />;
  }

  const organization = request.data as OrganizationTableRowData;

  return (
    <DetailPage title={organization.name}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={3} lg={3} xl={3}>
          <CardOrganizationDetails organization={organization} />
        </Grid>
        <Grid item xs={12} md={9} lg={9} xl={9}>
          <CardOrganizationProfessionals organizationId={organizationId} />
        </Grid>
      </Grid>
    </DetailPage>
  );
};

export default OrganizationDetail;
