import React from 'react';

import { SubmitHandler } from 'react-hook-form';
import { RouteComponentProps } from 'react-router-dom';

import DetailPage from 'components/DetailPage';
import StaffForm, { StaffFormValues } from 'components/forms/staff/StaffForm';
import { IUser } from 'core/user';
import useRequest from 'hooks/useRequest';
import { useLocalization } from 'services/localization/localization';
import { ProfessionalRouteParams, routeCreator } from 'services/routing';

export type StaffRequestValues = Pick<
  IUser,
  'first_name' | 'middle_name' | 'last_name' | 'email' | 'language'
> &
  Pick<StaffFormValues, 'role'>;

type Props = RouteComponentProps<ProfessionalRouteParams>;

const CreateStaff: React.FC<Props> = (props) => {
  const { professionalId } = props.match.params;
  const postRequest = useRequest<IUser, never, StaffRequestValues>({
    method: 'POST',
    url: `/professionals/${professionalId}/staff`,
  });

  const { getLocalizedString } = useLocalization();

  const handleSubmit: SubmitHandler<StaffFormValues> = async (values) => {
    const response = await postRequest.request({
      data: {
        ...values,
      },
    });

    props.history.push(routeCreator.StaffDetail(professionalId, response.data.id), response.data);
  };

  const pageTitle = getLocalizedString('add-model', {
    model: getLocalizedString('staff').toLowerCase(),
  });

  return (
    <DetailPage title={pageTitle} maxWidth='sm'>
      <StaffForm onSubmit={handleSubmit} professionalId={professionalId} />
    </DetailPage>
  );
};

export default CreateStaff;
