import { Select as MuiSelect, InputLabel, SelectProps } from '@material-ui/core';
import { Controller } from 'react-hook-form';

import { useLocalization } from 'services/localization/localization';

import { FormControllerProps } from '.';
import BaseFormController from './BaseFormController';

type Props = FormControllerProps & Pick<SelectProps, 'readOnly'>;

const Select: React.FC<Props> = ({
  label,
  controllerProps,
  children,
  helperText,
  readOnly,
  ...props
}) => {
  const { getLocalizedString } = useLocalization();
  const translatedLabel = getLocalizedString(controllerProps.name);

  return (
    <BaseFormController helperText={helperText} {...props}>
      <InputLabel id={label}>{translatedLabel}</InputLabel>
      <Controller
        {...controllerProps}
        as={
          <MuiSelect labelId={label} label={translatedLabel} readOnly={readOnly}>
            {children}
          </MuiSelect>
        }
      />
    </BaseFormController>
  );
};

export default Select;
