import React from 'react';

import { Box, Card, CardContent, CardHeader, Divider } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { User as UserIcon } from 'react-feather';

import CheckIcon from 'components/CheckIcon';
import LuxonDate from 'components/LuxonDate';
import ListItemText from 'components/mui/ListItemText';
import { IUser } from 'core/user';
import { useLocalization } from 'services/localization/localization';

interface IProps {
  user: IUser;
}

const CardUserDetails: React.FC<IProps> = ({ user }) => {
  const { getLocalizedString } = useLocalization();

  return (
    <Card>
      <CardHeader
        avatar={<UserIcon size={25} />}
        titleTypographyProps={{
          color: 'textPrimary',
          variant: 'h5',
        }}
        title={getLocalizedString('information')}
        subheader={getLocalizedString(
          'general-data',
          { model: getLocalizedString('user').toLowerCase() },
          'General user data',
        )}
      />
      <Divider />
      <CardContent>
        <Box height={455}>
          <List dense disablePadding>
            <ListItem>
              <ListItemText
                primary={getLocalizedString('first_name')}
                secondary={user.first_name}
              />
            </ListItem>
            <ListItem>
              <ListItemText primary={getLocalizedString('last_name')} secondary={user.last_name} />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={getLocalizedString('email')}
                secondary={user.email}
                style={{ overflowWrap: 'break-word' }}
              />
            </ListItem>
            <ListItem>
              <ListItemText primary={getLocalizedString('language')} secondary={user.language} />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={getLocalizedString('email_verified_at')}
                secondary={<LuxonDate date={user.email_verified_at} fallback='-' />}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={getLocalizedString('has_password')}
                secondary={<CheckIcon condition={user.has_password} />}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={getLocalizedString('last_activity_at')}
                secondary={<LuxonDate date={user.last_activity_at} fallback='-' />}
              />
            </ListItem>
          </List>
        </Box>
      </CardContent>
    </Card>
  );
};

export default CardUserDetails;
