import React from 'react';

import { AppBar, Toolbar, makeStyles, AppBarProps, Box } from '@material-ui/core';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';

import LanguageSelector from 'layouts/components/LanguageSelector';
import TopBarTitle from 'layouts/components/TopBarTitle';

const useStyles = makeStyles({
  root: {},
  toolbar: {
    height: 64,
  },
});

const MainLayoutTopBar: React.FC<AppBarProps> = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <AppBar className={clsx(classes.root, className)} elevation={0} {...rest}>
      <Toolbar className={classes.toolbar}>
        <RouterLink to='/'>
          <TopBarTitle />
        </RouterLink>
        <Box flexGrow={1} />
        <>
          <LanguageSelector />
        </>
      </Toolbar>
    </AppBar>
  );
};

export default MainLayoutTopBar;
