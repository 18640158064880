import React from 'react';

import { Box, Card, CardHeader } from '@material-ui/core';
import { Clipboard as ClipboardIcon } from 'react-feather';

import PaginatedServerDataGrid from 'containers/PaginatedServerDataGrid';
import { IPaginated, LoadedProfessionalUser } from 'core/backend';
import { IProfessionalFull } from 'core/professional';
import { IUser } from 'core/user';
import useFetch from 'hooks/useFetch';
import { useLocalization } from 'services/localization/localization';
import {
  userProfessionalsTableColumns,
  userProfessionalsTableColumnsWithoutHomeUsage,
} from 'services/tables/userDetail/userProfessionalsTable';

interface IProps {
  user: IUser;
}

const CardUserProfessionals: React.FC<IProps> = ({ user }) => {
  const request = useFetch<IPaginated<IProfessionalFull & LoadedProfessionalUser>>(
    `/users/${user.id}/professionals`,
  );

  const { getLocalizedString } = useLocalization();

  const data = request.data?.data || [];

  const hasPatient = data.some((professional) => professional.professional_user.role === 'patient');

  const columns = hasPatient
    ? userProfessionalsTableColumns
    : userProfessionalsTableColumnsWithoutHomeUsage;

  return (
    <Card>
      <CardHeader
        avatar={<ClipboardIcon size={25} />}
        titleTypographyProps={{
          color: 'textPrimary',
          variant: 'h5',
        }}
        title={getLocalizedString('associated_practices')}
        subheader={getLocalizedString('associated_practices_description', {
          model: getLocalizedString('user').toLowerCase(),
        })}
      />
      <Box height={455}>
        <PaginatedServerDataGrid
          request={request}
          columns={columns}
          components={{ Toolbar: undefined }}
        />
      </Box>
    </Card>
  );
};

export default CardUserProfessionals;
