import { Patient } from 'core/professionalUser';
import { isPlaceholderEmail } from 'core/user';
import { useReduceptForm } from 'hooks/useReduceptForm';
import { useLocalization } from 'services/localization/localization';

import { FormComponent } from '.';
import BaseForm from './BaseForm';
import ServerSelect, { ServerSelectValue } from './elements/ServerSelect';

export type ILoginUserOnDeviceFormValues = {
  patient: ServerSelectValue<Patient>;
};

interface IProps {
  professionalId: string | number;
}

const LoginUserOnDeviceForm: FormComponent<ILoginUserOnDeviceFormValues, IProps> = ({
  onSubmit,
  defaultValues,
  professionalId,
}) => {
  const form = useReduceptForm<ILoginUserOnDeviceFormValues>(onSubmit, {
    defaultValues,
    mode: 'onChange',
  });

  const { control } = form;

  const { getLocalizedString } = useLocalization();

  return (
    <BaseForm form={form}>
      <ServerSelect<Patient>
        label='Patient'
        controllerProps={{
          control,
          name: 'patient',
          rules: {
            required: getLocalizedString('required'),
          },
          defaultValue: null,
        }}
        autocompleteProps={{
          getOptionLabel: (user) =>
            `${user.name}${isPlaceholderEmail(user.email) ? '' : ` (${user.email})`}`,
        }}
        url={`/professionals/${professionalId}/patients`}
        filter={{ column: 'name' }}
        helperText={getLocalizedString('login_patient_on_device')}
        fullWidth
        margin='normal'
        variant='outlined'
      />
    </BaseForm>
  );
};

export default LoginUserOnDeviceForm;
