import React from 'react';

import { Grid } from '@material-ui/core';
import { Edit2 as EditIcon, Trash as DeleteIcon } from 'react-feather';
import { RouteComponentProps } from 'react-router-dom';

import Button from 'components/Button';
import DetailPage from 'components/DetailPage';
import LinkButton from 'components/LinkButton';
import useStateOrFetch from 'hooks/useStateOrFetch';
import { useLocalization } from 'services/localization/localization';
import { DeviceRouteParams, history, routeCreator, routes } from 'services/routing';
import { DeviceTableRowData } from 'services/tables/devicesTable';

import ErrorPage from 'pages/ErrorPage';
import LoadingPage from 'pages/LoadingPage';

import CardDeviceDetails from './components/CardDeviceDetails';
import CardDeviceLoginInformation from './components/CardDeviceLoginInformation';
import CardDeviceSpecification from './components/CardDeviceSpecification';
import DeleteDeviceDialog from './components/DeleteDeviceDialog';

type FetchResponse = DeviceTableRowData;
type Props = RouteComponentProps<DeviceRouteParams, any, FetchResponse | undefined>;

const DeviceDetail: React.FC<Props> = (props) => {
  const { deviceId } = props.match.params;
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const { getLocalizedString } = useLocalization();

  const request = useStateOrFetch<FetchResponse | null>(
    props.location.state,
    `/devices/${deviceId}`,
    { includes: ['user', 'professional', 'playerAccount'] },
  );

  if (request.loading && !request.data) {
    return <LoadingPage />;
  }

  if (!request.data) {
    return <ErrorPage />;
  }

  const handleDeviceDeleted = (): void => {
    /** @todo add Snackbar notification */
    alert(getLocalizedString('delete_device_success'));

    setDeleteDialogOpen(false);

    // Go back if there is a way back, otherwise go to the home page
    if (history.action !== 'POP') {
      history.goBack();
    } else {
      history.push(routes.Dashboard);
    }
  };

  const device = request.data as FetchResponse;

  const actions = (
    <>
      <Button
        translationKey='delete'
        onClick={() => setDeleteDialogOpen(true)}
        disabled={!device}
        color='default'
        variant='contained'
        startIcon={<DeleteIcon size={18} />}
        style={{ marginLeft: 4, marginRight: 4 }}
      >
        Delete
      </Button>
      <LinkButton
        translationKey='edit'
        to={routeCreator.EditDevice(deviceId)}
        state={device}
        startIcon={<EditIcon size={18} />}
        color='primary'
        variant='contained'
        style={{ marginLeft: 4 }}
      >
        Edit
      </LinkButton>
    </>
  );

  return (
    <>
      <DetailPage title={device.name} actions={actions}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4} lg={4} xl={4}>
            <CardDeviceDetails device={device} />
          </Grid>
          <Grid item xs={12} md={4} lg={4} xl={4}>
            <CardDeviceSpecification device={device} />
          </Grid>
          <Grid item xs={12} md={4} lg={4} xl={4}>
            <CardDeviceLoginInformation device={device} />
          </Grid>
        </Grid>
      </DetailPage>
      <DeleteDeviceDialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onDeviceDeleted={handleDeviceDeleted}
        deviceId={deviceId}
      />
    </>
  );
};

export default DeviceDetail;
