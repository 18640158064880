import React from 'react';

import axios from 'axios';
import { useRendersCount } from 'react-use';

import useRequest, { UseRequestCallback, UseRequestHook, UseRequestOptions } from './useRequest';

/**
 * A single sort option.
 */
export interface UseFetchSortOption {
  column: string;
  direction: 'asc' | 'desc';
}

export interface UseFetchFilterOption {
  column: string;
  value: string;
  operator?: 'gt' | 'gte' | 'lt' | 'lte' | 'not' | 'in' | 'eq';
}

export type IncludeOption =
  | 'subscriptions'
  | 'professional'
  | 'professionals'
  | 'professionalsCount'
  | 'user'
  | 'users'
  | 'usersCount'
  | 'organization'
  | 'professionalGroupUsers'
  | 'professionalGroupUsersCount'
  | 'playerAccount'
  | 'groups'
  | 'creator'
  | 'teamleaderSubscriptionsCount'
  | string;

/**
 * Additional options specifically for the useFetch hook.
 */
export interface UseFetchOptions {
  /**
   * Pagination options. If these are provided, the URL will be modified to
   * add query parameters based on the JSON API specification. An example of
   * what would be added is ?page[number]=2&page[size]=50.
   */
  pagination?: {
    number: number | string;
    size: number | string;
  };

  sorting?: UseFetchSortOption[];

  filtering?: UseFetchFilterOption[];

  includes?: IncludeOption[];

  /**
   * Implies that the request should be fired upon mounting the component. The
   * default configuration of the request will be used.
   */
  fireOnMount: boolean;

  /**
   * Implies that the request should not be fired. This is specifically useful
   * when you want to conditionally fire the requests.
   */
  ignore: boolean;
}

type UseFetchCallback<D> = (options?: Partial<UseFetchOptions>) => UseRequestCallback<D, never>;

/**
 * The returned value from the useFetch hook. This is the same as the
 * underlying useRequest hook, but also adds an 'ignored' boolean in case the
 * request was not fired.
 */
export type UseFetchHook<D, E> = Omit<UseRequestHook<D, E, never>, 'request'> & {
  ignored: boolean;
  request: UseFetchCallback<D>;
  options: UseFetchOptions;
  setOptions: (options: Partial<UseFetchOptions>) => void;
};

/**
 * The default options that will be used with a useFetch hook.
 */
export const defaultFetchOptions: UseFetchOptions = {
  pagination: undefined,
  sorting: undefined,
  includes: undefined,
  fireOnMount: true,
  ignore: false,
};

/**
 * Hook for performing GET requests. This hook typically fetches a source
 * when a component is mounted. Adds additional options for building up
 * frequently used querystrings such as pagination or filtering.
 *
 * @template D The data to be received from the response.
 * @template E The data of the error to be received from a bad request.
 *
 * @returns {UseFetchHook<D, E>}
 */
const useFetch = <D, E = never>(
  url: string,
  fetchOptions?: Partial<UseFetchOptions>,
  useRequestOptions?: Partial<UseRequestOptions<D>>,
): UseFetchHook<D, E> => {
  const renderCount = useRendersCount();

  const [options, setOptions] = React.useState<UseFetchOptions>({
    ...defaultFetchOptions,
    ...fetchOptions,
  });

  const { ignore, fireOnMount } = options;

  /**
   * Appends pagination parameters to the URL if necessary.
   *
   * @param {string} url
   *   The URL to append pagination options to.
   * @param {UseFetchOptions<D>['pagination']} pagination
   *   The pagination options to apply.
   *
   * @return {string}
   *   The string with optionally appended pagination options.
   */
  const appendQuerystring = (url: string, opts: Partial<UseFetchOptions>) => {
    const params = new URLSearchParams();
    const { pagination, sorting, filtering, includes } = opts;

    if (pagination) {
      params.set('page[number]', String(pagination.number));
      params.set('page[size]', String(pagination.size));
    }

    if (sorting !== undefined && sorting.length) {
      const value = sorting
        .map((sort) => `${sort.direction === 'desc' ? '-' : ''}${sort.column}`.trim())
        .join(',');

      params.set('sort', value);
    }

    if (filtering !== undefined && filtering.length) {
      for (const filter of filtering) {
        let name = `filter[${filter.column}]`;

        if (filter.operator) {
          name += `[${filter.operator}]`;
        }

        params.set(name, filter.value);
      }
    }

    if (includes !== undefined) {
      params.set('include', includes.join(','));
    }

    if (Array.from(params).length > 0) {
      url = `${url}?${params.toString()}`;
    }

    return url;
  };

  const requestHook = useRequest<D, E>(
    {
      method: 'GET',
      url: appendQuerystring(url, options),
    },
    useRequestOptions,
  );

  /**
   * Reduces the current options to use for a request.
   *
   * @param {Partial<UseFetchOptions>} options
   *   The new options to reduce.
   */
  const reduceOptions = (options: Partial<UseFetchOptions>) =>
    setOptions((state) => ({
      ...state,
      ...options,
    }));

  /**
   * The 'useRequest' callback but wrapped so it's possible to provide
   * useFetch options too.
   *
   * @param {UseFetchOptions<D>} opts
   *   Options to add to the request.
   *
   * @return {UseRequestCallback<D>}
   *   Callback to trigger the useRequest callback.
   */
  const doRequest: UseFetchCallback<D> = (opts = options) => (config) =>
    requestHook.request({
      ...config,
      method: 'GET',
      url: appendQuerystring(url, opts),
    });

  // The request will be fired upon mounting the component, and will re-render
  // when options such as pagination are changed.
  React.useEffect(() => {
    if (ignore) {
      return;
    }

    if (renderCount === 1 && !fireOnMount) {
      return;
    }

    doRequest(options)().catch((error) => {
      // Ignore errors that were thrown due to cancellation of the request.
      if (!axios.isCancel(error)) {
        throw error;
      }
    });
  }, [
    options.pagination?.number,
    options.pagination?.size,
    JSON.stringify(options.filtering),
    options.sorting,
    options.includes,
    url,
  ]);

  // Each time the fetch options are modified as parameters in this hook, they
  // are reduced in the options state so that a re-fetch is done.
  React.useEffect(() => {
    reduceOptions(typeof fetchOptions === 'undefined' ? defaultFetchOptions : fetchOptions);
  }, [JSON.stringify(fetchOptions)]);

  return {
    ...requestHook,
    request: doRequest,
    ignored: ignore,
    options,
    setOptions: reduceOptions,
  };
};

export default useFetch;
