import React from 'react';
import { Card, Table, TableBody, TableCell, TableHead, Typography } from '@material-ui/core';
import { Redirect, useParams } from 'react-router-dom';
import { useAsync, useAsyncFn } from 'react-use';
import DetailPage from 'components/DetailPage';
import { IPaginated, LoadedSubscription } from 'core/backend';
import { IProfessionalFull } from 'core/professional';
import {
  ITeamleaderCompany,
  ITeamleaderSubscription,
  LoadedTeamleaderProducts,
} from 'core/teamleader';
import httpClient from 'services/http';
import { routes } from 'services/routing';
import LoadingPage from 'pages/LoadingPage';
import AssignSubscriptionRow from './AssignSubscriptionRow';

type FetchedTeamleaderCompany = ITeamleaderCompany & {
  teamleader_subscriptions: Array<
    ITeamleaderSubscription & LoadedSubscription & LoadedTeamleaderProducts
  >;
};

const fetchDependencies = (teamleaderCompanyId: string) => async () => {
  const companyRes = await httpClient.get<FetchedTeamleaderCompany>(
    `/teamleader/companies/${teamleaderCompanyId}?include=teamleaderSubscriptions.subscription,teamleaderSubscriptions.teamleaderProducts`,
  );

  if (companyRes.data.organization_id === null) {
    throw new Error('Teamleader company is not attached to a partner');
  }

  const professionalsRes = await httpClient.get<IPaginated<IProfessionalFull>>(
    `/organizations/${companyRes.data.organization_id}/professionals`,
  );

  return {
    teamleaderCompany: companyRes.data,
    professionals: professionalsRes.data.data,
  };
};

const TeamleaderCompanyManageOrganizationSubscriptions: React.FC = () => {
  const { teamleaderCompanyId } = useParams<{ teamleaderCompanyId: string }>();
  const [{ loading, error, value }, fetch] = useAsyncFn(fetchDependencies(teamleaderCompanyId));
  useAsync(fetch, [teamleaderCompanyId]);

  if (loading || (!loading && value === undefined && error === undefined)) {
    return <LoadingPage />;
  }

  if (error || value === undefined) {
    return <Redirect to={routes.TeamleaderOrganizations.path} />;
  }

  const { professionals, teamleaderCompany } = value;

  return (
    <DetailPage maxWidth='md' title='Attach subscriptions to partner'>
      <Card>
        <Typography color='textPrimary'>
          Manage subscriptions of partner:&nbsp;
          <a target='_blank' href={teamleaderCompany.website_url} rel='noreferrer'>
            {teamleaderCompany.name}
          </a>
        </Typography>
      </Card>

      <Card>
        <Table>
          <TableHead>
            <TableCell>Subscription name in Teamleader</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Product</TableCell>
            <TableCell>Related practice</TableCell>
          </TableHead>

          <TableBody>
            {teamleaderCompany.teamleader_subscriptions.map((teamleaderSubscription) => (
              <AssignSubscriptionRow
                key={teamleaderSubscription.id}
                teamleaderSubscription={teamleaderSubscription}
                options={professionals}
                onAssignSuccess={fetch}
              />
            ))}
          </TableBody>
        </Table>
      </Card>
    </DetailPage>
  );
};
export default TeamleaderCompanyManageOrganizationSubscriptions;
