import { Link } from 'react-router-dom';

import Button from 'components/Button';
import LinkButton from 'components/LinkButton';
import httpClient from 'services/http';
import { routeCreator } from 'services/routing';
import { cellRenderer, GridColumn, renderDateCell } from 'utils/mui';
import { TeamleaderProductOverviewData } from 'pages/teamleader/TeamleaderProductsOverview';

type TableData = TeamleaderProductOverviewData;

export const teamleaderProductsTable = (onChange: () => void): GridColumn<TableData>[] => [
  {
    field: 'name',
    headerName: 'Name',
    flex: 1,
    renderCell: cellRenderer<TableData>((row) => (
      <a target='_blank' href={row.website_url} rel='noreferrer'>
        {row.name}
      </a>
    )),
  },
  {
    field: 'code',
    headerName: 'ID',
    flex: 0.5,
  },
  {
    field: 'teamleader_subscriptions_count',
    headerName: 'Subscriptions',
    flex: 0.75,
  },
  {
    field: 'product_id',
    headerName: 'Reducept product',
    flex: 0.75,
    renderCell: cellRenderer<TableData>(({ id, product, name }) => {
      if (product !== null) {
        return <Link to={routeCreator.ProductDetail(product.id)}>{product.internal_name}</Link>;
      }

      const handleClick: React.MouseEventHandler<HTMLButtonElement> = async () => {
        if (
          !window.confirm(
            `Ignoring this teamleader product will hide it from the dashboard. This is useful for products that are not used for subscriptions. Are you sure that you want to hide ${name}?`,
          )
        ) {
          return;
        }

        try {
          await httpClient.post(`/teamleader/products/${id}/ignore`);
          await onChange();
        } catch (error) {
          window.alert(`Failed to hide this product: ${error}`);
        }
      };

      return (
        <>
          <LinkButton
            to={routeCreator.TeamleaderProductAttach(id)}
            color='primary'
            size='small'
            variant='contained'
          >
            Attach product
          </LinkButton>
          <>
            &nbsp;or&nbsp;
            <Button color='secondary' variant='contained' onClick={handleClick} size='small'>
              Hide
            </Button>
          </>
        </>
      );
    }),
  },
  {
    field: 'added_at',
    headerName: 'Added at',
    flex: 0.4,
    renderCell: renderDateCell(),
  },
  {
    field: 'synced_at',
    headerName: 'Synced at',
    flex: 0.4,
    renderCell: renderDateCell(),
  },
];
