import React from 'react';

import { GridCellParams, GridColDef } from '@material-ui/data-grid';

import CheckIcon from 'components/CheckIcon';

interface IProps {
  params: GridCellParams;
}

const DataGridCheckCell: React.FC<IProps> = ({ params }) => {
  return <CheckIcon condition={Boolean(params.value)} />;
};

/**
 * Convenience function for Material UI's 'renderCell' option.
 *
 * @param {GridCellParams} params
 *   Grid cell parameters
 */
export const renderDataGridCheckCell: GridColDef['renderCell'] = (params) => (
  <CheckIcon condition={Boolean(params.value)} />
);

export default DataGridCheckCell;
