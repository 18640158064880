import { Dialog, DialogProps, DialogTitle } from '@material-ui/core';
import { SubmitHandler } from 'react-hook-form';

import CommentForm, { IFormValues } from 'components/forms/comments/CommentForm';
import { IPaginated } from 'core/backend';
import { IComment } from 'core/comment';
import useRequest from 'hooks/useRequest';
import { useLocalization } from 'services/localization/localization';

interface IProps {
  endpointUrl: string;
  onSubmitSuccess: (response: IPaginated<IComment>) => void;
}

export type AddCommentDialogProps = IProps & Pick<DialogProps, 'open' | 'onClose'>;

const AddCommentDialog: React.FC<AddCommentDialogProps> = (props) => {
  const { getLocalizedString } = useLocalization();

  const postCommentRequest = useRequest<IPaginated<IComment>, never, IFormValues>({
    url: props.endpointUrl,
    method: 'POST',
  });

  const handleSubmit: SubmitHandler<IFormValues> = async ({ comment }) => {
    try {
      const response = await postCommentRequest.request({
        data: {
          comment: comment,
        },
      });

      props.onSubmitSuccess(response.data);
    } catch (error) {
      /**
       * @todo - Add a pop-up to show the error to users.
       */
      console.error(getLocalizedString('error_message'), error);
    }
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby='form-dialog-title'
      fullWidth
      maxWidth='sm'
    >
      <DialogTitle id='form-dialog-title'>
        {getLocalizedString('add-model', {
          model: getLocalizedString('comment').toLowerCase(),
        })}
      </DialogTitle>
      <CommentForm onSubmit={handleSubmit} />
    </Dialog>
  );
};

export default AddCommentDialog;
