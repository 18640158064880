import { GridColDef } from '@material-ui/data-grid';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';

import { renderDataGridCheckCell } from 'components/mui/DataGrid/DataGridCheckCell';
import { LoadedProfessional, LoadedUser } from 'core/backend';
import {
  IEcurringSubscription,
  IStripeSubscription,
  ISubscription,
  paymentProviderTypes,
  subscriptionIsSyncable,
} from 'core/subscription';
import { useLocalization } from 'services/localization/localization';
import { routeCreator } from 'services/routing';
import { DateCell, GridColumn, renderDateCell } from 'utils/mui';

export type SubscriptionsOverviewRowData = ISubscription & LoadedUser & LoadedProfessional;

/**
 * @param {GridCellParams} params
 *   Grid cell parameters.
 */
const RenderSubscriptionType: GridColDef['renderCell'] = (params) => {
  const { getLocalizedString } = useLocalization();
  const row = params.row as SubscriptionsOverviewRowData;

  return (
    <Link to={{ pathname: routeCreator.SubscriptionDetail(row.id), state: row }}>
      {getLocalizedString(row.provider_type)}
    </Link>
  );
};

/**
 * @param {GridCellParams} params
 *   Grid cell parameters.
 */
const renderUserCell: GridColDef['renderCell'] = (params) => {
  const row = params.row as SubscriptionsOverviewRowData;

  if (row.user === null) {
    return <>-</>;
  }

  return (
    <Link to={{ pathname: routeCreator.UserDetail(row.user.id), state: row.user }}>
      {row.user.name}
    </Link>
  );
};

/**
 * @param {GridCellParams} params
 *   Grid cell parameters.
 */
const renderProfessionalCell: GridColDef['renderCell'] = (params) => {
  const row = params.row as SubscriptionsOverviewRowData;

  if (row.professional === null) {
    return <>-</>;
  }

  return (
    <Link
      to={{
        pathname: routeCreator.ProfessionalDetail(row.professional.id),
        state: row.professional,
      }}
    >
      {row.professional.name}
    </Link>
  );
};

/**
 * @param {GridCellParams} params
 *   Grid cell parameters.
 */
const RenderStatusCell: GridColDef['renderCell'] = (params) => {
  const { getLocalizedString } = useLocalization();

  const row = params.row as SubscriptionsOverviewRowData;

  if (!paymentProviderTypes.includes(row.provider_type)) {
    return <>-</>;
  }

  const provider = row.provider as IStripeSubscription | IEcurringSubscription;

  return <>{getLocalizedString(provider.status)}</>;
};

/**
 * @param {GridCellParams} params
 *   Grid cell parameters.
 */
const renderSyncAtCell: GridColDef['renderCell'] = (params) => {
  const row = params.row as SubscriptionsOverviewRowData;

  if (!subscriptionIsSyncable(row)) {
    return <>-</>;
  }

  return (
    <DateCell format={DateTime.DATETIME_MED} value={row.provider.synced_at} fallback='Never' />
  );
};

export const subscriptionsOverviewTableColumns: GridColumn<SubscriptionsOverviewRowData>[] = [
  {
    field: 'id',
    headerName: '#',
    filterable: false,
    flex: 0.25,
  },
  {
    field: 'provider_type',
    headerName: 'Type',
    flex: 0.5,
    renderCell: RenderSubscriptionType,
  },
  {
    field: 'active',
    headerName: 'Active',
    filterable: false,
    flex: 0.5,
    renderCell: renderDataGridCheckCell,
  },
  {
    field: 'status',
    headerName: 'External status',
    description: 'The status of this subscription in the payment provider',
    filterable: false,
    sortable: false,
    flex: 0.5,
    renderCell: RenderStatusCell,
  },
  {
    field: 'start_date',
    headerName: 'Start date',
    sortable: false,
    filterable: false,
    flex: 0.75,
    renderCell: renderDateCell(DateTime.DATE_MED, '-'),
  },
  {
    field: 'end_date',
    headerName: 'End date',
    sortable: false,
    filterable: false,
    description: 'Subscriptions that have ended or are cancelled have an ending date.',
    flex: 1,
    renderCell: renderDateCell(DateTime.DATETIME_MED, '-'),
  },
  {
    field: 'user_id',
    headerName: 'User',
    description: 'The user that instantiated the subscription',
    filterable: false,
    sortable: false,
    renderCell: renderUserCell,
    flex: 1,
  },
  {
    field: 'practice_id',
    headerName: 'Practice',
    description: 'The practice that is affected by the subscription',
    renderCell: renderProfessionalCell,
    filterable: false,
    sortable: false,
    flex: 1.35,
  },
  {
    field: 'synced_at',
    headerName: 'Last synchronization',
    description:
      'The last time that a synchronization was done with the external payment provider. This is only available for Stripe and eCurring subscriptions',
    sortable: false,
    filterable: false,
    flex: 0.5,
    renderCell: renderSyncAtCell,
  },
  {
    field: 'updated_at',
    headerName: 'Updated',
    flex: 0.75,
    renderCell: renderDateCell(),
    filterable: false,
  },
];
