import React from 'react';

import { Box, Card, CardHeader } from '@material-ui/core';
import { Smartphone as SmartphoneIcon } from 'react-feather';

import PaginatedServerDataGrid from 'containers/PaginatedServerDataGrid';
import { IPaginated, LoadedUser } from 'core/backend';
import { IProfessionalAccessPeriod, LoadedCreator } from 'core/professionalAccessPeriod';
import useFetch from 'hooks/useFetch';
import { useLocalization } from 'services/localization/localization';
import { professionalAccessPeriodsTableColumns } from 'services/tables/professionalDetail/professionalAccessPeriodsTable';

interface IProps {
  professionalId: number | string;
}

type FetchResponse = IProfessionalAccessPeriod & LoadedUser & LoadedCreator;

const CardProfessionalAccessPeriods: React.FC<IProps> = (props) => {
  const request = useFetch<IPaginated<FetchResponse>>(
    `/professionals/${props.professionalId}/access-periods`,
    { includes: ['user', 'creator'] },
  );

  const { getLocalizedString } = useLocalization();

  return (
    <Card>
      <CardHeader
        avatar={<SmartphoneIcon size={25} />}
        titleTypographyProps={{
          color: 'textPrimary',
          variant: 'h5',
        }}
        title={getLocalizedString('access_periods')}
        subheader={getLocalizedString('access_periods_used_by', {
          model: getLocalizedString('practice').toLowerCase(),
        })}
      />
      <Box height={455}>
        <PaginatedServerDataGrid
          request={request}
          columns={professionalAccessPeriodsTableColumns}
          components={{ Toolbar: undefined }}
        />
      </Box>
    </Card>
  );
};

export default CardProfessionalAccessPeriods;
