import React from 'react';

import { GridFilterInputValueProps, GridFilterOperator } from '@material-ui/data-grid';
import { useDebounce } from 'react-use';

import TextField from 'components/mui/TextField';
import { useLocalization } from 'services/localization/localization';

const StringInput: GridFilterOperator['InputComponent'] = (props: GridFilterInputValueProps) => {
  const { item, applyValue } = props;

  const { getLocalizedString } = useLocalization();
  const [search, setSearch] = React.useState<string>(props.item.value ?? '');

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  useDebounce(
    () => {
      applyValue({ ...item, value: search });
    },
    500,
    [search],
  );

  return (
    <TextField
      placeholder={getLocalizedString('filter_value')}
      value={search}
      onChange={handleFilterChange}
      translationKey='filter_value'
      size='small'
      variant='outlined'
      style={{ marginTop: 10, marginLeft: 10 }}
    />
  );
};

export default StringInput;
