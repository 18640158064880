const colors = {
  primary: '#514395',
  secondary: '#6F6DD0',
  graph: {
    primary: '#514395',
    secondary: '#6F6DD0',
    tertiary: '#28d1c3',
    quaternary: '#000',
  },
};

export default colors;
