import React from 'react';
import { UserPlus } from 'react-feather';
import { RouteComponentProps, useParams } from 'react-router-dom';
import DataGridOverviewPage from 'components/DataGridOverviewPage';
import LinkButton from 'components/LinkButton';
import PaginatedServerDataGrid from 'containers/PaginatedServerDataGrid';
import { IPaginated } from 'core/backend';
import useFetch from 'hooks/useFetch';
import useProfessionalLicenses from 'hooks/useProfessionalLicenses';
import useRequest from 'hooks/useRequest';
import { useLocalization } from 'services/localization/localization';
import { ProfessionalRouteParams, routeCreator } from 'services/routing';
import { ProfessionalInviteTableRowData } from 'services/tables/professionalInvitesTable';
import {
  IProfessionalProfessionalInvitesTableColumnProps,
  professionalProfessionalInvitesTableColumns,
} from 'services/tables/professionalProfessionalInvitesTable';
import { isAxiosError } from 'utils/guard';

const ProfessionalProfessionalInvitesOverview: React.FC = () => {
  const { professionalId } = useParams<ProfessionalRouteParams>();
  const { noLicensesLeft } = useProfessionalLicenses(professionalId);

  const professionalInvitesRequest = useFetch<IPaginated<ProfessionalInviteTableRowData>>(
    `/professionals/${professionalId}/professional-invites`,
    { includes: ['creator'] },
  );
  const resendProfessionalInviteRequest = useRequest<never, unknown, never>({
    method: 'POST',
  });

  /**
   * Resend an invite to the user.
   */
  const handleInviteResend: IProfessionalProfessionalInvitesTableColumnProps['onInviteResend'] = async (
    professionalInviteId,
  ) => {
    try {
      await resendProfessionalInviteRequest.request({
        url: `professionals/${professionalId}/professional-invites/${professionalInviteId}/resend`,
      });
      professionalInvitesRequest.request()();
    } catch (error) {
      if (isAxiosError<{ message: string }>(error)) {
        const { message } = error.response!.data;

        if (typeof message === 'string') {
          alert(message);
        }

        console.error(message);
      }

      throw error;
    }
  };

  const { getLocalizedString } = useLocalization();

  const actions = (
    <>
      <LinkButton
        style={{ marginLeft: 4, marginRight: 4, whiteSpace: 'nowrap' }}
        translationKey='invite-staff-button'
        to={routeCreator.InviteStaff(professionalId)}
        color='secondary'
        variant='contained'
        startIcon={<UserPlus size={20} />}
      >
        Invite staff
      </LinkButton>
      <LinkButton
        disabled={noLicensesLeft}
        translationKey='invite-patient-button'
        to={routeCreator.InvitePatient(professionalId)}
        color='secondary'
        variant='contained'
        startIcon={<UserPlus size={20} />}
      >
        Invite patient
      </LinkButton>
    </>
  );

  return (
    <DataGridOverviewPage
      title={getLocalizedString('open_professional_invites')}
      subtitle={`${professionalInvitesRequest.data?.meta.total || 0}`}
      actions={actions}
    >
      <PaginatedServerDataGrid
        request={professionalInvitesRequest}
        searchable
        columns={professionalProfessionalInvitesTableColumns({
          onInviteResend: handleInviteResend,
        })}
        componentsProps={{
          toolbar: {
            searchPlaceholder: getLocalizedString('search-model', {
              model: getLocalizedString('professional_invites').toLowerCase(),
            }),
          },
        }}
      />
    </DataGridOverviewPage>
  );
};

export default ProfessionalProfessionalInvitesOverview;
