import React from 'react';

import { SubmitHandler } from 'react-hook-form';
import { RouteComponentProps } from 'react-router-dom';

import DetailPage from 'components/DetailPage';
import UserForm, { UserFormValues } from 'components/forms/users/UserForm';
import { IUser } from 'core/user';
import useRequest from 'hooks/useRequest';
import { useLocalization } from 'services/localization/localization';
import { routeCreator } from 'services/routing';

type PostResponse = IUser;

const CreateUser: React.FC<RouteComponentProps> = (props) => {
  const postRequest = useRequest<PostResponse, never, UserFormValues>({
    url: `/users`,
    method: 'POST',
  });

  const { getLocalizedString } = useLocalization();

  const handleSubmit: SubmitHandler<UserFormValues> = async (values) => {
    const response = await postRequest.request({
      data: values,
    });

    props.history.push(routeCreator.UserDetail(response.data.id), response.data);
  };

  return (
    <DetailPage
      title={getLocalizedString('add-model', { model: getLocalizedString('user').toLowerCase() })}
      maxWidth='xs'
    >
      <UserForm onSubmit={handleSubmit} />
    </DetailPage>
  );
};

export default CreateUser;
