import React from 'react';

import { Box, Card, colors, Container, makeStyles, Typography } from '@material-ui/core';
import { RouteComponentProps } from 'react-router-dom';

import Page from 'components/Page';
import { useLocalization } from 'services/localization/localization';
import { userSelector } from 'store/session/sessionSelectors';
import { useTypedSelector } from 'store/store';
import { darkBackground } from 'theme/theme';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: darkBackground,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  card: {
    padding: theme.spacing(6),
    borderWidth: 8,
    borderColor: colors.common.white,
    hoverBorderColor: colors.common.white,
  },
}));

const Dashboard: React.FC<RouteComponentProps> = (_) => {
  const classes = useStyles();
  const { getLocalizedString } = useLocalization();

  const user = useTypedSelector(userSelector);

  return (
    <Page className={classes.root} title='Dashboard - Reducept'>
      <Box display='flex' flexDirection='column' height='100%'>
        <Container maxWidth='sm'>
          <Card className={classes.card}>
            <Typography color='textPrimary' variant='h2'>
              {getLocalizedString('dashboard_welcome', { username: user.name ?? 'Reducept user' })}!
            </Typography>
          </Card>
        </Container>
      </Box>
    </Page>
  );
};

export default Dashboard;
