import { GridColDef } from '@material-ui/data-grid';
import { Link } from 'react-router-dom';

import DataGridCheckCell from 'components/mui/DataGrid/DataGridCheckCell';
import { routeCreator } from 'services/routing';
import { GridColumn, renderDateCell } from 'utils/mui';

import { ProfessionalTableRowData } from '../professionalsTable';

/**
 * The type of data in each table row.
 */
export type ProfessionalSubscriptionTableDataRow = ProfessionalTableRowData;

/**
 * @param {GridCellParams} params
 *   Grid cell parameters.
 */
const renderNameCell: GridColDef['renderCell'] = (params) => {
  const row = params.row as ProfessionalSubscriptionTableDataRow;

  return (
    <Link
      to={{
        pathname: routeCreator.ProfessionalDetail(row.id),
        state: row,
      }}
    >
      {row.name}
    </Link>
  );
};

/**
 * @param {GridCellParams} params
 *   Grid cell parameters.
 */
const renderActiveCell: GridColDef['renderCell'] = (params) => {
  const row = params.row as ProfessionalSubscriptionTableDataRow;

  return <DataGridCheckCell params={{ ...params, value: row.active_subscription_id !== null }} />;
};

export const organizationProfessionalsTableColumns: GridColumn<ProfessionalSubscriptionTableDataRow>[] = [
  {
    field: 'name',
    headerName: 'Name',
    flex: 1,
    renderCell: renderNameCell,
    sortable: false,
    filterable: false,
  },
  {
    field: 'has_active_subscription',
    headerName: 'Active',
    flex: 0.5,
    renderCell: renderActiveCell,
    sortable: false,
    filterable: false,
  },
  {
    field: 'created_at',
    headerName: 'Created at',
    flex: 0.75,
    renderCell: renderDateCell(),
    sortable: false,
    filterable: false,
  },
  {
    field: 'updated_at',
    headerName: 'Updated at',
    flex: 0.75,
    renderCell: renderDateCell(),
    sortable: false,
    filterable: false,
  },
];
