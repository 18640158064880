import { FormComponent } from 'components/forms';
import TextField from 'components/mui/TextField';
import { useReduceptForm } from 'hooks/useReduceptForm';

import { useLocalization } from 'services/localization/localization';
import BaseForm from '../BaseForm';

export interface IFormValues {
  comment: string;
}

const CommentForm: FormComponent<IFormValues> = ({ defaultValues, onSubmit }) => {
  const form = useReduceptForm<IFormValues>(onSubmit, { defaultValues });
  const {
    register,
    formState: { touched, errors },
  } = form;

  const { getLocalizedString } = useLocalization();

  return (
    <BaseForm form={form}>
      <TextField
        error={Boolean(touched.comment && errors.comment)}
        fullWidth
        helperText={touched.comment && errors.comment?.message}
        label='Comment'
        margin='normal'
        translationKey='comment'
        inputRef={register({ required: getLocalizedString('required') })}
        type='text'
        variant='outlined'
      />
    </BaseForm>
  );
};

export default CommentForm;
