import React from 'react';

import { LinearProgress } from '@material-ui/core';
import { Redirect, Route, RouteProps } from 'react-router';

import { ProtectedRoute, routes } from 'services/routing';
import {
  loggedInSelector,
  matchRoles,
  onInitialLoadSelector,
} from 'store/session/sessionSelectors';
import { useTypedSelector } from 'store/store';

type Props = RouteProps & Partial<ProtectedRoute>;

const PrivateRoute: React.FC<Props> = ({ roles = [], component: Component, ...props }) => {
  const appState = useTypedSelector((state) => state);
  const loggedIn = useTypedSelector(loggedInSelector);
  const onInitialLoad = useTypedSelector(onInitialLoadSelector);

  if (onInitialLoad) {
    return <LinearProgress style={{ height: 0.5 }} />;
  }

  if (!loggedIn) {
    return <Redirect to={routes.Login} />;
  }

  if (!Component) {
    return null;
  }

  return (
    <Route
      {...props}
      render={(routeProps) => {
        if (roles.length === 0 || matchRoles(roles)(appState)) {
          return <Component {...routeProps} />;
        }

        return <Redirect to={routes.Dashboard} />;
      }}
    />
  );
};

export default PrivateRoute;
