import React from 'react';

import { Plus as PlusIcon } from 'react-feather';
import { RouteComponentProps } from 'react-router-dom';

import DataGridOverviewPage from 'components/DataGridOverviewPage';
import LinkButton from 'components/LinkButton';
import PaginatedServerDataGrid from 'containers/PaginatedServerDataGrid';
import { IPaginated } from 'core/backend';
import useFetch from 'hooks/useFetch';
import { useLocalization } from 'services/localization/localization';
import { defaultPaginationOptions } from 'services/pagination';
import { routes } from 'services/routing';
import {
  SubscriptionsOverviewRowData,
  subscriptionsOverviewTableColumns,
} from 'services/tables/subscriptionsTable';

const SubscriptionsOverview: React.FC<RouteComponentProps> = (_) => {
  const request = useFetch<IPaginated<SubscriptionsOverviewRowData>>('/subscriptions', {
    pagination: defaultPaginationOptions,
    includes: ['professional', 'user'],
  });

  const { getLocalizedString } = useLocalization();

  const actions = (
    <>
      <LinkButton
        translationKey='add'
        to={routes.CreateSubscription.path}
        color='primary'
        variant='contained'
        startIcon={<PlusIcon size={20} />}
      >
        Add subscription
      </LinkButton>
    </>
  );

  return (
    <DataGridOverviewPage
      title={getLocalizedString('subscriptions')}
      subtitle={`${request.data?.meta.total || 0}`}
      actions={actions}
    >
      <PaginatedServerDataGrid
        request={request}
        columns={subscriptionsOverviewTableColumns}
        componentsProps={{
          toolbar: {
            searchPlaceholder: getLocalizedString('search-model', {
              model: getLocalizedString('subscription_type').toLowerCase(),
            }),
            searchColumnField: 'provider_type',
          },
        }}
      />
    </DataGridOverviewPage>
  );
};

export default SubscriptionsOverview;
