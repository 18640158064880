import React from 'react';

import { Plus as PlusIcon } from 'react-feather';
import { RouteComponentProps } from 'react-router-dom';

import DataGridOverviewPage from 'components/DataGridOverviewPage';
import LinkButton from 'components/LinkButton';
import PaginatedServerDataGrid from 'containers/PaginatedServerDataGrid';
import { IPaginated } from 'core/backend';
import useFetch from 'hooks/useFetch';
import { useLocalization } from 'services/localization/localization';
import { defaultPaginationOptions } from 'services/pagination';
import { routes } from 'services/routing';
import {
  professionalsTableColumns,
  ProfessionalTableRowData,
} from 'services/tables/professionalsTable';

const ProfessionalsOverview: React.FC<RouteComponentProps> = (_) => {
  const request = useFetch<IPaginated<ProfessionalTableRowData>>('/professionals', {
    pagination: defaultPaginationOptions,
    includes: ['usersCount', 'organization'],
  });

  const { getLocalizedString } = useLocalization();

  const actions = (
    <>
      <LinkButton
        translationKey='add'
        to={routes.CreateProfessional.path}
        color='primary'
        variant='contained'
        startIcon={<PlusIcon size={20} />}
      >
        Add professional
      </LinkButton>
    </>
  );

  return (
    <DataGridOverviewPage
      title={getLocalizedString('practices')}
      subtitle={`${request.data?.meta.total || 0}`}
      actions={actions}
    >
      <PaginatedServerDataGrid
        request={request}
        searchable
        columns={professionalsTableColumns}
        componentsProps={{
          toolbar: {
            searchPlaceholder: getLocalizedString('search-model', {
              model: getLocalizedString('practices').toLowerCase(),
            }),
          },
        }}
      />
    </DataGridOverviewPage>
  );
};

export default ProfessionalsOverview;
