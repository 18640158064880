import React from 'react';

import { Box, Card, CardContent, CardHeader, Divider, List, ListItem } from '@material-ui/core';
import { ShoppingBag as ShoppingBagIcon } from 'react-feather';
import { Link } from 'react-router-dom';

import CheckIcon from 'components/CheckIcon';
import LuxonDate from 'components/LuxonDate';
import ListItemText from 'components/mui/ListItemText';
import { useLocalization } from 'services/localization/localization';
import { routeCreator } from 'services/routing';
import { SubscriptionsOverviewRowData } from 'services/tables/subscriptionsTable';

interface IProps {
  subscription: SubscriptionsOverviewRowData;
}

const CardSubscriptionDetails: React.FC<IProps> = ({ subscription }) => {
  const { getLocalizedString } = useLocalization();
  return (
    <Card>
      <CardHeader
        avatar={<ShoppingBagIcon size={25} />}
        titleTypographyProps={{
          color: 'textPrimary',
          variant: 'h5',
        }}
        title={getLocalizedString('information')}
        subheader={getLocalizedString('general-data', {
          model: getLocalizedString('subscription').toLowerCase(),
        })}
      />
      <Divider />
      <CardContent>
        <Box height={550} position='relative'>
          <List dense disablePadding>
            {subscription.user && (
              <ListItem>
                <ListItemText
                  primary={getLocalizedString('subscription_holder')}
                  secondary={
                    <Link
                      to={{
                        pathname: routeCreator.UserDetail(subscription.user!.id),
                        state: subscription.user,
                      }}
                    >
                      {subscription.user!.name}
                    </Link>
                  }
                />
              </ListItem>
            )}
            <ListItem>
              <ListItemText
                primary={getLocalizedString('associated_practice')}
                secondary={
                  subscription.professional === null ? (
                    '-'
                  ) : (
                    <Link
                      to={{
                        pathname: routeCreator.ProfessionalDetail(subscription.professional.id),
                        state: subscription.professional,
                      }}
                    >
                      {subscription.professional.name}
                    </Link>
                  )
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={getLocalizedString('active')}
                secondary={<CheckIcon condition={subscription.active} />}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={getLocalizedString('provider_type')}
                secondary={getLocalizedString(subscription.provider_type)}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={getLocalizedString('start_date')}
                secondary={<LuxonDate date={subscription.start_date} fallback='-' />}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={getLocalizedString('end_date')}
                secondary={<LuxonDate date={subscription.end_date} fallback='-' />}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={getLocalizedString('updated_at')}
                secondary={<LuxonDate date={subscription.updated_at} />}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={getLocalizedString('created_at')}
                secondary={<LuxonDate date={subscription.created_at} />}
              />
            </ListItem>
          </List>
        </Box>
      </CardContent>
      <Divider />
    </Card>
  );
};

export default CardSubscriptionDetails;
