import React, { useEffect } from 'react';

import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles,
} from '@material-ui/core';
import {
  BarChart as BarChartIcon,
  Lock as LockIcon,
  ShoppingBag as ShoppingBagIcon,
  Users as UsersIcon,
  Clipboard as ClipboardIcon,
  Activity as ActivityIcon,
  Folder as FolderIcon,
  Smartphone as SmartphoneIcon,
  UserPlus as ExtraUserIcon,
} from 'react-feather';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import RoleBoundary from 'components/RoleBoundary';
import { useLocalization } from 'services/localization/localization';
import { Role } from 'services/roles';
import { routeCreator, routes } from 'services/routing';
import { matchRoles, userSelector } from 'store/session/sessionSelectors';
import { useTypedSelector } from 'store/store';

import ProfessionalInformation from './components/ProfessionalInformation';
import NavItem from './NavItem';

const admininistratorItems = [
  {
    href: routes.Users.path,
    icon: UsersIcon,
    title: 'Users',
    name: 'users',
  },
  {
    href: routes.Professionals.path,
    icon: ClipboardIcon,
    title: 'Practices',
    name: 'professionals',
  },
  {
    href: routes.Organizations.path,
    icon: FolderIcon,
    title: 'Partners',
    name: 'organizations',
  },
  {
    href: routes.PlayerAccounts.path,
    icon: ActivityIcon,
    title: 'Player accounts',
    name: 'player_accounts',
  },
  {
    href: routes.Devices.path,
    icon: SmartphoneIcon,
    title: 'Devices',
    name: 'devices',
  },
  {
    href: routes.Subscriptions.path,
    icon: ShoppingBagIcon,
    title: 'Subscriptions',
    name: 'subscriptions',
  },
  {
    href: routes.Roles.path,
    icon: LockIcon,
    title: 'Roles',
    name: 'roles',
  },
  {
    href: routes.Administrators.path,
    icon: UsersIcon,
    title: 'Administrators',
    name: 'administrators',
  },
  {
    href: routes.Products.path,
    icon: ShoppingBagIcon,
    title: 'Products',
    name: 'products',
  },
  {
    href: routes.ProfessionalInvites.path,
    icon: ExtraUserIcon,
    title: 'Invites',
    name: 'invites',
  },
];

const teamleaderItems = [
  {
    href: routes.TeamleaderStatus.path,
    icon: LockIcon,
    title: 'Status',
    name: 'status',
  },
  {
    href: routes.TeamleaderProducts.path,
    icon: ShoppingBagIcon,
    title: 'Products',
    name: 'products',
  },
  {
    href: routes.TeamleaderCompanies.path,
    icon: ClipboardIcon,
    title: 'Companies',
    name: 'companies',
  },
  {
    href: routes.TeamleaderOrganizations.path,
    icon: FolderIcon,
    title: 'Partners',
    name: 'partners',
  },
  {
    href: routes.TeamleaderSubscriptions.path,
    icon: ShoppingBagIcon,
    title: 'Subscriptions',
    name: 'subscriptions',
  },
];

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 192,
  },
  desktopDrawer: {
    width: 192,
    top: 64,
    height: 'calc(100% - 64px)',
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64,
  },
}));

interface IProps {
  onMobileClose(): void;
  openMobile: boolean;
}

const NavBar: React.FC<IProps> = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const user = useTypedSelector(userSelector);
  const professionalScope = useTypedSelector((state) => state.session.professionalScope);
  const isTherapistOrOwner = useTypedSelector(matchRoles([Role.Therapist, Role.Owner]));
  const { getLocalizedString } = useLocalization();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  let items = [
    {
      href: routes.Dashboard,
      icon: BarChartIcon,
      title: 'Dashboard',
      name: 'dashboard',
    },
  ];

  if (isTherapistOrOwner && professionalScope !== null) {
    items.push(
      {
        href: routeCreator.ProfessionalPatients(professionalScope),
        icon: UsersIcon,
        title: 'Patients',
        name: 'patients',
      },
      {
        href: routeCreator.ProfessionalProfessionalGroups(professionalScope),
        icon: UsersIcon,
        title: 'Groups',
        name: 'groups',
      },
      {
        href: routeCreator.ProfessionalDevices(professionalScope),
        icon: SmartphoneIcon,
        title: 'Devices',
        name: 'devices',
      },
      {
        href: routeCreator.ProfessionalStaff(professionalScope),
        icon: UsersIcon,
        title: 'Staff',
        name: 'staff',
      },
      {
        href: routeCreator.ProfessionalProfessionalInvites(professionalScope),
        icon: ExtraUserIcon,
        title: 'Open invites',
        name: 'open_professional_invites',
      },
    );
  }

  const content = (
    <Box height='100%' display='flex' flexDirection='column'>
      <Box alignItems='center' display='flex' flexDirection='column' p={2}>
        <Avatar
          className={classes.avatar}
          component={RouterLink}
          to={routeCreator.UserDetail(user.id)}
        />
        <Box mt={1}>
          <Typography color='textPrimary' variant='h5' align='center'>
            {user.name}
          </Typography>
          <RoleBoundary allowed={[Role.Therapist, Role.Owner]}>
            <ProfessionalInformation />
          </RoleBoundary>
        </Box>
      </Box>

      <RoleBoundary allowed={[Role.Therapist, Role.Owner]}>
        <Divider />

        <Box p={2}>
          <List>
            {items.map((item) => (
              <NavItem
                href={item.href}
                key={item.name}
                translationKey={item.name}
                title={item.title}
                icon={item.icon}
              />
            ))}
          </List>
        </Box>
      </RoleBoundary>

      <RoleBoundary allowed={[Role.Admin]}>
        <Divider />

        <Box alignItems='center' display='flex' flexDirection='column' p={2} pb={1}>
          <Typography color='textPrimary' variant='h4'>
            {getLocalizedString('administration')}
          </Typography>
        </Box>

        <Box p={2} pt={0}>
          <List>
            {admininistratorItems.map((item) => (
              <NavItem
                href={item.href as string}
                key={item.name}
                translationKey={item.name}
                title={item.title}
                icon={item.icon}
              />
            ))}
          </List>
        </Box>

        <Divider />

        <Box alignItems='center' display='flex' flexDirection='column' p={2} pb={1}>
          <Typography color='textPrimary' variant='h5'>
            Teamleader
          </Typography>
        </Box>

        <Box p={2} pt={0}>
          <List>
            {teamleaderItems.map((item) => (
              <NavItem
                href={item.href as string}
                key={item.name}
                translationKey={item.name}
                title={item.title}
                icon={item.icon}
              />
            ))}
          </List>
        </Box>
      </RoleBoundary>
    </Box>
  );

  return (
    <>
      <Hidden mdUp>
        <Drawer
          anchor='left'
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant='temporary'
        >
          {content}
        </Drawer>
      </Hidden>

      <Hidden smDown>
        <Drawer anchor='left' classes={{ paper: classes.desktopDrawer }} open variant='persistent'>
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

export default NavBar;
