import { Role } from 'services/roles';
import { isAdminSelector, matchRoles } from 'store/session/sessionSelectors';
import { useTypedSelector } from 'store/store';

import useFetch, { UseFetchHook } from './useFetch';
import { UseRequestOptions } from './useRequest';

export interface IUseProfessionalFetchProps {
  default: Parameters<typeof useFetch>;
  professional: (professionalId: number) => Parameters<typeof useFetch>;
}

const useProfessionalFetch = <D, E = never>(
  params: IUseProfessionalFetchProps,
): UseFetchHook<D, E> => {
  let configuration = params.default;

  const { isAdmin, isProfessional, professionalId } = useTypedSelector((state) => ({
    isAdmin: isAdminSelector(state),
    isProfessional: matchRoles([Role.Therapist, Role.Owner])(state),
    professionalId: state.session.professionalScope,
  }));

  if (!isAdmin && !isProfessional) {
    throw new Error('User is no administrator or professional');
  }

  if (isProfessional) {
    if (professionalId === null) {
      throw new Error("Failed to resolve the professional's id");
    }

    configuration = params.professional(professionalId);
  }

  const request = useFetch<D, E>(
    configuration[0],
    configuration[1],
    configuration[2] as Partial<UseRequestOptions<D>> | undefined,
  );

  return request;
};

export default useProfessionalFetch;
