import { LoadedOrganization, LoadedProfessional } from './backend';
import { Identifier, ISyncableEntity, Uuid } from './core';
import { IProduct } from './product';

/**
 * A teamleader user.
 */
export interface TeamleaderUser {
  id: string;
  email: string;
  function: string;
}

/**
 * Sync status per runner. Examples of runners are 'products' or 'subscriptions.
 * The value is a string-date, or null if the sync was not performed yet.
 */
export interface TeamleaderSyncStatus {
  results: Record<string, string | null>;
}

/**
 * Response after running a sync.
 */
export interface TeamleaderSyncResponse {
  results: Record<
    string,
    {
      deleted: Uuid[];
      synced: Uuid[];
    }
  >;
}

export interface TeamleaderRequestAuth {
  redirect_url: string;
}

interface BaseTeamleaderModel extends Identifier, ISyncableEntity {
  teamleader_id: Uuid;
  website_url: string;
}

export interface ITeamleaderProduct extends BaseTeamleaderModel {
  product_id: number | null;
  name: string;
  description: string;

  /**
   * Internal teamleader product code.
   */
  code: string;

  added_at: string;

  /**
   * Whether this product can be ignored. It can be ignored if the product
   * isn't used for subscriptions (like VR-headset rentals).
   */
  ignore: boolean;

  product: IProduct | null;
}

export interface ITeamleaderCompany
  extends BaseTeamleaderModel,
    LoadedProfessional,
    LoadedOrganization {
  professional_id: number;
  organization_id: number;
  name: string;
}

export const attachable = (company: ITeamleaderCompany): boolean =>
  company.organization_id === null && company.professional_id === null;

export interface ITeamleaderSubscription extends BaseTeamleaderModel {
  teamleader_company_id: number;
  title: string;
  status: string;
  starts_on: string;
  ends_on: string | null;
  teamleader_company: ITeamleaderCompany;
}

export interface LoadedTeamleaderProducts {
  teamleader_products: ITeamleaderProduct[];
}
