import React from 'react';

import { DateTime, DateTimeFormatOptions } from 'luxon';

interface IProps {
  date: string | null;
  fallback?: React.ReactNode;
  format?: DateTimeFormatOptions;
}

const LuxonDate: React.FC<IProps> = ({
  date,
  format = DateTime.DATETIME_MED_WITH_SECONDS,
  fallback,
}) => {
  if (date === null || typeof date === 'undefined') {
    return typeof fallback !== 'undefined' ? <>{fallback}</> : null;
  }

  const dt = DateTime.fromISO(date);

  if (dt.invalidReason !== null) {
    console.error('Could not parse a date with luxon: ', dt.invalidExplanation);
    return null;
  }

  return <>{dt.toLocaleString(format)}</>;
};

export default LuxonDate;
