import React from 'react';

import { Box, Card, CardContent, CardHeader, Divider, List, ListItem } from '@material-ui/core';
import { Layers as LayersIcon } from 'react-feather';

import ListItemText from 'components/mui/ListItemText';
import { useLocalization } from 'services/localization/localization';
import { SubscriptionsOverviewRowData } from 'services/tables/subscriptionsTable';
import { capitalizeFirstLetter } from 'utils/utils';

interface IProps {
  subscription: SubscriptionsOverviewRowData;
}

const CardSubscriptionLicenseInfo: React.FC<IProps> = ({ subscription }) => {
  const { getLocalizedString } = useLocalization();

  return (
    <Card>
      <CardHeader
        avatar={<LayersIcon size={25} />}
        titleTypographyProps={{
          color: 'textPrimary',
          variant: 'h5',
        }}
        title={getLocalizedString('licensing')}
        subheader={getLocalizedString('licensing_description')}
      />
      <Divider />
      <CardContent>
        <Box height={550} position='relative'>
          {subscription.professional_id === null ? (
            <Box display='flex' justifyContent='center' alignItems='center' minHeight='80%'>
              {getLocalizedString('licensing_only_professionals')}
            </Box>
          ) : (
            <List dense disablePadding>
              <ListItem>
                <ListItemText
                  primary={getLocalizedString('licenses_reset_interval')}
                  secondary={
                    subscription.licenses_reset_interval
                      ? capitalizeFirstLetter(
                          getLocalizedString(subscription.licenses_reset_interval),
                        )
                      : '-'
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={getLocalizedString('amount_licenses')}
                  secondary={subscription.amount_licenses}
                />
              </ListItem>
            </List>
          )}
        </Box>
      </CardContent>
      <Divider />
    </Card>
  );
};

export default CardSubscriptionLicenseInfo;
