import React from 'react';

import { Box, Card, CardHeader } from '@material-ui/core';
import { Smartphone as SmartphoneIcon } from 'react-feather';

import PaginatedServerDataGrid from 'containers/PaginatedServerDataGrid';
import { IPaginated } from 'core/backend';
import { ISubscription } from 'core/subscription';
import useFetch from 'hooks/useFetch';
import { useLocalization } from 'services/localization/localization';
import { professionalSubscriptionsTableColumns } from 'services/tables/professionalDetail/professionalSubscriptionsTable';

interface IProps {
  professionalId: number | string;
}
const CardProfessionalSubscriptions: React.FC<IProps> = (props) => {
  const request = useFetch<IPaginated<ISubscription>>(
    `/professionals/${props.professionalId}/subscriptions`,
  );

  const { getLocalizedString } = useLocalization();

  return (
    <Card>
      <CardHeader
        avatar={<SmartphoneIcon size={25} />}
        titleTypographyProps={{
          color: 'textPrimary',
          variant: 'h5',
        }}
        title={getLocalizedString('subscriptions')}
        subheader={getLocalizedString('subscriptions_for_model', {
          model: getLocalizedString('practice').toLowerCase(),
        })}
      />
      <Box height={625}>
        <PaginatedServerDataGrid
          request={request}
          columns={professionalSubscriptionsTableColumns}
          components={{ Toolbar: undefined }}
        />
      </Box>
    </Card>
  );
};

export default CardProfessionalSubscriptions;
