import React from 'react';

import DataGridOverviewPage from 'components/DataGridOverviewPage';
import PaginatedServerDataGrid from 'containers/PaginatedServerDataGrid';
import { IPaginated } from 'core/backend';
import { ITeamleaderProduct } from 'core/teamleader';
import useFetch from 'hooks/useFetch';
import { teamleaderCompaniesTable } from 'services/tables/teamleaderCompaniesTable';

const TeamleaderCompaniesOverview: React.FC = () => {
  const request = useFetch<IPaginated<ITeamleaderProduct>>('/teamleader/companies');

  return (
    <DataGridOverviewPage
      title='Teamleader companies'
      subtitle={`${request.data?.meta.total || 0}`}
    >
      <PaginatedServerDataGrid request={request} searchable columns={teamleaderCompaniesTable} />
    </DataGridOverviewPage>
  );
};

export default TeamleaderCompaniesOverview;
