import React from 'react';

import { makeStyles } from '@material-ui/core';
import { Check, X as CrossIcon } from 'react-feather';

const useStyles = makeStyles((_) => ({
  checkIcon: {
    color: 'green',
    opacity: 0.5,
  },
  crossIcon: {
    color: 'red',
    opacity: 0.3,
  },
}));

interface IProps {
  condition: boolean;
}

const CheckIcon: React.FC<IProps> = ({ condition }) => {
  const classes = useStyles();
  const Icon = condition ? Check : CrossIcon;

  return <Icon className={condition ? classes.checkIcon : classes.crossIcon} />;
};

export default CheckIcon;
