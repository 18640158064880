import { FormComponent } from 'components/forms';
import { isPlaceholderEmail, IUser } from 'core/user';
import { useReduceptForm } from 'hooks/useReduceptForm';

import BaseForm from '../BaseForm';
import ServerSelect from '../elements/ServerSelect';

export interface IFormValues {
  users: IUser[];
}

interface IProps {
  userApiUrl?: string;
}

const AdministratorForm: FormComponent<IFormValues, IProps> = ({
  defaultValues,
  onSubmit,
  userApiUrl = '/users',
}) => {
  const form = useReduceptForm<IFormValues>(onSubmit, { defaultValues });
  const { control } = form;

  return (
    <BaseForm form={form}>
      <ServerSelect<[IUser[]]>
        multiple
        label='users'
        controllerProps={{
          control,
          name: 'users',
          defaultValue: [],
        }}
        autocompleteProps={{
          style: { color: 'aqua' },
          getOptionLabel: (user) =>
            `${user['name']}${isPlaceholderEmail(user['email']) ? '' : ` (${user['email']})`}`,
        }}
        required
        url={userApiUrl}
        filter={{ column: 'name' }}
        fullWidth
        margin='normal'
        variant='outlined'
      />
    </BaseForm>
  );
};

export default AdministratorForm;
