import { FieldError } from 'react-hook-form';

import TextField from 'components/mui/TextField';
import { LoadedProfessionalUserFull } from 'core/backend';
import { IProfessionalGroup } from 'core/professionalGroup';
import { isPlaceholderEmail, IUser } from 'core/user';
import { useLocalization } from 'services/localization/localization';
import { emailValidationPattern } from 'utils/forms';

import { FormComponent } from '..';
import Checkbox from '../elements/Checkbox';
import ServerSelect, { ServerSelectValue } from '../elements/ServerSelect';
import BasePatientForm, { IBasePatientFormProps, PatientFormValues } from './BasePatientForm';

export interface IEditPatientFormValues extends PatientFormValues {
  email: IUser['email'];
  professional_groups: ServerSelectValue<IProfessionalGroup[]>;
  home_usage: boolean;
}

interface IProps {
  professionalId: string | number;
  initialValues: IUser & LoadedProfessionalUserFull;
}

const EditPatientForm: FormComponent<IEditPatientFormValues, IProps> = ({
  professionalId,
  initialValues,
  ...props
}) => {
  const { getLocalizedString } = useLocalization();

  let email: string | undefined = initialValues.email;
  // Unset the e-mail field of this user if it has a placeholder e-mail set.
  // This is done to stop auto-filling the input field with unimportant info
  // for therapists.
  if (typeof email !== 'undefined' && isPlaceholderEmail(email)) {
    email = undefined;
  }

  const defaultValues: Partial<IEditPatientFormValues> = {
    ...props.defaultValues,
    professional_groups: initialValues.professional_user.groups || [],
    email,
  };

  const renderInner: IBasePatientFormProps<IEditPatientFormValues>['children'] = (form) => {
    const {
      errors,
      control,
      watch,
      register,
      formState: { touched, submitCount },
    } = form;
    const emailValue = watch('email', '');

    return (
      <>
        <TextField
          error={Boolean((touched.email || submitCount > 0) && errors.email)}
          fullWidth
          helperText={(touched.email || submitCount > 0) && (errors.email as FieldError)?.message}
          label='E-mail'
          margin='normal'
          translationKey='email'
          inputRef={register({
            pattern: emailValidationPattern(getLocalizedString),
          })}
          InputProps={{
            readOnly: !isPlaceholderEmail(initialValues.email),
          }}
          type='email'
          variant={isPlaceholderEmail(initialValues.email) ? 'outlined' : 'filled'}
        />

        <ServerSelect<[IProfessionalGroup[]]>
          multiple
          error={Boolean(touched.professional_groups && errors.professional_groups)}
          label='Groups'
          controllerProps={{
            control,
            name: 'professional_groups',
            defaultValue: [],
          }}
          url={`/professionals/${professionalId}/groups`}
          filter={{ column: 'name' }}
          helperText={getLocalizedString('professional_groups_description')}
          fullWidth
          margin='normal'
          variant='outlined'
        />

        <Checkbox
          helperText={getLocalizedString('home_usage_description')}
          label='Home usage enabled'
          controllerProps={{
            control,
            name: 'home_usage',
          }}
          margin='dense'
          disabled={emailValue.length === 0 || typeof errors.email !== 'undefined'}
        />
      </>
    );
  };

  return (
    <BasePatientForm<IEditPatientFormValues> {...props} defaultValues={defaultValues}>
      {renderInner}
    </BasePatientForm>
  );
};

export default EditPatientForm;
