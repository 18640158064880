import React from 'react';
import { Tooltip } from '@material-ui/core';
import { GridCellParams, GridColDef } from '@material-ui/data-grid';
import { DateTime } from 'luxon';
import { Check, X } from 'react-feather';
import { Link } from 'react-router-dom';
import { useAsyncFn } from 'react-use';
import Button from 'components/Button';
import Localized from 'components/Localized';
import { IProfessionalInvite } from 'core/professional';
import { LoadedCreator } from 'core/professionalAccessPeriod';
import { routeCreator } from 'services/routing';
import { GridColumn } from 'utils/mui';
import { stringToDateTime } from 'utils/utils';
import { professionalInvitesTableColumns } from './professionalInvitesTable';

export type ProfessionalProfessionalInviteTableRowData = IProfessionalInvite & LoadedCreator;
export interface IProfessionalProfessionalInvitesTableColumnProps {
  onInviteResend(professionalInviteId: number): Promise<unknown>;
}
/**
 * @param {GridCellParams} params
 *   Grid cell parameters.
 */
const renderEmailCell: GridColDef['renderCell'] = (params) => {
  const row = params.row as ProfessionalProfessionalInviteTableRowData;

  if (row.user_id === null) {
    return <span>(deleted) {row.email}</span>;
  }

  return <span>{row.email}</span>;
};

/**
 * @param {GridCellParams} params
 *   Grid cell parameters.
 */
const RenderResendInviteCell = (
  params: GridCellParams,
  props: IProfessionalProfessionalInvitesTableColumnProps,
): React.ReactElement => {
  const [isSent, setIsSent] = React.useState(false);

  const row = params.row as ProfessionalProfessionalInviteTableRowData;

  const resendInviteEmail = async () => {
    setIsSent(true);
    return props.onInviteResend(row.id);
  };

  const [{ loading, value, error }, fetch] = useAsyncFn(resendInviteEmail, undefined, {
    value: null,
    loading: false,
  });

  let canSendAgain = !isSent;

  // Only allow sending a new invite every 5 minutes to prevent massive spamming
  if (!isSent && row.last_sent_at) {
    canSendAgain = DateTime.now().minus({ minutes: 5 }) > DateTime.fromISO(row.last_sent_at);
  }

  const tooltip = !canSendAgain ? (
    <Localized id='professional-invite__resend-not-allowed-tooltip'>
      Can only sent email once every 5 minutes
    </Localized>
  ) : (
    ''
  );

  return (
    <Tooltip enterDelay={300} title={tooltip}>
      <div>
        <Button
          translationKey='resend'
          color='primary'
          variant='contained'
          size='small'
          onClick={fetch}
          loading={loading}
          endIcon={error ? <X size={18} /> : value !== null ? <Check size={18} /> : null}
          disabled={!canSendAgain}
        >
          Resend
        </Button>
      </div>
    </Tooltip>
  );
};

/**
 * @param {GridCellParams} params
 *   Grid cell parameters.
 */
const renderCreatedByCell: GridColDef['renderCell'] = (params) => {
  const row = params.row as ProfessionalProfessionalInviteTableRowData;

  if (!row.creator) {
    return <span>-</span>;
  }

  const inner = (
    <Link
      to={{
        pathname: routeCreator.StaffDetail(row.professional_id, row.creator.id),
      }}
    >
      {row.creator.name}
    </Link>
  );

  return <span>{inner}</span>;
};

export const professionalProfessionalInvitesTableColumns = (
  props: IProfessionalProfessionalInvitesTableColumnProps,
): GridColumn<ProfessionalProfessionalInviteTableRowData>[] => [
  {
    field: 'invited_user_email',
    headerName: 'Email',
    flex: 0.75,
    renderCell: renderEmailCell,
    sortable: false,
    filterable: false,
  },
  professionalInvitesTableColumns.find(
    (column) => column.field === 'role',
  ) as GridColumn<ProfessionalProfessionalInviteTableRowData>,
  {
    field: 'resend_professional_invite',
    headerName: 'Resend invite',
    flex: 0.5,
    renderCell: (params) => RenderResendInviteCell(params, props),
    sortable: false,
    filterable: false,
  },
  professionalInvitesTableColumns.find(
    (column) => column.field === 'last_sent_at',
  ) as GridColumn<ProfessionalProfessionalInviteTableRowData>,
  {
    field: 'created_by',
    headerName: 'Creator',
    flex: 1,
    renderCell: renderCreatedByCell,
    sortable: false,
    filterable: false,
  },
  professionalInvitesTableColumns.find(
    (column) => column.field === 'created_at',
  ) as GridColumn<ProfessionalProfessionalInviteTableRowData>,
];
